/** @jsxImportSource @emotion/react */
import { Box, Slider, SliderProps } from '@mui/material'
import { prioritySliderContainer } from './PrioritySlider.styles'

export const PrioritySlider = ({ ...props }: SliderProps) => {
  return (
    <Box css={prioritySliderContainer()}>
      <p>Lower</p>
      <Slider
        {...props}
        marks={[
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
        ]}
        min={1}
        max={5}
        color="uintel"
      />
      <p>Higher</p>
    </Box>
  )
}
