/** @jsxImportSource @emotion/react */
import { Box, MenuItem, Select, useTheme } from '@mui/material'
import { FancySuspense } from '@src/components/Atoms/FancySuspense'
import { useMemo } from 'react'
import { elementExposureList, elementExposureRow } from './ExposureHistogram.styles'
import { usePrioritisationContext } from '@contexts/PrioritisationContext'

export const ExposureHistogram = () => {
  const {
    scenarioGroup,
    allScenarioGroups,
    setScenarioGroup,
    exposurePercIsLoading,
    exposurePercIsInitialized,
    exposurePercByElement,
  } = usePrioritisationContext()
  const theme = useTheme()

  const sortedElementExposure = useMemo(() => {
    return exposurePercByElement.sort((a, b) => b.percentage - a.percentage)
  }, [exposurePercByElement])
  const elementExposureRows = sortedElementExposure
    .toSorted((a, b) => a.element.localeCompare(b.element))
    .map((element) => {
      return (
        <Box
          css={elementExposureRow({
            index: sortedElementExposure.indexOf(element),
            percentage: element.percentage,
            theme,
          })}
          key={element.element}
        >
          <Box className="td" sx={{ minWidth: 200, maxWidth: 200 }}>
            {element.element}
          </Box>
          <Box className="td" sx={{ flexGrow: 1, fontSize: '10px' }}>
            <Box className="bar" />
            <Box className={`bar-label`}>
              {element.percentage}%{' '}
              <Box className="metrics">
                ({element.exposure}
                {element.unit})
              </Box>
            </Box>
          </Box>
        </Box>
      )
    })
  return (
    <>
      <FancySuspense isLoading={!(scenarioGroup && allScenarioGroups)} variant="box">
        {allScenarioGroups && scenarioGroup && (
          <Select
            css={{ width: '224px' }}
            value={scenarioGroup.scenario_id}
            onChange={(e) => {
              const scenarioGroup = allScenarioGroups.find(
                (group) => group.scenario_id === e.target.value,
              )
              if (scenarioGroup) setScenarioGroup(scenarioGroup)
            }}
            color="uintel"
          >
            {allScenarioGroups.map((sg) => (
              <MenuItem key={sg.scenario_id} value={sg.scenario_id}>
                {sg.scenario_id}
              </MenuItem>
            ))}
          </Select>
        )}
      </FancySuspense>
      <FancySuspense isLoading={!exposurePercIsInitialized} variant="horizontalBarChartInverse">
        <Box css={elementExposureList(exposurePercByElement.length, exposurePercIsLoading)}>
          {elementExposureRows}
        </Box>
      </FancySuspense>
    </>
  )
}
