import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface TabsContainerProps {
  theme: Theme
}

export const tabsContainer = ({ theme }: TabsContainerProps) => css`
  border: 1px solid ${theme.palette.grey[100]};
  border-radius: 4px;
  width: 100%;

  & .MuiTabs-scroller {
    display: flex;
    justify-content: space-between;
  }

  & .MuiTabs-flexContainer {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
  }
`

export const tabsMenuContainer = ({ theme, color }: { theme: Theme; color: string }) => css`
  border-bottom: 1px solid ${theme.palette.grey[100]};
  color: ${color};
  width: 100%;
  display: flex;
`

export const tabMenuTitle = ({ theme }: { theme: Theme }) => css`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  font-weight: 700;

  &[aria-selected='true'] {
    color: ${theme.palette.primary.main};
  }

  &[aria-selected='false'] {
    color: ${theme.palette.black[400]};
  }

  &[disabled] {
    color: ${theme.palette.grey[200]};
  }

  & * {
    & .MuiTabs-scrollableX {
      flex-grow: 0;
    }
  }
`

export const headerButtonsBox = () => css`
  display: flex;
  gap: 8px;
  padding: 8px 16px;
  margin-left: auto;
  align-items: center;
  align-self: flex-end;
  flex-grow: 0;
`

export const tabsMenu = () => css`
  flex-grow: 1;
`
