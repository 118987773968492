/** @jsxImportSource @emotion/react */

import { Box, css, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { BASE_API_URL } from '@src/app-constants'
import {
  layerDataHazardIdTransformer,
  parameterKeyTitleConverter,
  useLayerDataFetcher,
} from '@src/components/Molecules/RiskSideDrawerContent/data_fetchers/layerDataFetcher'
import axios from '@src/utils/customAxios'
import { Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { hazardBoxSLRSlider } from '../../../LayersTab/HazardControls/HazardControls.styles'
import {
  chartContainer,
  chartHeader,
  chartSection,
} from '../../../RiskSummaryTab/RiskContent/RiskContent.styles'
import {
  noAvailableProjectedRiskDataContainer,
  radioGroup,
  radioGroupContent,
  toggleGroup,
} from './ProjectedExposure.styles'
import { EmptySection } from '@src/components/Molecules/RiskSideDrawerContent/components/EmptySection'
import { Slider } from '@src/components/Molecules/Slider'
import {
  DataPoint,
  ProjectedExposureChart,
} from '@src/components/Molecules/Charts/ProjectedExposureChart/ProjectedExposureChart'
import { useDispatch } from 'react-redux'
import { setProjectedExposureChartState } from '@redux/projectedExposureChartSlice'
import { convertToMetersOrCentimeters } from '@src/utils/numbers.utils'
import { RadioGroup } from '@src/components/Atoms/RadioGroup'

interface ProjectedExposureProps {
  asset_id: number | undefined
  hazardLayer: MapLayer
}

// This likely goes against React's philosophy of dealing with state but after fighting
// many weird useEffect cascades I certify this as: good-enough.
type ProjectedExposureParameters = {
  primaryParameterKey: string
  secondaryParameter?: { key: string; value: string | number; availableValues: (number | string)[] }
}

export const ProjectedExposure = ({ asset_id, hazardLayer }: ProjectedExposureProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const layerData = useLayerDataFetcher()
  const transformedLayerData = layerDataHazardIdTransformer(layerData)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [projectedRiskData, setProjectedRiskData] = useState<DataPoint[] | null>(null)

  const [projectedRiskParameters, setProjectedRiskParameters] =
    useState<ProjectedExposureParameters | null>(null)

  const hazardGroupedByHazardId = useMemo(
    () => (hazardLayer.hazard_id ? transformedLayerData?.[hazardLayer.hazard_id] : null),
    [hazardLayer.hazard_id, transformedLayerData],
  )

  const parameterUnit = projectedRiskParameters?.primaryParameterKey
    ? `${
        (
          hazardGroupedByHazardId?.parameters[projectedRiskParameters.primaryParameterKey]?.unit ??
          ''
        ).trim()
          ? ` (${
              hazardGroupedByHazardId?.parameters[projectedRiskParameters.primaryParameterKey]?.unit
            })`
          : ''
      }`
    : ''

  const parameterList: string[] = useMemo(() => {
    //Gets a list of parameters that have numerical values and sorted by priority

    const parameterListWithNumericalValues = Object.keys(
      hazardGroupedByHazardId?.availableParameterValues ?? {},
    ).filter((key) => {
      const values = hazardGroupedByHazardId?.availableParameterValues[key].filter(
        (val) => !isNaN(parseFloat(`${val}`)),
      )
      return values && values.length > 1
    })

    const topTwoNumericalParameters =
      hazardGroupedByHazardId?.form
        .filter((formInput) => parameterListWithNumericalValues.includes(formInput.key))
        .sort((a, b) => a.priority - b.priority)
        .splice(0, 2)
        .map((formInput) => formInput.key) ?? []

    return topTwoNumericalParameters
  }, [hazardGroupedByHazardId?.availableParameterValues, hazardGroupedByHazardId?.form])

  const getProjectedRiskData = useCallback(
    async () => {
      if (
        !transformedLayerData ||
        !hazardGroupedByHazardId ||
        !projectedRiskParameters?.primaryParameterKey
      )
        return

      const filter_key_values = { ...hazardLayer.parameters }

      // As we want all possible values for the primary parameter this gets removed from the filters
      delete filter_key_values[projectedRiskParameters.primaryParameterKey]

      if (
        projectedRiskParameters.secondaryParameter &&
        projectedRiskParameters.secondaryParameter?.key !== null
      )
        filter_key_values[projectedRiskParameters.secondaryParameter.key] =
          projectedRiskParameters.secondaryParameter.value

      const url = `${BASE_API_URL}/asset/projected_exposure_for_selected_element/${asset_id}/${hazardLayer.hazard_id}/${projectedRiskParameters.primaryParameterKey}`

      try {
        const res = await axios.post(url, filter_key_values)

        return res.data
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error fetching projected risk data: ${e}`)
      } finally {
        setIsLoading(false)
        setIsFirstLoad(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectedRiskParameters?.primaryParameterKey,
      projectedRiskParameters?.secondaryParameter?.value,
      hazardLayer.hazard_id,
      asset_id,
    ],
  )

  const handleSecondaryParameterChange = (newSecondaryParameterValue: number) => {
    setIsLoading(true)

    setProjectedRiskParameters((old): ProjectedExposureParameters | null => {
      if (!old) return null
      if (!old.secondaryParameter) return old

      return {
        primaryParameterKey: old.primaryParameterKey,
        secondaryParameter: {
          key: old.secondaryParameter.key,
          value: newSecondaryParameterValue,
          availableValues: old.secondaryParameter.availableValues,
        },
      }
    })
  }

  const handleParameterChange = (newPrimaryParameterKey: string | null) => {
    setIsLoading(true)

    if (
      newPrimaryParameterKey == projectedRiskParameters?.primaryParameterKey ||
      !newPrimaryParameterKey ||
      !hazardGroupedByHazardId
    )
      return

    let newSecondaryParameterKey = ''
    if (parameterList[0] == newPrimaryParameterKey) newSecondaryParameterKey = parameterList[1]
    else newSecondaryParameterKey = parameterList[0]

    const primaryParameterValue =
      hazardLayer.parameters && hazardLayer.parameters[newPrimaryParameterKey]

    const parametersToFilter = {
      ...hazardLayer.parameters,
      [newPrimaryParameterKey]: primaryParameterValue,
    }

    delete parametersToFilter[newSecondaryParameterKey]

    const secondaryParameterAvailableValues = [
      ...new Set(
        hazardGroupedByHazardId.scenarios
          .filter((scenario) => {
            for (const skey in scenario.parameters) {
              if (skey == newSecondaryParameterKey) continue
              if (scenario.parameters[skey] !== parametersToFilter[skey]) return false
            }
            return true
          })
          .map((scenario) => scenario.parameters[newSecondaryParameterKey]),
      ),
    ]

    setProjectedRiskParameters({
      primaryParameterKey: newPrimaryParameterKey,
      secondaryParameter: {
        key: newSecondaryParameterKey,
        value: secondaryParameterAvailableValues[0],
        availableValues: secondaryParameterAvailableValues,
      },
    })
  }

  useEffect(
    () => {
      getProjectedRiskData().then((data) => {
        // If no data then data is an empty string so set
        // null explicitly
        if (data) {
          // For isolation layers all the exposure values are null
          // which makes for silly looking charts
          const allExposureValuesAreNull = data.every(
            (datum: DataPoint) => datum.exposure_value == null,
          )

          if (allExposureValuesAreNull) {
            setProjectedRiskData(null)
          } else {
            setProjectedRiskData(data)
          }
        } else setProjectedRiskData(null)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      projectedRiskParameters?.primaryParameterKey,
      projectedRiskParameters?.secondaryParameter?.value,
      asset_id,
    ],
  )

  useEffect(() => {
    if (projectedRiskParameters || !hazardGroupedByHazardId) return

    if (parameterList.length == 1) {
      setProjectedRiskParameters({ primaryParameterKey: parameterList[0] })
      return
    }

    const primaryParameterKey = parameterList[0]
    const secondaryParameterKey = parameterList[1]

    const primaryParameterValue =
      hazardLayer.parameters && hazardLayer.parameters[primaryParameterKey]

    const parametersToFilter = {
      ...hazardLayer.parameters,
      [primaryParameterKey]: primaryParameterValue,
    }

    delete parametersToFilter[secondaryParameterKey]

    const secondaryParameterAvailableValues = [
      ...new Set(
        hazardGroupedByHazardId.scenarios
          .filter((scenario) => {
            for (const skey in scenario.parameters) {
              if (skey == secondaryParameterKey) continue
              if (scenario.parameters[skey] !== parametersToFilter[skey]) return false
            }
            return true
          })
          .map((scenario) => scenario.parameters[secondaryParameterKey]),
      ),
    ].map((value) => parseFloat(`${value}`))

    setProjectedRiskParameters({
      primaryParameterKey,
      secondaryParameter: {
        key: secondaryParameterKey,
        value: secondaryParameterAvailableValues[0],
        availableValues: secondaryParameterAvailableValues,
      },
    })
  }, [hazardGroupedByHazardId, hazardLayer.parameters, parameterList, projectedRiskParameters])

  const secondaryParameterFormInput = hazardGroupedByHazardId?.form.find(
    (input) => input.key == projectedRiskParameters?.secondaryParameter?.key,
  )

  function formatValue(key: string | undefined, value: number | string | null | undefined): string {
    if (!key || value == null) return ''

    const parameter = hazardGroupedByHazardId?.parameters[key]
    const type = parameter?.type
    if (!type || !parameter) return ''

    switch (type) {
      case 'number':
        return convertToMetersOrCentimeters(+value, parameter.unit)
      case 'keyValue': {
        const keyValues = hazardGroupedByHazardId?.parameters[key]?.keyValues ?? {}

        return keyValues[value] ?? ''
      }
    }

    return `${value}`
  }

  const untrimmed_x_unit = projectedRiskParameters?.primaryParameterKey
    ? (hazardGroupedByHazardId?.parameters[projectedRiskParameters.primaryParameterKey]
        ?.unit as string)
    : null

  const x_unit = untrimmed_x_unit
    ? untrimmed_x_unit.trim()
    : projectedRiskParameters?.primaryParameterKey?.includes('aep')
    ? '%'
    : projectedRiskParameters?.primaryParameterKey?.includes('ari')
    ? ' year'
    : ''

  const parameterTitle = parameterKeyTitleConverter(
    projectedRiskParameters?.primaryParameterKey || '',
    layerData,
  )

  const y_unit = hazardGroupedByHazardId?.scenarios[0].tilesets[0].unit ?? null

  const fullParameterListCount = hazardGroupedByHazardId?.parameters
    ? Object.keys(hazardGroupedByHazardId?.parameters).length
    : 0

  useEffect(() => {
    if (!hazardLayer.hazard_id || !projectedRiskData || !projectedRiskData.length) return

    dispatch(
      setProjectedExposureChartState({
        hazard_id: hazardLayer.hazard_id,
        projectedExposureChartState: {
          data: projectedRiskData,
          parameter: projectedRiskParameters?.primaryParameterKey ?? '',
          parameterTitle,
          x_unit,
          y_unit,
        },
      }),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    asset_id,
    dispatch,
    hazardGroupedByHazardId,
    hazardLayer.hazard_id,
    layerData,
    parameterUnit,
    projectedRiskData,
    projectedRiskParameters?.primaryParameterKey,
    secondaryParameterFormInput,
    projectedRiskParameters?.secondaryParameter?.value,
  ])

  if (!parameterList.length)
    return (
      <Typography css={noAvailableProjectedRiskDataContainer({ theme })}>
        No projected risk data available for this scenario
      </Typography>
    )

  return (
    <Box sx={{ margin: theme.spacing(1), minWidth: '400px', width: '100%' }}>
      {isFirstLoad ? (
        <EmptySection isLoading={isLoading} title="Loading..." />
      ) : (
        <>
          <Box css={chartHeader({ theme })}>
            <Typography>Projected Risk</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {parameterList.length > 1 && (
              <>
                <Box
                  sx={{
                    paddingRight: theme.spacing(2),
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    margin: '4px 0px 12px 0px',
                  }}
                >
                  <Box css={radioGroup({ theme })}>
                    <Typography
                      sx={{
                        marginRight: theme.spacing(6),
                        fontSize: '12px',
                        color: '#0b2948',
                      }}
                    >
                      Parameter
                    </Typography>
                  </Box>
                  <ToggleButtonGroup
                    css={toggleGroup({ theme })}
                    value={projectedRiskParameters?.primaryParameterKey}
                    exclusive
                    size="small"
                    onChange={(_, value) => handleParameterChange(value)}
                  >
                    {parameterList.map((parameter) => {
                      return (
                        <ToggleButton key={parameter} value={parameter}>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#0b2948',
                            }}
                          >
                            {parameterKeyTitleConverter(parameter, layerData)}
                          </Typography>
                        </ToggleButton>
                      )
                    })}
                  </ToggleButtonGroup>
                </Box>

                <Box
                  css={[
                    radioGroup({ theme }),
                    css`
                      flex-grow: 1;
                      width: 50%;
                      flex-direction: column;
                      align-items: flex-start;
                    `,
                  ]}
                >
                  <Typography
                    sx={{
                      marginRight: theme.spacing(6),
                      fontSize: '12px',
                      color: '#0b2948',
                    }}
                  >
                    {secondaryParameterFormInput?.title}
                  </Typography>

                  {projectedRiskParameters?.secondaryParameter?.availableValues &&
                    projectedRiskParameters?.secondaryParameter?.availableValues?.length > 1 && (
                      <>
                        {(secondaryParameterFormInput?.type == 'slider' ||
                          secondaryParameterFormInput?.type == 'sealevelSlider') && (
                          <Box css={hazardBoxSLRSlider({ theme })}>
                            <Slider
                              key={`${secondaryParameterFormInput.key}-slider`}
                              value={parseFloat(
                                `${projectedRiskParameters.secondaryParameter.value ?? 0}`,
                              )}
                              handleValueChange={handleSecondaryParameterChange}
                              marks={projectedRiskParameters.secondaryParameter.availableValues.map(
                                (value) => {
                                  const parameter =
                                    hazardGroupedByHazardId?.parameters[
                                      secondaryParameterFormInput.key
                                    ]

                                  return {
                                    label: convertToMetersOrCentimeters(
                                      value as number,
                                      parameter?.unit,
                                    ),
                                    value: +value,
                                  }
                                },
                              )}
                              useTriangleTicks
                              minimalLabels
                              disabled={
                                projectedRiskParameters.secondaryParameter.availableValues.length ==
                                1
                              }
                              useOnChangeCommitted
                            />
                          </Box>
                        )}

                        {secondaryParameterFormInput?.type == 'radioGroup' && (
                          <RadioGroup
                            css={radioGroupContent({ theme })}
                            value={projectedRiskParameters.secondaryParameter.value ?? 0}
                            key={`${secondaryParameterFormInput.key}-radioGroup`}
                            onChange={(event) =>
                              handleSecondaryParameterChange(+event.target.value)
                            }
                            options={projectedRiskParameters.secondaryParameter.availableValues
                              .sort()
                              .map((value) => {
                                return {
                                  label: formatValue(secondaryParameterFormInput.key, value),
                                  value: +value,
                                  selected:
                                    projectedRiskParameters.secondaryParameter?.value == value,
                                }
                              })}
                          />
                        )}
                      </>
                    )}
                  {projectedRiskParameters?.secondaryParameter?.availableValues?.length == 1 && (
                    <Typography
                      sx={{
                        marginLeft: theme.spacing(2),
                        fontSize: '12px',
                        color: '#0b2948',
                      }}
                    >
                      {`• ${formatValue(
                        secondaryParameterFormInput?.key,
                        projectedRiskParameters.secondaryParameter.availableValues[0],
                      )}`}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Box>

          {projectedRiskData &&
            projectedRiskData.length > 0 &&
            projectedRiskParameters?.primaryParameterKey && (
              <Box css={chartSection({ theme })}>
                <Box
                  css={css`
                    ${chartContainer()}
                    min-height: 200px;
                  `}
                >
                  <Box sx={{ width: '100%' }}>
                    <ProjectedExposureChart
                      data={projectedRiskData}
                      parameter={projectedRiskParameters?.primaryParameterKey}
                      parameterTitle={parameterTitle}
                      x_unit={x_unit}
                      y_unit={y_unit}
                    />
                  </Box>
                </Box>
                {fullParameterListCount > 2 && (
                  <Typography variant="caption">
                    Some parameters have been automatically set based on scenario controls.
                  </Typography>
                )}
              </Box>
            )}
          {!isLoading && projectedRiskData && projectedRiskData.length == 0 && (
            <Box css={chartSection({ theme })}>
              <Box
                css={css`
                  ${chartContainer()}
                  min-height: 200px;
                  margin-left: 15px;
                  margin-right: 15px;
                `}
              >
                <EmptySection title="The selected element is not exposed under this combination" />
              </Box>
            </Box>
          )}
          {!projectedRiskData && (
            <Box css={chartSection({ theme })}>
              <Box
                css={css`
                  ${chartContainer()}
                  min-height: 200px;
                  margin-left: 15px;
                  margin-right: 15px;
                `}
              >
                <EmptySection title="No projected risk data available for this combination" />
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default ProjectedExposure
