/** @jsxImportSource @emotion/react */
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { Autocomplete, Box, Slider, TextField, Typography, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import axios from '@src/utils/customAxios'
import { BASE_API_URL } from '@src/app-constants'
import { useLayers } from '@contexts/LayerContext'
import { Button } from '@src/components/Atoms/Button'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { EmptySection } from '../../RiskSideDrawerContent/components/EmptySection'
import { layerDetailsHeader } from '../../RiskSideDrawerContent/tabs/InformativePanel/LayersTab/InfoAccordions/LayerInfo/LayerInfo.styles'
import { Icon } from '@src/components/Atoms/Icon'
import { elementFilterForm } from '../LayersControl.styles'

interface ElementFilterFormProps {
  layer: MapLayer
}

// example format of layer.filterable_information:
// "filterable_information": [
//   {
//     "key": "Orchard",
//     "Label": "Orchard",
//     "type": "text",
//     "lookup": true
//   },
//   {
//     "key": "Trees",
//     "Label": "Trees",
//     "type": "numeric"
//   }
// ],

export const ElementFilterForm = function ElementFilterForm({ layer }: ElementFilterFormProps) {
  const theme = useTheme()
  const {
    elementFilters,
    setElementFilters,
    filterKeyValues,
    setfilterKeyValues,
    filterKeyValuesCache,
    setFilterKeyValuesCache,
  } = useLayers()
  const [filterValues, setFilterValues] = useState<{ [key: string]: string | string[] }>({})
  const [prevAssetType, setPrevAssetType] = useState<string | null>(null)
  const [minMaxByFilter, setMinMaxByFilter] = useState<{ [key: string]: number[] | Dayjs[] }>({})
  const [isLoading, setIsLoading] = useState(false)
  const shouldFetch = useCallback(() => {
    if (!prevAssetType || prevAssetType === layer.type) return false
    setPrevAssetType(layer.type)
    return true
  }, [layer, prevAssetType])

  // used for setting the values when swapping tabs or other changes
  useEffect(() => {
    if (elementFilters[layer.type]) {
      setFilterValues(elementFilters[layer.type])
    } else {
      if (!filterKeyValues || !layer.filterable_information) return
      const filterKeyValuesWithRange = layer.filterable_information.filter(
        (info) => info.type === 'numeric' || info.type === 'date',
      )
      const newFilterValues = { ...filterValues }
      filterKeyValuesWithRange.forEach((info) => {
        if (!filterKeyValues[info.key]) return
        newFilterValues[info.key] = [filterKeyValues[info.key][0], filterKeyValues[info.key][1]]
      })
      setFilterValues((prev) => {
        return {
          ...prev,
          ...newFilterValues,
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementFilters, filterKeyValues])

  useEffect(() => {
    if (!layer.filterable_information || !shouldFetch) return
    setIsLoading(true)
    const abortController = new AbortController()

    const textFilters = layer.filterable_information.filter(
      (item) => item.type === 'text' && item.lookup,
    )
    const numericFilters = layer.filterable_information.filter((item) => item.type === 'numeric')
    const dateFilters = layer.filterable_information.filter((item) => item.type === 'date')

    const textQueryString = textFilters.length
      ? `text_keys=${textFilters.map((item) => item.key).join(',')}`
      : ''
    const numericQueryString = numericFilters.length
      ? `numeric_keys=${numericFilters.map((item) => item.key).join(',')}`
      : ''
    const dateQueryString = dateFilters.length
      ? `date_keys=${dateFilters.map((item) => item.key).join(',')}`
      : ''

    let totalQueryString = textQueryString ? `?${textQueryString}` : ''
    if (numericQueryString) {
      totalQueryString = totalQueryString
        ? `${totalQueryString}&${numericQueryString}`
        : `?${numericQueryString}`
    }
    if (dateQueryString) {
      totalQueryString = totalQueryString
        ? `${totalQueryString}&${dateQueryString}`
        : `?${dateQueryString}`
    }
    const urlSuffix = `${layer.type}${totalQueryString}`
    if (filterKeyValuesCache?.[urlSuffix]) {
      setfilterKeyValues(filterKeyValuesCache[urlSuffix])
      setIsLoading(false)
      return
    }
    const getfilterKeyValues = async () => {
      try {
        const res = await axios.get(`${BASE_API_URL}/asset/identifying_information/${urlSuffix}`, {
          signal: abortController.signal,
        })
        if (res.data) {
          setFilterKeyValuesCache((prev) => {
            return {
              ...prev,
              [urlSuffix]: res.data,
            }
          })
          setfilterKeyValues(res.data)
          setIsLoading(false)
        } else {
          setfilterKeyValues(null)
          setIsLoading(false)
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: Error | any) {
        if (e.code === 'ERR_CANCELED') return
        setfilterKeyValues(null)
        setIsLoading(false)
      }
    }
    getfilterKeyValues()

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer, shouldFetch])

  // set the range of the slider to the min and max values of the filterKeyValues by setting the formvalue to an array of the min and max values
  useEffect(() => {
    if (!filterKeyValues || !layer.filterable_information) return
    const filterKeyValuesWithRange = layer.filterable_information.filter(
      (info) => info.type === 'numeric' || info.type === 'date',
    )
    if (!filterKeyValuesWithRange) return
    // set the range of the slider to the min and max values of the filterKeyValues by setting the formvalue to an array of the min and max values
    const minMax = filterKeyValuesWithRange.reduce((acc, info) => {
      if (!filterKeyValues[info.key]) return acc
      if (info.type === 'numeric') {
        acc[info.key] = [
          parseFloat(filterKeyValues[info.key][0]),
          parseFloat(filterKeyValues[info.key][1]),
        ]
      } else if (info.type === 'date') {
        acc[info.key] = [dayjs(filterKeyValues[info.key][0]), dayjs(filterKeyValues[info.key][1])]
      }
      return acc
    }, {} as { [key: string]: number[] | Dayjs[] })
    setMinMaxByFilter(minMax)
  }, [filterKeyValues, layer])

  const clearFilters = () => {
    const filteredElementFilters = { ...elementFilters }
    delete filteredElementFilters[layer.type]
    setElementFilters(filteredElementFilters ?? {})
    setFilterValues({})
  }

  const updateFormDateValue = (key: string, value: string, index: number) => {
    const formattedValue = dayjs(value).format('YYYY-MM-DD')
    if (index === 0) {
      updateFormValue(key, [formattedValue, filterValues[key]?.[1]])
    } else if (index === 1) {
      updateFormValue(key, [filterValues[key]?.[0], formattedValue])
    }
  }

  const updateFormValue = (key: string, value: string | number[] | string[]) => {
    // typescript casting and checking for array ranges to be formatted as strings
    const formattedValue: string | string[] = Array.isArray(value)
      ? value.map((val) => `${val}`)
      : value
    setFilterValues((prev) => {
      const prevClone = { ...prev }
      if (key && !formattedValue) {
        delete prevClone[key]
        return prevClone
      }
      return {
        ...prevClone,
        [key]: formattedValue,
      }
    })
  }

  const applyFilters = () => {
    setElementFilters((prev) => {
      return {
        ...prev,
        [layer.type]: {
          ...filterValues,
        },
      }
    })
  }

  if (isLoading) {
    return <EmptySection isLoading={isLoading} title={'Loading...'} />
  }

  if (!layer.filterable_information || !filterKeyValues || !Object.keys(filterKeyValues).length)
    return null

  return (
    <Box css={elementFilterForm()}>
      <Box css={layerDetailsHeader({ theme })} sx={{ marginBottom: theme.spacing(1) }}>
        <Icon iconName={'s3://filter'} size="medium" colour={theme.palette.primary.main} />
        <h4>Filter by:</h4>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: theme.spacing(4),
          margin: theme.spacing(3),
        }}
      >
        {layer.filterable_information.map((filterable, index) => {
          return (
            <Box
              key={index}
              sx={{
                alignSelf: 'center',
                minWidth: '233.5px',
              }}
            >
              {filterable.type === 'text' && !filterable.lookup && (
                <TextField
                  type="text"
                  label={filterable.label}
                  value={filterValues[filterable.key] ?? ''}
                  onChange={(e) => updateFormValue(filterable.key, e.target.value)}
                />
              )}
              {/* has a min max like numeric */}
              {filterable.type === 'date' && filterValues[filterable.key] && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: theme.spacing(4),
                    width: '100%',
                  }}
                >
                  <DatePicker
                    label={`${filterable.label} after`}
                    value={dayjs(
                      filterValues[filterable.key]?.[0] ?? minMaxByFilter[filterable.key]?.[0],
                    )}
                    minDate={dayjs(minMaxByFilter[filterable.key]?.[0])}
                    maxDate={dayjs(filterValues[filterable.key]?.[1])}
                    onChange={(val) => updateFormDateValue(filterable.key, `${val}`, 0)}
                  />
                  <DatePicker
                    label={`${filterable.label} before`}
                    value={dayjs(
                      filterValues[filterable.key]?.[1] ?? minMaxByFilter[filterable.key]?.[1],
                    )}
                    minDate={dayjs(filterValues[filterable.key]?.[0])}
                    maxDate={dayjs(minMaxByFilter[filterable.key]?.[1])}
                    onChange={(val) => updateFormDateValue(filterable.key, `${val}`, 1)}
                  />
                </Box>
              )}

              {filterable.type === 'numeric' && filterValues[filterable.key] && (
                <Box
                  sx={{
                    width: '100%',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                  }}
                >
                  <Typography variant="subtitle2" sx={{ marginLeft: `-${theme.spacing(2)}` }}>
                    {filterable.label}
                  </Typography>
                  <Slider
                    value={[
                      parseFloat(filterValues?.[filterable.key]?.[0] ?? '0'),
                      parseFloat(filterValues?.[filterable.key]?.[1] ?? '1'),
                    ]}
                    min={(minMaxByFilter[filterable.key]?.[0] ?? 0) as number}
                    max={(minMaxByFilter[filterable.key]?.[1] ?? 1) as number}
                    onChange={(_, val: number | number[]) =>
                      updateFormValue(filterable.key, Array.isArray(val) ? val : [val])
                    }
                    step={1}
                    valueLabelDisplay="auto"
                  />
                </Box>
              )}
              {filterable.lookup && filterKeyValues && filterKeyValues[filterable.key] && (
                <Autocomplete
                  fullWidth
                  value={(filterValues[filterable.key] ?? '') as string}
                  onChange={(_, val) => {
                    updateFormValue(filterable.key, val as string)
                  }}
                  options={[...filterKeyValues[filterable.key], '']}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} label={filterable.label} />}
                />
              )}
            </Box>
          )
        })}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(4),
            justifyContent: 'flex-end',
            alignSelf: 'end',
            width: '100%',
          }}
        >
          <Button variant="text" onClick={() => clearFilters()}>
            Clear Filters
          </Button>
          <Button onClick={() => applyFilters()}>Apply</Button>
        </Box>
      </Box>
    </Box>
  )
}
