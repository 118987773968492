/** @jsxImportSource @emotion/react */
import { Box, IconButton, Modal, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useCallback, useEffect, useState } from 'react'
import axios from '@src/utils/customAxios'

import { Icon } from '../../../Atoms/Icon'
import {
  moreInformationModalContainer,
  moreInformationModalContent,
  moreInformationModalHeader,
  moreInformationModalNoData,
  moreInformationModalTitle,
} from './MoreInformationModal.styles'

interface HelpContent {
  title: string
  body: string
}

interface MoreInformationModalProps {
  baseURL: string
  isOpen: boolean
  helpFileName: string
  handleClose: () => void
}

export const MoreInformationModal = ({
  isOpen,
  helpFileName,
  handleClose,
  baseURL,
}: MoreInformationModalProps) => {
  const theme = useTheme()
  const [helpContent, setHelpContent] = useState<HelpContent>()
  const [isLoading, setIsLoading] = useState(false)

  const fetchHelpModal = useCallback(async () => {
    const response = await axios.get(`${baseURL}/help/${helpFileName}`)
    const data = await response.data
    return data
  }, [baseURL, helpFileName])

  useEffect(() => {
    const abortController = new AbortController()
    const fetchData = async () => {
      setIsLoading(true)

      try {
        const helpContent = await fetchHelpModal()
        setHelpContent(helpContent)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`error: `, error)
        return error
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()

    return () => {
      abortController.abort()
    }
  }, [fetchHelpModal])

  if (isLoading) {
    return (
      <Modal open={isOpen} onClose={handleClose}>
        <Box css={moreInformationModalContainer({ theme })}>
          <Box css={moreInformationModalHeader({ theme })}>
            <Box css={moreInformationModalTitle({ theme })}>
              <Icon iconName="Info" size="large" colour={theme.palette.text.primary} />
              <Typography variant="h6">Loading</Typography>
            </Box>
            <IconButton size="small" onClick={handleClose}>
              <Icon iconName="Close" size="large" colour={theme.palette.text.primary} />
            </IconButton>
          </Box>
          <Box css={moreInformationModalNoData({ theme })}>
            <Typography variant="body1" margin={6} align="center" fontWeight="700">
              Getting information data...
            </Typography>
          </Box>
        </Box>
      </Modal>
    )
  }

  if (!isLoading && helpContent === undefined) {
    return (
      <Modal open={isOpen} onClose={handleClose}>
        <Box css={moreInformationModalContainer({ theme })}>
          <Box css={moreInformationModalHeader({ theme })}>
            <Box css={moreInformationModalTitle({ theme })}>
              <Icon iconName="Info" size="large" colour={theme.palette.text.primary} />
              <Typography variant="h6">Error</Typography>
            </Box>
            <IconButton size="small" onClick={handleClose}>
              <Icon iconName="Close" size="large" colour={theme.palette.text.primary} />
            </IconButton>
          </Box>
          <Box css={moreInformationModalNoData({ theme })}>
            <Typography variant="body1" margin={6} align="center" fontWeight="700">
              Oops, something went wrong...
            </Typography>
          </Box>
        </Box>
      </Modal>
    )
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box css={moreInformationModalContainer({ theme })}>
        <Box css={moreInformationModalHeader({ theme })}>
          <Box css={moreInformationModalTitle({ theme })}>
            <Icon iconName="Info" size="large" colour={theme.palette.text.primary} />
            <Typography variant="h6">{helpContent?.title} - Info</Typography>
          </Box>
          <IconButton size="small" onClick={handleClose}>
            <Icon iconName="Close" size="large" colour={theme.palette.text.primary} />
          </IconButton>
        </Box>
        <Box css={moreInformationModalContent({ theme })}>
          <Typography variant="body1" margin={6} align="center" fontWeight="700"></Typography>
          <Box dangerouslySetInnerHTML={{ __html: helpContent ? helpContent.body : '' }} />
        </Box>
      </Box>
    </Modal>
  )
}
