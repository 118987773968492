import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const valuesFormContainer = () => css`
  display: flex;
  flex-direction: column;

  > h4 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 4px;
  }
`

export const formHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #aaa;
  padding: 8px 0;
  margin-bottom: 8px;

  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    align-self: flex-end;
  }

  .buttons {
    display: flex;
    gap: 8px;
    button {
      font-size: 14px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`

export const formBox = ({ theme, showElements }: { theme: Theme; showElements: boolean }) => css`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 240px;
  flex-shrink: 0;
  max-height: 400px;
  line-height: 1.2;
  transition: background-color 0s 0.2s, transform 0.4s ease-in-out;
  background-color: ${theme.palette.grey[100]}${showElements ? '80' : ''};
  transform: rotateY(${showElements ? '180deg' : '0deg'});

  button {
    text-transform: uppercase;
    align-self: ${showElements ? 'flex-start' : 'flex-end'};
    padding: 8px 16px;
    font-size: 14px;
    transition: transform 0s 0.2s;
    transform: rotateY(${showElements ? '180deg' : '0deg'});
  }

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 24px 16px;

    h1 {
      font-size: 16px;
      font-weight: 700;
    }
    transition: transform 0s 0.2s;
    transform: rotateY(${showElements ? '180deg' : '0deg'});
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
    transition: transform 0s 0.2s;
    transform: rotateY(${showElements ? '180deg' : '0deg'});

    .elementList {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px 4px;
      padding: 0 16px;
      transition: opacity 0s 0.2s, max-height 0s 0.2s;
      opacity: ${showElements ? 1 : 0};
      overflow: hidden;
      max-height: ${showElements ? '200000px' : '0'};
      pointer-events: ${showElements ? 'auto' : 'none'};
    }

    .valueForm {
      flex-grow: 1;
      padding: 0 24px;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0s 0.2s, max-height 0s 0.2s;
      opacity: ${showElements ? 0 : 1};
      pointer-events: ${showElements ? 'none' : 'auto'};

      p {
        font-size: 14px;
        margin-bottom: 24px;
      }
    }
  }
`

export const formBoxRow = css`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 16px;
  margin: -16px;
  margin-bottom: 0;
  perspective: 1000px;
`
