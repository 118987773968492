/** @jsxImportSource @emotion/react */

import { memo, useEffect } from 'react'
import { Box, Typography, useTheme, Checkbox } from '@mui/material'
import {
  FormElementType,
  HazardOptionState,
} from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog/HazardLayersForm/HazardBox'
import {
  hazardBoxSectionInfo,
  radioGroupContent,
  hazardBoxSLRSlider,
  sectionTitle,
} from '../HazardControls.styles'
import {
  HazardDetail,
  HazardScenario,
} from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog'
import { Accordion } from '@src/components/Atoms/Accordion'
import { Slider } from '@src/components/Molecules/Slider'
import { Icon } from '@src/components/Atoms/Icon'
import { convertToMetersOrCentimeters } from '@src/utils/numbers.utils'
import { checkboxMainClass } from '@styles/global.styles'
import { RadioGroup } from '@src/components/Atoms/RadioGroup'

export const HazardControlFormElement = memo(function HazardControlFormElement({
  hazardDetail,
  formElement,
  hazardOptionState,
  handleParameterChange,
  handleSwitch,
  handleNoteKeys,
}: {
  hazardDetail: HazardDetail
  formElement: FormElementType
  hazardOptionState: HazardOptionState
  handleParameterChange: (key: string, value: string | number) => void
  handleSwitch: (key: string, isOn: boolean) => void
  handleNoteKeys: (hazardDetail: HazardDetail, key: string, value: boolean) => void
}) {
  const theme = useTheme()

  const hazardParameter = hazardDetail.parameters[formElement.key]
  const hasSetValue =
    hazardOptionState[formElement.key]?.value !== undefined &&
    hazardOptionState[formElement.key]?.value !== '' &&
    hazardOptionState[formElement.key]?.value !== null

  const filteredScenarios = (
    scenarios: HazardScenario[],
    parameterKey?: string,
  ): HazardScenario[] => {
    const res = scenarios.filter((scenario: HazardScenario) => {
      // filter by all 'present' keys in hazardOptionState
      const keys = Object.keys(hazardOptionState)
      const isAvailable = keys.every((key) => {
        if (key === parameterKey) return true
        const hazardOption: HazardOptionState = hazardOptionState
        const value = hazardOption[key].value
        if (value === '' || value === null || value === undefined || value === 'Not Specified')
          return true
        if (Array.isArray(value))
          return +scenario.parameters[key] >= value[0] && +scenario.parameters[key] <= value[1]
        if (scenario.parameters[key] === undefined) return true
        return scenario.parameters[key].toString() === value.toString()
      })
      return isAvailable
    })
    return res
  }

  const availableValues = hazardDetail.scenarios
    .map((scenario) => scenario.parameters[formElement.key])
    .filter((value, index, self) => self.indexOf(value) === index)

  // if the availableValues are numbers, sort them
  if (availableValues.every((value) => typeof value === 'number')) {
    availableValues.sort((a: string | number, b: string | number) => {
      const numA = typeof a === 'string' ? parseFloat(a) : (a as number)
      const numB = typeof b === 'string' ? parseFloat(b) : (b as number)
      return numA - numB
    })
  }

  const isAvailableObjectDisabled = (
    scenarios: HazardScenario[],
    key: string,
    value: string | number,
  ): boolean => {
    if (value === '' || value === null || value === undefined || value === 'Not specified')
      return false
    const keyValueFound = filteredScenarios(scenarios, key).some((scenario) => {
      return scenario.parameters[key] === value
    })
    return !keyValueFound
  }

  const availableObjects = availableValues.map((value) => {
    // at least one scenario contains all the above option
    const foundScenario = hazardDetail.scenarios.some((scenario) => {
      return scenario.parameters[formElement.key] === value
    })
    // we know there's no options, return early
    if (!foundScenario) return { value, disabled: true }
    const disabled = isAvailableObjectDisabled(hazardDetail.scenarios, formElement.key, value)

    if (disabled) return { value, disabled }
    return { value }
  })

  useEffect(() => {
    if (availableObjects.length <= 1 || !availableValues.length) {
      handleNoteKeys(hazardDetail, formElement.key, true)
    } else {
      handleNoteKeys(hazardDetail, formElement.key, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!hazardParameter) return null
  if (availableObjects.length <= 1 || !availableValues.length) {
    return null
  }

  let header = null
  let body = null
  let setDefaultValue = null

  if (
    formElement.type === 'slider' ||
    (formElement.type === 'sealevelSlider' && availableValues.length)
  ) {
    const availableSliderValues = availableValues.filter((value) => {
      return availableObjects.some((availableObject) => {
        return availableObject.value === value && !availableObject.disabled
      })
    })
    const default_slider_value: number = +availableSliderValues[0]
    let slider_value: number = default_slider_value
    if (
      typeof hazardOptionState[formElement.key]?.value === 'number' &&
      (hazardOptionState[formElement.key].value ?? false)
    ) {
      slider_value = hazardOptionState[formElement.key]?.value as number
    }

    setDefaultValue = () => {
      handleParameterChange(formElement.key, slider_value ?? 0.01)
    }

    header = formElement.title

    body = (
      <>
        {availableSliderValues.length == 0 && (
          <Typography>No results found from current filter</Typography>
        )}
        {availableSliderValues.length > 0 && (
          <Box key={`${formElement.key}-sealevelSlider-box`} css={hazardBoxSLRSlider({ theme })}>
            <Slider
              key={`${formElement.key}-slider`}
              value={slider_value}
              handleValueChange={(value) => handleParameterChange(formElement.key, value)}
              marks={availableObjects.map((availableObject) => ({
                label: convertToMetersOrCentimeters(
                  availableObject.value as number,
                  hazardParameter.unit,
                ),
                value: +availableObject.value,
                disabled: availableObject.disabled,
              }))}
              defaultValue={default_slider_value}
              valueLabelDisplay={hasSetValue ? 'on' : 'off'}
              useOnChangeCommitted
              useTriangleTicks
              minimalLabels
            />
          </Box>
        )}
      </>
    )
  } else if (formElement.type === 'radioGroup' && availableValues.length) {
    header = formElement.title
    setDefaultValue = () => {
      handleParameterChange(
        formElement.key,
        availableObjects.filter((aObj) => !aObj.disabled)[0].value,
      )
    }
    body = (
      <>
        {availableObjects.filter((aObj) => !aObj.disabled).length > 0 && (
          <RadioGroup
            css={radioGroupContent({ theme })}
            value={hazardOptionState[formElement.key]?.value ?? ''}
            key={`${formElement.key}-radioGroup`}
            onChange={(event) => handleParameterChange(formElement.key, event.target.value)}
            options={availableObjects
              .filter((aObj) => !aObj.disabled)
              .map((availableObject) => ({
                label: hazardParameter.keyValues
                  ? hazardParameter.keyValues[availableObject.value as number | string]
                  : '',
                value: availableObject.value as number | string,
                selected: hazardOptionState[formElement.key]?.value === availableObject.value,
              }))}
          />
        )}
      </>
    )
  }

  if (header && body && setDefaultValue) {
    return (
      <Accordion
        variant={hasSetValue ? 'filled' : 'outline'}
        hasTextContent
        level="h3"
        canCollapse={false}
        header={
          <Box css={hazardBoxSectionInfo({ theme, hasSetValue })}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography css={sectionTitle} key={`${formElement.key}-label`}>
                {header}
              </Typography>
            </Box>
            <Checkbox
              onChange={(_e) => handleSwitch(formElement.key, hasSetValue)}
              icon={<Icon iconName="s3://checkcircleempty.svg" size="large" />}
              checkedIcon={<Icon iconName="s3://checkcircle.svg" colour="#FFF" size="large" />}
              key={formElement.key}
              checked={hasSetValue}
              disabled={false}
              id={formElement.key}
              size="medium"
              css={checkboxMainClass}
            />
          </Box>
        }
        body={hasSetValue ? body : undefined}
      />
    )
  }
  return null
})
