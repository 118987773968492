/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { AnimatePresence, motion } from 'framer-motion'
import { forwardRef, memo, useImperativeHandle, useState } from 'react'

import {
  moreMenuButtonItem,
  moreMenuButtonItemContainer,
  moreMenuButtonTooltip,
  moreMenuContainer,
  moreMenuContentContainer,
  moreMenuCustomIcon,
  moreMenuExpandedContentContainer,
  moreMenuMainIcon,
} from './MoreMenu.styles'
import { Icon } from '@src/components/Atoms/Icon'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'

export interface MoreMenuButton {
  title: string
  handleClick: () => void
  icon: Icon | JSX.Element
  isHidden?: boolean
  disabled?: boolean
  isActive?: boolean
}

export type MoreMenuSize = 'small' | 'medium' | 'large'

export interface MainButton {
  icon: Icon | JSX.Element
}

export interface MoreMenuProps {
  buttons: MoreMenuButton[]
  moreButtonIcon?: Icon
  children?: EmotionJSX.Element
  mainButton?: MainButton
  size?: MoreMenuSize
  className?: string
  defaultOpen?: boolean
  showOutline?: boolean
  disableExpanding?: boolean
}

export type MoreMenuOpenState = {
  setOpen: (visible: boolean) => void
}

const MoreMenuComp = forwardRef<MoreMenuOpenState, MoreMenuProps>(
  (
    {
      buttons,
      className,
      mainButton,
      children,
      moreButtonIcon,
      showOutline = false,
      defaultOpen = false,
      size = 'large',
      disableExpanding = false,
    },
    ref,
  ) => {
    const theme = useTheme()
    const [showButtons, setShowButtons] = useState(defaultOpen)

    // expose the setOpen function to the parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
      setOpen(visible: boolean) {
        setShowButtons(visible)
      },
    }))

    const moreMenuContent = (
      <>
        {children}
        {buttons.map((button, index) => {
          const { isHidden, disabled, isActive } = button

          if (isHidden) return null
          return (
            <Box
              key={button.title + index}
              onClick={(e) => {
                e.stopPropagation()

                if (disabled) return
                button.handleClick()
              }}
              css={moreMenuButtonItemContainer({ theme })}
            >
              <Tooltip
                enterDelay={400}
                leaveDelay={0}
                css={moreMenuButtonTooltip({ theme })}
                title={button.title}
              >
                <Box
                  css={moreMenuButtonItem({
                    theme,
                    disabled: !!disabled,
                    isActive: !!isActive,
                  })}
                >
                  {typeof button.icon === 'string' ? (
                    <Icon
                      iconName={button.icon}
                      size="medium"
                      colour={disabled ? theme.palette.grey[400] : theme.palette.grey[700]}
                    />
                  ) : (
                    <Box css={moreMenuCustomIcon({ theme, disabled: !!disabled })}>
                      {button.icon}
                    </Box>
                  )}
                </Box>
              </Tooltip>
            </Box>
          )
        })}
      </>
    )
    if (disableExpanding) {
      return <Box css={moreMenuContentContainer({ theme })}>{moreMenuContent}</Box>
    }

    return (
      // <ClickAwayListener onClickAway={() => setShowButtons(false)}>
      <Box
        css={moreMenuContainer({ theme, size, showButtons, mainButton, showOutline })}
        className={className}
      >
        {mainButton ? (
          <>
            {typeof mainButton.icon === 'string' ? (
              <Icon
                iconName={mainButton.icon}
                size={size === 'small' ? 'medium' : 'large'}
                colour={theme.palette.primary.contrastText}
                onClick={() => {
                  setShowButtons((prev) => !prev)
                }}
              />
            ) : (
              <Box css={moreMenuMainIcon({ theme })}>{mainButton.icon}</Box>
            )}
          </>
        ) : (
          <Icon
            iconName={moreButtonIcon ? moreButtonIcon : 'More'}
            colour={theme.palette.grey[600]}
            size={size === 'small' ? 'medium' : 'large'}
            onClick={() => {
              setShowButtons((prev) => !prev)
            }}
          />
        )}
        <Box>
          <AnimatePresence>
            {showButtons && (
              <motion.div
                css={[
                  moreMenuContentContainer({ theme }),
                  moreMenuExpandedContentContainer({ theme }),
                ]}
                initial={{ opacity: 0, width: 0, overflow: 'hidden' }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{
                  opacity: 0,
                  width: 0,
                  overflow: 'hidden',
                  transition: { delay: 0.15 },
                }}
              >
                {moreMenuContent}
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </Box>
      // </ClickAwayListener>
    )
  },
)
MoreMenuComp.displayName = 'MoreMenu'

export const MoreMenu = memo(MoreMenuComp)
