/** @jsxImportSource @emotion/react */
import { alpha, Box, Slider as MuiSlider, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
  indicatorContainer,
  indicatorsContainer,
  slider,
  sliderContainer,
  tickContainer,
  tickValue,
  triangleTick,
} from './Slider.styles'
import { SliderIndicator } from './SliderIndicator'
import { useEffect, useMemo, useState } from 'react'

export type SliderMark = {
  value: number
  label: string
  disabled?: boolean
}

export type SliderIndicator = {
  value: number
  label: string
  tooltip?: string
}

export interface SliderProps {
  marks: SliderMark[]
  defaultValue?: number | number[]
  valueLabelDisplay?: 'on' | 'off' | 'auto'
  orientation?: 'vertical' | 'horizontal'
  sliderIndicators?: SliderIndicator[]
  ariaLabel?: string
  disabled?: boolean
  minimalLabels?: boolean
  useTriangleTicks?: boolean
  value: number | number[]
  useOnChangeCommitted?: boolean
  handleValueChange: (value: number) => void
}

export const Slider = ({
  ariaLabel = 'slider',
  marks,
  defaultValue,
  value,
  handleValueChange,
  valueLabelDisplay = 'auto',
  useOnChangeCommitted = false,
  sliderIndicators = [],
  orientation = 'horizontal',
  disabled = false,
  minimalLabels = false,
  useTriangleTicks = false,
}: SliderProps) => {
  const theme = useTheme()
  const [sliderValue, setSliderValue] = useState<number | number[]>(value)

  const values = useMemo(() => marks.map((mark) => mark.value), [marks])
  const validValues = useMemo(
    () => marks.flatMap((mark) => (mark.disabled ? [] : mark.value)),
    [marks],
  )
  const highestValue = useMemo(() => Math.max(...values), [values])
  const lowestValue = useMemo(() => Math.min(...values), [values])
  const displayIndicators = !!sliderIndicators.length && orientation === 'horizontal' // TODO:indicators for vertical sliders

  const getIndicatorXPositionPercentage = (min: number, max: number) => (value: number) =>
    (100 * (value - min)) / (max - min)

  function valueLabelFormat(value: number) {
    const marker = marks.findIndex((mark) => mark.value === value)
    return marks[marker]?.label ?? 'Not available'
  }

  const getValidValue = (newValue: number) => {
    if (validValues.includes(newValue)) return newValue
    // If the new value is non-selectable, find the nearest selectable value
    if (newValue === 0 && validValues.includes(0)) return 0
    const nearestSelectable = validValues.reduce((prevValue, currValue) => {
      const numCurr = typeof currValue === 'string' ? parseFloat(currValue) : currValue
      const numPrev = typeof prevValue === 'string' ? parseFloat(prevValue) : prevValue
      return Math.abs(numCurr - newValue) < Math.abs(numPrev - newValue) ? currValue : prevValue
    }, validValues[0])
    return nearestSelectable
  }
  const handleSliderValueChange = (value: number) => {
    const validValue = getValidValue(value)
    setSliderValue(validValue)
    if (!useOnChangeCommitted) handleValueChange(validValue)
  }

  const handleSliderValueCommited = (value: number) => {
    if (useOnChangeCommitted) handleValueChange(getValidValue(value))
  }

  const minimalMarks = marks.map((mark, index) => {
    return {
      ...mark,
      label: (
        <Box key={mark.value} css={tickContainer}>
          {useTriangleTicks && value !== mark.value && (
            <Typography
              color={mark.disabled ? `${alpha(theme.palette.text.disabled, 0.5)}` : 'initial'}
              css={triangleTick()}
            >
              ▲
            </Typography>
          )}
          <Typography
            color={mark.disabled ? `${alpha(theme.palette.text.disabled, 0.5)}` : 'initial'}
            css={tickValue({
              theme,
              index,
              totalTicks: marks.length,
              disabled: mark.disabled,
            })}
          >
            {mark.label}
          </Typography>
        </Box>
      ),
    }
  })

  // unsure why this is necessary, but the initial value set is not being respected regardless, so this is a workaround
  useEffect(() => {
    setSliderValue(value)
  }, [value])

  return (
    <Box css={sliderContainer({ theme, orientation })}>
      <Box css={indicatorsContainer}>
        {displayIndicators &&
          sliderIndicators.map((indicator, index) => {
            const indicatorXPosition = getIndicatorXPositionPercentage(
              lowestValue,
              highestValue,
            )(indicator.value)

            return (
              <Box
                key={index}
                css={indicatorContainer({
                  indicatorXPosition: indicatorXPosition - indicatorXPosition / 50,
                })}
              >
                <SliderIndicator
                  arrowPosition="up"
                  label={indicator.label}
                  tooltip={indicator.tooltip}
                />
              </Box>
            )
          })}
      </Box>

      <MuiSlider
        css={displayIndicators && slider({ orientation })}
        getAriaLabel={() => ariaLabel}
        step={null}
        min={lowestValue}
        max={highestValue}
        marks={minimalLabels ? minimalMarks : marks}
        defaultValue={defaultValue}
        valueLabelFormat={(value) => valueLabelFormat(value)}
        getAriaValueText={(value) => valueLabelFormat(value)}
        orientation={orientation}
        valueLabelDisplay={valueLabelDisplay}
        disabled={disabled}
        value={sliderValue}
        onChange={(_, value) => handleSliderValueChange(value as number)}
        onChangeCommitted={(_, value) => handleSliderValueCommited(value as number)}
      />
    </Box>
  )
}
