/** @jsxImportSource @emotion/react */

import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  FormControlLabel,
  Checkbox,
  useTheme,
  List,
  Popover,
  Button,
  MenuItem,
} from '@mui/material'
import { Replay } from '@mui/icons-material'
import { Icon } from '@src/components/Atoms/Icon'
import { HazardDetail, HazardScenario } from '../../AddLayersDialog.types'
import {
  hazardBoxSectionInfo,
  sectionTitle,
  radioGroupContent,
  hazardBoxSLRSliderContainer,
  hazardBoxSLRSlider,
  checkboxLabel,
  hazardBoxOption,
  hazardBoxCheckboxContainer,
  hazardBoxRadioGroupContainer,
  formElementsBox,
  imageBox,
} from './HazardBox.styles'
import { anyToBoolean, getAvailableHazardAreas } from '../HazardLayersForm.utils'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import React from 'react'
import HazardListItem from '../HazardListItem/HazardListItem'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { Slider } from '@src/components/Molecules/Slider'
import { usePreferences } from '@contexts/PreferencesContext'
import { toSnakeCase } from '@src/utils/strings.utils'
import { convertToMetersOrCentimeters } from '@src/utils/numbers.utils'
import { RadioGroup } from '@src/components/Atoms/RadioGroup'
import { Section } from '@src/components/Atoms/Section'
import { Dropdown } from '@src/components/Atoms/Dropdown'

export interface FormElementType {
  key: string
  title: string
  type: string
  priority: number
}

export interface HazardOptionState {
  [key: string]: {
    value: string | number | number[]
  }
}

export const FormElement = ({
  hazardDetail,
  formElement,
  availableValues,
  hazardOptionState,
  anchorEl,
  handleParameterChange,
  setInformationDetailsModal,
  setAnchorEl,
}: {
  hazardDetail: HazardDetail
  formElement: FormElementType
  availableValues: (string | number)[]
  hazardOptionState: HazardOptionState
  anchorEl: HTMLButtonElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>
  handleParameterChange: (key: string, value: string | number) => void
  setInformationDetailsModal: ({
    isOpen,
    helpFileName,
  }: {
    isOpen: boolean
    helpFileName: string
  }) => void
}) => {
  const theme = useTheme()
  if (!hazardDetail || !hazardDetail.parameters[formElement.key]) return null

  const hazardParameter = hazardDetail.parameters[formElement.key]
  if (!hazardParameter) return null

  // if the availableValues are numbers, sort them
  if (availableValues.every((value) => typeof value === 'number')) {
    availableValues.sort((a: string | number, b: string | number) => {
      const numA = typeof a === 'string' ? parseFloat(a) : (a as number)
      const numB = typeof b === 'string' ? parseFloat(b) : (b as number)
      return numA - numB
    })
  }

  switch (formElement.type) {
    case 'radioGroup':
      return (
        <Popover
          key={`${formElement.key}-radioGroup-section`}
          id={anchorEl ? formElement.key : undefined}
          open={anchorEl?.id === formElement.key}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Section css={hazardBoxRadioGroupContainer({ theme })}>
            <Box css={hazardBoxSectionInfo({ theme })}>
              <Typography css={sectionTitle}>{formElement.title}</Typography>
              {/* clear button */}
              <Box>
                <IconButton onClick={() => handleParameterChange(formElement.key, '')} size="small">
                  <Replay />
                </IconButton>
                <Tooltip title={formElement.title}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setInformationDetailsModal({
                        isOpen: true,
                        helpFileName: toSnakeCase(formElement.title),
                      })
                    }
                  >
                    <Icon iconName="Info" colour={theme.palette.grey[500]} size="medium" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>

            <RadioGroup
              css={radioGroupContent({ theme })}
              value={hazardOptionState[formElement.key].value}
              key={`${formElement.key}-radioGroup`}
              onChange={(event) => handleParameterChange(formElement.key, event.target.value)}
              options={availableValues.map((value) => ({
                label: hazardParameter.keyValues
                  ? hazardParameter.keyValues[value as number | string]
                  : '',
                value: value as number | string,
                selected: hazardOptionState[formElement.key]?.value === value,
              }))}
            />
          </Section>
        </Popover>
      )

    case 'slider':
    case 'sealevelSlider': {
      const default_slider_value: number[] = [
        +availableValues[0],
        +availableValues[availableValues.length - 1],
      ]
      let slider_value: number | number[] = default_slider_value
      if (typeof hazardOptionState[formElement.key].value !== 'string') {
        slider_value = hazardOptionState[formElement.key].value as number | number[]
      }
      return (
        <Popover
          key={`${formElement.key}-sealevelSlider-section`}
          id={anchorEl ? formElement.key : undefined}
          open={anchorEl?.id === formElement.key}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: { style: { minWidth: '225px' } },
          }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Section css={hazardBoxSLRSliderContainer({ theme })}>
            <Box css={hazardBoxSectionInfo({ theme })}>
              <Typography css={sectionTitle} key={`${formElement.key}-label`}>
                {formElement.title}
              </Typography>
              {/* clear button */}
              <Box>
                <IconButton onClick={() => handleParameterChange(formElement.key, '')} size="small">
                  <Replay />
                </IconButton>
                <Tooltip title={formElement.title}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setInformationDetailsModal({
                        isOpen: true,
                        helpFileName: 'slr',
                      })
                    }
                  >
                    <Icon iconName="Info" colour={theme.palette.grey[500]} size="medium" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            {availableValues.length == 1 && <Typography>{availableValues[0]}</Typography>}
            {availableValues.length > 1 && (
              <Box
                key={`${formElement.key}-sealevelSlider-box`}
                css={hazardBoxSLRSlider({ theme })}
              >
                <Slider
                  key={`${formElement.key}-slider`}
                  value={slider_value}
                  handleValueChange={(value) => handleParameterChange(formElement.key, value)}
                  marks={availableValues.map((value) => ({
                    label: convertToMetersOrCentimeters(value as number, hazardParameter.unit),
                    value: +value,
                  }))}
                  defaultValue={default_slider_value}
                  minimalLabels
                  useOnChangeCommitted
                />
              </Box>
            )}
          </Section>
        </Popover>
      )
    }

    case 'checkbox': {
      const defaultChecked = (availableValues.length == 1 &&
        anyToBoolean(availableValues[0])) as boolean
      const checked =
        availableValues.length === 1
          ? defaultChecked
          : anyToBoolean(hazardOptionState[formElement.key]?.value)
      const disabled = availableValues.length === 1
      return (
        <Popover
          key={`${formElement.key}-checkbox-section`}
          id={anchorEl ? formElement.key : undefined}
          open={anchorEl?.id === formElement.key}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Section css={hazardBoxCheckboxContainer({ theme })}>
            <Box css={hazardBoxSectionInfo({ theme })}>
              <FormControlLabel
                key={`${formElement.key}-checkbox-label`}
                disabled={disabled}
                control={
                  <Checkbox
                    onChange={(e) => handleParameterChange(formElement.key, `${e.target.checked}`)}
                    key={formElement.key}
                    checked={checked}
                    disabled={disabled}
                    id={formElement.key}
                    size="small"
                  />
                }
                label={
                  <Typography
                    key={`${formElement.key}-checkbox-typography-label`}
                    css={checkboxLabel({ theme, disabled })}
                  >
                    {`${formElement.title}: ${
                      hazardParameter.keyValues ? hazardParameter.keyValues[checked.toString()] : ''
                    }`}
                  </Typography>
                }
              />
            </Box>
          </Section>
        </Popover>
      )
    }
    default:
      return (
        <Typography key={`${formElement.key}-default-typography`}>{formElement.title}</Typography>
      )
  }
}

export const HazardBox = ({
  hazardDetail,
  selectedHazards,
  setInformationDetailsModal,
  allScenarios,
  addHazardScenario,
  addingDisallowed,
  isKnownRisks = false,
}: {
  hazardDetail: HazardDetail
  selectedHazards: MapLayer[]
  addingDisallowed: boolean
  allScenarios: HazardScenario[]
  setInformationDetailsModal: ({
    isOpen,
    helpFileName,
  }: {
    isOpen: boolean
    helpFileName: string
  }) => void
  addHazardScenario: (scenario: HazardScenario) => void
  isKnownRisks?: boolean
}) => {
  const theme = useTheme()
  const { term_preference } = usePreferences()
  const [hazardArea, setHazardArea] = useState<string>('')
  const [availableHazardAreas, setAvailableHazardAreas] = useState<string[]>([])

  const initialHazardOptionStateFunc = useCallback(() => {
    const initialHazardOptionState: HazardOptionState = {}
    hazardDetail.form.forEach((formElement: FormElementType) => {
      initialHazardOptionState[formElement.key] = { value: '' }
    })
    return initialHazardOptionState
  }, [hazardDetail.form])

  const [hazardOptionState, setHazardOptionState] = useState(initialHazardOptionStateFunc())
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleHazardAreaChange = (newHazardArea: string) => {
    setHazardArea(newHazardArea)
  }

  const handleClearFilters = () => {
    setHazardOptionState(initialHazardOptionStateFunc())
    setHazardArea('')
  }

  const handleParameterChange = (key: string, value: string | number | number[]) => {
    setHazardOptionState((prevState) => ({
      ...prevState,
      [key]: { value },
    }))
  }

  const hasHazardIds = allScenarios.every((scenario) => scenario.hazard_id !== undefined)

  const filteredScenarios = (scenarios: HazardScenario[]): HazardScenario[] => {
    const res = scenarios.filter((scenario: HazardScenario) => {
      // filter by all 'present' keys in hazardOptionState
      const keys = Object.keys(hazardOptionState)
      const isAvailable = keys.every((key) => {
        const hazardOption: HazardOptionState = hazardOptionState
        const value = hazardOption[key].value
        if (value === '' || value === null || value === undefined) return true
        if (Array.isArray(value))
          return +scenario.parameters[key] >= value[0] && +scenario.parameters[key] <= value[1]
        return scenario.parameters[key].toString() === value.toString()
      })
      // filter by hazardArea
      return isAvailable && (hazardArea ? scenario.hazardAreas.includes(hazardArea) : true)
    })

    if (isKnownRisks) return res

    // Only show one scenario per unique hazard_id to reduce the number listed
    const seenHazardIds: { [key: string]: boolean } = {}
    const oneRepresentativeScenarioPerHazardId = res.filter((scenario) => {
      if (scenario.hazard_id === undefined) return true
      if (scenario.hazard_id && !seenHazardIds[scenario.hazard_id]) {
        seenHazardIds[scenario.hazard_id] = true
        return true
      }
      return false
    })
    return oneRepresentativeScenarioPerHazardId
  }

  useEffect(() => {
    const availableHazardAreasRes = getAvailableHazardAreas(hazardDetail)
    setAvailableHazardAreas(availableHazardAreasRes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAvailableHazardAreas])

  useEffect(() => {
    setHazardOptionState(initialHazardOptionStateFunc())
  }, [initialHazardOptionStateFunc])

  if (!hazardDetail) return null

  return (
    <Box key={hazardDetail.title} css={hazardBoxOption({ theme })}>
      {hazardDetail.description?.content && (
        <UiMarkdown>{hazardDetail.description.content}</UiMarkdown>
      )}
      {hazardDetail.description?.image && (
        <Box css={imageBox({ theme })} component="img" src={hazardDetail.description.image} />
      )}

      <Box css={formElementsBox({ theme })} key={`${hazardDetail.title}-form-element-box`}>
        {/* Build hazard select dropdown in same style, but separately from params */}
        {availableHazardAreas.length > 1 && (
          <>
            <Button
              size="small"
              key={`${hazardDetail.title}-button`}
              id={`${hazardDetail.title}-button`}
              variant={hazardArea ? 'contained' : 'outlined'}
              sx={{
                color: hazardArea ? theme.palette.primary.contrastText : theme.palette.primary.dark,
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
            >
              Region
              <Icon
                iconName="ChevronDown"
                colour={
                  hazardArea ? theme.palette.primary.contrastText : theme.palette.primary.main
                }
              />
            </Button>
            <Popover
              key={`${hazardDetail.title}-popover`}
              id={anchorEl ? `${hazardDetail.title}-popover` : undefined}
              open={anchorEl?.id === `${hazardDetail.title}-button`}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              slotProps={{
                paper: { style: { minWidth: '250px' } },
              }}
            >
              <Section css={hazardBoxRadioGroupContainer({ theme })}>
                <Box css={hazardBoxSectionInfo({ theme })}>
                  <Typography css={sectionTitle}>Region</Typography>
                  {/* clear button */}
                  <Box>
                    <IconButton onClick={() => handleHazardAreaChange('')} size="small">
                      <Replay />
                    </IconButton>
                  </Box>
                </Box>
                <Dropdown
                  label={term_preference.hazard ? 'Hazard Region' : 'Risk Source Region'}
                  handleChange={(event) => handleHazardAreaChange(event.target.value)}
                  value={hazardArea}
                >
                  {availableHazardAreas.map((hazardArea) => (
                    <MenuItem key={hazardArea} value={hazardArea}>
                      {hazardDetail.hazardAreas[hazardArea]}
                    </MenuItem>
                  ))}
                </Dropdown>
              </Section>
            </Popover>
          </>
        )}
        {hazardDetail.form.map((formElement: FormElementType) => {
          const hasValue = !!(
            hazardOptionState[formElement.key].value !== undefined &&
            hazardOptionState[formElement.key].value !== '' &&
            hazardOptionState[formElement.key].value !== null
          )

          if (hasHazardIds && !isKnownRisks && !['catchment', 'river'].includes(formElement.key))
            return

          const hazardAreaScenarios = hazardArea
            ? allScenarios.filter((scenario) => scenario.hazardAreas.includes(hazardArea))
            : allScenarios

          const availableValues = hazardAreaScenarios
            .map((scenario) => scenario.parameters[formElement.key])
            .filter((value, index, self) => self.indexOf(value) === index)
          if (availableValues.length <= 1) return

          return (
            <Fragment key={`${formElement.key}-fragment-key`}>
              <Button
                size="small"
                key={`${formElement.key}-button`}
                id={formElement.key}
                variant={hasValue ? 'contained' : 'outlined'}
                sx={{
                  color: hasValue ? theme.palette.primary.contrastText : theme.palette.primary.dark,
                }}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
              >
                {formElement.title}
                <Icon
                  iconName="ChevronDown"
                  colour={
                    hasValue ? theme.palette.primary.contrastText : theme.palette.primary.main
                  }
                />
              </Button>
              <FormElement
                key={`${formElement.key}-formElement`}
                hazardDetail={hazardDetail}
                formElement={formElement}
                availableValues={availableValues}
                hazardOptionState={hazardOptionState}
                anchorEl={anchorEl}
                handleParameterChange={handleParameterChange}
                setInformationDetailsModal={setInformationDetailsModal}
                setAnchorEl={setAnchorEl}
              />
            </Fragment>
          )
        })}
        {(hazardDetail?.form?.length !== 0 || availableHazardAreas.length > 1) && (
          <Button
            variant="text"
            sx={{ color: theme.palette.primary.dark }}
            size="small"
            onClick={() => handleClearFilters()}
          >
            Clear Filters
          </Button>
        )}
      </Box>
      <Box key="scenarioBox">
        {filteredScenarios.length && (
          <List disablePadding>
            {filteredScenarios(hazardDetail.scenarios).map((scenario: HazardScenario) => {
              const selected = !!selectedHazards.find((sHazard) => {
                if (isKnownRisks || scenario.hazard_id === undefined)
                  return sHazard.assetTag === scenario.assetTag
                return sHazard.hazard_id === scenario.hazard_id
              })
              return (
                <HazardListItem
                  selected={selected}
                  key={scenario.assetTag}
                  disallowed={addingDisallowed}
                  scenario={scenario}
                  addHazardScenario={addHazardScenario}
                  isKnownRisks={isKnownRisks}
                />
              )
            })}
          </List>
        )}
        {filteredScenarios(hazardDetail.scenarios).length === 0 && (
          <Typography sx={{ textAlign: 'center', marginY: `${theme.spacing(5)}` }}>
            No scenarios available for the selected parameters
          </Typography>
        )}
      </Box>
    </Box>
  )
}
