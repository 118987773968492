import { css } from '@emotion/react'

export const prioritisationPageContainer = () => css`
  width: 100%;
  height: 100%;

  display: flex;
  position: relative;

  overflow: hidden;
`
export const sideDrawerContainer = css`
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
`
