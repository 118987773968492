import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const landingOverlayContainer = (theme: Theme, visible: boolean) => css`
  width: 100%;
  height: 100%;

  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  justify-content: center;
  opacity: ${visible ? 1 : 0};
  transition: opacity 0.2s;
  pointer-events: ${visible ? 'auto' : 'none'};
  user-select: none;

  background: ${theme.palette.primary[900]};

  overflow: hidden;
`

export const landingOverlay = (theme: Theme) => css`
  width: 100%;
  padding: 32px;
  max-width: 800px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  color: ${theme.palette.primary[200]};
  text-align: center;

  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
`

export const circleBackground = (theme: Theme, visible: boolean) => css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 101;
  opacity: ${visible ? 1 : 0};
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(${visible ? 1 : 1.1});

  background: radial-gradient(
    circle,
    transparent 64%,
    ${theme.palette.primary[850]} 64%,
    ${theme.palette.primary[850]} 82%,
    ${theme.palette.primary[800]} 82%
  );
`

export const actionButtonsContainer = css`
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 32px;

  > * {
    flex: 1;
  }

  button {
    padding: 8px;
    display: flex;
    transition: border-color 0.1s, background 0.1s;
    border-color: #2d364880;
    gap: 12px;
    width: 100%;

    img {
      height: 28px;
      width: 28px;
      flex-grow: 0;
    }

    p {
      flex-grow: 1;
    }
  }
`
