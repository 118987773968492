/** @jsxImportSource @emotion/react */
import { Box, FormControl, InputLabel, MenuItem, Select, useTheme } from '@mui/material'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl, { Map, MapboxGeoJSONFeature, MapLayerMouseEvent } from 'mapbox-gl'
import {
  mapBottomLeftContainer,
  mapBottomRightContainer,
  mapOverlayContainer,
  mapTopLeftContainer,
  mapTopRightContainer,
  prioritisationLegend,
} from './PrioritisationMapView.styles'
import { useEffect, useMemo, useRef } from 'react'
import { GeocoderButton } from '@src/components/Atoms/Geocoder'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { PrioritisationMapTools } from '@src/components/Pages/Map/MapTools/MapTools'
import { BaseMapButton } from '@src/components/Pages/Map/BaseMapButton'
import { GenericMapView } from '..'
import { css } from '@emotion/react'
import { usePrioritisationMap } from '@contexts/PrioritisationMapContext'
import {
  addSelectedMarkerToMap,
  MapMouseEventManager,
} from '../GenericMapView/GenericMapView.utilities'
import {
  selectionMeasurementContainer,
  selectionMeasurementHeader,
} from '../GenericMapView/GenericMapView.styles'
import {
  prioritisationColorLegend,
  usePrioritisationContext,
} from '@contexts/PrioritisationContext'
import ReactDOMServer from 'react-dom/server'
import { PrioritisationMapTooltip } from './Tooltip/PrioritisationMapTooltip'
import Color from 'colorjs.io'

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

export const PrioritisationMapView = () => {
  mapboxgl.accessToken = MAPBOX_TOKEN
  const theme = useTheme()

  const { areaFeature, adaptationAreaPriorities, legend, area, setArea, prioritisationLayers } =
    usePrioritisationContext()
  const mapContext = usePrioritisationMap()
  const {
    map,
    deselectFeature,
    selectedPrioLayer,
    setSelectedPrioLayer,
    setMapInstance,
    flyToFeature,
    mapPaddingRef,
  } = mapContext
  const { layers, drawAreas, selectedMarker } = useSelector(
    (state: RootState) => state.prioritisationMap,
  )
  const selectedMapboxGeoJsonFeature = areaFeature as MapboxGeoJSONFeature
  const { tab } = useSelector((state: RootState) => state.sideDrawer)

  const geocoderEnabled = useMemo(() => tab !== 'Stories', [tab])

  const tooltipRef = useRef(
    new mapboxgl.Popup({ closeButton: false, className: 'prioritisation-tooltip-container' }),
  )
  const mapTopRightControlsRef = useRef<HTMLDivElement>(null)

  const tooltipFeature = useRef<MapboxGeoJSONFeature | undefined>()
  const tooltipTimeout = useRef<NodeJS.Timeout>()

  const mapMouseEventManager = map ? new MapMouseEventManager(map) : null

  async function controlMouseEvents(map: Map) {
    const rootSelectedLayerId = selectedMapboxGeoJsonFeature?.layer.id
      .replace('-zoomed-out', '')
      .replace('-extrusion', '')

    if (
      selectedMarker &&
      selectedMapboxGeoJsonFeature &&
      layers.map((l) => l.id).includes(rootSelectedLayerId)
    ) {
      addSelectedMarkerToMap({
        map,
        coordinates: [selectedMarker.longitude, selectedMarker.latitude],
        colour: theme.palette.primary.main,
      })
    }

    addMouseEvents(map)
  }

  async function addMouseEvents(map: Map) {
    const popup = tooltipRef.current
    const mapStyle = map.getStyle()
    if (!mapStyle || !popup || !mapMouseEventManager) return

    const layerIDs = prioritisationLayers?.map(
      (layer) => layer.layer_id.replace('mapbox://urbanintelligence.', '') + '-fill',
    )
    if (!layerIDs) return

    const layerIdMap = mapStyle.layers
      .filter((layer) => layerIDs.includes(layer.id) && !layer.id.includes('-line'))
      .map((layer) => layer.id)

    const updateTooltip = (e: MapLayerMouseEvent) => {
      if (!e.features || !e.features.length) return
      const feature = e.features[0]

      const tooltipContent = feature.properties?.toString()
      popup.setLngLat(e.lngLat)

      // return if no content or already hovered over
      if (!tooltipContent || (tooltipFeature.current && tooltipFeature.current.id === feature.id))
        return
      tooltipFeature.current = e.features[0]

      map.getCanvas().style.cursor = 'pointer'

      // Fade out outdated tooltip content (this typecast is safe btw)
      const htmlContent = document.getElementsByClassName('tooltip-container')[0] as HTMLElement
      if (htmlContent) htmlContent.style.color = '#AAA'

      const mapToolTip = PrioritisationMapTooltip({
        areaName: feature.properties?.name,
        properties: feature.properties,
      })

      if (!mapToolTip) return
      popup.setHTML(ReactDOMServer.renderToString(mapToolTip))
      popup.addTo(map)
    }
    const mouseClickEvent = async (e: MapLayerMouseEvent) => {
      if (e.features === undefined || !e.features[0]) return
      const feature = e.features[0]
      if (feature.properties && feature.properties.name) {
        if (feature.properties.name !== area) {
          setArea(feature.properties.name)
        } else {
          setArea('')
        }
      }
    }

    const mouseEnterEvent = function (e: MapLayerMouseEvent) {
      if (!e) return
      updateTooltip(e)
    }

    const mouseMoveEvent = (e: MapLayerMouseEvent) => {
      updateTooltip(e)
    }

    const mouseLeaveEvent = (_e: MapLayerMouseEvent) => {
      if (!map) return
      clearTimeout(tooltipTimeout.current)
      tooltipRef.current.remove()
      tooltipFeature.current = undefined
      map.getCanvas().style.cursor = ''
    }

    mapMouseEventManager.applyMouseEventsToLayers(layerIdMap, {
      onMouseEnter: mouseEnterEvent,
      onMouseMove: mouseMoveEvent,
      onMouseLeave: mouseLeaveEvent,
      onClick: mouseClickEvent,
    })
  }

  useEffect(() => {
    async function addMapLayersAndEvents() {
      if (!map) return
      else if (!prioritisationLayers) return
      const colorsByAreas = adaptationAreaPriorities.reduce((acc, area) => {
        acc[area.area] = area.color
        return acc
      }, {} as Record<string, string>)
      const areas = adaptationAreaPriorities.map((area) => area.area)
      for (const prioLayer of prioritisationLayers) {
        const mapLayers = map.getStyle().layers
        if (mapLayers && mapLayers.find((layer) => layer.id === prioLayer.layer_id)) continue
        const cleanID = prioLayer.layer_id.replace('mapbox://urbanintelligence.', '')
        const sourceId = cleanID + '-source'
        map.addSource(sourceId, {
          type: 'vector',
          url: prioLayer.layer_id,
        })
        map.addLayer({
          id: `${cleanID}-fill`,
          type: 'fill',
          source: sourceId,
          'source-layer': cleanID,
          layout: {
            visibility: 'visible',
          },
          paint: {
            'fill-opacity': 0.8,
            'fill-color': ['get', ['get', 'name'], ['literal', colorsByAreas]],
          },
          filter: ['in', ['get', 'name'], ['literal', areas]],
        })
      }
      if (map) await controlMouseEvents(map)
    }
    addMapLayersAndEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, prioritisationLayers])

  //When area changes, update map styles to highlight the selected area
  useEffect(() => {
    if (!map) return
    const areas = adaptationAreaPriorities.map((area) => area.area)
    if (area && prioritisationLayers && prioritisationLayers.length) {
      const colorsByAreas = adaptationAreaPriorities.reduce((acc, areaPrio) => {
        const color = new Color(areaPrio.color)
        if (areaPrio.area != area) color.hsl[2] += (100 - color.hsl[2]) * 0.5
        acc[areaPrio.area] = color.toString({ format: 'hex' })
        return acc
      }, {} as Record<string, string>)
      for (const prioLayer of prioritisationLayers) {
        const prioLayerID = prioLayer.layer_id.replace('mapbox://urbanintelligence.', '') + '-fill'
        map.setPaintProperty(prioLayerID, 'fill-color', [
          'get',
          ['get', 'name'],
          ['literal', colorsByAreas],
        ])
        map.setPaintProperty(prioLayerID, 'fill-opacity', [
          'case',
          ['==', ['get', 'name'], area],
          0.8,
          0.35,
        ])
      }
    } else {
      const colorsByAreas = adaptationAreaPriorities.reduce((acc, area) => {
        acc[area.area] = area.color
        return acc
      }, {} as Record<string, string>)
      if (prioritisationLayers && prioritisationLayers.length) {
        for (const prioLayer of prioritisationLayers) {
          const prioLayerID =
            prioLayer.layer_id.replace('mapbox://urbanintelligence.', '') + '-fill'
          map.setPaintProperty(prioLayerID, 'fill-color', [
            'get',
            ['get', 'name'],
            ['literal', colorsByAreas],
          ])
          map.setPaintProperty(prioLayerID, 'fill-opacity', 0.8)
        }
      }
    }
    if (prioritisationLayers && prioritisationLayers.length) {
      for (const prioLayer of prioritisationLayers) {
        const prioLayerID = prioLayer.layer_id.replace('mapbox://urbanintelligence.', '') + '-fill'
        map.setFilter(prioLayerID, ['in', ['get', 'name'], ['literal', areas]])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, adaptationAreaPriorities])

  useEffect(() => {
    if (!map || !selectedMapboxGeoJsonFeature || !layers) return

    const rootSelectedLayerId = selectedMapboxGeoJsonFeature?.layer.id
      .replace('-zoomed-out', '')
      .replace('-extrusion', '')

    if (!layers.map((l) => l.id).includes(rootSelectedLayerId)) {
      deselectFeature()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers])

  useEffect(() => {
    if (!map) return
    controlMouseEvents(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layers, selectedMarker, area])

  useEffect(() => {
    if (!map || !area) return
    // Find area feature with "area" name
    const queryableLayers = prioritisationLayers?.map(
      (layer) => layer.layer_id.replace('mapbox://urbanintelligence.', '') + '-fill',
    )
    if (!queryableLayers) return
    const areaFeatureNew = map.queryRenderedFeatures(undefined, {
      layers: queryableLayers,
      filter: ['==', ['get', 'name'], area],
    })[0]
    if (areaFeatureNew) {
      flyToFeature(areaFeatureNew, { zoom: 11, usePadding: true })
    }
  }, [area, map, flyToFeature, prioritisationLayers])

  const mapOverlayRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <GenericMapView
        map={map}
        mapContext={mapContext}
        setMapInstance={setMapInstance}
        customStyle={css`
          & .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
            margin-top: ${(mapTopRightControlsRef.current?.clientHeight ?? 0) + 16}px;
          }
          & div.mapboxgl-popup-content {
            width: auto !important;
          }
        `}
        mapPaddingRef={mapPaddingRef}
        inContainerSlot={
          drawAreas.length ? (
            <div id="selection-measurement" css={selectionMeasurementContainer}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h6 css={selectionMeasurementHeader}>Selection Details:</h6>
                <span>
                  Area: <span id="selection-calculated-area"></span>
                </span>
                <span>
                  Length: <span id="selection-calculated-length"></span>
                </span>
              </div>
            </div>
          ) : null
        }
      />

      <Box ref={mapOverlayRef} css={mapOverlayContainer}>
        <Box ref={mapTopRightControlsRef} css={mapTopRightContainer}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '2px' }}
          >
            <PrioritisationMapTools mapOverlayRef={mapOverlayRef} />
            {geocoderEnabled && <GeocoderButton map={map} />}
          </Box>
        </Box>
        <Box css={mapTopLeftContainer({ theme })}>
          {prioritisationLayers && prioritisationLayers.length > 1 && (
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="areatype" sx={{ color: `${theme.palette.uintel.main} !important` }}>
                Area Type
              </InputLabel>
              <Select
                label="Area Type"
                color="uintel"
                labelId="areatype"
                value={selectedPrioLayer?.layer_id ?? ''}
                onChange={(e) => {
                  setSelectedPrioLayer(prioritisationLayers[+e.target.value])
                }}
              >
                {prioritisationLayers.map((option, i) => (
                  <MenuItem key={i} value={option.layer_id}>
                    {option.display_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box css={mapBottomLeftContainer}>
          <BaseMapButton />
        </Box>
        <Box css={mapBottomRightContainer({ theme })}>
          {legend && (
            <Box css={prioritisationLegend}>
              Relative Risk
              <Box className="legend">
                {Object.entries(prioritisationColorLegend).map(([key, value]) => (
                  <Box
                    key={key}
                    sx={{ display: 'flex', alignItems: 'center', gap: '4px', lineHeight: 1 }}
                  >
                    <Box
                      className="legend-color"
                      sx={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: value,
                      }}
                    />
                    {key}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        <Box className="tutorial-map" />
      </Box>
    </>
  )
}
