/** @jsxImportSource @emotion/react */
import axios from '@src/utils/customAxios'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { Disclaimer } from '../Report.utils'
import {
  getPageBodySize,
  pageMargins,
  reportDefaultStyle,
  reportFooter,
  reportStyles,
} from '@pdfGeneration/Report.pdfstyle'
import mapboxgl from 'mapbox-gl'
import { GeneratePrioritisationMapContent } from './Sections/PrioritisationMap'
import { PrioritisationReportProperties } from './PrioritisationReport'
import { GenerateMultipageApdaptionAreaPriorities } from './Sections/PrioritisationRankingTable'
import { GenerateExposurePercByElement } from './Sections/ExposurePercentage'
import { GenerateValueSets } from './Sections/ValueSets'
import { GenerateScenarioDescription } from './Sections/ScenarioDescription'

const bodySize = getPageBodySize('A4')

export const GeneratePrioritisationReport = async (
  map: mapboxgl.Map,
  props: PrioritisationReportProperties,
): Promise<TDocumentDefinitions> => {
  const rexLogo: string = (await axios.get('/logos/RexLogo.svg')).data

  const sortedFilteredPriorities = props.adaptationAreaPriorities
    .filter((adaptationArea) => adaptationArea.areaGroup == props.areasType)
    .sort((a, b) => a.priorityIndex - b.priorityIndex)

  return {
    header: (_currentPage, _, pageSize) => [
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: pageSize.width,
            h: pageMargins.top - 32,
            color: '#0b2948',
          },
        ],
        absolutePosition: { x: 0, y: 0 },
      },
      {
        svg: rexLogo,
        width: 130,
        absolutePosition: { x: pageMargins.left, y: 14 },
      },
      {
        text: `Prioritisation Report`,
        color: 'white',
        style: 'pageHeader',
        absolutePosition: { x: 404, y: 26 }, // align right is ignored when using absolutePosition :-(
      },
    ],
    content: [
      { text: props.area ? props.area : `${props.clientDisplayName} - All areas`, style: 'h1' },
      {
        text: `Prioritisation using ${props.scenarioGroup?.scenario_id ?? ''} scenario`,
        style: 'h3',
      },
      await GeneratePrioritisationMapContent(map, bodySize),
      GenerateMultipageApdaptionAreaPriorities(props.area, sortedFilteredPriorities),

      { text: 'TOP 10 EXPOSED ELEMENTS', style: 'h2', pageBreak: 'before' },
      GenerateExposurePercByElement(props.exposurePercByElement),

      { text: 'VALUE SETS', style: 'h2', pageBreak: 'before' },
      {
        text: 'The rankings selected below effect area prioritisation.',
        marginBottom: 20,
        style: 'word',
      },
      GenerateValueSets(props.valueWeightings, props.elementGroups),
      props.scenarioGroup
        ? [{ text: 'SCENARIO', style: 'h2' }, GenerateScenarioDescription(props.scenarioGroup)]
        : [],
      {
        text: [
          { text: 'Disclaimer: ', font: 'Inter700' },
          {
            text: Disclaimer,
          },
        ],
        unbreakable: true,
        fontSize: 7,
      },
    ],

    defaultStyle: reportDefaultStyle,
    footer: reportFooter(`Prioritisation report${props.area ? ` for ${props.area}` : ''}`),
    styles: { ...reportStyles, word: { fontSize: 10 } },
    pageMargins: [pageMargins.left, pageMargins.top, pageMargins.right, pageMargins.bottom],
  }
}
