/** @jsxImportSource @emotion/react */
import contoursThumbnail from '@assets/images/contours-basemap-thumbnail.png'
import satelliteThumbnail from '@assets/images/satellite-basemap-thumbnail.png'
import streetThumbnail from '@assets/images/street-basemap-thumbnail.png'
import { useTheme } from '@mui/material/styles'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'

import { useMap } from '@contexts/RiskMapContext'
import { Box, Typography } from '@mui/material'
import { Icon } from '@src/components/Atoms/Icon'
import {
  BASE_MAP_CONTOURS,
  BASE_MAP_LIGHT_WITH_HOUSE_NUMBERS,
  BASE_MAP_LINZ,
} from '../../../../app-constants'
import {
  baseMapButton,
  baseMapButtonContainer,
  baseMapTitleWrapper,
  previewContainer,
  previewMap,
  previewMapContainer,
} from './BaseMapButton.styles'

export type BaseMapLabel = 'Street' | 'Satellite' | 'Contours'
type BaseMap = { label: BaseMapLabel; source: string; thumbnail: string }

const baseMaps: BaseMap[] = [
  { label: 'Street', source: BASE_MAP_LIGHT_WITH_HOUSE_NUMBERS, thumbnail: streetThumbnail },
  {
    label: 'Satellite',
    source: BASE_MAP_LINZ,
    thumbnail: satelliteThumbnail,
  },
  {
    label: 'Contours',
    source: BASE_MAP_CONTOURS,
    thumbnail: contoursThumbnail,
  },
]

export const BaseMapButton = () => {
  const theme = useTheme()
  const [baseMapIndex, setBaseMapIndex] = useState(0)
  const [showPreview, setShowPreview] = useState(false)

  const { basemap, changeMapStyle, setBasemap } = useMap()

  const cycleBaseMap = useCallback(() => {
    if (baseMapIndex < baseMaps.length - 1) {
      setBasemap(baseMaps[baseMapIndex + 1].label)
    } else {
      setBasemap(baseMaps[0].label)
    }
  }, [baseMapIndex, setBasemap])

  useEffect(() => {
    const index = baseMaps.findIndex((map) => map.label === basemap)
    if (index !== -1) {
      setBaseMapIndex(index)
      changeMapStyle({ style: baseMaps[index].source })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basemap])

  return (
    <Box
      css={baseMapButtonContainer({ theme })}
      onMouseEnter={() => setShowPreview(true)}
      onMouseLeave={() => setShowPreview(false)}
      className="tutorial-basemap"
    >
      <Box
        component="button"
        onClick={cycleBaseMap}
        css={baseMapButton({
          thumbnail: baseMaps[baseMapIndex].thumbnail,
          theme,
        })}
      >
        <Box css={baseMapTitleWrapper({ theme })}>
          <Icon iconName="Layers" size="medium" colour={theme.palette.white.main} />
          <Typography variant="body1" color={theme.palette.white.main}>
            View
          </Typography>
        </Box>
      </Box>
      <AnimatePresence>
        {showPreview && (
          <motion.div
            css={previewContainer({ theme })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {baseMaps.map((map, index) => {
              const isSelected = index === baseMapIndex

              return (
                <Box
                  key={map.label}
                  css={previewMapContainer({ theme })}
                  onClick={() => {
                    setBaseMapIndex(index)
                    changeMapStyle({ style: baseMaps[index].source })
                  }}
                >
                  <Box
                    component="span"
                    css={previewMap({ theme, isSelected, thumbnail: map.thumbnail })}
                  />
                  <Typography variant="body2">{map.label}</Typography>
                </Box>
              )
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  )
}
