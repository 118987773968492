/** @jsxImportSource @emotion/react */
import axios from '@src/utils/customAxios'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { Feature } from 'geojson'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { GenerateSelectedElementMapContent } from './Sections/SelectedElementMap'
import {
  GenerateElementHeader,
  GenerateSelectedElementDetailsContent,
  GenerateSelectedElementExposureBySelectedRisks,
} from './Sections/SelectedElementDetails'
import {
  GenerateKnownRiskListForSelectedElement,
  GenerateKnownRisksToSelectedElement,
} from './Sections/SelectedElementKnownRisks'
import { AssetDetails, Disclaimer, getElementAddress } from '../Report.utils'
import { GenerateDataSourceContent2 } from '../RiskSummary/Pages/LayerDetails'
import { ProjectedExposureChartStateByHazardId } from '@redux/projectedExposureChartSlice'
import { BASE_API_URL } from '@src/app-constants'
import { LegendsData, HazardDetail } from '../../MapView/RiskMapView/AddLayersDialog'
import { ScenariosByAsset } from '../../RiskSideDrawerContent/data_fetchers/scenariosFetcher'
import {
  getPageBodySize,
  pageMargins,
  reportDefaultStyle,
  reportFooter,
  reportStyles,
} from '@pdfGeneration/Report.pdfstyle'

const bodySize = getPageBodySize('A4')
export const GenerateSelectedElementReport = async (
  selectedAssetDetails: AssetDetails,
  selectedAssetLayer: MapLayer,
  selectedFeature: Feature,
  hazardLayers: MapLayer[],
  prefer_hazard_term: boolean,
  prefer_risk_term: boolean,
  projectedExposureChartState: ProjectedExposureChartStateByHazardId,
  legendsData: LegendsData,
  hazardDetails: HazardDetail[],
): Promise<TDocumentDefinitions> => {
  const rexLogo: string = (await axios.get('/logos/RexLogo.svg')).data

  const identifyingInformationCount = Object.keys(
    selectedAssetDetails.asset?.identifyingInformation ?? {},
  ).length

  const showFullPageMap = identifyingInformationCount > 6

  const scenarios: ScenariosByAsset[] = (
    await axios.get(
      `${BASE_API_URL}/hazard/scenarios/${selectedAssetDetails.asset?.type}/${selectedAssetDetails.asset?.id}`,
    )
  ).data

  return {
    header: (_currentPage, _, pageSize) => [
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: pageSize.width,
            h: pageMargins.top - 32,
            color: '#0b2948',
          },
        ],
        absolutePosition: { x: 0, y: 0 },
      },
      {
        svg: rexLogo,
        width: 130,
        absolutePosition: { x: pageMargins.left, y: 14 },
      },
      {
        text: `Element ${prefer_hazard_term ? 'Hazard' : 'Risk'} Report`,
        color: 'white',
        style: 'pageHeader',
        absolutePosition: { x: prefer_hazard_term ? 404 : 424, y: 26 }, // align right is ignored when using absolutePosition :-(
      },
    ],
    content: [
      await GenerateElementHeader(selectedAssetDetails.asset, selectedAssetLayer),
      await GenerateSelectedElementMapContent(
        selectedFeature,
        bodySize,
        hazardLayers,
        showFullPageMap,
        legendsData,
      ),
      GenerateKnownRisksToSelectedElement(selectedAssetDetails, scenarios, hazardDetails),
      GenerateSelectedElementDetailsContent({
        assetDetails: selectedAssetDetails,
        addPageBreak: showFullPageMap,
      }),
      GenerateSelectedElementExposureBySelectedRisks(
        selectedAssetDetails,
        prefer_risk_term,
        prefer_hazard_term,
        projectedExposureChartState,
        bodySize,
        hazardLayers.length > 0,
      ),
      GenerateKnownRiskListForSelectedElement(
        selectedAssetDetails,
        hazardDetails,
        scenarios,
        legendsData['vulnerability'].sections[0].items ?? [],
        prefer_risk_term,
        prefer_hazard_term,
        bodySize.width,
      ),
      ...(await GenerateDataSourceContent2(
        [selectedAssetLayer, ...hazardLayers],
        prefer_hazard_term,
      )),
      {
        text: [
          { text: 'Disclaimer: ', font: 'Inter700' },
          {
            text: Disclaimer,
          },
        ],
        marginTop: 20,
        unbreakable: true,
      },
    ],

    defaultStyle: reportDefaultStyle,
    footer: reportFooter(getElementAddress(selectedAssetDetails.asset?.identifyingInformation)),
    styles: reportStyles,
    pageMargins: [pageMargins.left, pageMargins.top, pageMargins.right, pageMargins.bottom],
  }
}
