/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'

import { toggleButton } from './VerticalThumbTab.styles'
import { Icon } from '@src/components/Atoms/Icon'
import { useMemo } from 'react'

interface ToggleDrawerButtonProps {
  arrowPosition?: 'left' | 'right' | 'up' | 'down'
  side?: 'left' | 'right' | 'top' | 'bottom'
  disabled?: boolean
  onClick: () => void
  initialIcon?: string | null
  expanded?: boolean
  label?: string
  color?: string
}

export const ToggleArrowButton = ({
  arrowPosition,
  side = 'left',
  onClick,
  disabled = false,
  initialIcon = null,
  expanded = false,
  label,
  color,
  ...rest
}: ToggleDrawerButtonProps) => {
  const theme = useTheme()

  const icon = useMemo(() => {
    if (initialIcon !== null) return initialIcon
    switch (arrowPosition) {
      case 'left':
        return 'ChevronLeft'
      case 'right':
        return 'ChevronRight'
      case 'up':
        return 'ChevronUp'
      case 'down':
        return 'ChevronDown'
      default:
        return null
    }
  }, [arrowPosition, initialIcon])

  return (
    <Box
      component="button"
      css={toggleButton({ theme, disabled, expanded, icon, side, color })}
      onClick={onClick}
      {...rest}
    >
      <Box className="button-background">
        {icon !== null && (
          <Icon
            size="large"
            iconName={icon}
            colour={
              disabled
                ? theme.palette.grey[100]
                : expanded
                ? theme.palette.primary.main
                : theme.palette.white.main
            }
          />
        )}
        {label && <Box className="label">{label}</Box>}
      </Box>
      <div className="tutorial-sidedrawer-tab" />
    </Box>
  )
}
