/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { RiskSideDrawerContent } from '@molecules/RiskSideDrawerContent'
import { RiskSideDrawerState, setSideDrawerIsOpen } from '@redux/riskSideDrawer/riskSideDrawerSlice'
import { CombinedState } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sideDrawerContainer } from '../RiskPage/RiskPage.styles'
import {
  strategyPageContainer,
  strategyPageContent,
  strategyPageContentContainer,
  strategyPageContentWrapper,
  topNavbarContainer,
} from './StrategyPage.styles'
import { LinkTabProps, TopNavbar } from '@src/components/Molecules/TopNavbar'
import { SideDrawer } from '@src/components/Molecules/SideDrawer'

export const StrategyPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const links: LinkTabProps[] = useMemo(() => {
    return [
      {
        value: 'review',
        label: 'Review',
        disabled: true,
        handleClick: () => navigate('review'),
      },
      {
        value: 'horizon-scan',
        label: 'Horizon Scan',
        handleClick: () => navigate(`horizon-scan`),
      },
      {
        value: 'strategy',
        label: 'Strategy',
        disabled: true,
        handleClick: () => navigate(`strategy`),
      },
      {
        value: 'action-plan',
        label: 'Action Plan',
        disabled: true,
        handleClick: () => navigate(`action-plan`),
      },
    ]
  }, [navigate])

  const activeLink = useMemo(() => {
    return links.find((link) => {
      return location.pathname.includes(`strategy/${link.value}`)
    })
  }, [links, location.pathname])

  const sideDrawerStore = useSelector(
    (
      state: CombinedState<{
        sideDrawer: RiskSideDrawerState
      }>,
    ) => state.sideDrawer,
  )

  if (location.pathname === '/strategy/') {
    return <Navigate to="/strategy/horizon-scan" replace state={{ from: location }} />
  }

  return (
    <Box css={strategyPageContainer}>
      <Box css={sideDrawerContainer}>
        <SideDrawer
          isOpen={sideDrawerStore.isOpen}
          handleToggle={() => dispatch(setSideDrawerIsOpen(!sideDrawerStore.isOpen))}
          initialWidth={600}
        >
          <RiskSideDrawerContent />
        </SideDrawer>
      </Box>
      <Box css={strategyPageContentWrapper}>
        <Box css={strategyPageContentContainer({ theme })}>
          <Box css={topNavbarContainer}>
            <TopNavbar links={links} initialActiveLink={activeLink?.value ?? 'review'} />
          </Box>
          <Box css={strategyPageContent({ theme })}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
