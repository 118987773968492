/** @jsxImportSource @emotion/react */
import { Expression, Map, LineLayer } from 'mapbox-gl'
import { MapLayerStyle } from '@contexts/RiskMapContext'
import { byAssetIdIn } from '../RiskMapView.utilities'

export interface LineLayerProps {
  map: Map
  layerStyle: MapLayerStyle
  id: string
  beforeId: string
  isVisible: boolean
  sourceLayer: string
  colorExpression: Expression
  lineOpacityExpression: Expression
  assetIds?: { [asset_id: number]: boolean }
  sortKeyExpression?: Expression
  hasFilters?: boolean
  shouldUpdate?: boolean
}

export const addLineLayer = ({
  map,
  layerStyle,
  id,
  beforeId,
  isVisible,
  sourceLayer,
  colorExpression,
  lineOpacityExpression,
  assetIds,
  sortKeyExpression,
  hasFilters = false,
  shouldUpdate = false,
}: LineLayerProps) => {
  const layerData: LineLayer = {
    id,
    type: 'line',
    source: id,
    'source-layer': sourceLayer,
    layout: {
      visibility: isVisible ? 'visible' : 'none',
      'line-join': 'round',
      'line-cap': 'round',
      'line-sort-key': sortKeyExpression || 0,
    },
    paint: {
      'line-width': ['interpolate', ['exponential', 2], ['zoom'], 1, 0.11, 15, 7, 20, 30],
      'line-opacity': lineOpacityExpression,
      ...layerStyle,
    },
  }
  if (shouldUpdate) {
    if (layerData.paint) {
      for (const paintProp of Object.entries(layerData.paint)) {
        map.setPaintProperty(id, paintProp[0], paintProp[1])
      }
    }
    if (layerData.layout) {
      for (const layoutProp of Object.entries(layerData.layout)) {
        map.setLayoutProperty(id, layoutProp[0], layoutProp[1])
      }
    }
    if (!hasFilters && !assetIds) map.setFilter(id, null)
  } else {
    map.addSource(id, { type: 'vector', url: `mapbox://${id}` })
    map.addLayer(layerData, beforeId)
  }
  if (hasFilters && assetIds) {
    const visibilityExpression: Expression = ['boolean', byAssetIdIn(assetIds)]
    map.setFilter(id, visibilityExpression)
  }
  if (colorExpression) {
    map.setPaintProperty(id, 'line-color', colorExpression)
  }
}
