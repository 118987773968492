/** @jsxImportSource @emotion/react */
import { accordionHeader } from '../HazardControls.styles'
import { HazardDetail } from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog'
import { CombinedHazardDetail, getScenarioByHazardDetail } from '../HazardControls'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { usePreferences } from '@contexts/PreferencesContext'

export interface HazardControlFormHeaderProps {
  hazardDetails: HazardDetail | CombinedHazardDetail
}

export const HazardControlFormHeader = ({ hazardDetails }: HazardControlFormHeaderProps) => {
  const { layers } = useSelector((state: RootState) => state.riskMap)
  const mapLayer = getScenarioByHazardDetail(layers, hazardDetails as CombinedHazardDetail)
  const { use_hazard_name } = usePreferences()

  return (
    <span css={accordionHeader}>
      {use_hazard_name.flag
        ? mapLayer?.hazard_name ?? mapLayer?.display_name ?? hazardDetails.title
        : mapLayer?.display_name || hazardDetails.title}
      <span>{mapLayer?.scenario_name}</span>
    </span>
  )
}
