import { css } from '@emotion/react'
import { SIDEBAR_MENU_WIDTH } from '@src/app-constants'

export const mapViewContainer = (
  left_offset: number,
  right_offset: number,
  left_changing: boolean,
  right_changing: boolean,
  mapIs3D: boolean,
) => {
  return css`
    width: calc(100% - ${SIDEBAR_MENU_WIDTH}px);
    height: 100%;

    position: fixed;
    z-index: 99;

    & .mapboxgl-popup {
      z-index: 100;
    }

    & .measureTooltip {
      background-color: white;
      border-radius: 4px;
    }

    & .measureTooltip > .mapboxgl-popup-content {
      width: unset !important;
      padding: 3px 6px !important;
    }

    & .measureTooltip > .mapboxgl-popup-tip {
      display: none;
    }

    & .mapboxgl-ctrl-group.mapboxgl-ctrl:nth-of-type(2) {
      margin-top: 4px;
    }

    & .mapboxgl-ctrl-bottom-left {
      transition: opacity 0.1s;
      opacity: ${left_changing ? 0 : 1};
      left: ${left_offset + 4}px;
      display: flex;
      gap: 12px;

      & .mapboxgl-ctrl {
        margin: 0;
        position: relative;

        & .mapboxgl-ctrl-logo {
          order: 1;
          transform: translateY(-4px);
          box-sizing: content-box;
        }
        &.mapboxgl-ctrl-attrib {
          width: auto;
          order: 2;
          padding: 0 8px;
          white-space: nowrap;
        }
      }

      & .mapboxgl-ctrl-scale {
        position: absolute;
        left: 84px;
        height: 22px;
        bottom: 36px;
        pointer-events: none;
        opacity: ${mapIs3D ? 0 : 1};
        transition: opacity 0.1s;
      }
    }

    & .mapboxgl-ctrl-bottom-right {
      transition: opacity 0.1s;
      opacity: ${left_changing ? 0 : 1};
      left: ${100 + left_offset}px;
      right: auto;
    }

    & .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
      margin: 4px 8px;
      transition: opacity 0.1s;
      opacity: ${right_changing ? 0 : 1};
      margin-right: ${right_offset + 4}px;

      & button {
        width: 30px;
        height: 30px;
      }
    }
  `
}

export const selectionMeasurementContainer = css`
  position: absolute;
  top: 8px;
  right: 48px;
  z-index: 99;
  background-color: white;
  padding: 4px 8px;
  border-radius: 4px;
  display: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
`

export const selectionMeasurementHeader = css`
  margin: 4px 0;
  color: #1c3855;
  font-size: 0.9rem;
  border-bottom: 1px solid #1c3855;
  padding-bottom: 4px;
`
