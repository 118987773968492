import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const mapOverlayContainer = () => css`
  flex: 1 1 auto;
  position: relative;

  .tutorial-map {
    position: absolute;
    inset: 8px;
    pointer-events: none;
    user-select: none;
  }
`

export const mapOverlay = css`
  position: absolute;
  z-index: 100;

  display: flex;
  gap: 12px;
`

export const mapTopLeftContainer = ({ theme }: { theme: Theme }) => css`
  ${mapOverlay}

  top: ${theme.spacing(4)};
  left: ${theme.spacing(2)};
  flex-wrap: wrap;
  width: 200px;

  .MuiSelect-select {
    background-color: ${theme.palette.background.paper};
  }
`

export const mapBottomRightContainer = ({ theme }: { theme: Theme }) => css`
  ${mapOverlay}
  pointer-events: none;
  flex-direction: row-reverse;
  bottom: ${theme.spacing(1)};
  right: ${theme.spacing(1)};
`

export const mapBottomLeftContainer = css`
  ${mapOverlay}

  bottom: 0;
  left: 0;
`

export const mapTopRightContainer = css`
  ${mapOverlay}

  top: 8px;
  right: 8px;
`

export const prioritisationLegend = css`
  display: flex;
  flex-direction: column;
  padding: 8px;
  padding-top: 4px;
  background-color: white;
  border-radius: 4px;
  font-weight: 600;
  gap: 4px;
  font-size: 12px;
  padding-right: 20px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

  .legend {
    font-weight: 400;

    > div:first-of-type .legend-color {
      border-radius: 4px 4px 0 0;
    }
    > div:last-of-type .legend-color {
      border-radius: 0 0 4px 4px;
    }
  }
`
