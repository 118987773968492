/** @jsxImportSource @emotion/react */
import { useSnackbars } from '@contexts/SnackbarContext'
import { useMap } from '@contexts/RiskMapContext'
import { generateReport } from './Report'
import { MenuItem, useTheme } from '@mui/material'
import 'pdfmake/build/pdfmake'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { getConsequencePalette } from '../../Charts/utils/utils'
import { usePreferences } from '@contexts/PreferencesContext'
import { exportButton } from './ExportRiskSummaryXlxs.styles'
const pdfMake = window['pdfMake']

pdfMake.fonts = {
  Inter400: {
    normal: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Regular-400.ttf`,
    bold: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Bold-700.ttf`, // Used in SVGs annoyingly
  },
  Inter500: {
    normal: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Medium-500.ttf`,
  },
  Inter600: {
    normal: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-SemiBold-600.ttf`,
  },
  Inter700: {
    normal: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Bold-700.ttf`,
  },
}

export const RiskSummaryReportButton = () => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const { layers, drawAreas, legendsData } = useSelector((state: RootState) => state.riskMap)
  const projectedRiskChartState = useSelector((state: RootState) => state.projectedRiskChartState)
  const { term_preference } = usePreferences()
  const { clientName } = useSelector((state: RootState) => state.user)
  const { regionMasks, map, cascadingAssetNDays } = useMap()
  const vulnerabilityPalette = useSelector(
    (state: RootState) => state.riskMap.legendsData['vulnerability'],
  )
  const consequencePalette = getConsequencePalette(vulnerabilityPalette) || {}

  const isMinorMajor = useSelector((state: RootState) =>
    state.riskMap.legendsData.vulnerability.sections.some(
      (section) =>
        section.items &&
        section.items.filter((item) => item.label === 'Minor' || item.label === 'Major').length > 0,
    ),
  )

  const handleClick = async () => {
    displaySnackbar({
      message: 'Generating report...',
      type: 'info',
    })
    pdfMake
      .createPdf(
        await generateReport(
          layers,
          drawAreas,
          theme,
          regionMasks,
          consequencePalette,
          legendsData,
          map,
          clientName,
          term_preference.hazard,
          projectedRiskChartState,
          cascadingAssetNDays,
          isMinorMajor,
        ),
      )
      .download(
        clientName == 'christchurch'
          ? 'Resilience Explorer Hazard Summary'
          : `Resilience Explorer Risk Summary`,
      )
  }

  return (
    <MenuItem css={exportButton({ theme })} onClick={handleClick}>
      Report (PDF)
    </MenuItem>
  )
}
