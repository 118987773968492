import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { parse as html2pdfmake } from 'html2pdfmake'
import { Content, ContentBase } from 'pdfmake/interfaces'
import { MapTooltipProps } from '../MapView/RiskMapView/Tooltip/RiskMapTooltip'
import { CssStyles } from 'html2pdfmake/lib/types/global.types'

export const HorizontalBar = (width: number, marginBottom = 10): Content => {
  return {
    canvas: [
      {
        type: 'line',
        x1: 0,
        y1: 5,
        x2: width,
        y2: 5,
        lineWidth: 1,
        lineColor: '#e6e8ff', // Change this to the color you want
      },
    ],
    margin: [0, 0, 0, marginBottom],
  }
}

export const parseHtml = (html: string, fontSize?: number): Content => {
  const styles: CssStyles = {
    p: {
      'margin-top': '0px',
      'margin-bottom': '0px',
    },
  }

  // html2pdfmake default font-size is 12 so override it if need be
  if (fontSize) {
    styles['p']['font-size'] = `${fontSize}pt`
    styles['p']['line-height'] = 1.1
  }

  const { content } = html2pdfmake('<p>' + html + '</p>', { styles })
  return content as Content
}

// Figma uses rectangles with fill patterns for rendering bitmaps when it exports SVGs
// There is a bug in PDFMake when it is minified which breaks pattern filling so these images appear black
// This code modifies the SVG to use image elements directly instead of rectangles like so:

// <svg>
// from:
//   <rect x="-136" y="-9" width="1277" height="851" fill="url(#pattern0_456_3)"/>
// to:
//   <use href="image0_456_3" transform="translate(-136 -9) scale(1277 851)"/>
//   <defs>
//     <pattern id="pattern0_456_3" patternContentUnits="objectBoundingBox" width="1" height="1">
//       <use xlink:href="#image0_456_3" transform="matrix(0.000666406 0 0 0.001 -0.133085 0)"/>
//     </pattern>
// from:
//     <image id="image0_456_3" width="1900" height="1000" xlink:href="data:image/jpeg;base64,/9j .... "/>
// to:
//     <image id="image0_456_3" width="1900" height="1000" transform="matrix(0.000666406 0 0 0.001 -0.133085 0)" xlink:href="data:image/jpeg;base64,/9j .... "/>
//   </defs>
// </svg>

export const convertFigmaPatternsToImages = (svg: string): string => {
  let toReturn = svg

  // Find all rectangles that use a pattern fill and record the pattern id and dimensions
  const rectsWithPatterns =
    /<rect (x="(.*?)" )?(y="(.*?)" )?width="(.*?)" height="(.*?)" fill="url\(#(pattern.*?)\)"/g

  const matches = svg.matchAll(rectsWithPatterns)
  const patternImages: {
    [key: string]: {
      x: string
      y: string
      width: string
      height: string
      imageId?: string
      transform?: string
    }
  } = {}

  for (const match of matches) {
    // The <rect> may have optional x and y attributes when they are '0' so check they exist with match[1] for x and match[3] for y.
    // The value of x and y are match[2] and match[4] respectively.
    let x = '0'
    let y = '0'
    if (match[1]) x = match[2]
    if (match[3]) y = match[4]

    patternImages[match[7]] = { x, y, width: match[5], height: match[6] }
  }

  // Look for the pattern definitions used by the rectangles and record the image ids and transforms
  for (const patternId in patternImages) {
    const patternRegex = new RegExp(
      `<pattern id="${patternId}".*?>\\n\\s*<use xlink:href="#(image.*?)" transform="(.*?)".*?/>`,
      'm',
    )
    const patternMatches = svg.match(patternRegex)
    if (patternMatches) {
      patternImages[patternId].imageId = patternMatches[1]
      patternImages[patternId].transform = patternMatches[2]
    }
  }

  // Replace the rectangles with a reference to the image translating and scaling based on the rectangle's dimensions and position.
  // Add the pattern's transform directly to the image
  for (const patternId in patternImages) {
    const replaceRectRegEx = new RegExp(`<rect.*?fill="url\\(#${patternId}\\)".*?/>`)
    const r = patternImages[patternId]
    toReturn = svg.replace(
      replaceRectRegEx,
      `<use href="#${r.imageId}" transform="translate(${r.x} ${r.y}) scale(${r.width} ${r.height})"/>`,
    )

    const replaceImageRegEx = new RegExp(`<image id="${r.imageId}"`)
    toReturn = toReturn.replace(
      replaceImageRegEx,
      `<image id="${r.imageId}" transform="${r.transform}" `,
    )
  }

  return toReturn
}

export const getLayerIcons = async (layers: MapLayer[]): Promise<{ [layerId: string]: string }> => {
  const toReturn: { [layerId: string]: string } = {}

  const defaultIconResponse = await fetch(`${PUBLIC_ASSETS_BASE_URL}/icons/home.svg`)

  const defaultIconSvg = await defaultIconResponse.text()

  await Promise.all(
    layers.map(async (layer): Promise<void> => {
      if (!layer.icon) return
      const iconId = layer.icon.replace('s3://', '').replace('.svg', '').toLowerCase()
      const iconSvgResponse = await fetch(`${PUBLIC_ASSETS_BASE_URL}/icons/${iconId}.svg`)

      if (iconSvgResponse.ok) {
        const iconSvg = await iconSvgResponse.text()
        toReturn[iconId] = iconSvg
      } else {
        toReturn[iconId] = defaultIconSvg
      }
    }),
  )
  toReturn['default'] = defaultIconSvg

  return toReturn
}
export interface Size {
  width: number
  height: number
}

export const Disclaimer =
  'Urban Intelligence® Ltd does not accept any responsibility or liability for any direct, indirect, incidental, consequential, special, exemplary or punitive damage or for any loss of profit, income or any intangible losses or any claims, costs, expenses or damages, whether in contract, tort (including negligence), equity or otherwise, arising directly or indirectly from or connected with your use of this document or your reliance on information contained in this document.'

export interface AssetDetails {
  asset: MapTooltipProps['asset'] | undefined | null
  hazards: MapTooltipProps['hazards'] | undefined | null | undefined | null
}

export const getElementAddress = (
  identifyingInformation:
    | {
        [key: string]: string | number
      }
    | null
    | undefined,
): string => {
  // Elements don't share standard fields across clients
  // so this finds the first available field and returns.
  // Be prepared to keep adding to the below and be aware
  // the order matters

  const address = identifyingInformation
    ? identifyingInformation['Address'] ??
      identifyingInformation['Road Id'] ??
      identifyingInformation['Name'] ??
      identifyingInformation['Description'] ??
      null
    : null

  if (address) return address.toString()
  return ''
}

interface SvgOptions extends ContentBase {
  width?: number | undefined
  height?: number | undefined
  fit?: [number, number] | undefined
}

// This mainly avoids the PDF generator crashing when result is an empty string
export const makeSvg = (svgSource: HTMLDivElement | string, options?: SvgOptions): Content => {
  let svgContent = ''
  if (typeof svgSource == 'string') svgContent = svgSource
  else svgContent = svgSource.innerHTML

  if (svgContent == '') return []

  return { svg: svgContent, ...options }
}

export const shadedTextBox = (text: string): Content => {
  const cellHeight = 30
  const cellPaddingTop = 8.79

  return {
    table: {
      heights: cellHeight - 2 - cellPaddingTop,
      widths: ['*'],
      body: [[{ text, style: 'bodyBold' }]],
    },
    layout: {
      fillColor: () => '#E7EaEd',
      vLineWidth: () => 0,
      hLineWidth: () => 0,
      paddingTop: () => cellPaddingTop,
      paddingLeft: () => 12,
    },
  }
}
