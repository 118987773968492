import { MapLayer, updateMapStateProperty } from '@redux/riskMap/riskMapSlice'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'

import * as turf from '@turf/turf'
import { Map } from 'mapbox-gl'
import { removeSelectedMarkerFromMapIfExists } from '../../../components/Molecules/MapView/GenericMapView/GenericMapView.utilities'
import { RegionOption } from '../../../components/Molecules/RegionFilter'
import { Position } from 'geojson'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'

export interface RemoveMaskLayerProps {
  map?: Map
}

export function handleRemoveRegionMasksLayers({ map }: RemoveMaskLayerProps) {
  if (!map) return

  const regionMaskLayers = map
    ?.getStyle()
    ?.layers.filter((layer) => layer.id.includes('regionMask'))

  if (regionMaskLayers) {
    try {
      regionMaskLayers.forEach((mask) => {
        map.removeLayer(mask.id)
        map.removeSource(mask.id)
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Couldn't find mask layer id: `, error)
    }
  }
}

export interface AddMaskLayerProps {
  map?: Map
  regionGeometry: { [key: string]: Position[][] }
}

export async function loadRegionGeometry(
  regions: RegionOption[],
): Promise<{ [key: string]: Position[][] }> {
  const fetchRegionGeometries = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/regions/geometries/${regions.map((region) => region.region).join(',')}`,
      )

      return response.data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return {}
    }
  }
  const result = await fetchRegionGeometries()
  return result.reduce(
    (
      acc: { [key: string]: Position[][] },
      region: {
        region: string
        geometry: Position[][]
      },
    ) => {
      acc[region.region] = region.geometry
      return acc
    },
    {},
  )
}

export function handleAddRegionMasksLayers({ regionGeometry, map }: AddMaskLayerProps) {
  if (!map || !regionGeometry) return

  // create array of geomtries[0] for each region
  const geometryArray = Object.values(regionGeometry).map((geometry) => geometry[0])
  const regionMaskLayerId = 'regionMask-backdrop-layer'
  const layerToAdd: mapboxgl.AnyLayer = {
    id: regionMaskLayerId,
    type: 'fill',
    source: {
      type: 'geojson',
      data: turf.polygon([
        [
          [-180, -90],
          [-180, 90],
          [180, 90],
          [180, -90],
          [-180, -90],
        ],
        ...geometryArray,
      ]),
    },
    layout: {},
    paint: {
      'fill-color': '#5c5c5c',
      'fill-opacity': 0.6,
    },
  }
  map.addLayer(layerToAdd)
}

export interface UpdateLayersProps {
  layers: MapLayer[]
  dispatch: Dispatch<AnyAction>
}

export function handleUpdateLayers({ layers, dispatch }: UpdateLayersProps) {
  dispatch(updateMapStateProperty({ layers }))
}

export interface RemoveLayerByIdProps {
  layerId: string
  map: Map
  dispatch?: Dispatch<AnyAction>
  layers?: MapLayer[]
}

export function handleRemoveLayerById({ layerId, map, layers, dispatch }: RemoveLayerByIdProps) {
  if (!map.getLayer(layerId) || !map.getSource(layerId)) return
  const foundLayer = map.getStyle().layers.find((layer) => layer.id === layerId)
  // for removing the extra layers attached to some types
  if (foundLayer?.type === 'symbol') {
    if (map.getLayer(`${layerId}-zoomed-out`)) map.removeLayer(`${layerId}-zoomed-out`)
  } else if (foundLayer?.type === 'fill') {
    if (map.getLayer(`${layerId}-line`)) map.removeLayer(`${layerId}-line`)
    if (map.getLayer(`${layerId}-extrusion`)) map.removeLayer(`${layerId}-extrusion`)
  }

  map.removeLayer(layerId)
  map.removeSource(layerId)

  /* If the function needs to update redux, run  */
  if (layers && dispatch) {
    const updatedLayers = layers.filter((layer) => layer.id !== layerId)
    dispatch(updateMapStateProperty({ layers: updatedLayers }))
  }
}

export interface SetLayerVisibilityProps {
  showLayers?: MapLayer[]
  hideLayers?: MapLayer[]
  map: Map
  allLayers: MapLayer[]
  dispatch: Dispatch<AnyAction>
}

export function handleSetLayerVisibility({
  showLayers = [],
  hideLayers = [],
  map,
  dispatch,
  allLayers,
}: SetLayerVisibilityProps) {
  const getId = (layer: MapLayer) => layer.tilesets[0].id ?? layer.id

  // Filter showLayers out of hideLayers
  if (showLayers.length && hideLayers.length) {
    const shownIds = showLayers.map((layer) => getId(layer))
    hideLayers = hideLayers.filter((layer) => !shownIds.includes(getId(layer)))
  }

  for (const layer of [...hideLayers, ...showLayers]) {
    const isVisible = showLayers.includes(layer)

    if (isVisible === layer.visible) continue
    // hazards don't have passed IDs, so we need to handle them differently
    if (layer.layerType === 'hazard') {
      for (const tileset of layer.tilesets) {
        map.setLayoutProperty(tileset.id, 'visibility', isVisible ? 'visible' : 'none')
      }
      //map.setLayoutProperty(getId(layer), 'visibility', isVisible ? 'visible' : 'none')
      // for contextual hazards/info hazards
      if (layer.infoLookupTileset?.length) {
        for (const lookupTileset of layer.infoLookupTileset) {
          map.setLayoutProperty(lookupTileset.id, 'visibility', isVisible ? 'visible' : 'none')
        }
      }
    } else {
      const foundLayer = map.getStyle().layers.find((maplayer) => getId(layer) === maplayer.id)

      // for changing the extra layers attached to some types
      if (foundLayer?.type === 'symbol') {
        if (map.getLayer(`${getId(layer)}-zoomed-out`))
          map.setLayoutProperty(
            `${getId(layer)}-zoomed-out`,
            'visibility',
            isVisible ? 'visible' : 'none',
          )
      }
      if (foundLayer?.type === 'fill') {
        if (map.getLayer(`${getId(layer)}-line`))
          map.setLayoutProperty(
            `${getId(layer)}-line`,
            'visibility',
            isVisible ? 'visible' : 'none',
          )
        if (map.getLayer(`${getId(layer)}-extrusion`))
          map.setLayoutProperty(
            `${getId(layer)}-extrusion`,
            'visibility',
            isVisible ? 'visible' : 'none',
          )
      }
      map.setLayoutProperty(getId(layer), 'visibility', isVisible ? 'visible' : 'none')
    }
  }

  const shownIds = showLayers.map((layer) => getId(layer))
  const hiddenIds = hideLayers.map((layer) => getId(layer))
  const updatedLayers = allLayers.map((layer) => {
    if (
      shownIds.includes(getId(layer)) ||
      layer.tilesets?.some((tileset) => shownIds.includes(tileset.id))
    ) {
      return {
        ...layer,
        visible: true,
      }
    } else if (
      hiddenIds.includes(getId(layer)) ||
      layer.tilesets?.some((tileset) => hiddenIds.includes(tileset.id))
    ) {
      return {
        ...layer,
        visible: false,
      }
    }

    return layer
  })

  dispatch(updateMapStateProperty({ layers: updatedLayers }))
}

export interface ToggleLayerVisibilityProps {
  layer: MapLayer
  layers: MapLayer[]
  dispatch: Dispatch<AnyAction>
}

export function handleToggleLayerVisibility({
  layer,
  layers,
  dispatch,
}: ToggleLayerVisibilityProps) {
  const updatedLayers = layers.map((item) => {
    if (item.id === layer.id) {
      return {
        ...layer,
        visible: !layer.visible,
      }
    }
    return { ...layer }
  })

  dispatch(updateMapStateProperty({ layers: updatedLayers }))
}

export function handleToggleLayerInteractivity({
  layer,
  layers,
  dispatch,
}: ToggleLayerVisibilityProps) {
  const updatedLayers = layers.map((item) => {
    if (item.id === layer.id || item.tilesets.some((tileset) => tileset.id === layer.id)) {
      return {
        ...layer,
        interactivityDisabled: !layer.interactivityDisabled,
      }
    }
    return { ...item }
  })
  dispatch(updateMapStateProperty({ layers: updatedLayers }))
}

export function handleClearRemovedLayers({ map, layers }: { map: Map; layers: MapLayer[] }) {
  if (!map) return
  const allLayers = map.getStyle()?.layers
  if (!allLayers) return

  const customLayers = allLayers.filter((layer) => layer.id.includes('urbanintelligence'))

  // get list of custom sources that are not found in layers
  const filteredCustomLayerIds = customLayers
    .filter((cLayer) => {
      const foundLayer = layers.find((layer) => layer.id === cLayer.id)
      return !foundLayer
    })
    .map((layer) => layer.id)

  for (const filteredCustomLayerId of filteredCustomLayerIds) {
    handleRemoveLayerById({ layerId: filteredCustomLayerId, map })
  }

  handleRemoveRegionMasksLayers({ map })
  removeSelectedMarkerFromMapIfExists()
}

export function handleClearMap({ map }: { map: Map }) {
  if (!map) return

  const mapStyle = map.getStyle()
  if (!mapStyle) return

  const allLayers = mapStyle.layers

  if (!allLayers) return

  const customLayers = allLayers.filter((layer) => layer.id.includes('urbanintelligence'))
  const allSources = mapStyle.sources
  const customSources = Object.keys(allSources).filter((sources) =>
    sources.includes('urbanintelligence'),
  )

  customLayers.forEach((layer) => {
    map.removeLayer(layer.id)
  })

  customSources.forEach((source) => {
    map.removeSource(source)
  })

  handleRemoveRegionMasksLayers({ map })
  removeSelectedMarkerFromMapIfExists()
}
