/** @jsxImportSource @emotion/react */
import { Box, Typography, useTheme } from '@mui/material'
import { Modal } from '@src/components/Atoms/Modal'
import {
  wizardModal,
  wizardModalHeader,
  wizardModalTitle,
  wizardModalContent,
  wizardModalActionButtons,
  wizardModalContainer,
} from './WizardModal.styles'
import { useEffect, useState } from 'react'
import { Button } from '@src/components/Atoms/Button'
import { ValuesForm } from './ValuesForm'
import { usePrioritisationContext } from '@contexts/PrioritisationContext'

export const WizardModal = ({
  open,
  completeWizard,
  returnFromWizard,
}: {
  open: boolean
  completeWizard: () => void
  returnFromWizard: () => void
}) => {
  const theme = useTheme()
  const handleClose = () => {
    // handle closing the modal
  }
  const { setValueWeightings } = usePrioritisationContext()
  const [wizardStage, setWizardStage] = useState(0)
  const [updatedValueWeightings, setUpdatedValueWeightings] = useState({})

  const stages = [
    {
      title: 'Which values are most important?',
      subtitle:
        'Use the sliders to choose to importance of each value set, use the balance button to weight them equally, or select the weightings your organisation has set. You can change the weightings again later.',
      content: <ValuesForm updatedValueWeightings={setUpdatedValueWeightings} />,
    },
  ]

  useEffect(() => {
    setWizardStage(0)
  }, [open])
  return (
    <Modal css={wizardModalContainer} open={open} onClose={handleClose}>
      <Box css={wizardModal()}>
        <Box css={wizardModalHeader()}>
          <Box css={wizardModalTitle()}>
            <Typography variant="h1">{stages[wizardStage].title}</Typography>
          </Box>
          <Typography variant="body1">
            Step {wizardStage + 1} of {stages.length}
          </Typography>
        </Box>
        <Box css={wizardModalContent()}>
          <Typography variant="body1">{stages[wizardStage].subtitle}</Typography>
          {stages[wizardStage].content}
        </Box>
        <Box css={wizardModalActionButtons({ theme })}>
          <Button
            variant="text"
            color="uintel"
            onClick={() => {
              if (wizardStage === 0) {
                returnFromWizard()
                return
              }
              setWizardStage(wizardStage - 1)
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="uintel"
            onClick={() => {
              if (wizardStage === stages.length - 1) {
                setValueWeightings(updatedValueWeightings)
                completeWizard()
                return
              }
              setWizardStage(wizardStage + 1)
            }}
          >
            {wizardStage === stages.length - 1 ? 'Confirm' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
