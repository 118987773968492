import { Tileset, VectorTileset } from '@molecules/LayersControl/LayersControl.types'
import { RootState } from '@redux/store'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BASE_MAP_LIGHT_WITH_HOUSE_NUMBERS } from '@src/app-constants'
import { FutureRiskChart } from '@src/components/Molecules/RiskSideDrawerContent/tabs/InformativePanel/RiskSummaryTab/RiskContent/FutureRisk'
import { Feature } from '@turf/turf'
import { Icon } from '@src/components/Atoms/Icon'
import { MapboxOptions, MapboxGeoJSONFeature } from 'mapbox-gl'
import { RegionOption } from '@src/components/Molecules/RegionFilter'
import {
  DialogData,
  LegendsData,
} from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog'
import { VisibleAssets } from '@src/components/Molecules/MapView/RiskMapView/RiskMapView.utilities'

export type MapLayerType = 'hazard' | 'asset' | 'information'

export type AssetByHazardExposureAndVulnerabilityChartData = {
  categories: string[]
  stacks: string[]
  title: string
  xLabel: string
  yLabel: string
}

export type MapLayer = {
  id: string
  display_name: string
  assetTag: string
  tilesets: Tileset[]
  legend: string
  visible: boolean
  helpFileName: string
  type: string
  layerType: MapLayerType
  icon: Icon
  infoLookupTileset?: VectorTileset[]
  hazard_id?: string
  addedByKnownRisks?: boolean
  parameters?: {
    [index: string]: number | string
  }
  filterable_information?: {
    key: string
    label: string
    type: string
    lookup?: boolean
  }[]
  interactivityDisabled?: boolean
  is_cascading?: boolean
  n_days?: {
    [key: string]: number[]
  }
  hazard_name?: string
  last_updated?: string
  metric_type?: string
  owner?: string
  source?: string
  unit?: string
  hazard_sentence?: string
  scenario_name?: string
  hazard_title?: string
  hazardAreas?: string[]
  data_sources?: {
    title: string
    url: string
  }[]
  charts?: AssetByHazardExposureAndVulnerabilityChartData
  futureRiskChart?: FutureRiskChart
}

export type ViewOrientation = Pick<
  MapboxOptions,
  'center' | 'zoom' | 'pitch' | 'maxPitch' | 'zoom' | 'bearing'
>

export type DrawArea = Feature<GeoJSON.Geometry, object>

export type SimplifiedGeoJSONFeature = {
  type: string
  properties: unknown
  geometry: {
    type: string
    coordinates: number[]
  }
}

export interface MapLocation {
  latitude: number
  longitude: number
  zoom?: number
}

export interface MapState {
  drawAreas: DrawArea[]
  layers: MapLayer[]
  layerData: DialogData | null
  style: string
  visibleAssets: VisibleAssets
  legendsData: LegendsData
  selectedMarker: MapLocation | null
  canInteractWithLayers: boolean
  selectedFeature: SimplifiedGeoJSONFeature | null
  selectedLayer: MapLayer | null
  isFullScreen: boolean
  regions: RegionOption[]
}

export const riskMapInitialState: MapState = {
  style: BASE_MAP_LIGHT_WITH_HOUSE_NUMBERS,
  layers: [],
  layerData: null,
  drawAreas: [],
  visibleAssets: {},
  legendsData: {},
  selectedMarker: null,
  canInteractWithLayers: true,
  selectedFeature: null,
  selectedLayer: null,
  isFullScreen: false,
  regions: [],
}

export const riskMapSlice = createSlice({
  name: 'riskMapSlice',
  initialState: riskMapInitialState,
  reducers: {
    updateMapStateProperty: (state, action: PayloadAction<Partial<MapState>>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateSelectedFeature: (
      state,
      action: PayloadAction<{
        selectedFeature: MapboxGeoJSONFeature | null
        selectedLayer: MapLayer | null
      }>,
    ) => {
      return {
        ...state,
        selectedFeature: action.payload.selectedFeature as SimplifiedGeoJSONFeature,
        selectedLayer: action.payload.selectedLayer,
      }
    },
    updateSelectedLayer: (state, action: PayloadAction<MapLayer>) => {
      return {
        ...state,
        selectedLayer: action.payload,
      }
    },
    updateRegions: (state, action: PayloadAction<RegionOption[]>) => {
      return {
        ...state,
        regions: action.payload,
      }
    },
  },
})

export const cascadingLayersSelector = createSelector(
  (state: RootState) => state.riskMap.layers,
  (layers: MapLayer[]) => layers.filter((layer: MapLayer) => layer.is_cascading),
)

export const cascadingLayerPresent = createSelector(
  (state: RootState) => state.riskMap.layers,
  (layers: MapLayer[]) => {
    return !!layers && !!layers.length && layers.some((layer: MapLayer) => layer.is_cascading)
  },
)

export const { updateMapStateProperty, updateSelectedFeature, updateSelectedLayer, updateRegions } =
  riskMapSlice.actions

export const riskMapReducer = riskMapSlice.reducer
