/** @jsxImportSource @emotion/react */
import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
  buttonContainer,
  emptySideDrawerContainer,
  emptySideDrawerContent,
  emptyTitle,
  addLayersButton,
  addLayersFabIcon,
} from './EmptySideDrawer.styles'
import { useMap } from '@contexts/RiskMapContext'
import { Button } from '@src/components/Atoms/Button'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { Icon } from '@src/components/Atoms/Icon'
import { useTutorialContext } from '@contexts/TutorialContext'
import { Add } from '@mui/icons-material'
import { ImageWithFilters } from '@src/components/Atoms/ImageWithFilters'

export const EmptySideDrawer = ({
  text,
  type,
  buttonText,
}: {
  text: string
  type: 'select' | 'element' | 'hazard' | 'information'
  buttonText?: string
}) => {
  const theme = useTheme()
  const { setTutorialActive } = useTutorialContext()
  const { setShowAddLayersModalTab } = useMap()
  const { clientName } = useSelector((state: RootState) => state.user)

  if (type === 'select') {
    return (
      <>
        <Box css={emptySideDrawerContainer({ theme })}>
          <Box css={emptySideDrawerContent()}>
            <Icon
              colour={theme.palette.primary.main}
              iconName="s3://select-element.png"
              className="smallicon"
            />
            <Box>
              <Typography variant="subtitle1" css={emptyTitle({ theme })}>
                {text}
              </Typography>
              <Box css={buttonContainer}>
                <Button
                  onClick={() => setTutorialActive(true)}
                  title="Learn how to use the Risk Explorer"
                  variant="outlined"
                >
                  Tutorial
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box css={emptySideDrawerContainer({ theme })}>
        <Box css={emptySideDrawerContent()}>
          <ImageWithFilters
            src="/images/illustrations/emptySideDrawer.svg"
            alt="Woman looking for hazards"
            color={theme.palette.primary.main}
            hueStrength={0.9}
            lightenStrength={0.4}
            desaturateStrength={0.4}
            backgroundColor={theme.palette.primary.main + '20'}
            customCss={{
              backgroundColor: theme.palette.background.paper,
              paddingBottom: theme.spacing(4),
              opacity: 0.9,
            }}
          />
          <Box>
            <Typography variant="subtitle1" css={emptyTitle({ theme })}>
              {text}
            </Typography>
            <Box css={buttonContainer}>
              <IconButton
                size="small"
                onClick={() => setShowAddLayersModalTab(type)}
                css={addLayersButton({ theme })}
              >
                <Box css={addLayersFabIcon({ theme })}>
                  <Add fontSize="medium" />
                </Box>
                {buttonText}
              </IconButton>
              {clientName && (
                <Button
                  onClick={() => setTutorialActive(true)}
                  title="Learn how to use the Risk Explorer"
                  variant="outlined"
                >
                  Tutorial
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
