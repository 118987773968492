/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MuiTab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import { useState } from 'react'

import { TabContent } from './TabContent'
import { headerButtonsBox, tabsContainer, tabsMenu, tabsMenuContainer } from './Tabs.styles'
import { getTabMenuProps } from './Tabs.utils'

export interface Tab {
  label: string | JSX.Element | React.ReactNode
  content: JSX.Element | React.ReactNode
  disabled?: boolean
}

export interface TabsProps {
  tabs: Tab[]
  className?: string
  tabIndex?: number
  handleChangeTab?: (newValue: number) => void
  headerButtons?: (JSX.Element | React.ReactNode)[]
  color?:
    | 'uintel'
    | 'red'
    | 'pink'
    | 'orange'
    | 'green'
    | 'yellow'
    | 'black'
    | 'grey'
    | 'white'
    | 'blue'
    | 'primary'
    | 'secondary'
}

export const Tabs = ({
  tabs,
  className,
  tabIndex,
  handleChangeTab,
  headerButtons,
  color,
}: TabsProps) => {
  const theme = useTheme()
  const [localTabIndex, setLocalTabIndex] = useState(tabIndex ?? 0)

  return (
    <Box
      css={tabsContainer({ theme })}
      sx={{ color: color ? theme.palette[color][400] : undefined }}
      className={className}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
        css={tabsMenuContainer({ theme, color: color ? 'inherit' : theme.palette.primary.main })}
      >
        <MuiTabs
          variant="scrollable"
          css={tabsMenu()}
          value={tabIndex ?? localTabIndex}
          indicatorColor={color ?? 'primary'}
          onChange={(_e, newValue) =>
            handleChangeTab ? handleChangeTab(newValue) : setLocalTabIndex(newValue)
          }
          allowScrollButtonsMobile
          scrollButtons
        >
          {tabs.map(({ label, disabled = false }, index) => (
            <MuiTab
              key={index}
              label={label}
              disabled={disabled}
              {...getTabMenuProps(index, theme)}
            />
          ))}
        </MuiTabs>
        {headerButtons && <Box css={headerButtonsBox()}>{headerButtons}</Box>}
      </Box>

      {tabs.map(({ content }, index) => (
        <TabContent
          key={index}
          index={index}
          tabIndex={tabIndex ?? localTabIndex}
          css={css`
            width: 100%;
            height: calc(100% - 49px);
          `}
        >
          {content}
        </TabContent>
      ))}
    </Box>
  )
}
