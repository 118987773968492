/** @jsxImportSource @emotion/react */
import { Box, useTheme, Typography } from '@mui/material'
import {
  buttonsSection,
  chapterTitle,
  sectionFooter,
  sectionInlineImage,
  sectionTextContent,
  sectionTitle,
  sectionWideImage,
  sectionsContainer,
  stepContainerParent,
  stepTitle,
} from './StoryStep.styles'
import { SequencedStep, addCloudfrontUrl } from '../../Stories.utils'
import { SourceButton } from '@src/components/Molecules/RiskSideDrawerContent/components/SourceButton'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { Video } from '@src/components/Atoms/Video'
import { usePreferences } from '@contexts/PreferencesContext'
import { memo } from 'react'

export const StoryStep = memo(
  function StoryStepComp({
    sequencedStep,
    isActive,
    totalSteps,
    handleOnClick,
  }: {
    sequencedStep: SequencedStep
    isActive: boolean
    totalSteps: number
    handleOnClick: () => void
  }) {
    const theme = useTheme()
    const { term_preference } = usePreferences()

    return (
      <Box
        css={stepContainerParent({ theme, isActive })}
        onClick={!isActive ? handleOnClick : undefined}
      >
        <Box>
          {sequencedStep.chapterTitle !== undefined && (
            <Box css={chapterTitle({ theme })}>
              <Typography>{sequencedStep.chapterTitle}</Typography>
            </Box>
          )}
          <Typography css={stepTitle({ theme })}>{sequencedStep.stepTitle}</Typography>
          <Box css={sectionsContainer({ theme })}>
            {sequencedStep.sections.map((section, index) => (
              <Box key={index}>
                <Box css={sectionTextContent({ theme })}>
                  {section.sideImage !== undefined && (
                    <img
                      src={addCloudfrontUrl(section.sideImage)}
                      alt="media"
                      css={sectionInlineImage({ theme })}
                    />
                  )}
                  <Typography css={sectionTitle({ theme })}>{section.header}</Typography>
                  {section.content !== undefined && section.content !== '' && (
                    <UiMarkdown>{section.content}</UiMarkdown>
                  )}
                </Box>
                <Box key={index}>
                  {section.videoUrl !== undefined && (
                    <Box css={sectionWideImage({ theme })}>
                      <Video videoUrl={section.videoUrl} />
                    </Box>
                  )}
                  {section.wideImage !== undefined && (
                    <Box css={sectionWideImage({ theme })}>
                      <img src={addCloudfrontUrl(section.wideImage)} alt="media" width="100%" />
                    </Box>
                  )}
                  {section.buttons && (
                    <Box css={buttonsSection({ theme })}>
                      {section.buttons.map((button, index) => (
                        <SourceButton source={button.url} key={index + button.url}>
                          {button.title}
                        </SourceButton>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box css={sectionFooter({ theme })}>
          <Box>
            <Typography whiteSpace="nowrap">
              {sequencedStep.sequencedIndex + 1} / {totalSteps}
            </Typography>
          </Box>
          <Box>
            {sequencedStep.elementsText && (
              <Typography>
                <b>Shown Elements:</b> {sequencedStep.elementsText}
              </Typography>
            )}
            {sequencedStep.scenariosText && (
              <Typography>
                {term_preference.hazard ? (
                  <b>Shown Hazard Scenarios:</b>
                ) : (
                  <b>Shown Risk Scenarios:</b>
                )}{' '}
                {sequencedStep.scenariosText}
              </Typography>
            )}
            {sequencedStep.contextsText && (
              <Typography>
                <b>Shown Contexts:</b> {sequencedStep.contextsText}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isActive === nextProps.isActive &&
      prevProps.handleOnClick === nextProps.handleOnClick
    )
  },
)
