import { DynamicContent, Style, StyleDictionary, TFontDictionary } from 'pdfmake/interfaces'

export const reportFonts: TFontDictionary = {
  Inter400: {
    normal: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Regular-400.ttf`,
    bold: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Bold-700.ttf`, // Used in SVGs annoyingly
    italics: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Italic.ttf`,
  },
  Inter500: {
    normal: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Medium-500.ttf`,
  },
  Inter600: {
    normal: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-SemiBold-600.ttf`,
  },
  Inter700: {
    normal: `${window.location.origin}/fonts/inter_pdf/Inter_18pt-Bold-700.ttf`,
  },
}

export const reportDefaultStyle: Style = { font: 'Inter400', fontSize: 10.5, color: '#0b2948' }

export const reportStyles: StyleDictionary = {
  h1: {
    fontSize: 22,
    font: 'Inter700',
    marginBottom: 9,
  },
  h2: {
    fontSize: 15,
    font: 'Inter700',
    marginBottom: 9,
  },
  h3: {
    fontSize: 15,
    font: 'Inter500',
    marginBottom: 9,
  },
  footerBold: {
    fontSize: 8,
    font: 'Inter700',
  },
  footerRegular: {
    fontSize: 8,
  },
  bodySemiBold: {
    fontSize: 10.5,
    font: 'Inter600',
  },
  bodyBold: {
    fontSize: 10.5,
    font: 'Inter700',
  },
  pageHeader: {
    fontSize: 14,
  },
  bold: {
    font: 'Inter700',
  },
}

export interface Size {
  width: number
  height: number
}
export type PageSize =
  | '4A0'
  | '2A0'
  | 'A0'
  | 'A1'
  | 'A2'
  | 'A3'
  | 'A4'
  | 'A5'
  | 'A6'
  | 'A7'
  | 'A8'
  | 'A9'
  | 'A10'
  | 'EXECUTIVE'
  | 'FOLIO'
  | 'LEGAL'
  | 'LETTER'
  | 'TABLOID'

export const pageSizeDimensions: { [pageSize: string]: [number, number] } = {
  '4A0': [4767.87, 6740.79],
  '2A0': [3370.39, 4767.87],
  A0: [2383.94, 3370.39],
  A1: [1683.78, 2383.94],
  A2: [1190.55, 1683.78],
  A3: [841.89, 1190.55],
  A4: [595.28, 841.89],
  A5: [419.53, 595.28],
  A6: [297.64, 419.53],
  A7: [209.76, 297.64],
  A8: [147.4, 209.76],
  A9: [104.88, 147.4],
  A10: [73.7, 104.88],
  EXECUTIVE: [521.86, 756.0],
  FOLIO: [612.0, 936.0],
  LEGAL: [612.0, 1008.0],
  LETTER: [612.0, 792.0],
  TABLOID: [792.0, 1224.0],
}

export const pageMargins = { top: 96, bottom: 70, left: 32, right: 32 }
export const getPageBodySize = (pageSize: PageSize): Size => {
  const pageDimensions = pageSizeDimensions[pageSize]
  return {
    width: pageDimensions[0] - pageMargins.left - pageMargins.right,
    height: pageDimensions[1] - pageMargins.top - pageMargins.bottom,
  }
}

export const reportFooter = (footerText: string): DynamicContent => {
  return (currentPage, totalPages, pageSize) => {
    const even = currentPage % 2 == 0
    return [
      {
        canvas: [
          {
            type: 'line',
            x1: pageMargins.left,
            y1: 0,
            x2: pageSize.width - pageMargins.right,
            y2: 0,
            color: '#0b2948',
          },
        ],
        absolutePosition: { x: 0, y: 0 },
      },
      {
        table: {
          body: [
            even
              ? [
                  {
                    text: `${currentPage} of ${totalPages}`,
                    alignment: 'left',
                    style: 'footerRegular',
                    marginTop: 13,
                    marginLeft: pageMargins.left,
                  },
                  {
                    text: footerText,
                    style: 'footerBold',
                    marginTop: 13,
                    alignment: 'right',
                    marginRight: pageMargins.right,
                  },
                ]
              : [
                  {
                    text: footerText,
                    style: 'footerBold',
                    marginTop: 13,
                    marginLeft: pageMargins.left,
                  },
                  {
                    text: `${currentPage} of ${totalPages}`,
                    alignment: 'right',
                    style: 'footerRegular',
                    marginTop: 13,
                    marginRight: pageMargins.right,
                  },
                ],
          ],
          widths: even ? ['auto', '*'] : ['*', 'auto'],
        },

        margin: [15, 0, 15, 0], // [left, top, right, bottom]
        layout: 'noBorders',
      },
    ]
  }
}
