import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const wizardModalContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const wizardModal = () => css`
  width: 1000px;
  max-width: 100%;
  min-width: 400px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: #2d3648;
`

export const wizardModalHeader = () => css`
  width: 100%;
  padding: 0 24px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

export const wizardModalTitle = () => css`
  display: flex;
  align-items: center;
  gap: 8px;

  h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
`

export const wizardModalContent = () => css`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: stretch;

  p {
    font-size: 16px;
  }
`

export const wizardModalActionButtons = ({ theme }: { theme: Theme }) => css`
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  background-color: ${theme.palette.primary.main}10;
`
