/** @jsxImportSource @emotion/react */

import { cascadingLayerPresent, MapLayer } from '@redux/riskMap/riskMapSlice'
import { Icon } from '@src/components/Atoms/Icon'
import { useTheme } from '@mui/material'
import { CascadingNDaysSelector } from '@src/components/Molecules/RiskSideDrawerContent/components/CascadingNDaysSelector/CascadingNDaysSelector'

import { useSelector } from 'react-redux'
import { Accordion } from '@src/components/Atoms/Accordion'
import { variantColor } from '@src/components/Atoms/Accordion/Accordion.styles'
import { GroupedLayers } from '../InformativePanel'
import { RiskAccordion } from './RiskAccordion'
import { MultiRiskAccordion } from './MultiRiskAccordion'
import {
  MultiRiskData,
  RiskData,
  RiskFetcherIsLoading,
} from '@contexts/RiskFetcherContext/RiskFetcher.types'

interface ElementAccordionProps {
  layer: MapLayer
  groupedLayers: GroupedLayers
  animationDelay: number
  riskPerHazard: { [key: string]: { [key: string]: RiskData | null } }
  riskMultiHazard: MultiRiskData | null
  isLoading: RiskFetcherIsLoading
}

export const ElementAccordion = ({
  layer,
  groupedLayers,
  animationDelay,
  riskPerHazard,
  riskMultiHazard,
  isLoading,
}: ElementAccordionProps) => {
  const theme = useTheme()

  const hasCascadingLayers = useSelector(cascadingLayerPresent)

  if (!layer) return null
  const title = layer.display_name

  const iconColour = variantColor({ theme, variant: 'clear' })
  const showCascading = hasCascadingLayers && layer.is_cascading
  const multipleHazards = groupedLayers.hazards.flatMap((x) => x.scenarios).length > 1

  // For multihazard's sake
  return (
    <Accordion
      defaultExpanded={true}
      key={`sidedrawer-accordion-${layer.assetTag ?? layer.id ?? layer.type}`}
      title={title}
      animationDelay={animationDelay}
      variant={'clear'}
      level={'h1'}
      hasTextContent={false}
      body={
        <>
          {showCascading && (
            <CascadingNDaysSelector
              key={layer.type ?? layer.assetTag}
              layer={layer}
              inAccordion={true}
            />
          )}
          {!showCascading && multipleHazards && (
            <MultiRiskAccordion
              riskMultiHazard={riskMultiHazard}
              assetLayer={layer}
              useCascading={false}
              isLoading={isLoading}
            />
          )}
          {groupedLayers.hazards.map((hazardLayer) => (
            <RiskAccordion
              key={hazardLayer.hazard_type}
              hazardGroup={hazardLayer}
              elementLayer={layer}
              defaultExpanded={true}
              riskPerHazard={riskPerHazard}
              isLoading={isLoading}
            />
          ))}
        </>
      }
      icon={<Icon colour={iconColour} iconName={layer.icon} />}
    />
  )
}
