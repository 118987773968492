import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const fullWidthAccordionContainer = ({ theme }: { theme: Theme }) => css`
  .MuiAccordionSummary-root {
    background-color: ${theme.palette.primary[800]};
    border-bottom: 2px solid ${theme.palette.primary[250]};
    color: ${theme.palette.primary[100]};
    text-transform: uppercase;
    padding: 16px 16px !important;
    font-size: 20px !important;

    svg {
      color: ${theme.palette.primary[100]} !important;
      transform: scale(1.4);
    }
  }
  &.MuiAccordion-root.MuiAccordion-rounded {
    border-radius: 0 !important;
  }
`
