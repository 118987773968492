import { Content } from 'pdfmake/interfaces'
import { Disclaimer } from '../../Report.utils'

export const GenerateSummaryPageContent = (): Content[] => {
  return [
    { text: 'Risk Report Summary', style: 'h1' },
    { text: 'Prepared by: Urban Intelligence®' },
    {
      table: {
        body: [
          [
            { text: 'Revision', style: 'tableHeader', color: '#ffffff', margin: [5, 2, 5, 2] },
            { text: 'Description', style: 'tableHeader', color: '#ffffff', margin: [5, 2, 5, 2] },
            { text: 'Date', style: 'tableHeader', color: '#ffffff', margin: [5, 2, 5, 2] },
          ],
          [
            { text: `Version ${APP_VERSION}`, margin: [5, 5, 5, 5] },
            { text: 'Autogenerated by Resilience Explorer®', margin: [5, 5, 5, 5] },
            { text: new Date().toLocaleDateString(), margin: [5, 5, 5, 5] },
          ],
        ],
        widths: ['auto', '*', 'auto'],
      },
      marginTop: 40,
      layout: {
        hLineWidth: () => 1,
        vLineWidth: () => 1,
        hLineColor: () => '#0b2948',
        vLineColor: () => '#0b2948',
        fillColor: (rowIndex: number) => (rowIndex === 0 ? '#0b2948' : null),
      },
    },
    {
      text: [
        { text: 'Disclaimer: ', font: 'Inter700' },
        {
          text: Disclaimer,
        },
      ],
      marginTop: 500,
      pageBreak: 'after',
    },
  ]
}
