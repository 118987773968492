/** @jsxImportSource @emotion/react */
import { GeneratePrioritisationReport } from './Report'
import 'pdfmake/build/pdfmake'
import { reportFonts } from '../Report.pdfstyle'
import mapboxgl from 'mapbox-gl'
import { MCAScenario, ValueSet } from '@contexts/PrioritisationContext'
const pdfMake = window['pdfMake']

pdfMake.fonts = reportFonts

export interface AdaptationAreaPriority {
  area: string
  areaGroup: string
  priorityIndex: number
  color: string
}

export interface PrioritisationReportProperties {
  area: string | null
  valueWeightings: { [key: string]: number }
  scenarioGroup: MCAScenario | null
  adaptationAreaPriorities: AdaptationAreaPriority[]
  areasType: string | null
  exposurePercByElement: { element: string; percentage: number; exposure: number; unit: string }[]
  elementGroups: ValueSet[]
  clientDisplayName: string
}

export const downloadPrioritisationReport = async (
  map: mapboxgl.Map,
  props: PrioritisationReportProperties,
) => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  pdfMake
    .createPdf(await GeneratePrioritisationReport(map, props))
    .download(`Prioritisation_Report_${year}-${month}-${day}`)
}
