import { Content } from 'pdfmake/interfaces'
import { convertFigmaPatternsToImages } from '../../Report.utils'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'

export const GenerateBackPageContent = async (): Promise<Content[]> => {
  const svgBackpageResponse = await fetch(
    `${PUBLIC_ASSETS_BASE_URL}/reports-ui/cover_pages/backpage.svg`,
  )

  const svgBackpage = convertFigmaPatternsToImages(await svgBackpageResponse.text())

  return [
    {
      svg: svgBackpage,
      absolutePosition: { x: 0, y: 0 },
      pageBreak: 'before',
    },
  ]
}
