import { MapMarker } from '@src/components/Atoms/MapMarker'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'
import { createRoot } from 'react-dom/client'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import mapboxgl, { EventData, MapboxGeoJSONFeature, MapMouseEvent } from 'mapbox-gl'
import { GeoJsonProperties } from 'geojson'

export function addSelectedMarkerToMap({
  map,
  coordinates,
  colour,
}: {
  map: mapboxgl.Map
  coordinates: mapboxgl.LngLatLike
  colour: string
}) {
  removeSelectedMarkerFromMapIfExists()

  const selectedMarkerRef = document.createElement('div')
  selectedMarkerRef.id = 'selected-marker'

  createRoot(selectedMarkerRef).render(
    <MapMarker colour={colour}>
      <Tooltip title="Explore additional details within the information panel">
        <TaskOutlinedIcon />
      </Tooltip>
    </MapMarker>,
  )

  new mapboxgl.Marker(selectedMarkerRef).setLngLat(coordinates).addTo(map)
}

export function removeSelectedMarkerFromMapIfExists() {
  const selectedMarker = document.getElementById('selected-marker')
  if (selectedMarker) selectedMarker.remove()
}

type Coordinate = [number, number]
type Coordinates = Coordinate | Coordinate[] | Coordinate[][]
type BoundingCoordinates = { max_coord: Coordinate; min_coord: Coordinate }

export function getCenterOfFeature(feature: MapboxGeoJSONFeature): [number, number] | null {
  if (!feature?.geometry) return null
  const coordinates = (feature.geometry as GeoJsonProperties)?.coordinates as Coordinates
  if (!coordinates) return null

  const getBoundsOfGeometry = (coords: Coordinates): BoundingCoordinates => {
    if (Array.isArray(coords[0])) {
      return coords.reduce(
        (acc: BoundingCoordinates, curr) => {
          const { max_coord, min_coord } = getBoundsOfGeometry(curr as Coordinates)
          return {
            max_coord: [
              Math.max(acc.max_coord[0], max_coord[0]),
              Math.max(acc.max_coord[1], max_coord[1]),
            ],
            min_coord: [
              Math.min(acc.min_coord[0], min_coord[0]),
              Math.min(acc.min_coord[1], min_coord[1]),
            ],
          }
        },
        { max_coord: [-Infinity, -Infinity], min_coord: [Infinity, Infinity] },
      )
    }
    return { max_coord: coords as Coordinate, min_coord: coords as Coordinate }
  }

  const { max_coord, min_coord } = getBoundsOfGeometry(coordinates)
  return [(max_coord[0] + min_coord[0]) / 2, (max_coord[1] + min_coord[1]) / 2]
}

export type GenericMapMouseEvents = {
  onClick?: (
    ev: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined } & EventData,
  ) => void
  onMouseEnter?: (
    ev: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined } & EventData,
  ) => void
  onMouseLeave?: (
    ev: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined } & EventData,
  ) => void
  onMouseMove?: (
    ev: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined } & EventData,
  ) => void
}

/**
 * Keeps track of the mouse events attached to map layers, automatically cleaning up old events when new events are added.
 */
export class MapMouseEventManager {
  private previousMouseEvents: GenericMapMouseEvents | null = null
  private previousLayerIds: string[] = []

  constructor(private map: mapboxgl.Map) {}

  public applyMouseEventsToLayers(layerIds: string[], mouseEvents: GenericMapMouseEvents) {
    this.removePreviousMouseEvents()
    this.addNewMouseEvents(layerIds, mouseEvents)
  }

  public removePreviousMouseEvents() {
    if (this.previousMouseEvents === null) return
    if (this.previousMouseEvents.onClick !== undefined) {
      for (const layerId of this.previousLayerIds) {
        this.map.off('click', layerId, this.previousMouseEvents.onClick)
      }
    }
    if (this.previousMouseEvents.onMouseEnter !== undefined) {
      for (const layerId of this.previousLayerIds) {
        this.map.off('mouseenter', layerId, this.previousMouseEvents.onMouseEnter)
      }
    }
    if (this.previousMouseEvents.onMouseLeave !== undefined) {
      for (const layerId of this.previousLayerIds) {
        this.map.off('mouseleave', layerId, this.previousMouseEvents.onMouseLeave)
      }
    }
    if (this.previousMouseEvents.onMouseMove !== undefined) {
      for (const layerId of this.previousLayerIds) {
        this.map.off('mousemove', layerId, this.previousMouseEvents.onMouseMove)
      }
    }
    this.previousMouseEvents = null
    this.previousLayerIds = []
  }

  private addNewMouseEvents(layerIds: string[], mouseEvents: GenericMapMouseEvents) {
    this.previousLayerIds = layerIds
    this.previousMouseEvents = mouseEvents

    if (mouseEvents.onClick !== undefined) {
      for (const layerId of layerIds) {
        this.map.on('click', layerId, mouseEvents.onClick)
      }
    }
    if (mouseEvents.onMouseEnter !== undefined) {
      for (const layerId of layerIds) {
        this.map.on('mouseenter', layerId, mouseEvents.onMouseEnter)
      }
    }
    if (mouseEvents.onMouseLeave !== undefined) {
      for (const layerId of layerIds) {
        this.map.on('mouseleave', layerId, mouseEvents.onMouseLeave)
      }
    }
    if (mouseEvents.onMouseMove !== undefined) {
      for (const layerId of layerIds) {
        this.map.on('mousemove', layerId, mouseEvents.onMouseMove)
      }
    }
  }
}
