import { Content } from 'pdfmake/interfaces'
import {
  AssetDetails,
  getElementAddress,
  getLayerIcons,
  HorizontalBar,
  makeSvg,
  parseHtml,
  Size,
} from '../../Report.utils'
import { MakeProjectedExposureChartSvg } from '@src/components/Molecules/Charts/ProjectedExposureChart/ProjectedExposureChart'
import {
  ProjectedExposureChartState,
  ProjectedExposureChartStateByHazardId,
} from '@redux/projectedExposureChartSlice'
import pluralize from 'pluralize'
import { firstLetterToUpperCase } from '@src/utils/strings.utils'
import { MapTooltipProps } from '@src/components/Molecules/MapView/RiskMapView/Tooltip/RiskMapTooltip'
import { MapLayer } from '@redux/riskMap/riskMapSlice'

export const GenerateElementHeader = async (
  asset: MapTooltipProps['asset'] | undefined | null,
  selectedAssetLayer: MapLayer,
): Promise<Content> => {
  if (!asset) return []

  const elementIconId = asset.icon.replace('s3://', '').replace('.svg', '') ?? ''
  const iconSvg = (await getLayerIcons([selectedAssetLayer]))[elementIconId]

  const address = getElementAddress(asset.identifyingInformation)

  return [
    {
      table: {
        body: [
          [
            makeSvg(iconSvg, { fit: [25, 25] }),
            { text: pluralize.singular(asset.name), style: 'h1', margin: [0, 0, 0, 0] },
          ],
        ],
      },
      layout: 'noBorders',
    },

    address ? { text: address, style: 'h3' } : [],
  ]
}

export const GenerateSelectedElementDetailsContent = (params: {
  assetDetails: AssetDetails | null | undefined
  addPageBreak: boolean
}): Content => {
  const { assetDetails, addPageBreak } = params
  if (!assetDetails || !assetDetails.asset) return []

  const pageBreak = addPageBreak ? 'before' : undefined

  const margin = [-2.5, -2.5] // Bit of a hack to reduce the height of table rows

  // Too many nulls floating about
  const identifyingInformation: { [key: string]: string | number | null } = assetDetails.asset
    .identifyingInformation
    ? assetDetails.asset.identifyingInformation
    : {}

  const existingKeys = Object.keys(identifyingInformation).filter(
    (key) => identifyingInformation[key] !== null && !key.includes('_'), // Sometimes raw data ends up in here like 'replacement_cost'
  )

  const identifyingInformationContent = existingKeys.map((idKey) => [
    { text: idKey, style: 'bodySemiBold', margin },
    {
      text: assetDetails.asset?.identifyingInformation
        ? assetDetails.asset.identifyingInformation[idKey]
        : '',
      margin,
    },
  ])

  return [
    { text: 'ELEMENT OVERVIEW', style: 'h2', pageBreak },
    {
      table: {
        body: [
          [
            { text: 'Element type', style: 'bodySemiBold', margin },
            { text: assetDetails.asset.name, margin },
          ],
          [
            { text: `ID`, style: 'bodySemiBold', margin },
            { text: assetDetails.asset.id, margin },
          ],
          ...identifyingInformationContent,
          [
            { text: 'Date generated', style: 'bodySemiBold', margin },
            { text: new Date().toLocaleDateString(), margin },
          ],
        ],
        widths: ['33.33%', '66.66%'],
      },
      layout: {
        fillColor: function (rowIndex) {
          return rowIndex % 2 === 0 ? '#E7EAED' : '#FAFBFB'
        },
        vLineWidth: () => 0,
        hLineWidth: () => 0,
        paddingTop: () => 7.25,
        paddingBottom: () => 7.25,
        paddingLeft: () => 7.25,
      },
      marginBottom: 8,
    },
  ]
}

export const GenerateSelectedElementExposureBySelectedRisks = (
  assetDetails: AssetDetails | null | undefined,
  prefer_risk_term: boolean,
  prefer_hazard_term: boolean,
  projectedExposureChartState: ProjectedExposureChartStateByHazardId,
  bodySize: Size,
  hasHazardLayers: boolean,
): Content => {
  if (!assetDetails) return []

  const hazardOrRisk = prefer_hazard_term ? 'hazard' : 'risk'

  if (hasHazardLayers && (!assetDetails.hazards || assetDetails.hazards.length == 0))
    return `This element is not exposed to any of the selected ${hazardOrRisk}s.`

  if (!assetDetails.hazards || assetDetails.hazards.length == 0) return []

  const toReturn: Content = [
    {
      text: `SELECTED NATURAL ${hazardOrRisk.toLocaleUpperCase()}S`,
      style: 'h2',
      pageBreak: 'before',
      marginBottom: 6,
    },
    `The following ${hazardOrRisk}s have been selected on the map.`,
    {
      text: `Element Exposure and Projected ${firstLetterToUpperCase(hazardOrRisk)}s`,
      style: 'h3',
      marginTop: 26,
    },
    ...assetDetails.hazards.map((hazard): Content => {
      const projectedExposureChartDivElement = document.createElement('div')
      const hazardProjectedExposureChartState: ProjectedExposureChartState | null =
        hazard.hazard_id !== undefined ? projectedExposureChartState[hazard.hazard_id] : null
      const showProjectedExposureChart =
        hazardProjectedExposureChartState &&
        hazardProjectedExposureChartState.data &&
        hazardProjectedExposureChartState.data.length > 0
      if (showProjectedExposureChart)
        MakeProjectedExposureChartSvg({
          ...hazardProjectedExposureChartState,
          svgElement: projectedExposureChartDivElement,
          width: 450,
        })
      return [
        HorizontalBar(bodySize.width),
        {
          stack: [
            {
              margin: [0, 10, 0, 0],
              table: {
                body: [
                  [
                    {
                      text: '',
                      fillColor: hazard.vulnerabilityColour,
                      rowSpan: 2,
                    },
                    {
                      stack: [
                        { text: hazard.title, font: 'Inter700', marginBottom: 3 },
                        parseHtml(hazard.sentence, 10.5),
                      ],
                      colSpan: 2,
                      margin: [10, 0, 0, 0],
                    },
                    '',
                  ],
                  [
                    '',
                    {
                      text: [
                        { text: prefer_risk_term ? 'Vulnerability: ' : 'Consequence: ' },
                        { text: hazard.vulnerabilityLabel, font: 'Inter700' },
                      ],
                      margin: [10, 0, 0, 0],
                    },
                    hazard.exposure_value == null
                      ? []
                      : {
                          text: [
                            { text: 'Exposure: ' },
                            { text: `${hazard.exposure_value} ${hazard.unit}`, font: 'Inter700' },
                          ],
                        },
                  ],
                ],
                widths: ['auto', '*', '*'],
              },
              layout: 'noBorders',
              unbreakable: true,
            },
            showProjectedExposureChart
              ? {
                  stack: [
                    {
                      svg: projectedExposureChartDivElement.innerHTML,
                      marginTop: 20,
                      alignment: 'center',
                    },
                  ],
                  unbreakable: true,
                }
              : [
                  {
                    text: `No projected ${hazardOrRisk} data available for this scenario`,
                    italics: true,
                    marginTop: 9,
                  },
                ],
          ],
          unbreakable: true,
        },
      ]
    }),
  ]
  return toReturn
}
