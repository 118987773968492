/** @jsxImportSource @emotion/react */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import { SignInModal } from '@src/components/Organisms/Modals/SignInModal'
import axios from '@src/utils/customAxios'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  setUserClientWelcomeSidebarContent,
  setupUserState,
  userInitialState,
} from '@src/redux/user/userSlice'
import { useDispatch } from 'react-redux'
import { riskMapInitialState, updateMapStateProperty } from '@redux/riskMap/riskMapSlice'
import { AppInitialLocation, GenericMapContext, useMap } from '@contexts/RiskMapContext'
import { useNavigate } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import { useSnackbars } from '@contexts/SnackbarContext'
import { clearSelectedClient } from '@src/components/Organisms/Modals/SignInModal/ChangeClient/ChangeClient'
import { usePrioritisationMap } from '@contexts/PrioritisationMapContext'
import { clearJwt } from '@src/components/Organisms/Modals/SignInModal/SignInModal.utils'

interface AppAuthHolderProps {
  open: boolean
  showLogoutDialog: boolean
  handleLogoutDialogClose: () => void
  handleClose: () => void
  handleOpen: () => void
}

export const AppAuthHolder = ({
  open,
  showLogoutDialog,
  handleLogoutDialogClose,
  handleClose,
  handleOpen,
}: AppAuthHolderProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const riskMapContext = useMap()
  const prioritisationMapContext = usePrioritisationMap()
  const { displaySnackbar } = useSnackbars()

  const [isAuth, setIsAuth] = useState(false)

  // this is a global interceptor for our custom axios, it's used here to call the react functions
  // it's a ref to avoid re-creating the interceptor on every render
  const authInterceptor = useRef<number | null>(null)

  const handleLogoutForMap = async (mapContext: GenericMapContext) => {
    const { map, clearMap, updateLayers, updateDrawAreas, handleSetClientLocation } = mapContext

    updateLayers({ layers: [] })
    updateDrawAreas({ drawAreas: [] })
    if (map) {
      clearMap({ map })
    }
    handleSetClientLocation(AppInitialLocation)
  }

  const handleLogout = async () => {
    handleLogoutForMap(riskMapContext)
    handleLogoutForMap(prioritisationMapContext)

    navigate('home')

    setIsAuth(false)
    clearJwt()
    clearSelectedClient()
    Sentry.setUser(null)
    datadogRum.setUser({})
    dispatch(setUserClientWelcomeSidebarContent({}))

    /* Update redux user */
    dispatch(
      setupUserState({
        clientDisplayName: userInitialState.clientDisplayName,
        clientName: userInitialState.clientName,
        feedback_url: userInitialState.feedback_url,
        logos: userInitialState.logos,
        theme: userInitialState.theme,
        accepted_tsncs: userInitialState.accepted_tsncs,
        methods_report_url: userInitialState.methods_report_url,
        tsncs_html: userInitialState.tsncs_html,
      }),
    )
    dispatch(updateMapStateProperty(riskMapInitialState))
  }

  const handleExpiredSession = useCallback(async () => {
    handleLogout()
    displaySnackbar({
      message: 'Session expired, please log in again',
      type: 'warning',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displaySnackbar])

  const handleSignout = () => {
    handleLogoutDialogClose()
    handleLogout()
  }

  useEffect(() => {
    if (authInterceptor.current === null) {
      authInterceptor.current = axios.interceptors.response.use(
        (res) => res,
        (err) => {
          if (err?.response?.data?.message === 'Session expired') {
            handleExpiredSession()
            setIsAuth(false)
            handleOpen()
          }
          // this needs to return a rejection, otherwise it doesn't get caught!
          if (err) return Promise.reject(err)
        },
      )
    }
  }, [handleExpiredSession, handleOpen, setIsAuth])

  useEffect(() => {
    const storedClientName = window.localStorage.getItem('client_name')
    const accessJwt = window.localStorage.getItem('access_token')
    setIsAuth(
      storedClientName !== null &&
        storedClientName !== 'openaccess' &&
        accessJwt !== null &&
        accessJwt !== '',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      {open ? (
        <SignInModal
          handleClose={handleClose}
          open={open && !isAuth}
          isAuth={isAuth}
          setIsAuth={setIsAuth}
          handleLogout={handleLogout}
        />
      ) : null}
      {showLogoutDialog ? (
        <Dialog
          open={showLogoutDialog}
          onClose={() => handleLogoutDialogClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Sign out</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to continue signing out?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleLogoutDialogClose()}>Cancel</Button>
            <Button onClick={() => handleSignout()} autoFocus>
              Signout
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  )
}
