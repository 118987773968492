/** @jsxImportSource @emotion/react */

import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { Box, useTheme, Typography } from '@mui/material'
import {
  FormElementType,
  HazardOptionState,
} from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog/HazardLayersForm/HazardBox'
import {
  hazardControlRightColumn,
  hazardControlsColumn,
  hazardControlsRow,
  hazardDetailFormContainer,
  hiddenOptionsNote,
} from './HazardControls.styles'
import {
  HazardDetail,
  HazardScenario,
} from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog'
import { useLayers } from '@contexts/LayerContext'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { HazardControlFormElement } from './HazardControlFormElement'
import { BASE_API_URL } from '@src/app-constants'
import { DialogData } from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog'
import { createPortal } from 'react-dom'
import { useMap } from '@contexts/RiskMapContext'
import {
  layerDataByHazardId,
  layerDataByHazardIdDetail,
} from '@src/components/Molecules/RiskSideDrawerContent/data_fetchers/layerDataFetcher'
import { HazardControlShownScenario } from './HazardControlShownScenario'
import { HazardControlFormHeader } from './HazardControlFormHeader'
import { Accordion } from '@src/components/Atoms/Accordion'
import { usePreferences } from '@contexts/PreferencesContext'
import { MoreInformationModal } from '@src/components/Organisms/Modals/MoreInformationModal'

export interface GenericKeyValues<T> {
  [key: string]: T
}

export interface HazardOptionStateByHazard {
  [key: string]: HazardOptionState
}

export interface AvailableObjects {
  value: string | number
  disabled?: boolean
}

export type CombinedHazardDetail = HazardDetail & layerDataByHazardIdDetail

/*
 * Given a hazardDetail, return the current scenario using layers
 */
export const getScenarioByHazardDetail = (
  layers: MapLayer[],
  hazardDetail: CombinedHazardDetail,
) => {
  if (hazardDetail.hazard_id)
    return layers.find(
      (layer) => layer.hazard_id === (hazardDetail as CombinedHazardDetail).hazard_id,
    )
  return layers.find((layer) => layer.hazard_title === hazardDetail.title)
}

export const HazardControls = memo(
  function HazardControls({
    layersData,
    scenarioByHazardId,
  }: {
    layersData: DialogData | null
    scenarioByHazardId: layerDataByHazardId | undefined
    hazardIds: string[]
  }) {
    const { term_preference, use_hazard_name } = usePreferences()
    const theme = useTheme()
    const { prepareAndUpdateLayers } = useLayers()
    const { layers } = useSelector((state: RootState) => state.riskMap)

    const {
      hazardOptionState,
      setHazardOptionState,
      prevHazardOptionState,
      setPrevHazardOptionState,
    } = useMap()
    const [lastUpdatedHazard, setLastUpdatedHazard] = useState(null as HazardDetail | null)
    const [informationDetailsModal, setInformationDetailsModal] = useState({
      isOpen: false,
      helpFileName: '',
    })
    const [keysWithNoValues, setKeysWithNoValues] = useState<{
      [key: string]: { [key: string]: boolean }
    }>({})
    const hazardMapLayers = useMemo(() => {
      return layers.filter((layer: MapLayer) => layer.layerType === 'hazard')
    }, [layers])

    /**
     * Get the dialog layer data that matches the hazardMapLayers
     * VERY side effect heavy
     * Different returns depending on if hazard_id's are present
     */
    const filteredLayersData = useMemo(() => {
      if (!layersData) return []
      const hasHazardIds = layersData?.hazardDialogData.hazardDetails.some(
        (HazardDetail: HazardDetail) =>
          HazardDetail.scenarios.some((scenario: HazardScenario) => scenario.hazard_id),
      )

      if (hasHazardIds && scenarioByHazardId) {
        const hazardIdRes = Object.keys(scenarioByHazardId).reduce((acc, hId) => {
          const foundCurrentScenario = scenarioByHazardId[hId].scenarios.find((scenario) => {
            return hazardMapLayers.some((layer: MapLayer) => {
              return layer.assetTag === scenario.assetTag
            })
          })

          if (foundCurrentScenario) {
            acc.push({
              ...scenarioByHazardId[hId],
              title: `${scenarioByHazardId[hId].hazard_name}`,
              scenarios: scenarioByHazardId[hId].scenarios.filter(
                (scenario) => scenario.hazard_id === foundCurrentScenario?.hazard_id,
              ),
            })
          }

          return acc
        }, [] as CombinedHazardDetail[])
        return hazardIdRes
      }
      const res = layersData.hazardDialogData.hazardDetails.filter((hazardDetail: HazardDetail) => {
        // filter based on title
        return hazardMapLayers.some((layer: MapLayer) => {
          const comparison = (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title
          return hasHazardIds
            ? layer?.hazard_id ?? '' === comparison
            : layer.hazard_title === comparison
        })
      })
      return res
    }, [layersData, hazardMapLayers, scenarioByHazardId])

    /**
     * Used for reactivity as a primitive
     */
    const hazardTitleString = useMemo(() => {
      const hazardString = filteredLayersData
        .map((hazardDetail) => {
          return (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title
        })
        .join(', ')
      return hazardString
    }, [filteredLayersData])

    const sortFormByPriority = (form: HazardDetail['form']) => {
      return [...form].sort((a, b) => a.priority - b.priority)
    }

    /**
     * Set all values to an initial state of '' unless it finds a scenario that matches the current hazardMapLayers
     * Or is added from known risks using .addedByKnownRisks
     */
    const initialHazardOptionStateFunc = () => {
      const initialHazardOptionState: HazardOptionStateByHazard = { ...hazardOptionState }
      filteredLayersData?.forEach((hazardDetail) => {
        const hazardKey = (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title
        if (!initialHazardOptionState[hazardKey]) {
          initialHazardOptionState[hazardKey] = {}
        }
        for (const formElement of hazardDetail.form) {
          if (hazardKey in initialHazardOptionState) {
            // get the relevant MapLayer from hazardMapLayers
            const currentHazardLayer = hazardMapLayers.find((layer) =>
              hazardDetail.scenarios.find((s) => s.assetTag === layer.assetTag),
            )
            if (prevHazardOptionState?.[hazardKey]?.[formElement.key]?.value) {
              initialHazardOptionState[hazardKey][formElement.key] = {
                value: prevHazardOptionState[hazardKey][formElement.key].value,
              }
            } else {
              if (
                formElement.key === 'slr' &&
                initialHazardOptionState?.[hazardKey]?.[formElement?.key]?.value !== ''
              ) {
                initialHazardOptionState[hazardKey][formElement.key] = {
                  value: currentHazardLayer?.parameters?.[formElement.key] ?? '',
                }
              } else {
                initialHazardOptionState[hazardKey][formElement.key] = {
                  value: '',
                }
              }
            }
            // must remain last to be an overwrite
            if (currentHazardLayer?.addedByKnownRisks && currentHazardLayer.parameters) {
              initialHazardOptionState[hazardKey][formElement.key] = {
                value: currentHazardLayer.parameters[formElement.key],
              }
            }
          }
        }
      })
      return initialHazardOptionState
    }

    /**
     * find the scenario based on the hazardOptionState
     */
    const findScenario = (
      scenarios: HazardScenario[],
      hazardKey: string,
    ): HazardScenario | undefined => {
      if (!hazardOptionState[hazardKey]) return
      const res = scenarios.find((scenario: HazardScenario) => {
        const hazardOptionKeys = Object.keys(hazardOptionState[hazardKey])
        const isAvailable = hazardOptionKeys.every((optionKey) => {
          const optionValue = hazardOptionState[hazardKey][optionKey]?.value
          if (
            optionValue === '' ||
            optionValue === null ||
            optionValue === undefined ||
            optionValue === 'Not Specified'
          )
            return true

          return Object.keys(scenario.parameters).some((param) => {
            if (param === optionKey) {
              return scenario.parameters[param].toString() === optionValue.toString()
            }
          })
        })
        return isAvailable
      })

      return res
    }

    const hasMultipleAvailableValues = (hazardDetail: HazardDetail) => {
      const parameterKeys = Object.keys(hazardDetail.scenarios[0]?.parameters || {})
      return parameterKeys.some((key) => {
        const uniqueValues = [
          ...new Set(hazardDetail.scenarios.map((scenario) => scenario.parameters[key])),
        ]
        return uniqueValues.length > 1
      })
    }

    const updateHazardOptionState = ({
      key,
      value,
      hazardDetail,
    }: {
      key: string
      value: string | number
      hazardDetail: CombinedHazardDetail | HazardDetail
    }) => {
      setHazardOptionState((prevState: HazardOptionStateByHazard) => {
        const hazardKey = (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title
        return {
          ...prevState,
          [hazardKey]: {
            ...prevState[hazardKey],
            [key]: {
              value,
            },
          },
        }
      })
      setPrevHazardOptionState((prev) => {
        if (!prev)
          return {
            [hazardDetail.title]: {
              [key]: {
                value,
              },
            },
          }
        const hazardKey = (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title
        return {
          ...prev,
          [hazardKey]: {
            ...prev[hazardKey],
            [key]: {
              value,
            },
          },
        }
      })
      setLastUpdatedHazard(hazardDetail)
    }
    /**
     * Switch between the present value and the value from the scenario whenever a switch is clicked downline
     */
    const handleSwitch = (key: string, isOn: boolean, hazardDetail: HazardDetail) => {
      if (isOn) updateHazardOptionState({ key, value: '', hazardDetail })
      else {
        const scenario = getScenarioByHazardDetail(layers, hazardDetail as CombinedHazardDetail)
        if (!scenario?.parameters) return
        updateHazardOptionState({ key, value: scenario.parameters[key], hazardDetail })
      }
    }

    const handleNoteKeys = (hazardDetail: HazardDetail, key: string, value: boolean) => {
      setKeysWithNoValues((prevState) => {
        const hazardKey = (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title
        return {
          ...prevState,
          [hazardKey]: {
            [key]: value,
          },
        }
      })
    }

    /**
     * final function call for updating layers based on the hazard option state and findScenario
     * @TODO don't call if data is same
     */
    const updateLayersFromHazardOptionState = ({
      hazardDetail,
    }: {
      hazardDetail: CombinedHazardDetail | HazardDetail
    }) => {
      const allScenariosForHazard = scenarioByHazardId
        ? scenarioByHazardId[(hazardDetail as CombinedHazardDetail).hazard_id]?.scenarios
        : layersData?.hazardDialogData?.hazardDetails.find(
            (hazard) => hazard.title === hazardDetail.title,
          )?.scenarios
      let scenarios = hazardDetail.scenarios
      if (allScenariosForHazard) scenarios = allScenariosForHazard
      const newScenario = findScenario(
        scenarios,
        (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title,
      )
      if (!newScenario) return
      let scenarioTitle = ''

      // remove existing layer
      let newLayers = layers.filter((layer) => layer.hazard_title !== hazardDetail.title)
      // works because there is only one hazard_id per hazardDetail
      if (newScenario.hazard_id) {
        layersData?.hazardDialogData?.hazardDetails.find((hazard) => {
          return hazard.scenarios.some((scenario) => {
            if (scenario.hazard_id === newScenario.hazard_id) {
              scenarioTitle = hazard.title
              return true
            }
            return false
          })
        })
        newLayers = layers.filter((layer) => layer.hazard_id !== newScenario.hazard_id)
        // add new layer
        newLayers.push({
          ...newScenario,
          hazard_title: scenarioTitle,
          layerType: 'hazard',
          visible: true,
        })
        const layersSorted = layers
          .map((layer) => layer.assetTag ?? layer.type ?? layer.id)
          .toSorted()
        const newLayersSorted = newLayers
          .map((layer) => layer.assetTag ?? layer.type ?? layer.id)
          .toSorted()
        if (layersSorted.join() === newLayersSorted.join()) return
        prepareAndUpdateLayers({ layers: newLayers })
        return
      }

      // add new layer
      newLayers.push({
        ...newScenario,
        hazard_title: hazardDetail.title,
        layerType: 'hazard',
        visible: true,
      })
      const layersSorted = layers
        .map((layer) => layer.assetTag ?? layer.type ?? layer.id)
        .toSorted()
      const newLayersSorted = newLayers
        .map((layer) => layer.assetTag ?? layer.type ?? layer.id)
        .toSorted()
      if (layersSorted.join() === newLayersSorted.join()) return
      prepareAndUpdateLayers({ layers: newLayers })
    }

    /**
     * If changes are detected, it calls the updateLayersFromHazardOptionState function with the last updated hazard as the parameter.
     * This ensures that the layers are updated whenever there is a change in the hazard option state.
     */
    const updateLayersTimeout = useRef<NodeJS.Timeout | null>(null)
    useEffect(() => {
      if (lastUpdatedHazard === null) return
      if (updateLayersTimeout.current) clearTimeout(updateLayersTimeout.current)
      updateLayersTimeout.current = setTimeout(
        () => updateLayersFromHazardOptionState({ hazardDetail: lastUpdatedHazard }),
        200,
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUpdatedHazard, hazardOptionState, prevHazardOptionState])

    // use a string cause it won't recalc on rerender unless it actually changed cause it's a primitive
    // used for initial setup as well as on hazard changes
    useEffect(() => {
      const initialHazardOptionState = initialHazardOptionStateFunc()
      setHazardOptionState((prev) => {
        return {
          ...prev,
          ...initialHazardOptionState,
        }
      })
      setPrevHazardOptionState((prev) => {
        if (!prev)
          return {
            ...initialHazardOptionState,
          }
        return {
          ...prev,
          ...initialHazardOptionState,
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hazardTitleString])

    if (!filteredLayersData) return null
    if (!Object.keys(hazardOptionState).length) return null

    return (
      <>
        <Typography>
          Adjust the slider and button controls below to view different{' '}
          {term_preference.hazard ? 'hazard' : 'risk'} scenarios.
        </Typography>
        {filteredLayersData.map((hazardDetail: CombinedHazardDetail | HazardDetail) => {
          if (!hazardDetail.form.length) return null
          if (!hasMultipleAvailableValues(hazardDetail)) return null
          const mapLayer = getScenarioByHazardDetail(layers, hazardDetail as CombinedHazardDetail)
          return (
            <Accordion
              variant="outline"
              level="h3"
              key={
                use_hazard_name.flag
                  ? mapLayer?.hazard_name ?? mapLayer?.display_name
                  : mapLayer?.display_name
              }
              header={
                <HazardControlFormHeader hazardDetails={hazardDetail as CombinedHazardDetail} />
              }
              defaultExpandedOnce
              accordionStateKey={(hazardDetail as CombinedHazardDetail).hazard_id}
              body={
                <Box css={hazardControlsColumn}>
                  <Box css={hazardControlsRow}>
                    <Box css={hazardDetailFormContainer({ theme })}>
                      <Typography
                        onClick={() => {
                          setHazardOptionState((prevState) => {
                            const newState = { ...prevState }
                            const hazardKey =
                              (hazardDetail as CombinedHazardDetail)?.hazard_id ??
                              hazardDetail.title
                            Object.keys(newState[hazardKey]).forEach((key) => {
                              newState[hazardKey][key].value = ''
                            })
                            return newState
                          })
                          updateLayersFromHazardOptionState({ hazardDetail })
                        }}
                        className="reset-filters"
                      >
                        Reset Controls
                      </Typography>
                      {sortFormByPriority(hazardDetail.form).map((formElement: FormElementType) => {
                        if (
                          !hazardOptionState[
                            (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title
                          ]
                        )
                          return null
                        return (
                          <HazardControlFormElement
                            key={formElement.key}
                            hazardDetail={hazardDetail}
                            formElement={formElement}
                            hazardOptionState={
                              hazardOptionState[
                                (hazardDetail as CombinedHazardDetail)?.hazard_id ??
                                  hazardDetail.title
                              ]
                            }
                            handleParameterChange={(key, value) =>
                              updateHazardOptionState({ key, value, hazardDetail })
                            }
                            handleSwitch={(key, isOn) => handleSwitch(key, isOn, hazardDetail)}
                            handleNoteKeys={handleNoteKeys}
                          />
                        )
                      })}
                      <Box
                        css={hiddenOptionsNote({
                          theme,
                          shouldHideNote: Object.keys(
                            keysWithNoValues[
                              (hazardDetail as CombinedHazardDetail)?.hazard_id ?? ''
                            ] ?? {},
                          ).some((innerKey) => {
                            return keysWithNoValues[
                              (hazardDetail as CombinedHazardDetail)?.hazard_id ?? ''
                            ][innerKey]
                          }),
                        })}
                      >
                        <Typography variant="h6">Note</Typography>
                        <Typography>
                          Control options will be hidden if a scenario is not possible. To show all
                          options reset controls.
                        </Typography>
                      </Box>
                    </Box>
                    <Box css={hazardControlRightColumn}>
                      <HazardControlShownScenario
                        hazardDetail={hazardDetail as CombinedHazardDetail}
                        scenario={findScenario(
                          hazardDetail.scenarios,
                          (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title,
                        )}
                        setInformationDetailsModal={setInformationDetailsModal}
                        hazardOptionState={
                          hazardOptionState[
                            (hazardDetail as CombinedHazardDetail)?.hazard_id ?? hazardDetail.title
                          ]
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              }
            ></Accordion>
          )
        })}
        {informationDetailsModal.isOpen &&
          createPortal(
            <MoreInformationModal
              baseURL={BASE_API_URL}
              helpFileName={informationDetailsModal.helpFileName}
              isOpen={informationDetailsModal.isOpen}
              handleClose={() =>
                setInformationDetailsModal({
                  isOpen: false,
                  helpFileName: '',
                })
              }
            />,
            document.body,
          )}
      </>
    )
  },
  (prevProps, nextProps) => {
    return prevProps.hazardIds.toString() === nextProps.hazardIds.toString()
  },
)
