/** @jsxImportSource @emotion/react */
import { Map, Expression } from 'mapbox-gl'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { handleChangeMapStyle } from './utils/style'

import {
  BASE_API_URL,
  NEW_ZEALAND_LAT,
  NEW_ZEALAND_LGT,
  NEW_ZEALAND_ZOOM_LEVEL,
} from '@src/app-constants'
import {
  ChangeMapStyleProps,
  ClearMapProps,
  RemoveLayerByIdProps,
  RemoveRegionMasksLayersProps,
  UpdateDrawAreasProps,
  UpdateLayersProps,
} from './PrioritisationMapContext.types'
import { handleUpdateDrawAreas } from './utils/drawAreas'
import {
  handleClearMap,
  handleRemoveLayerById,
  handleRemoveRegionMasksLayers,
  handleUpdateLayers,
} from './utils/layers'
import {
  FitBoundsProps,
  FlyTo,
  handleFitBounds,
  handleFlyTo,
  handleFlyToFeature,
} from './utils/location'
import { RegionOption } from '@src/components/Molecules/RegionFilter'
import axios from '@src/utils/customAxios'
import { RootState } from '@redux/store'
import { BaseMapLabel } from '@src/components/Pages/Map/BaseMapButton'
import { removeSelectedMarkerFromMapIfExists } from '@src/components/Molecules/MapView/GenericMapView/GenericMapView.utilities'
import {
  MapLocation,
  updateMapStateProperty,
  updateSelectedFeature,
} from '@redux/prioritisationMap/prioritisationMapSlice'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { GenericMapContext } from '@contexts/RiskMapContext'
import { PrioritisationMapLayer } from '@contexts/PrioritisationContext'

export interface PrioritisationMapContext extends GenericMapContext {
  selectedPrioLayer: PrioritisationMapLayer | null
  setSelectedPrioLayer: Dispatch<SetStateAction<PrioritisationMapLayer | null>>
  selectFeature: (feature: mapboxgl.MapboxGeoJSONFeature, layer: MapLayer) => boolean
}

export const PrioritisationMapContext = createContext({} as PrioritisationMapContext)

export interface MapProviderProps {
  children: React.ReactNode
}

export const AppInitialLocation: MapLocation = {
  latitude: NEW_ZEALAND_LAT,
  longitude: NEW_ZEALAND_LGT,
  zoom: NEW_ZEALAND_ZOOM_LEVEL,
}

export interface MapLayerStyle {
  [property: string]: Expression
}

export interface MapLayerStyles {
  [id: string]: MapLayerStyle
}

export function PrioritisationMapProvider({ children }: MapProviderProps) {
  const dispatch = useDispatch()
  const [clientLocation, setClientLocation] = useState<MapLocation>(AppInitialLocation)
  const [regionMasks, setRegionMasks] = useState<RegionOption[] | null>(null)
  const [cascadingAssetNDays, setCascadingAssetNDaysState] = useState<{
    [assetId: string]: number | null
  }>({})
  const [mapLayerStyles, setMapLayerStyles] = useState<MapLayerStyles>({})
  const [basemap, setBasemap] = useState<BaseMapLabel>('Street')
  const [selectedPrioLayer, setSelectedPrioLayer] = useState<PrioritisationMapLayer | null>(null)
  const [map, setMap] = useState<Map | null>(null)
  const [clientHasChanged, setClientHasChanged] = useState(false)
  const [mapIs3D, setMapIs3D] = useState(false)

  const [mapPaddingIsChanging, setMapPaddingIsChanging] = useState({
    top: false,
    right: false,
    bottom: false,
    left: false,
  })

  const setCascadingAssetNDays = (assetId: string, nDays: number | null) => {
    setCascadingAssetNDaysState((prev) => ({
      ...prev,
      [assetId]: nDays,
    }))
  }

  const mapPaddingRef = useRef<{
    top: number
    right: number
    bottom: number
    left: number
  }>({ top: 0, right: 0, bottom: 0, left: 0 })
  const { tab } = useSelector((state: RootState) => state.sideDrawer)

  const setMapPadding = (padding: {
    top?: number
    right?: number
    bottom?: number
    left?: number
  }) => {
    mapPaddingRef.current = {
      ...mapPaddingRef.current,
      ...padding,
    }
  }

  const getMapPadding = (usePadding: boolean | undefined) =>
    usePadding ? mapPaddingRef.current : { top: 0, right: 0, bottom: 0, left: 0 }

  const fetchMapLayerStyles = async (): Promise<MapLayerStyles> => {
    return axios
      .get<MapLayerStyles>(`${BASE_API_URL}/layer/styles`)
      .then((response) => response.data)
      .catch(() => {
        return {}
      })
  }

  const setMapInstance = (map: Map | null) => {
    setMap(map)
  }

  const handleSetClientLocation = (location: MapLocation) => {
    setClientLocation(location)
  }

  const removeLayerById = ({ layerId, map }: RemoveLayerByIdProps) => {
    if (!map) return

    handleRemoveLayerById({ layerId, map, dispatch })
  }

  const fitToBounds = ({ boundingBox, map, usePadding }: FitBoundsProps) => {
    if (!map) return

    handleFitBounds({ boundingBox, map, padding: getMapPadding(usePadding) })
  }

  const clearMap = ({ map }: ClearMapProps) => {
    if (!map) return

    handleClearMap({ map })
    if (regionMasks?.length) setRegionMasks(null)
    dispatch(updateMapStateProperty({ layers: [] }))
  }

  const flyTo = (flyTo: FlyTo) => {
    if (!map) return

    handleFlyTo({
      flyTo: { padding: getMapPadding(flyTo.usePadding), ...flyTo },
      map,
    })
  }

  const removeRegionMasksLayers = ({ map }: RemoveRegionMasksLayersProps) => {
    if (!map) return

    handleRemoveRegionMasksLayers({ map })
    setRegionMasks(null)
  }

  const flyToFeature = (feature: GeoJSON.Feature, flyToOptions?: Partial<FlyTo>) => {
    if (!map) return

    handleFlyToFeature({
      map,
      feature,
      flyToOptions: {
        padding: getMapPadding(flyToOptions?.usePadding),
        ...flyToOptions,
      },
    })
  }

  const deselectFeature = () => {
    dispatch(
      updateSelectedFeature({
        selectedFeature: null,
        selectedLayer: null,
      }),
    )
    dispatch(updateMapStateProperty({ selectedMarker: null }))
    removeSelectedMarkerFromMapIfExists()
  }

  const selectFeature = (feature: mapboxgl.MapboxGeoJSONFeature, layer: MapLayer) => {
    if (!map || tab === 'Stories') return false
    dispatch(
      updateSelectedFeature({
        selectedFeature: feature,
        selectedLayer: layer,
      }),
    )

    return true
  }

  const updateLayers = ({ layers }: UpdateLayersProps) => {
    handleUpdateLayers({ layers, dispatch })
  }
  const changeMapStyle = ({ style }: ChangeMapStyleProps) => {
    handleChangeMapStyle({ style, dispatch })
  }

  const updateDrawAreas = ({ drawAreas }: UpdateDrawAreasProps) => {
    handleUpdateDrawAreas({ drawAreas, dispatch })
  }

  useEffect(() => {
    setClientHasChanged(true)
  }, [clientLocation])

  // when the map is ready and the client location is set, fly to the client location
  useEffect(() => {
    if (clientLocation && map && clientHasChanged) {
      flyTo({ ...clientLocation, usePadding: true, pitch: 0, bearing: 0 })
      setClientHasChanged(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientHasChanged, map])

  useEffect(() => {
    // get styles once
    fetchMapLayerStyles().then((styles) => {
      setMapLayerStyles(styles)
    })
  }, [])

  return (
    <PrioritisationMapContext.Provider
      value={{
        map,
        flyTo,
        clearMap,
        setMapInstance,
        fitToBounds,
        updateDrawAreas,
        updateLayers,
        changeMapStyle,
        clientLocation,
        mapLayerStyles,
        removeLayerById,
        regionMasks,
        setRegionMasks,
        cascadingAssetNDays,
        setCascadingAssetNDays,
        handleSetClientLocation,
        setMapPadding,
        getMapPadding,
        flyToFeature,
        selectFeature,
        basemap,
        setBasemap,
        deselectFeature,
        selectedPrioLayer,
        setSelectedPrioLayer,
        mapPaddingRef,
        removeRegionMasksLayers,
        mapPaddingIsChanging,
        setMapPaddingIsChanging,
        mapIs3D,
        setMapIs3D,
      }}
    >
      {children}
    </PrioritisationMapContext.Provider>
  )
}

export const usePrioritisationMap = () => useContext(PrioritisationMapContext)
