import { MCAScenario } from '@contexts/PrioritisationContext'
import { Content } from 'pdfmake/interfaces'

export const GenerateScenarioDescription = (scenarioGroup: MCAScenario): Content => {
  return {
    stack: [
      {
        text: 'Prioritisation and exposure in this report is for the following selected scenario.',
        marginBottom: 10,
      },
      { text: scenarioGroup.scenario_id, style: 'bold' },
      { text: scenarioGroup.description },
      ...scenarioGroup.hazard_scenarios.map((scenario) => {
        return { text: `• ${scenario.display_name}` }
      }),
    ],
    marginBottom: 20,
  }
}
