/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { ActiveStory } from './ActiveStory'
import { Story } from './Stories.utils'
import { StoryMenu } from './StoryMenu/StoryMenu'
import { sideDrawerContentParent } from '../../RiskSideDrawerContent.styles'
import { useStoriesFetcher } from '../../data_fetchers/storiesFetcher'
import { SideDrawerHeader } from '../../components/SideDrawerHeader'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'

export const StoriesTab = () => {
  const [story, setStory] = useState<Story | null>(null)
  const stories: Story[] | undefined = useStoriesFetcher() ?? []
  const { clientName } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    setStory(null)
  }, [clientName])

  return (
    <Box css={sideDrawerContentParent()}>
      <SideDrawerHeader
        title="RISK STORIES"
        subtitle={story ? story.title : undefined}
        iconName="s3://openbook.svg"
        onTitleClicked={story ? () => setStory(null) : undefined}
        onclose={story ? () => setStory(null) : undefined}
      />
      {story ? (
        <ActiveStory story={story} />
      ) : stories ? (
        <StoryMenu stories={stories} setStory={setStory} />
      ) : null}
    </Box>
  )
}
