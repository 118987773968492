/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'
import {
  placeholder,
  textPlaceholderContainer,
  placeholderWave,
  barChartPlaceholderContainer,
  horizontalBarChartPlaceholderContainer,
  guagePlaceholder,
  guagePlaceholderContainer,
  chartsPlaceholderContainer,
  placeholderAxii,
  tablePlaceholderContainer,
  childrenCss,
  fallBackFadeout,
} from './FancySuspense.styles'
import { Suspense, useEffect, useState } from 'react'

export interface FancySuspenseProps {
  variant?:
    | 'box'
    | 'text'
    | 'barChart'
    | 'horizontalBarChart'
    | 'guage'
    | 'table-3-col'
    | 'table-2-col'
    | 'charts'
    | 'horizontalBarChartInverse'
  isLoading?: boolean
  children: React.ReactNode
}

export const FancySuspense = ({ variant, isLoading = false, children }: FancySuspenseProps) => {
  const theme = useTheme()
  const [wasLoading, setWasLoading] = useState(false)

  let fallback = null
  switch (variant) {
    case 'box':
      fallback = <div css={placeholder({ width: '100%', theme })} />
      break
    case 'text':
      fallback = (
        <div css={textPlaceholderContainer({ theme })}>
          <div css={placeholder({ width: '100%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '100%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '100%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '100%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '60%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '60%', theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'table-3-col':
      fallback = (
        <div css={tablePlaceholderContainer({ theme, cols: 3 })}>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'table-2-col':
      fallback = (
        <div css={tablePlaceholderContainer({ theme, cols: 2 })}>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'horizontalBarChart':
      fallback = (
        <div css={horizontalBarChartPlaceholderContainer({ theme })}>
          <div css={placeholder({ width: '25%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '50%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '75%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'horizontalBarChartInverse':
      fallback = (
        <div css={horizontalBarChartPlaceholderContainer({ theme })}>
          <div css={placeholder({ width: '75%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '50%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ width: '25%', height: '30%', theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'barChart':
      fallback = (
        <div css={barChartPlaceholderContainer({ theme })}>
          <div css={placeholder({ height: '5%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '10%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '20%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '40%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '66%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
          <div css={placeholder({ height: '100%', width: '15%', theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'guage':
      fallback = (
        <div css={guagePlaceholderContainer()}>
          <div css={guagePlaceholder({ theme })}>
            <div css={placeholderWave()} />
          </div>
        </div>
      )
      break
    case 'charts':
      fallback = (
        <div css={chartsPlaceholderContainer({ theme })}>
          <div css={placeholder({ height: '100%', width: '100%', theme })}>
            <div css={placeholderWave()} />
            <div css={placeholderAxii({ theme })} />
          </div>
          <div css={placeholder({ height: '100%', width: '100%', theme })}>
            <div css={placeholderWave()} />
            <div css={placeholderAxii({ theme })} />
          </div>
          <div css={placeholder({ height: '100%', width: '100%', gridColumn: '2', theme })}>
            <div css={placeholderWave()} />
            <div css={placeholderAxii({ theme })} />
          </div>
        </div>
      )
      break
  }

  useEffect(() => {
    if (isLoading && !wasLoading) {
      setWasLoading(true)
    }
    if (wasLoading && !isLoading) {
      setTimeout(() => {
        setWasLoading(false)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  if (isLoading) return fallback

  if (wasLoading)
    return (
      <Suspense fallback={fallback}>
        <Box sx={{ position: 'relative' }}>
          <Box css={fallBackFadeout(wasLoading)}>{fallback}</Box>
        </Box>
        <Box css={childrenCss(wasLoading)}>{children}</Box>
      </Suspense>
    )

  return <Suspense fallback={fallback}>{children}</Suspense>
}
