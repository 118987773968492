/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'

import { setSideDrawerIsOpen } from '@redux/riskSideDrawer/riskSideDrawerSlice'
import { RootState } from '@redux/store'
import { MapboxOptions } from 'mapbox-gl'
import { useDispatch, useSelector } from 'react-redux'
import { prioritisationPageContainer, sideDrawerContainer } from './PrioritisationPage.styles'
import { useEffect, useMemo, useState } from 'react'
import { SideDrawer } from '@src/components/Molecules/SideDrawer'
import { PrioritisationMapView } from '@src/components/Molecules/MapView/PrioritisationMapView'
import { SideDrawerContent } from '@src/components/PrioritisationPage/SideDrawerContent'
import { LandingOverlay } from '@src/components/PrioritisationPage/LandingOverlay'
import { WizardModal } from '@src/components/PrioritisationPage/WizardModal'
import { usePrioritisationContext } from '@contexts/PrioritisationContext'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { useNavigate } from 'react-router-dom'
import { usePrioritisationMap } from '@contexts/PrioritisationMapContext'
import { useLayerDataFetcher } from '@src/components/Molecules/RiskSideDrawerContent/data_fetchers/layerDataFetcher'

export type ViewOrientation = Pick<
  MapboxOptions,
  'center' | 'zoom' | 'pitch' | 'maxPitch' | 'zoom' | 'bearing'
>

export type sideDrawerTab = 'Risk' | 'Stories' | 'Welcome & FAQ'

export const PrioritisationPage = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const layersData = useLayerDataFetcher()

  // Temporary feature flag for the new Prioritisation page
  const { features } = useFeatureFlags()
  const attributeFiltersFeature = useMemo(
    () =>
      features.find(
        (feature) => feature.feature == 'prioritisationAnalysisPage' && feature.enabled,
      ),
    [features],
  )
  const navigate = useNavigate()

  const { setMapPadding, flyTo, map, getMapPadding } = usePrioritisationMap()
  const { initializePrioritisation, landingOverlayShown, setLandingOverlayShown } =
    usePrioritisationContext()

  const { clientName } = useSelector((state: RootState) => state.user)
  const { isOpen: sideDrawerIsOpen } = useSelector((state: RootState) => state.sideDrawer)
  const [wizardIsOpen, setWizardIsOpen] = useState(false)
  const [landingOverlayIsOpen, setLandingOverlayIsOpenState] = useState(!landingOverlayShown)
  const setLandingOverlayIsOpen = (open: boolean) => {
    if (!open) {
      setLandingOverlayShown(true)
    }
    setLandingOverlayIsOpenState(open)
  }

  useEffect(() => {
    if (attributeFiltersFeature !== undefined && !attributeFiltersFeature) navigate('/risk')
  }, [attributeFiltersFeature, navigate])

  // Open the side drawer by default
  // and close it when logged out
  useEffect(() => {
    if (clientName && sideDrawerIsOpen !== true) dispatch(setSideDrawerIsOpen(true))
    if (!clientName) dispatch(setSideDrawerIsOpen(false))
    if (layersData) initializePrioritisation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName, layersData])

  // Hide side drawer when entering full screen
  useEffect(() => {
    function onEnterFullscreen() {
      dispatch(setSideDrawerIsOpen(false))
    }

    document.addEventListener('EnteringFullScreen', onEnterFullscreen)

    return () => document.removeEventListener('EnteringFullScreen', onEnterFullscreen)
  }, [dispatch])

  return (
    <Box css={prioritisationPageContainer} id="prioritisationPageContainer">
      <Box css={sideDrawerContainer}>
        <SideDrawer
          isOpen={sideDrawerIsOpen}
          handleToggle={() => {
            dispatch(setSideDrawerIsOpen(!sideDrawerIsOpen))
          }}
          color={theme.palette.uintel.main}
          side="left"
          initialWidth={600}
          handleWidthChange={(width: number) => {
            const { left: previousWidth } = getMapPadding(true)
            setMapPadding({ top: 0, left: width, bottom: 0, right: 0 })

            if (map && width != previousWidth) {
              flyTo({
                latitude: map.getCenter().lat,
                longitude: map.getCenter().lng,
                zoom: map.getZoom(),
                usePadding: true,
                duration: 800,
              })
            }
          }}
        >
          <SideDrawerContent
            openWizard={() => {
              setWizardIsOpen(true)
            }}
          />
        </SideDrawer>
      </Box>
      <PrioritisationMapView />
      <LandingOverlay
        open={landingOverlayIsOpen}
        setOpen={setLandingOverlayIsOpen}
        openWizard={() => {
          setWizardIsOpen(true)
        }}
      />
      <WizardModal
        open={wizardIsOpen}
        completeWizard={() => {
          setWizardIsOpen(false)
        }}
        returnFromWizard={() => {
          setWizardIsOpen(false)
          setLandingOverlayIsOpen(true)
        }}
      />
    </Box>
  )
}
