/** @jsxImportSource @emotion/react */

import { Autocomplete, Box, TextField, useTheme } from '@mui/material'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { BASE_API_URL } from '@src/app-constants'
import { HazardDetail, HazardDialogData, HazardScenario } from '../AddLayersDialog.types'
import {
  accordionContainer,
  accordionContainerSmallGap,
  bold700,
  hazardLayersFormContainer,
  hazardLayersFormContent,
} from './HazardLayersForm.styles'
import { HazardBox } from './HazardBox'
import { MapLayer, MapLayerType } from '@redux/riskMap/riskMapSlice'
import { useSnackbars } from '@contexts/SnackbarContext'
import { Accordion } from '@src/components/Atoms/Accordion'
import { UiclAccordion } from '@src/components/Atoms/Accordion/UiclAccordion'
import { snakeCaseToTitleCase } from '@src/utils/strings.utils'
import { autocompleteFields } from '../AddLayersDialog.styles'
import { compareStrings } from '@src/components/Molecules/Charts/utils/utils'
import { MoreInformationModal } from '@src/components/Organisms/Modals/MoreInformationModal'
import { IconTypography } from '@src/components/Atoms/IconTypography'

export type HazardLayersFormProps = {
  hazardDialogData: HazardDialogData
  onSelectHazardLayer: (selectedHazard: MapLayer) => void
  onRemoveLayer: (layer: MapLayer, type: MapLayerType) => void
  selectedHazards: MapLayer[]
  selectedAssets: MapLayer[]
  isKnownRisks?: boolean
}

export const HazardLayersForm = ({
  hazardDialogData,
  selectedHazards,
  selectedAssets,
  onSelectHazardLayer,
  onRemoveLayer,
  isKnownRisks = false,
}: HazardLayersFormProps) => {
  const theme = useTheme()
  const [informationDetailsModal, setInformationDetailsModal] = useState({
    isOpen: false,
    helpFileName: '',
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [searchFoundHazardScenario, setSearchFoundHazardScenario] = useState<HazardScenario | null>(
    null,
  )
  const { displaySnackbar } = useSnackbars()

  // important clone! otherwise hazardDialogData will be mutated
  const hazardDialogDataCopy: HazardDialogData = JSON.parse(JSON.stringify(hazardDialogData))

  let groupFormattedHazards: { [key: string]: HazardDetail[] } =
    hazardDialogDataCopy.hazardDetails.reduce((acc, hazard) => {
      if (hazard.group) {
        if (acc[hazard.group]) {
          acc[hazard.group].push(hazard)
        } else {
          acc[hazard.group] = [hazard]
        }
      }
      return acc
    }, {} as { [key: string]: HazardDetail[] })
  if (Object.keys(groupFormattedHazards).length === 0) {
    groupFormattedHazards = {
      'Risk Sources': hazardDialogDataCopy.hazardDetails,
    }
  }

  const cascadingLayerIsPresent =
    selectedAssets && selectedAssets.some((asset) => asset.is_cascading)
  const cannotAddHazardLayer = cascadingLayerIsPresent && selectedHazards.length > 0

  const hasHazardIds = hazardDialogData.hazardDetails.every((hazardDetail) =>
    hazardDetail.scenarios.every((scenario) => scenario.hazard_id != undefined),
  )

  const groupIcons = Object.keys(groupFormattedHazards).reduce((acc, group) => {
    if (groupFormattedHazards[group][0].groupIcon) {
      acc[group] = groupFormattedHazards[group][0].groupIcon
    }
    return acc
  }, {} as { [key: string]: string })

  const handleAddLayerClick = (scenario: HazardScenario) => {
    if (
      selectedHazards.find((hazard) => {
        if (isKnownRisks || scenario.hazard_id === undefined)
          return hazard.assetTag === scenario.assetTag
        return scenario.hazard_id && hazard.hazard_id && scenario.hazard_id === hazard.hazard_id
      })
    ) {
      onRemoveLayer(scenario, 'hazard')
      return
    }
    if (cannotAddHazardLayer) {
      displaySnackbar({
        message:
          'While a cascading layer is active, you can only have one hazard active at a time. Please remove existing hazard layer before adding a new hazard layer.',
        type: 'error',
      })
      return
    }
    const formattedSelectedHazard: MapLayer = {
      ...scenario,
      display_name: scenario.display_name
        ? scenario.display_name
        : snakeCaseToTitleCase(scenario.assetTag),
      legend: scenario.legend,
      visible: true,
      interactivityDisabled: false,
      icon: scenario.icon ?? 'Warning',
      layerType: 'hazard',
    }
    onSelectHazardLayer(formattedSelectedHazard)
  }

  // sort hazardLayer.form by hazardLayer.form[n].priority
  const sortHazardLayerForm = (hazardLayer: HazardDetail) => {
    const sortedHazardLayer = {
      ...hazardLayer,
    }
    if (hazardLayer.form) {
      sortedHazardLayer.form = [...hazardLayer.form].toSorted((a, b) => {
        return a.priority - b.priority
      })
    }
    return sortedHazardLayer
  }

  const flatScenarios = useMemo(() => {
    const flatScenarios = hazardDialogData.hazardDetails.flatMap((hazard) => hazard.scenarios)

    // Only list one scenario per unique hazard id in results
    if (hasHazardIds) {
      const singleScenarioPerHazardId: HazardScenario[] = []
      const seenHazardIds: { [key: string]: boolean } = {}

      flatScenarios.forEach((scenario) => {
        if (scenario.hazard_id && !seenHazardIds[scenario.hazard_id]) {
          singleScenarioPerHazardId.push(scenario)
          seenHazardIds[scenario.hazard_id] = true
        }
      })

      return singleScenarioPerHazardId
    }

    flatScenarios.sort((a, b) => {
      return compareStrings(a.display_name, b.display_name)
    })

    return flatScenarios
  }, [hasHazardIds, hazardDialogData.hazardDetails])

  const isHazardSelected = (hazard: MapLayer) => {
    const existingHazards = selectedHazards

    const isAlreadySelected = existingHazards.some(
      (selectedHazard) => selectedHazard.tilesets[0].id === hazard.tilesets[0].id,
    )
    return isAlreadySelected
  }

  const AccordionToUse = isKnownRisks ? Accordion : (UiclAccordion as typeof Accordion)

  return (
    <>
      <Box css={hazardLayersFormContainer({ theme })}>
        <Autocomplete
          css={autocompleteFields({ theme })}
          autoHighlight
          freeSolo // Stops making it look like a drop down however this means values can either be MapLayers or strings
          clearOnBlur
          inputValue={searchTerm} // Because of freeSolo the inputValue is a string
          value={searchFoundHazardScenario} // while the value itself can be a string or MapLayer
          options={flatScenarios}
          isOptionEqualToValue={(option, value) => option.hazard_id == value.hazard_id}
          getOptionLabel={(option) =>
            typeof option == 'string' ? option : option.hazard_name ?? '?'
          }
          getOptionKey={(option) => (typeof option == 'string' ? option : option.assetTag)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              placeholder="Search"
              autoComplete="off"
              autoFocus
            />
          )}
          renderOption={(props, option, _state, ownerState) => {
            const { key, ...optionProps } = props
            return (
              <li key={key} {...optionProps}>
                {isHazardSelected(option) ? (
                  <b>{ownerState.getOptionLabel(option)}</b>
                ) : (
                  ownerState.getOptionLabel(option)
                )}
              </li>
            )
          }}
          onChange={(_event, hazardScenario) => {
            if (hazardScenario && typeof hazardScenario !== 'string') {
              // We found an actual HazardScenario and because of freeSolo
              // it's not some random string which doesn't match anything
              handleAddLayerClick(hazardScenario)
              setSearchFoundHazardScenario(null)
            }
          }}
          onInputChange={(_e, newInputValue, reason) => {
            // Clear the input when an item is selected
            if (reason == 'reset') setSearchTerm('')
            else setSearchTerm(newInputValue) // without this the text field doesn't update when typing
          }}
        />

        <Box css={hazardLayersFormContent()}>
          <Box css={accordionContainer({ theme })}>
            {Object.keys(groupFormattedHazards).length === 1 &&
            groupFormattedHazards['Risk Sources'] ? (
              <>
                {groupFormattedHazards['Risk Sources'] &&
                  groupFormattedHazards['Risk Sources'].map((hazardLayer) => (
                    <AccordionToUse
                      key={hazardLayer.title}
                      lazyContent
                      level="h3"
                      variant="outline"
                      header={
                        <IconTypography
                          icon={hazardLayer.groupIcon ? hazardLayer.groupIcon : ''}
                          css={bold700}
                        >
                          {hazardLayer.title}
                        </IconTypography>
                      }
                      body={
                        <HazardBox
                          hazardDetail={sortHazardLayerForm(hazardLayer)}
                          selectedHazards={selectedHazards}
                          addingDisallowed={cannotAddHazardLayer}
                          allScenarios={
                            hazardDialogData.hazardDetails.find((hDetail) => {
                              return hDetail.title === hazardLayer.title
                            })?.scenarios ?? hazardLayer.scenarios
                          }
                          setInformationDetailsModal={setInformationDetailsModal}
                          addHazardScenario={handleAddLayerClick}
                          isKnownRisks={isKnownRisks}
                        />
                      }
                    />
                  ))}
              </>
            ) : (
              <>
                {Object.keys(groupFormattedHazards).map((hazardGroupKey) => (
                  <AccordionToUse
                    key={hazardGroupKey}
                    defaultExpanded={true}
                    level="h3"
                    lazyContent
                    variant="filled"
                    header={
                      <IconTypography
                        icon={groupIcons[hazardGroupKey] ? groupIcons[hazardGroupKey] : ''}
                      >
                        {hazardGroupKey}
                      </IconTypography>
                    }
                    body={
                      <Box css={accordionContainerSmallGap({ theme })}>
                        {groupFormattedHazards[hazardGroupKey]?.map((hazardLayer) => (
                          <AccordionToUse
                            key={hazardLayer.title}
                            lazyContent
                            level="h3"
                            variant="outline"
                            header={
                              <IconTypography
                                icon={hazardLayer.group ? hazardLayer.icon : ''}
                                css={bold700}
                              >
                                {hazardLayer.title}
                              </IconTypography>
                            }
                            body={
                              <HazardBox
                                hazardDetail={sortHazardLayerForm(hazardLayer)}
                                selectedHazards={selectedHazards}
                                addingDisallowed={cannotAddHazardLayer}
                                allScenarios={
                                  hazardDialogData.hazardDetails.find((hDetail) => {
                                    return hDetail.title === hazardLayer.title
                                  })?.scenarios ?? hazardLayer.scenarios
                                }
                                setInformationDetailsModal={setInformationDetailsModal}
                                addHazardScenario={handleAddLayerClick}
                                isKnownRisks={isKnownRisks}
                              />
                            }
                          />
                        ))}
                      </Box>
                    }
                  ></AccordionToUse>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {informationDetailsModal.isOpen &&
        createPortal(
          <MoreInformationModal
            baseURL={BASE_API_URL}
            helpFileName={informationDetailsModal.helpFileName}
            isOpen={informationDetailsModal.isOpen}
            handleClose={() =>
              setInformationDetailsModal({
                isOpen: false,
                helpFileName: '',
              })
            }
          />,
          document.body,
        )}
    </>
  )
}
