/** @jsxImportSource @emotion/react */
import { Expression, Map, FillLayer, FillExtrusionLayer } from 'mapbox-gl'
import { byAssetIdIn } from '../RiskMapView.utilities'
import { MapLayerStyle } from '@contexts/RiskMapContext'
import { VectorTileset } from '@src/components/Molecules/LayersControl'

export interface VectorLayerProps {
  map: Map
  layerStyle: MapLayerStyle
  tileset: VectorTileset
  beforeId: string
  isVisible: boolean
  colorExpression: Expression
  fillOpacityExpression: Expression
  assetIds?: { [asset_id: number]: boolean }
  sortKeyExpression?: Expression
  hasFilters: boolean
  shouldUpdate?: boolean
}

export const addVectorLayer = ({
  map,
  layerStyle,
  tileset,
  beforeId,
  isVisible,
  colorExpression,
  fillOpacityExpression,
  assetIds,
  sortKeyExpression,
  hasFilters,
  shouldUpdate = false,
}: VectorLayerProps) => {
  const { id, sourceLayer } = tileset
  const fillLayerData: FillLayer = {
    id,
    type: 'fill',
    source: id,
    'source-layer': sourceLayer,
    layout: {
      visibility: isVisible ? 'visible' : 'none',
      'fill-sort-key': sortKeyExpression || 0,
    },
    paint: {
      'fill-opacity': fillOpacityExpression,
      ...layerStyle,
    },
  }

  const hasExtrusion = tileset.has_extrusion
  let extrusionLayer: FillExtrusionLayer | undefined

  if (hasExtrusion) {
    extrusionLayer = {
      id: `${id}-extrusion`,
      type: 'fill-extrusion',
      source: id,
      'source-layer': sourceLayer,
      layout: {
        visibility: isVisible ? 'visible' : 'none',
      },
      paint: {
        'fill-extrusion-color': colorExpression ?? '#3b3b3c',
        'fill-extrusion-height': ['coalesce', ['get', 'Height (m)'], 0] as Expression,
        'fill-extrusion-opacity': 0.8,
        ...layerStyle,
      },
    }
  }

  if (shouldUpdate) {
    if (fillLayerData.paint) {
      for (const paintProp of Object.entries(fillLayerData.paint)) {
        map.setPaintProperty(fillLayerData.id, paintProp[0], paintProp[1])
      }
    }
    if (fillLayerData.layout) {
      for (const layoutProp of Object.entries(fillLayerData.layout)) {
        map.setLayoutProperty(fillLayerData.id, layoutProp[0], layoutProp[1])
      }
    }
    if (extrusionLayer?.paint) {
      for (const paintProp of Object.entries(extrusionLayer?.paint || {})) {
        map.setPaintProperty(`${id}-extrusion`, paintProp[0], paintProp[1])
      }
    }
    if (extrusionLayer?.layout) {
      for (const layoutProp of Object.entries(extrusionLayer?.layout || {})) {
        map.setLayoutProperty(`${id}-extrusion`, layoutProp[0], layoutProp[1])
      }
    }
    if (!hasFilters && !assetIds) {
      map.setFilter(id, null)
      if (extrusionLayer) map.setFilter(`${id}-extrusion`, null)
    }
  } else {
    map.addSource(id, { type: 'vector', url: `mapbox://${id}` })
    if (extrusionLayer) map.addLayer(extrusionLayer, beforeId)
    map.addLayer(fillLayerData, beforeId)
  }
  if (hasFilters && assetIds) {
    const visibilityExpression: Expression = ['boolean', byAssetIdIn(assetIds)]
    map.setFilter(id, visibilityExpression)
    if (hasExtrusion) map.setFilter(`${id}-extrusion`, visibilityExpression)
  }
  if (colorExpression) {
    if (!layerStyle['fill-color']) map.setPaintProperty(id, 'fill-color', colorExpression)
    const alphaIsZeroRegex = /rgba\(\d{1,3}, \d{1,3}, \d{1,3}, 0(\.0)?\)/
    if (
      layerStyle['fill-color'] &&
      alphaIsZeroRegex.test(layerStyle['fill-color'] as unknown as string)
    ) {
      map.setPaintProperty(id, 'fill-outline-color', colorExpression)
    }
    if (hasExtrusion)
      map.setPaintProperty(`${id}-extrusion`, 'fill-extrusion-color', colorExpression)
  }
}
