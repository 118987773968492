import { css, keyframes } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface SidebarContainerProps {
  theme: Theme
}

export const sidebarContainer = ({ theme }: SidebarContainerProps) => css`
  background-color: ${theme.palette.primary.main};
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-top: ${theme.spacing(24)};
  user-select: none;
`

export const sidebarButtonsContainer = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(3)};
  gap: ${theme.spacing(1)};

  width: 100%;
`

export const appVersionLabel = (theme: Theme) => css`
  color: ${theme.palette.white?.main ?? 'white'};
  opacity: 0.4;
  font-size: 9px;
  margin: 0px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  width: 44px;
`

export const logosContainer = ({ width }: { width: number }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: ${width}px;
`

export const sidebarFooter = () => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const feedbackLink = (theme: Theme) => css`
  color: ${theme.palette.white.main};
  font-size: 10px;
  margin: 0px;
  cursor: pointer;
  white-space: nowrap;
  overflow: visible;
  text-decoration: none;

  :hover {
    opacity: 1;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(14px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const bounce = keyframes`
  0% {
    transform: translateX(0);
  }
  
  2% {
    transform: translateX(8px);
  }

  4% {
    transform: translateX(0px);
  }

  6% {
    transform: translateX(0px);
  }

  8% {
    transform: translateX(8px);
  }

  10% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(0);
  }
`

export const hintPointerOuter = css`
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
`
export const hintPointerContainer = (theme: Theme) => css`
  position: absolute;
  left: 24px;
  background-color: ${theme.palette.primary.main};
  border-radius: ${theme.spacing(8)};
  padding: ${theme.spacing(1)} ${theme.spacing(1)};
  height: 34px;
  display: flex;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  align-items: center;
  animation: ${fadeIn} 0.6s ease 2s both, ${bounce} 10s ease-in-out 2s infinite;
`
export const hintPointerIcon = css`
  position: absolute;
  left: -12px;
  transform: rotate(180deg) scaleX(0.7);
`
export const hintPointerInner = (theme: Theme) => css`
  background-color: ${theme.palette.secondary.main};
  border-radius: ${theme.spacing(8)};
  padding: ${theme.spacing(1)} ${theme.spacing(3)};
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.palette.primary.main};
  font-size: 0.9rem;
  font-weight: 700;
  z-index: 1;
  height: 100%;
  white-space: nowrap;
`

export const sidebarButtonContainer = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`
