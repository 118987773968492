import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { VisibleAssets } from '../../../components/Molecules/MapView/RiskMapView/RiskMapView.utilities'
import { updateMapStateProperty } from '../../../redux/riskMap/riskMapSlice'

export function handleUpdateVisibleAssets({
  visibleAssets,
  dispatch,
}: {
  visibleAssets: VisibleAssets
  dispatch: Dispatch<AnyAction>
}) {
  dispatch(updateMapStateProperty({ visibleAssets }))
}
