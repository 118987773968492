/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { legendMenu, mapLegends } from './MapLegends.styles'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { Legend, LegendItem, LegendSection } from '@src/components/Molecules/Legend'
import { Add, Remove } from '@mui/icons-material'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

export const MapLegends = memo(
  function MapLegendsComp() {
    const theme = useTheme()

    const { legendsData: fullLegendsData, layers } = useSelector(
      (state: RootState) => state.riskMap,
    )

    const [legendsMinimized, setLegendsMinimized] = useState<{ [key: string]: boolean }>({})

    const allMinimized = useMemo(
      () => Object.values(legendsMinimized).every((value) => value),
      [legendsMinimized],
    )

    const legendsData = useMemo(() => {
      // filter out legends for layers that aren't visible
      const visibleLayers = layers.filter((layer) => layer.visible)
      const visibleLayerLegends = visibleLayers.map((layer) => layer.legend)
      const filteredLegendsData: { [key: string]: { title: string; sections: LegendSection[] } } =
        {}
      Object.entries(fullLegendsData).forEach(([key, value]) => {
        if (visibleLayerLegends.includes(key)) {
          filteredLegendsData[key] = value
        }
      })
      return filteredLegendsData
    }, [fullLegendsData, layers])

    const toggleAllLegends = useCallback(() => {
      setLegendsMinimized((oldState) => {
        const newState: { [key: string]: boolean } = {}
        Object.keys(oldState).forEach((key) => {
          newState[key] = !allMinimized
        })
        return newState
      })
    }, [allMinimized])

    // Close legends when entering fullscreen
    useEffect(() => {
      function onEnterFullscreen() {
        setLegendsMinimized((oldState) => {
          const newState: { [key: string]: boolean } = {}
          Object.keys(oldState).forEach((key) => {
            newState[key] = true
          })
          return newState
        })
      }

      document.addEventListener('EnteringFullScreen', onEnterFullscreen)

      return () => document.removeEventListener('EnteringFullScreen', onEnterFullscreen)
    }, [])

    useEffect(() => {
      const newState: { [key: string]: boolean } = {}
      Object.keys(legendsMinimized).forEach((key) => {
        if (layers.map((l) => l.legend).includes(key)) {
          newState[key] = legendsMinimized[key]
        }
      })
      Object.keys(legendsData).forEach((key) => {
        if (layers.map((l) => l.legend).includes(key)) {
          if (!newState[key]) newState[key] = false
        }
      })
      setLegendsMinimized(newState)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [legendsData, layers])

    return (
      <Box css={mapLegends({ theme })}>
        {Object.keys(legendsData).length > 0 && (
          <Box css={legendMenu({ theme })} onClick={toggleAllLegends}>
            {allMinimized ? (
              <>
                Expand legends
                <Add />
              </>
            ) : (
              <>
                Collapse legends
                <Remove />
              </>
            )}
          </Box>
        )}
        {Object.entries(legendsData).map(([key, value]) => {
          if (legendsMinimized[key] === undefined) return null
          if (key === 'vulnerability' && !layers.some((l) => l.layerType === 'hazard')) {
            const elements = layers.flatMap((l) => {
              if (l.layerType === 'asset') {
                const output: LegendItem[] = [
                  {
                    label: l.display_name,
                    color: '#0a2845',
                  },
                ]
                return output
              }
              return []
            })
            return (
              <Legend
                key={key}
                title="Elements"
                minimized={legendsMinimized[key]}
                toggleMinimized={() => {
                  setLegendsMinimized((oldState) => ({
                    ...oldState,
                    [key]: !oldState[key],
                  }))
                }}
                sections={[{ type: 'categorical', items: elements }]}
              />
            )
          }
          return (
            <Legend
              key={key}
              title={value.title}
              sections={value.sections}
              minimized={legendsMinimized[key]}
              toggleMinimized={() => {
                setLegendsMinimized((oldState) => ({
                  ...oldState,
                  [key]: !oldState[key],
                }))
              }}
            />
          )
        })}
      </Box>
    )
  },
  () => true,
)
