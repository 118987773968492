/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { priorityHeader, priorityTable } from './PriorityTable.styles'
import { usePrioritisationContext } from '@contexts/PrioritisationContext'
import { FancySuspense } from '@src/components/Atoms/FancySuspense'
import { useState } from 'react'
import { Icon } from '@src/components/Atoms/Icon'
import { usePrioritisationMap } from '@contexts/PrioritisationMapContext'

export const PriorityTable = () => {
  const {
    adaptationAreaPrioritiesIsLoading,
    adaptationAreaPrioritiesIsInitialized,
    adaptationAreaPriorities,
    area,
    setArea,
  } = usePrioritisationContext()
  const { selectedPrioLayer, flyTo, clientLocation } = usePrioritisationMap()

  const [sortProp, setSortProp] = useState<'priorityIndex' | 'area'>('priorityIndex')
  const [sortAsc, setSortAsc] = useState(true)

  const clickHeader = (prop: 'priorityIndex' | 'area') => {
    if (prop === sortProp) {
      setSortAsc(!sortAsc)
    } else {
      setSortProp(prop)
      setSortAsc(true)
    }
  }

  const viewAllAreas = () => {
    setArea('')
    flyTo({ ...clientLocation, usePadding: true, pitch: 0, bearing: 0 })
  }

  return (
    <FancySuspense isLoading={!adaptationAreaPrioritiesIsInitialized} variant="table-2-col">
      <Box className="table" css={priorityTable(adaptationAreaPrioritiesIsLoading)}>
        <Box className="thead">
          <Box className="td">
            <Box css={priorityHeader(sortAsc)} onClick={() => clickHeader('area')}>
              Adaptation area
              {sortProp == 'area' && <Icon iconName="s3://triangle" colour="#000" />}
            </Box>
          </Box>
          <Box className="td">
            <Box css={priorityHeader(sortAsc)} onClick={() => clickHeader('priorityIndex')}>
              Priority
              {sortProp == 'priorityIndex' && <Icon iconName="s3://triangle" colour="#000" />}
            </Box>
          </Box>
        </Box>
        <Box className="tbody">
          {adaptationAreaPriorities
            .sort((a, b) => {
              return (sortAsc ? 1 : -1) * a.area.localeCompare(b.area)
            })
            .filter((areaRow) => areaRow.areaGroup === selectedPrioLayer?.layer_type)
            .map((areaRow) => (
              <Box
                className={'tr ' + (areaRow.area === area ? 'selected' : '')}
                key={areaRow.area}
                onClick={() => {
                  if ((!area || area !== areaRow.area) && areaRow.area !== 'All') {
                    setArea(areaRow.area)
                  } else {
                    viewAllAreas()
                  }
                }}
                css={{
                  order:
                    sortProp == 'priorityIndex' ? (sortAsc ? 1 : -1) * areaRow.priorityIndex : 0,
                }}
              >
                <Box className="td">{areaRow.area}</Box>
                <Box className="td">{areaRow.priorityIndex}</Box>
              </Box>
            ))}
        </Box>
      </Box>
    </FancySuspense>
  )
}
