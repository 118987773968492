/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'
import { formBox, formBoxRow, formHeader, valuesFormContainer } from './ValuesForm.styles'
import { usePrioritisationContext } from '@contexts/PrioritisationContext'
import { useState, useEffect } from 'react'
import { Button } from '@src/components/Atoms/Button'
import { PrioritySlider } from '../../PrioritySlider'
import { Icon } from '@src/components/Atoms/Icon'

export const ValuesForm = ({
  updatedValueWeightings,
}: {
  updatedValueWeightings: (valueWeightings: { [key: string]: number }) => void
}) => {
  const theme = useTheme()
  const [showElements, setShowElements] = useState<{ [key: string]: boolean }>({})
  const { valueWeightings, elementGroups, assessment } = usePrioritisationContext()
  const [sliderValues, setSliderValues] = useState<{ [key: string]: number }>({})

  useEffect(() => {
    const initialSliderValues = elementGroups.reduce((acc, { value_set_id }) => {
      acc[value_set_id] = valueWeightings[value_set_id] ?? 1
      return acc
    }, {} as { [key: string]: number })
    setSliderValues(initialSliderValues)
  }, [elementGroups, valueWeightings])

  useEffect(() => {
    updatedValueWeightings(sliderValues)
  }, [sliderValues, updatedValueWeightings])

  return (
    <Box css={valuesFormContainer()}>
      <Box css={formHeader}>
        <h4>YOUR ORGANISATION HAS {elementGroups.length} VALUE SETS</h4>
        <Box className="buttons">
          <Button
            variant="text"
            color="uintel"
            onClick={() => {
              setSliderValues({
                ...valueWeightings,
                ...assessment?.value_set_config.value_set_weights,
              })
            }}
          >
            Use organisation defaults
          </Button>
          <Button
            sx={{ minWidth: '32px', padding: '2px', maxHeight: '32px' }}
            color="uintel"
            onClick={() => {
              setSliderValues((prev) => {
                return Object.keys(prev).reduce((acc, key) => {
                  acc[key] = 3
                  return acc
                }, {} as { [key: string]: number })
              })
            }}
          >
            <Icon iconName="s3://scales" colour="#fff" />
          </Button>
        </Box>
      </Box>
      <Box css={formBoxRow}>
        {elementGroups.map(({ value_set_id, elements }) => {
          return (
            <Box
              key={value_set_id}
              css={formBox({ theme, showElements: showElements[value_set_id] })}
            >
              <Box className="header">
                <h1>{value_set_id}</h1>
              </Box>
              <Box className="content">
                <Box className="elementList">
                  {elements.map((element) => {
                    return <Box key={element.type}>●&nbsp;&nbsp;{element.label}</Box>
                  })}
                </Box>
                <Box className="valueForm">
                  <p>
                    facilisis sem. Cras sollicitudin hendrerit justo, quis ultricies tortor
                    scelerisque non.
                  </p>
                  <PrioritySlider
                    value={sliderValues[value_set_id] ?? 0}
                    onChange={(_, value) => {
                      setSliderValues({ ...sliderValues, [value_set_id]: value as number })
                    }}
                  ></PrioritySlider>
                </Box>
              </Box>
              <Button
                color="uintel"
                variant="text"
                onClick={() =>
                  setShowElements({ ...showElements, [value_set_id]: !showElements[value_set_id] })
                }
              >
                View {showElements[value_set_id] ? 'Sliders' : 'Elements'}
              </Button>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
