import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { useEffect, useState } from 'react'

type consequenceMethodologyObject = {
  content?: string
  image_url?: string
  source?: string
}

export const useConsequenceMethodologyFetcher = (
  asset: MapLayer | null,
  hazard_layer: MapLayer | null,
): { consequenceMethodologyObject: consequenceMethodologyObject | null; isLoading: boolean } => {
  const [consequenceMethodology, setConsequenceMethodology] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!asset || !hazard_layer) {
      return
    }

    setIsLoading(true)

    axios
      .get(`${BASE_API_URL}/risk/consequence_methodology/${asset.type}/${hazard_layer.assetTag}`)
      .then((response) => {
        setIsLoading(false)
        setConsequenceMethodology(response.data)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [asset, hazard_layer])

  return { consequenceMethodologyObject: consequenceMethodology, isLoading }
}
