/** @jsxImportSource @emotion/react */

import { Box } from '@mui/material'

import { onboardingPage } from './OnboardingPage.styles'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { useCallback, useEffect, useMemo, useState } from 'react'
import axios from '@src/utils/customAxios'
import { BASE_API_URL } from '@src/app-constants'
import UiMarkdown from '@src/components/Atoms/UiMarkdown/UiMarkdown'
import { DataDrivenBlock, DataDrivenBlocks } from '@src/components/Organisms/DataDrivenBlocks'
import { Tab, Tabs } from '@src/components/Molecules/Tabs'

export const OnboardingPage = () => {
  const { clientName } = useSelector((state: RootState) => state.user)
  const [pageContent, setPageContent] = useState<
    { section: string; content: string; blocks: DataDrivenBlock[] }[]
  >([])
  const [tabs, setTabs] = useState<Tab[]>([])
  const { features } = useFeatureFlags()
  const pageEnabled = features.find(
    (feature) =>
      feature.feature == 'onboardingPage' &&
      feature.enabled &&
      clientName &&
      clientName !== 'openaccess',
  )
  const [selectedTab, setSelectedTab] = useState(0)

  const selectedTabHasBanner = useMemo(() => {
    if (pageContent.length > 0) {
      return pageContent[selectedTab].blocks[0]?.type === 'banner'
    }
    return false
  }, [selectedTab, pageContent])

  useEffect(() => {
    if (!clientName) return
    const abortController = new AbortController()
    const fetchRegions = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/content/onboardingPage`, {
          signal: abortController.signal,
        })

        setPageContent(response.data)
      } catch (error) {
        setPageContent([])
      }
    }
    fetchRegions()
    return () => {
      abortController.abort()
    }
  }, [clientName])

  const renderTabContent = useCallback((content: string) => {
    return <UiMarkdown>{content}</UiMarkdown>
  }, [])

  const renderTabBlocks = (blocks: DataDrivenBlock[]) => {
    return (
      <Box sx={{ height: '100%', overflowY: 'auto' }}>
        <DataDrivenBlocks blocks={blocks} iconFooter={true} />
      </Box>
    )
  }

  useEffect(() => {
    if (!pageContent) return
    const newTabs: Tab[] = pageContent.map((item) => ({
      label: item.section,
      value: item.section,
      content: (
        <Box sx={{ minHeight: '100%' }}>
          {renderTabBlocks(item.blocks)}
          {item.content && renderTabContent(item.content)}
        </Box>
      ),
    }))
    setTabs(newTabs)
  }, [pageContent, renderTabContent])

  return (
    <>
      {pageEnabled && (
        <Box css={onboardingPage(selectedTabHasBanner)}>
          <Tabs
            tabs={tabs}
            tabIndex={selectedTab}
            handleChangeTab={(newTabIndex) => {
              setSelectedTab(newTabIndex)
            }}
          />
        </Box>
      )}
    </>
  )
}
