import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const sideDrawerContentContainer = () => css`
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  padding-bottom: 16px;
`

export const tabsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  max-height: 100%;
`

export const infoPanelTabs = ({ theme }: { theme: Theme }) => css`
  border: none;
  font-size: 16px;
  max-height: 100%;
  height: 100%;
  flex-grow: 1;

  .MuiTabs-scrollButtons {
    opacity: 0;
    pointer-events: none;
    width: 16px;
  }

  > div:first-of-type {
    border-bottom: none;
    padding-top: 1px;
  }

  div[role='tablist'] {
    justify-content: flex-start;
    gap: 8px;
    padding-top: 16px;
  }

  div[role='tabpanel'] {
    padding: 0;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &[hidden] {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 12px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: ${theme.palette.uintel.main}00 !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.uintel.main}40 !important;
      border-radius: 0px !important;

      &:hover {
        background-color: ${theme.palette.uintel.main}48 !important;
      }
    }
  }

  button[role='tab'] {
    font-size: 16px;
    letter-spacing: 0.8px;
    font-weight: 700;

    &[aria-selected='true'] {
      color: ${theme.palette.uintel.main};
    }
  }

  .MuiTabs-indicator {
    background-color: ${theme.palette.uintel.main}AA;
  }
`

export const headerButton = css`
  padding: 4px;
  min-width: 0;
  box-shadow: none;
`
