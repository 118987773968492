/** @jsxImportSource @emotion/react */
import { Box, Button, Tooltip, useTheme } from '@mui/material'
import {
  actionButtonsContainer,
  circleBackground,
  landingOverlay,
  landingOverlayContainer,
} from './LandingOverlay.styles'
import { ImageWithFilters } from '@src/components/Atoms/ImageWithFilters'
import { AddCircle } from '@mui/icons-material'

export const LandingOverlay = ({
  open,
  setOpen,
  openWizard,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  openWizard: () => void
}) => {
  const theme = useTheme()
  return (
    <>
      <Box css={landingOverlayContainer(theme, open)}>
        <Box css={landingOverlay(theme)}>
          <ImageWithFilters
            src="/images/illustrations/PrioLanding.svg"
            alt="Man Organizing a Whiteboard"
            color={theme.palette.primary.main}
            hueStrength={0.4}
            lightenStrength={0.6}
            desaturateStrength={0.2}
            backgroundColor={theme.palette.primary[900]}
            customCss={{ height: '372px' }}
          />
          <h1>Prioritise adaptation areas.</h1>
          <p>
            Prioritise where to focus your adaptation efforts based on the values and scenarios
            which are important to your organisation.
          </p>
          <div css={actionButtonsContainer}>
            <Tooltip title="Coming Soon" placement="bottom" arrow>
              <Box>
                <Button variant="text" onClick={() => setOpen(false)} color="uintel" disabled>
                  <AddCircle fontSize="large" htmlColor="#abadbd" />
                  Create new
                </Button>
              </Box>
            </Tooltip>
            <Button
              variant="contained"
              color="uintel"
              onClick={() => {
                setOpen(false)
                openWizard()
              }}
            >
              Use default
            </Button>
            <Tooltip title="Coming Soon" placement="bottom" arrow>
              <Box>
                <Button disabled variant="outlined" onClick={() => setOpen(false)} color="uintel">
                  Open saved
                </Button>
              </Box>
            </Tooltip>
          </div>
        </Box>
      </Box>
      <Box css={circleBackground(theme, open)} />
    </>
  )
}
