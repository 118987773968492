/** @jsxImportSource @emotion/react */
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { Box, Tooltip, useTheme } from '@mui/material'
import { GenericMapContext } from '@contexts/RiskMapContext'
import { threeDButtonBox } from './ThreeDButton.styles'

export const ThreeDButton = ({ mapContext }: { mapContext: GenericMapContext }) => {
  const { flyTo, mapIs3D } = mapContext
  const theme = useTheme()

  const handleButtonClick = () => {
    flyTo({ pitch: mapIs3D ? 0 : 75, duration: 800, usePadding: true })
  }

  return (
    <Box sx={{ marginTop: theme.spacing(1), display: 'flex', flexDirection: 'row' }}>
      <Tooltip arrow title="Ctrl + mouse to tilt map">
        <Box css={threeDButtonBox({ theme })} onClick={handleButtonClick}>
          {mapIs3D ? '2D' : '3D'}
        </Box>
      </Tooltip>
    </Box>
  )
}
