import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BASE_MAP_LIGHT_WITH_HOUSE_NUMBERS } from '@src/app-constants'
import { Feature } from '@turf/turf'
import { MapboxOptions, MapboxGeoJSONFeature } from 'mapbox-gl'
import { MapLayer } from '@redux/riskMap/riskMapSlice'

export type ViewOrientation = Pick<
  MapboxOptions,
  'center' | 'zoom' | 'pitch' | 'maxPitch' | 'zoom' | 'bearing'
>

export type DrawArea = Feature<GeoJSON.Geometry, object>

export type SimplifiedGeoJSONFeature = {
  type: string
  properties: unknown
  geometry: {
    type: string
    coordinates: number[]
  }
}

export interface MapLocation {
  latitude: number
  longitude: number
  zoom?: number
}

export interface MapState {
  drawAreas: DrawArea[]
  layers: MapLayer[]
  style: string
  selectedMarker: MapLocation | null
  selectedArea: SimplifiedGeoJSONFeature | null
  isFullScreen: boolean
}

export const prioritisationMapInitialState: MapState = {
  style: BASE_MAP_LIGHT_WITH_HOUSE_NUMBERS,
  layers: [],
  drawAreas: [],
  selectedMarker: null,
  selectedArea: null,
  isFullScreen: false,
}

export const prioritisationMapSlice = createSlice({
  name: 'prioritisationMapSlice',
  initialState: prioritisationMapInitialState,
  reducers: {
    updateMapStateProperty: (state, action: PayloadAction<Partial<MapState>>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateSelectedFeature: (
      state,
      action: PayloadAction<{
        selectedFeature: MapboxGeoJSONFeature | null
        selectedLayer: MapLayer | null
      }>,
    ) => {
      return {
        ...state,
        selectedFeature: action.payload.selectedFeature as SimplifiedGeoJSONFeature,
        selectedLayer: action.payload.selectedLayer,
      }
    },
  },
})

export const { updateMapStateProperty, updateSelectedFeature } = prioritisationMapSlice.actions

export const prioritisationMapReducer = prioritisationMapSlice.reducer
