import { ValueSet } from '@contexts/PrioritisationContext'
import { Content, CanvasElement, TableCell } from 'pdfmake/interfaces'
import { shadedTextBox } from '../../Report.utils'

export const GenerateValueSets = (
  valueWeightings: { [key: string]: number },
  elementGroups: ValueSet[],
): Content => {
  const makeSlider = (value: number): Content => {
    return {
      stack: [
        {
          canvas: [
            { type: 'rect', x: 0, y: 0, w: 261, h: 38, color: '#fff' },
            { type: 'line', x1: 25, y1: 19, x2: 240, y2: 19, lineWidth: 3, lineColor: '#E7EaEd' },
            { type: 'ellipse', x: 25 + (value - 1) * 53.75, y: 19, r1: 5, r2: 5, color: '#0b2a48' },
            ...[0, 1, 2, 3, 4].map((num: number): CanvasElement => {
              return {
                type: 'ellipse',
                x: 25 + num * 53.75,
                y: 19,
                r1: 1,
                r2: 1,
                color: '#0b2a48',
              }
            }),
          ],
        },
        { text: 'Lower', fontSize: 6, relativePosition: { x: 0, y: -23 } },
        { text: 'Higher', fontSize: 6, relativePosition: { x: 253, y: -23 } },
        ...[0, 1, 2, 3, 4].map((num: number): Content => {
          return {
            text: num + 1,
            relativePosition: { x: 25 - 1.5 + num * 53.75, y: -13 },
            fontSize: 6,
          }
        }),
      ],
      unbreakable: true,
    }
  }

  function breakIntoRows<T>(array: T[], columns: number): T[][] {
    let columnIndex = 0
    const toReturn: T[][] = []
    let currentRow: T[]
    array.forEach((value) => {
      if (columnIndex == 0) {
        currentRow = []
        toReturn.push(currentRow)
      }
      currentRow.push(value)
      columnIndex++
      if (columnIndex >= columns) columnIndex = 0
    })

    return toReturn
  }

  return elementGroups.map(({ value_set_id, elements }) => [
    shadedTextBox(value_set_id),
    makeSlider(valueWeightings[value_set_id]),
    { text: 'Elements in this value set:', marginBottom: 2 },
    {
      table: {
        widths: ['*', '*', '*'],
        body: [
          ...breakIntoRows(elements, 3).map((row): TableCell[] => [
            ...row.map((element) => `• ${element.label}`),
          ]),
        ],
      },
      layout: 'noBorders',
      style: 'word',
      marginBottom: 20,
    },
  ])
}
