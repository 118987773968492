import { combineReducers, configureStore } from '@reduxjs/toolkit'

import authReducer from './auth/authSlice'
import { riskMapReducer } from './riskMap/riskMapSlice'
import { userReducer } from './user/userSlice'
import { projectedRiskChartStateReducer } from './projectedRiskChartSlice'
import { projectedExposureChartStateReducer } from './projectedExposureChartSlice'
import { riskSideDrawerReducer } from './riskSideDrawer/riskSideDrawerSlice'
import { prioritisationMapReducer } from './prioritisationMap/prioritisationMapSlice'

const store = configureStore({
  reducer: {
    riskMap: riskMapReducer,
    prioritisationMap: prioritisationMapReducer,
    sideDrawer: riskSideDrawerReducer,
    user: userReducer,
    auth: authReducer,
    projectedRiskChartState: projectedRiskChartStateReducer,
    projectedExposureChartState: projectedExposureChartStateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

const rootReducer = combineReducers({
  riskMap: riskMapReducer,
  prioritisationMap: prioritisationMapReducer,
  auth: authReducer,
  user: userReducer,
  sideDrawer: riskSideDrawerReducer,
  projectedRiskChartState: projectedRiskChartStateReducer,
  projectedExposureChartState: projectedExposureChartStateReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default store
