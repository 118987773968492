import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface ToggleButtonContainerProps {
  theme: Theme
  disabled: boolean
  expanded: boolean
  icon: string | null
  side: 'left' | 'right' | 'top' | 'bottom'
  color?: string
}

export const toggleButton = ({
  theme,
  disabled,
  expanded,
  icon,
  side,
  color,
}: ToggleButtonContainerProps) => {
  let borderRadius = ''
  switch (side) {
    case 'left':
      borderRadius = '0 6px 6px 0'
      break
    case 'right':
      borderRadius = '6px 0 0 6px'
      break
    case 'top':
      borderRadius = '6px 6px 0 0'
      break
    case 'bottom':
      borderRadius = '0 0 6px 6px'
      break
  }
  color = color ?? theme.palette.primary.main

  return css`
    border: none;
    background-color: ${theme.palette.white.main};

    border-radius: ${borderRadius};

    display: flex;
    align-items: center;
    justify-content: ${expanded && icon !== null ? 'space-around' : 'flex-start'};
    position: relative;

    z-index: 50;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 1px 2px 1px -1px rgba(0, 0, 0, 0.14),
      3px 3px 3px -3px rgba(0, 0, 0, 0.12);

    transition: background-color 0.2s, box-shadow 0.1s;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    &:hover {
      box-shadow: ${!expanded && theme.shadows[2]};
      .label {
        opacity: 1;
        width: auto;
      }
      .button-background {
        ${icon !== null ? `max-width: 160px;` : ''}
      }
    }

    .button-background {
      transition: background-color 0.2s, padding 0.2s ease-in-out, max-width 0.2s ease-in-out;
      background-color: ${disabled ? theme.palette.grey[500] : expanded ? color + '20' : color};
      position: relative;
      box-sizing: initial;
      padding: ${theme.spacing(2)} ${theme.spacing(2)};
      max-width: 24px;
      ${!expanded
        ? `
      box-shadow: inset 4px 0 4px -8px ${theme.palette.grey[100]};`
        : `
      `}
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: ${borderRadius};
      overflow: hidden;
    }

    .label {
      transition: opacity 0.2s, transform 0.2s;
      white-space: nowrap;
      opacity: 0;
      color: ${expanded ? color : 'white'};
      font-size: 14px;
    }

    .tutorial-sidedrawer-tab {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `
}
