import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { useCallback, useEffect } from 'react'
import { DialogData, HazardDetail, HazardScenario } from '../../MapView/RiskMapView/AddLayersDialog'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { updateMapStateProperty } from '@redux/riskMap/riskMapSlice'
import { snakeCaseToTitleCase } from '@src/utils/strings.utils'

let fetchPromise: Promise<DialogData | null> | null = null
let isFetching = false

export const useLayerDataFetcher = (): DialogData | null => {
  const dispatch = useDispatch()
  const { clientName } = useSelector((state: RootState) => state.user)
  const { layerData } = useSelector((state: RootState) => state.riskMap)

  const fetchLayerData = useCallback(async () => {
    if (isFetching) {
      // If already fetching, return the existing promise
      return fetchPromise
    }

    isFetching = true
    fetchPromise = axios.get(`${BASE_API_URL}/layer`).then((response) => response.data)

    try {
      if (layerData) {
        return layerData
      }
      const data = await fetchPromise

      if (data) {
        for (const details of data.hazardDialogData.hazardDetails) {
          for (const scenario of details.scenarios) {
            if (!scenario.display_name) {
              scenario.display_name = snakeCaseToTitleCase(scenario.assetTag)
            }
          }
        }
      }
      dispatch(updateMapStateProperty({ layerData: data }))
      return data
    } catch (error) {
      return null
    } finally {
      isFetching = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName])

  useEffect(() => {
    if (!layerData) fetchLayerData()
  }, [fetchLayerData, layerData])

  return layerData
}

export const parameterKeyTitleConverter = (parameter: string, layerData: DialogData | null) => {
  if (!layerData) return parameter
  const formElements = layerData.hazardDialogData.hazardDetails.reduce((acc, hazardDetail) => {
    hazardDetail.form.forEach((formElement) => {
      acc[formElement.key] = formElement.title
    })
    return acc
  }, {} as { [key: string]: string })
  return formElements[parameter] ?? parameter
}

export type layerDataByHazardIdDetail = HazardDetail & {
  title: string
  hazard_id: string
  hazard_name?: string
  scenarios: HazardScenario[]
  availableParameterValues: { [key: string]: (string | number)[] }
}

export interface layerDataByHazardId {
  [key: number | string]: layerDataByHazardIdDetail
}

/**
 * Transforms hazardDialogData from current shape to shape where hazard_id is a subgroup of hazardDetails, that contain scenarios with the same hazard_id
 */
export const layerDataHazardIdTransformer = (layerData: DialogData | null) => {
  if (!layerData) return

  // transforms hazardDialogData from current shape to shape where hazard_id is a subgroup of hazardDetails, that contain scenarios with the same hazard_id
  const hazardDetails = layerData.hazardDialogData.hazardDetails
  // if there are no hazard_ids, don't use this
  if (
    !hazardDetails.some((hazardDetail) =>
      hazardDetail.scenarios.some((scenario) => scenario.hazard_id),
    )
  )
    return
  const groupFormattedHazards: { [key: string]: layerDataByHazardIdDetail } = {}

  hazardDetails.forEach((hazardDetail) => {
    hazardDetail.scenarios.forEach((scenario) => {
      if (scenario.hazard_id) {
        if (!groupFormattedHazards[scenario.hazard_id]) {
          groupFormattedHazards[scenario.hazard_id] = {
            ...hazardDetail,
            hazard_id: scenario.hazard_id,
            hazard_name: scenario.hazard_name,
            scenarios: [],
            availableParameterValues: {},
          }
        }
        groupFormattedHazards[scenario.hazard_id].scenarios.push(scenario)
        Object.keys(scenario.parameters).forEach((parameterKey: string) => {
          if (!scenario.hazard_id) return
          if (!groupFormattedHazards[scenario.hazard_id].availableParameterValues[parameterKey]) {
            groupFormattedHazards[scenario.hazard_id].availableParameterValues[parameterKey] = []
          }
          const parameterValue = scenario.parameters[parameterKey]

          if (
            !groupFormattedHazards[scenario.hazard_id].availableParameterValues[
              parameterKey
            ].includes(parameterValue)
          ) {
            groupFormattedHazards[scenario.hazard_id].availableParameterValues[parameterKey].push(
              parameterValue,
            )
          }
        })
      }
    })
  })

  // sorting of availableParameterValues
  for (const hazardId in groupFormattedHazards) {
    const hazard = groupFormattedHazards[hazardId]
    for (const parameterKey in hazard.availableParameterValues) {
      hazard.availableParameterValues[parameterKey].sort((a, b) => {
        // sort by number if possible
        if (typeof a === 'number' && typeof b === 'number') {
          return a - b
        }
        return 0
      })
    }
  }

  return groupFormattedHazards as layerDataByHazardId
}

export const flattenToMapLayerById = (layerData: DialogData | null) => {
  if (!layerData) return {}
  const flattenedLayerData: { [key: string]: HazardScenario } = {}
  for (const layerCategory of Object.values(layerData)) {
    let layerSuperGroups = []
    if (layerCategory.hazardDetails) {
      layerSuperGroups = layerCategory.hazardDetails as HazardDetail[]
    } else {
      layerSuperGroups = Object.values(layerCategory)
    }
    for (const layerSuperGroup of layerSuperGroups) {
      if (layerSuperGroup.groups) {
        for (const layerGroup of layerSuperGroup.groups) {
          if (layerGroup.layers) {
            for (const layer of layerGroup.layers) {
              flattenedLayerData[layer.id] = layer
            }
          } else if (layerGroup.assets) {
            for (const layer of layerGroup.assets) {
              flattenedLayerData[layer.type] = layer
            }
          }
        }
      } else if (layerSuperGroup.scenarios) {
        for (const scenario of layerSuperGroup.scenarios) {
          flattenedLayerData[scenario.assetTag] = scenario
        }
      }
    }
  }

  return flattenedLayerData
}
