import {
  blobToDataUrl,
  preparePageDuplicateMapAndExportImageBlob,
} from '@src/components/Pages/Map/MapTools/ExportMap/mapExporter'
import { Content } from 'pdfmake/interfaces'
import { Size } from '../Report'
import {
  assets,
  generateMapCaption,
  makeLegend,
  makeScale,
} from '@src/components/Molecules/PdfGeneration/pdfGeneratorUtils'
import * as turf from '@turf/turf'
import { LegendsData } from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog'
import { MapLayer, MapLocation } from '@redux/riskMap/riskMapSlice'
import { Theme } from '@mui/material'
import { truncateToNearestSpace } from '@src/utils/strings.utils'
import { generateVulnerabilityPalette } from '@src/components/Molecules/MapView/RiskMapView/RiskMapView.utilities'
import { makeSvg } from '../../Report.utils'

export const GenerateMapPageContent = async (
  map: mapboxgl.Map | null,
  bodySize: Size,
  legendsData: LegendsData,
  layers: MapLayer[],
  selectedElementMarkerLocation: MapLocation | null,
  theme: Theme | null,
  pageBreak: boolean,
): Promise<Content[]> => {
  if (!map) return []
  const vulnerabilityPalette = generateVulnerabilityPalette(legendsData, 'vulnerability')
  const bearing = map.getBearing()
  const compass = assets.compass(-bearing)
  const pitch = map.getPitch()

  const mapHeightInPixels = map.getCanvas().height
  const mapWidthInPixels = map.getCanvas().width

  const mapWidth = bodySize.height // Because landscape
  const mapHeight = 350
  const aspectRatio = mapWidth / mapHeight

  let mapImageDataUrl = ''
  await preparePageDuplicateMapAndExportImageBlob(
    map,
    2.0,
    aspectRatio,
    async (imageBlob: Blob) => {
      mapImageDataUrl = await blobToDataUrl(imageBlob)
    },
    vulnerabilityPalette,
    selectedElementMarkerLocation,
    theme,
  )

  const mapWidthInMetres = turf.distance(
    map.unproject([0, mapHeightInPixels / 2.0]).toArray(),
    map.unproject([mapWidthInPixels, mapHeightInPixels / 2.0]).toArray(),
    { units: 'meters' },
  )

  const scale = mapWidthInMetres / mapWidth
  const pitchMessage = pitch > 15 ? ' Scale varies in 3D view.' : ''
  const mapCaption = truncateToNearestSpace(generateMapCaption(layers), 480) + pitchMessage

  const toReturn: Content[] = []

  toReturn.push({ text: 'Risk Map', style: 'h1', tocItem: true })
  toReturn.push({ image: mapImageDataUrl, width: mapWidth })
  toReturn.push(makeSvg(compass, { relativePosition: { x: 0, y: -mapHeight } }))
  if (pitch < 15)
    toReturn.push(makeSvg(makeScale(scale), { relativePosition: { x: 37.5, y: -mapHeight } }))
  toReturn.push({ text: mapCaption, fontSize: 6, alignment: 'center' })
  toReturn.push({
    svg: makeLegend(layers, legendsData),
    width: mapWidth,
    pageBreak: pageBreak ? 'after' : undefined,
    pageOrientation: 'portrait',
  })

  return toReturn
}
