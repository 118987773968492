// As this component is only used in a mapbox tooltip it cannot use emotion CSS. All styles need to be manually added to each component.
import { Box } from '@mui/material'

import './PrioritisationMapTooltip.styles.css'
import { GeoJsonProperties } from 'geojson'

export interface MapTooltipProps {
  areaName: string
  properties: GeoJsonProperties
}

/**
 * A tooltip component for displaying information about a map feature
 * Returns null if there is no asset sentence, no information value, and no hazards
 */
export const PrioritisationMapTooltip = ({ areaName, properties }: MapTooltipProps) => {
  // React hooks and emotion CSS are not allowed in this file - MAPBOX

  let content = null
  if (properties && properties.population !== undefined) {
    content = (
      <Box
        style={{
          padding: '4px 0',
          paddingRight: '16px',
        }}
      >
        <span style={{ fontWeight: '700', fontSize: '12px', marginRight: '12px' }}>
          Population:
        </span>
        <span style={{ fontSize: '12px' }}>{properties.population}</span>
      </Box>
    )
  }

  return (
    <Box
      className="tooltip-container"
      style={{
        padding: '8px',
        backgroundColor: 'white',
        borderRadius: '4px',
        position: 'relative',
        boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.25)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box style={{ padding: '4px 0', borderBottom: content !== null ? '1px solid #000' : 'none' }}>
        {areaName && <h3>{areaName}</h3>}
      </Box>
      {content}
    </Box>
  )
}
