import { FeatureFlag } from '@contexts/FeatureFlagsContext'
import { NavigateFunction } from 'react-router-dom'
import { SidebarMenuButton } from '@src/components/Organisms/Sidebar'
import { UserTheme } from '@redux/user/userSlice'
import { createTheme, PaletteColor, Theme } from '@mui/material'
import Color from 'colorjs.io'

export const defaultLogo = [
  {
    simple: 'UILogoSimpleWhite.svg',
    full: 'UILogoFullWhite.svg',
    title: 'Urban Intelligence',
    logoBackgroundColour: '',
  },
]

export function getSidebarMiddleMenuLinks({
  pathname,
  navigate,
  featuresFlags,
  clientName,
}: {
  pathname: string
  navigate: NavigateFunction
  featuresFlags: FeatureFlag[]
  clientName?: string
}): SidebarMenuButton[] {
  const middleNavbar: SidebarMenuButton[] = [
    {
      label: 'Home',
      icon: 'Home',
      active: pathname === '/home' || pathname === '/',
      onClick: () => navigate('home'),
    },
    {
      label: 'Risk',
      icon: 'Pin',
      active: pathname === '/risk',
      onClick: () => navigate('risk'),
      pointerHint:
        clientName && (pathname === '/home' || pathname === '/') ? 'Explore your risk' : '',
    },
    {
      label: 'Access',
      icon: 'DirectionsWalk',
      active: pathname === '/access',
      onClick: () => window.open('https://access.resilience-explorer.com/', '_blank'),
    },
    // To be enabled later
    /* {
      label: 'Live',
      icon: 'Podcasts',
      active: pathname === '/live',
      onClick: () => navigate('live'),
      customClass: {
        iconClass: lowOpacitySidebarButton,
        labelClass: lowOpacitySidebarButton,
      },
    },
    {
      label: 'Adaptation',
      icon: 'ArrowLeftRight',
      active: pathname === '/adaptation',
      onClick: () => navigate('adaptation'),
      customClass: {
        iconClass: lowOpacitySidebarButton,
        labelClass: lowOpacitySidebarButton,
      },
    },
    {
      label: 'Landuse',
      icon: 'Land',
      active: pathname === '/landuse',
      onClick: () => navigate('landuse'),
      customClass: {
        iconClass: lowOpacitySidebarButton,
        labelClass: lowOpacitySidebarButton,
      },
    }, */
    // {
    //   label: 'Transport',
    //   icon: 'EmojiTransportation',
    //   active: pathname === '/transport',
    //   onClick: () => navigate('transport'),
    //   iconColor: theme.palette.grey[600],
    //   labelColor: theme.palette.grey[600],
    // },
  ]

  featuresFlags.forEach((featureFlag) => {
    if (!featureFlag.enabled) return

    switch (featureFlag.feature) {
      case 'strategyPage':
        middleNavbar.push({
          label: 'Strategy',
          icon: 'ChartLineUp',
          active: pathname.includes(`/strategy/`),
          onClick: () => navigate('/strategy/horizon-scan'),
        } as SidebarMenuButton)
        break
      case 'summaryPage':
        {
          middleNavbar.push({
            label: 'Summary',
            icon: 'ChartLineUp',
            active: pathname === '/summary',
            onClick: () => navigate('summary'),
          })
        }
        break
      case 'prioritisationPage':
        {
          middleNavbar.push({
            label: 'Prioritisation',
            icon: 's3://prioritise',
            active: pathname === '/prioritisation',
            onClick: () => navigate('prioritisation'),
          })
        }
        break
      case 'riskModuleIsInDevelopment':
        {
          const riskButtonIndex = middleNavbar.findIndex((button) => button.label === 'Risk')
          if (riskButtonIndex !== -1) middleNavbar.splice(riskButtonIndex, 1)
        }
        break
      case 'onboardingPage':
        middleNavbar.splice(1, 0, {
          label: 'Onboarding',
          icon: 's3://waving',
          active: pathname === '/onboarding',
          onClick: () => navigate('onboarding'),
        })
        break
      default:
        return null
    }
  })

  return middleNavbar
}

export function getSidebarBottomMenuLinks({
  isUserSignedIn,
  handleSignIn,
  handleSignOut,
  userRole,
  pathname,
  navigate,
}: {
  isUserSignedIn: boolean
  handleSignOut: () => void
  handleSignIn: () => void
  userRole: string
  pathname: string
  navigate: NavigateFunction
}): SidebarMenuButton[] {
  const bottomMenuLinks = [
    {
      label: isUserSignedIn ? 'Sign Out' : 'Sign In',
      icon: isUserSignedIn ? 'SignOut' : 'SignIn',
      active: false,
      onClick: isUserSignedIn ? handleSignOut : handleSignIn,
    },
  ]

  if (userRole === 'admin') {
    bottomMenuLinks.push({
      label: 'Admin',
      icon: 'DragIcon',
      active: pathname === '/manage-users',
      onClick: () => navigate('manage-users'),
    })
  }
  return bottomMenuLinks
}

function getShadeOf(color: Color, shade: number): string {
  // shade is a multiplier 0-2
  // so we calculate a relative shade based on the previous lightness of the color
  let rs = 1
  if (shade < 1) {
    rs = shade
  } else {
    rs = 2 - shade + 100 * ((shade - 1) / color.hsl[2])
  }
  const colorObj = color.clone()
  colorObj.hsl[2] *= rs
  // bump up saturation a bit (by 0.1)
  colorObj.hsl[1] *= rs * 0.9 + 0.1
  return colorObj.toString({ format: 'hex' })
}
function setShadeOf(color: Color, shade: number): string {
  // we calculate a relative saturation based on the previous lightness of the color
  let rs = 1
  if (shade < color.hsl[2]) {
    rs = shade / color.hsl[2]
  } else {
    rs = 1 + (color.hsl[2] - shade) / (100 - color.hsl[2])
  }
  const colorObj = color.clone()
  colorObj.hsl[2] = shade
  // bump up saturation a bit (by 0.1)
  colorObj.hsl[1] *= rs * 0.9 + 0.1
  return colorObj.toString({ format: 'hex' })
}
// Automatically generates appropriate shades of provided primary and secondary client colors
export function getClientTheme(theme: Theme, storeTheme: UserTheme): Theme {
  const autoStoreTheme = {
    ...theme.palette,
    primary: {
      ...theme.palette.primary,
      ...storeTheme.primary,
    },
    secondary: {
      ...theme.palette.secondary,
      ...storeTheme.secondary,
    },
  }
  if (storeTheme && (storeTheme.primary || storeTheme.secondary)) {
    const primaryColor = new Color(storeTheme.primary.main ?? theme.palette.primary.main)
    if (!storeTheme.primary.dark || storeTheme.primary.dark === storeTheme.primary.main) {
      autoStoreTheme.primary = {
        ...autoStoreTheme.primary,
        dark: getShadeOf(primaryColor, 0.8),
      }
    }
    if (!storeTheme.primary.light || storeTheme.primary.light === storeTheme.primary.main) {
      autoStoreTheme.primary = {
        ...autoStoreTheme.primary,
        light: getShadeOf(primaryColor, 1.2),
      }
    }
    // set primary 100-900
    for (let i = 1; i < 20; i++) {
      const key = (i * 50) as keyof PaletteColor
      autoStoreTheme.primary[key] = setShadeOf(primaryColor, (i / 20) * 100)
    }
    const secondaryColor = new Color(storeTheme.secondary.main ?? theme.palette.secondary.main)
    if (!storeTheme.secondary.dark || storeTheme.secondary.dark === storeTheme.secondary.main) {
      autoStoreTheme.secondary = {
        ...autoStoreTheme.secondary,
        dark: getShadeOf(secondaryColor, 0.8),
      }
    }
    if (!storeTheme.secondary.light || storeTheme.secondary.light === storeTheme.secondary.main) {
      autoStoreTheme.secondary = {
        ...autoStoreTheme.secondary,
        light: getShadeOf(secondaryColor, 1.2),
      }
    }
    // set secondary 100-900
    for (let i = 1; i < 20; i++) {
      const key = (i * 50) as keyof PaletteColor
      if (!storeTheme.secondary[key] || storeTheme.secondary[key] === storeTheme.secondary.main) {
        autoStoreTheme.secondary[key] = setShadeOf(secondaryColor, (i / 20) * 100)
      }
    }
  }
  return createTheme({
    ...theme,
    palette: autoStoreTheme,
  })
}
