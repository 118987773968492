import { css } from '@emotion/react'

export const priorityTable = (isLoading: boolean) => css`
  opacity: ${isLoading ? 0.3 : 1};
  transition: opacity 0.3s;
`

export const priorityHeader = (arrowDirection: boolean) => css`
  cursor: pointer;
  display: flex;
  gap: 2px;
  user-select: none;

  img {
    transition: transform 0.1s;
    transform: scaleY(${arrowDirection ? -0.5 : 0.5}) scaleX(0.6);
  }
`
