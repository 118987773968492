import { css } from '@emotion/react'

export const prioritySliderContainer = () => css`
  width: 100%;
  display: flex;
  gap: 16px;

  span {
    font-size: 12px;
  }

  p {
    font-size: 12px;
    padding-top: 6px;
  }

  .MuiSlider-mark {
    display: none;
  }
`
