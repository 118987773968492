import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface SideDrawerContainerProps {
  theme: Theme
  isOpen: boolean
  color?: string
}

export const sideDrawer = ({ theme, isOpen, color }: SideDrawerContainerProps) => css`
  position: relative;

  height: 100%;

  max-width: 90vw;
  min-width: ${isOpen ? '60px' : undefined};

  padding-right: 0;
  padding-bottom: 0px;

  background-color: ${theme.palette.secondary.main};
  box-shadow: ${theme.shadows[5]};
  transition: all 0.4s, width 0s;

  & .right-handle {
    &:hover::after {
      background-color: ${color ?? theme.palette.primary.main};
      box-shadow: ${theme.shadows[5]};
    }

    &::after {
      content: '';
      display: flex;
      height: 100%;
      width: 1px;

      margin-left: 5px;
      background-color: ${theme.palette.grey[100]};

      transition: background-color 0.2s, box-shadow 0.2s;
    }
  }

  & .pointer-events-none {
    pointer-events: none;
  }

  & * ::-webkit-scrollbar {
    width: 8px !important;
    border-radius: 0px !important;
  }

  & * ::-webkit-scrollbar-track {
    border-radius: 0px !important;
    border: 0px !important;

    background: #0b294820 !important;
  }

  & * ::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    background-color: #0b294888 !important;

    transition: background-color 0.2s !important;

    &:hover {
      background-color: #0b2948aa !important;
    }

    & * ::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
      border-radius: 4px !important;
    }
  }
`

export const sideDrawerContent = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  overflow: auto;
  overflow-x: hidden;

  margin-right: ${theme.spacing(13)};
`

export const toggleButtonParent = ({ theme, side }: { theme: Theme; side: string }) => {
  const invertedSide = {
    right: 'left',
    left: 'right',
    top: 'bottom',
    bottom: 'top',
  }[side]
  return css`
    position: absolute;
    ${invertedSide}: 12px;
    top: ${theme.spacing(22)};
  `
}

export const sideDrawerButton = ({
  theme,
  index,
  side,
}: {
  theme: Theme
  index?: number
  side: string
}) => {
  const dist_from_top = side === 'left' ? 10 : 32
  return css`
    position: absolute;
    top: ${index ? theme.spacing(dist_from_top + index * 10) : theme.spacing(dist_from_top)};
    ${side}: 12px;
  `
}
