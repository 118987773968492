import { Polygon } from 'geojson'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { MapLayer, DrawArea } from '@redux/riskMap/riskMapSlice'
import { MultiRiskDataObject, RiskData } from './RiskFetcher.types'
import { VisibleAssets } from '@src/components/Molecules/MapView/RiskMapView/RiskMapView.utilities'

export const fetchIdentifyingInformationAssetExposureStats = async (
  assetLayers: MapLayer[],
  hazardLayers: MapLayer[],
  attribute: string,
  regions: string[],
  drawAreas: DrawArea[],
  visibleAssets?: VisibleAssets,
  abortController?: AbortController,
): Promise<RiskData[]> => {
  const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)
  const hazardLayerAssetTags = hazardLayers.map((hazardLayer) => hazardLayer.assetTag)
  const query = [`attribute=${attribute}`]
  if (regions.length > 0) query.push(`regions=${regions.join(',')}`)
  const drawAreaCoordinateArray: Polygon | null =
    drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
  const drawAreaCsv = drawAreaCoordinateArray
    ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
    : ''

  const mappedVisibleAssets = visibleAssets
    ? Object.keys(visibleAssets).reduce((acc, assetType) => {
        acc[assetType] = Object.keys(visibleAssets[assetType])
        return acc
      }, {} as { [key: string]: string[] })
    : visibleAssets

  const url = `${BASE_API_URL}/asset/exposure/identifying_information_stats/${assetLayerTypes.join(
    ',',
  )}/${hazardLayerAssetTags.join(',')}/${drawAreaCsv}?${query.join('&')}`

  const response = await axios.post<RiskData[]>(
    url,
    {
      prefiltered_asset_ids: mappedVisibleAssets,
    },
    { signal: abortController?.signal },
  )

  return response.data.map((data) => ({ ...data, identifying_information_property: attribute }))
}

export const fetchAssetExposureStats = async (
  assetLayers: MapLayer[],
  hazardLayers: MapLayer[],
  regions: string[],
  drawAreas: DrawArea[],
  visibleAssets?: VisibleAssets,
  abortController?: AbortController,
): Promise<RiskData[]> => {
  const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)
  const hazardLayerAssetTags = hazardLayers.map((hazardLayer) => hazardLayer.assetTag)
  const query = []
  if (regions.length > 0) query.push(`regions=${regions.join(',')}`)
  const drawAreaCoordinateArray: Polygon | null =
    drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
  const drawAreaCsv = drawAreaCoordinateArray
    ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
    : ''

  const mappedVisibleAssets = visibleAssets
    ? Object.keys(visibleAssets).reduce((acc, assetType) => {
        acc[assetType] = Object.keys(visibleAssets[assetType])
        return acc
      }, {} as { [key: string]: string[] })
    : visibleAssets

  const url = `${BASE_API_URL}/asset/exposure/stats/${assetLayerTypes.join(
    ',',
  )}/${hazardLayerAssetTags.join(',')}/${drawAreaCsv}?${query.join('&')}`

  const response = await axios.post<RiskData[]>(
    url,
    {
      prefiltered_asset_ids: mappedVisibleAssets,
    },
    { signal: abortController?.signal },
  )

  return response.data
}

export const fetchAssetMultiHazardExposureStats = async (
  assetLayers: MapLayer[],
  hazardLayers: MapLayer[],
  regions: string[],
  drawAreas: DrawArea[],
  abortController: AbortController,
  visibleAssets?: VisibleAssets,
): Promise<MultiRiskDataObject> => {
  const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)
  const hazardLayerAssetTags = hazardLayers.map((hazardLayer) => hazardLayer.assetTag)
  const query = []
  if (regions.length > 0) query.push(`regions=${regions.join(',')}`)
  const drawAreaCoordinateArray: Polygon | null =
    drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
  const drawAreaCsv = drawAreaCoordinateArray
    ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
    : ''

  const mappedVisibleAssets = visibleAssets
    ? Object.keys(visibleAssets).reduce((acc, assetType) => {
        acc[assetType] = Object.keys(visibleAssets[assetType])
        return acc
      }, {} as { [key: string]: string[] })
    : visibleAssets

  const url = `${BASE_API_URL}/asset/exposure/multihazard_stats/${assetLayerTypes.join(
    ',',
  )}/${hazardLayerAssetTags.join(',')}/${drawAreaCsv}?${query.join('&')}`

  const response = await axios.post<MultiRiskDataObject>(
    url,
    {
      prefiltered_asset_ids: mappedVisibleAssets,
    },
    { signal: abortController.signal },
  )

  return response.data
}

export const fetchCheckIfIdentifyingInformationExists = async (
  attribute: string,
  assetLayers: MapLayer[],
): Promise<{ asset_type: string; has_identifying_information: boolean; attribute: string }[]> => {
  if (assetLayers.length === 0) return []
  try {
    const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)

    const url = `${BASE_API_URL}/asset/check_identifying_information_exists/${assetLayerTypes.join(
      ',',
    )}/?attribute=${attribute}`

    const response = await axios.post<
      { asset_type: string; has_identifying_information: boolean }[]
    >(url)

    return response.data.map((data) => ({ ...data, attribute }))
  } catch (error) {
    return []
  }
}
