/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback } from 'react'
import {
  useTheme,
  Box,
  TextField,
  Button,
  Alert,
  List,
  ListItem,
  ListItemText,
  Badge,
  Typography,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { RootState } from '@redux/store'
import { useSelector } from 'react-redux'
import { redirect, useNavigate } from 'react-router-dom'
import { Icon } from '@src/components/Atoms/Icon'
import { useSnackbars } from '@contexts/SnackbarContext'
import { BASE_API_URL } from '@src/app-constants'
import { isAdminUser } from '@src/components/Atoms/ProtectedRoute'
import { AdminPageContainer, AdminPageContentContainer, formWrapper } from './AdminPage.styles'
import axios from '@src/utils/customAxios'
import { Accordion } from '@src/components/Atoms/Accordion'
import { Email, People } from '@mui/icons-material'

interface InviteResponse {
  messages: string[]
  newAccounts: {
    email: string
    user_uuid: string
  }[]
  failedAccounts: string[]
}

interface UserInfo {
  user_uuid: string
  email: string
  date_created: string
  logged_in_count: number
  last_logged_in: string
  isAdmin: boolean
  status: string
}

/**
 * Check if the user is an admin via an API call, not using front-end variables for security reasons
 */
export async function ProtectedRouteLoader(): Promise<boolean | Response> {
  const isAdmin = await isAdminUser()
  if (!isAdmin) return redirect('/')
  return true
}

export const AdminPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { clientName } = useSelector((state: RootState) => state.user)

  const { displaySnackbar } = useSnackbars()

  const [emailsToInvite, setEmailsToInvite] = useState<string>('')
  const [users, setUsers] = useState<UserInfo[]>([])
  const [userToRemove, setUserToRemove] = useState<UserInfo | null>(null)
  const [approvedEmailDomains, setApprovedEmailDomains] = useState<string[]>([])
  const [showUserDialog, setShowUserDialog] = useState<boolean>(false)

  const InAdminProtectedRoute = useCallback(async (): Promise<void> => {
    const isAdmin = await isAdminUser()
    if (!isAdmin) navigate('/')
  }, [navigate])

  const getUsers = useCallback(async () => {
    if (!clientName) return []
    const response = await axios.get(`${BASE_API_URL}/client/users`)
    return response.data || []
  }, [clientName])

  const getEmailDomains = useCallback(async () => {
    if (!clientName) return []
    const response = await axios.get(`${BASE_API_URL}/client/email_domains`)
    return response.data || []
  }, [clientName])

  /**
   * Handle the result of the email invite API call
   */
  const handleInviteResult = (
    responseStatus: number,
    data: InviteResponse,
    emailArray: string[],
  ) => {
    if (responseStatus === 200) {
      const successfulEmails = data.newAccounts.map((account) => account.email)
      // truncate emailsToInvite to less than 250 characters
      if (successfulEmails.length > 0) {
        displaySnackbar({
          message: `Successfully invited ${successfulEmails.join().substring(0, 250)}${
            successfulEmails.length > 250 ? '...' : ''
          }`,
          type: 'success',
        })
        getAndFormatUsers()
      }

      // get difference between emailArray and successfulEmails
      const failedEmails = emailArray.filter((email) => !successfulEmails.includes(email))
      if (failedEmails.length > 0) {
        displaySnackbar({
          message: `Email invite failed for ${failedEmails.join().substring(0, 250)}${
            failedEmails.length > 250 ? '...' : ''
          }`,
          type: 'error',
        })
        // leave the failed emails in the input field
        setEmailsToInvite(failedEmails.join())
        return
      } else {
        setEmailsToInvite('')
      }
    }
  }

  const formatEmailsToInviteArray = (emailsToInvite: string): string[] => {
    let emailArray = emailsToInvite.split(',').map((email) => email.trim())
    if (!emailsToInvite.includes(',') && emailsToInvite.includes(' ')) {
      const singleSpacedEmailsToInvite = emailsToInvite.replace(/\s\s+/g, ' ')
      emailArray = singleSpacedEmailsToInvite.split(' ').map((email) => email.trim())
    }
    return emailArray
  }

  /**
   * Formats email input and sends email invites to api, then calls handleInviteResult
   */
  const sendEmails = async () => {
    if (checkIfInvitesAreValid() !== null) {
      displaySnackbar({
        message: `${checkIfInvitesAreValid()}`,
        type: 'error',
      })
      return
    }

    const emailArray = formatEmailsToInviteArray(emailsToInvite)

    const response = await axios.post<InviteResponse>(`${BASE_API_URL}/user/invite`, {
      emails_to_invite: emailArray,
      client_name: clientName,
    })

    handleInviteResult(response.status, response.data, emailArray)
  }

  const removeUser = async () => {
    const user = userToRemove
    if (!user) {
      displaySnackbar({
        message: `No user found, please select a user to delete`,
        type: 'error',
      })
      return
    }
    const response = await axios.post(`${BASE_API_URL}/user/delete_user`, {
      user_uuid: user.user_uuid,
    })
    if (response.status === 200) {
      displaySnackbar({
        message: `Successfully deleted user`,
        type: 'success',
      })
      setUsers(users.filter((u) => u.user_uuid !== user.user_uuid))
      setUserToRemove(null)
      handleRemoveUserDialogClose()
    } else {
      displaySnackbar({
        message: `Failed to delete user`,
        type: 'error',
      })
    }
  }

  const resendInvite = async (user: UserInfo) => {
    const response = await axios.post(`${BASE_API_URL}/user/resend_invite_email`, {
      email: user.email,
    })

    if (response.status === 200) {
      displaySnackbar({
        message: `Successfully resent invite to ${user.email}`,
        type: 'success',
      })
    }
  }

  const checkIfInvitesAreValid = (): string | null => {
    if (emailsToInvite.length === 0) return null
    const emailArray = formatEmailsToInviteArray(emailsToInvite)

    const invalidEmails: string[] = []

    emailArray.forEach((email) => {
      const trimmedEmail = email.trim()
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (!emailRegex.test(trimmedEmail)) {
        invalidEmails.push(trimmedEmail)
      }
    })

    if (invalidEmails.length > 0) {
      if (invalidEmails.join(', ') === '') return `Extra comma or space found in invite list`
      return `Invalid email: ${invalidEmails.join(', ')}`
    }

    return null // No invalid emails found
  }

  const handleRemoveUserDialogClose = () => {
    setUserToRemove(null)
    setShowUserDialog(false)
  }

  const openUserDialog = (user: UserInfo) => {
    setUserToRemove(user)
    setShowUserDialog(true)
  }

  const getAndFormatUsers = useCallback(async () => {
    getUsers().then((users) => {
      users.map((user: UserInfo) => {
        if (user.date_created)
          user.date_created = new Date(user.date_created).toLocaleString('en-NZ', {
            timeStyle: 'short',
            dateStyle: 'short',
          })
        if (user.last_logged_in)
          user.last_logged_in = new Date(user.last_logged_in).toLocaleString('en-NZ', {
            timeStyle: 'short',
            dateStyle: 'short',
          })
        return user
      })
      setUsers(users)
    })
  }, [getUsers])

  useEffect(() => {
    getAndFormatUsers()
    getEmailDomains().then((domains) => {
      setApprovedEmailDomains(domains)
    })
  }, [clientName, getUsers, getEmailDomains, getAndFormatUsers])

  // if user changes client and isnt an admin, redirect them to the home page
  useEffect(() => {
    InAdminProtectedRoute()
  }, [clientName, InAdminProtectedRoute])

  return (
    <>
      <Box css={AdminPageContainer({ theme })}>
        <Box css={AdminPageContentContainer({ theme })}>
          <Box>
            <Typography variant="h2" sx={{ color: theme.palette.primary.dark }}>
              Manage Users
            </Typography>
          </Box>
          <Box css={formWrapper}>
            <Paper elevation={1} sx={{ padding: '16px', marginY: '16px' }}>
              <Typography variant="h5" sx={{ color: theme.palette.primary.dark }}>
                Invite external users
              </Typography>
              <Typography variant="body1" sx={{ color: theme.palette.primary.dark }}>
                Enter the email addresses of the users you would like to invite to this client.
              </Typography>
              <Box sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
                <TextField
                  sx={{ width: '100%' }}
                  placeholder="mia@example.com, nikau@example.com"
                  name="emailsToInvite"
                  aria-describedby="emailHelp"
                  value={emailsToInvite}
                  onChange={(e) => setEmailsToInvite(e.target.value)}
                />
                <Button
                  onClick={sendEmails}
                  disabled={emailsToInvite.length < 1}
                  name="invite"
                  value="true"
                  variant="contained"
                  color="primary"
                >
                  Invite
                </Button>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: theme.palette.primary.dark,
                  fontSize: '11px',
                  fontStyle: 'italic',
                  marginTop: '4px',
                }}
              >
                *Invites will expire after 24 hours.
              </Typography>
              {checkIfInvitesAreValid() && (
                <Box sx={{ marginTop: '16px' }}>
                  <Alert severity="error">{checkIfInvitesAreValid()}</Alert>
                </Box>
              )}
            </Paper>
            <Accordion
              variant="panel"
              level="h2"
              icon={<Email />}
              defaultExpanded={false}
              title="Approved Email Domains"
              body={
                <List>
                  {approvedEmailDomains.map((emailDomain) => (
                    <ListItem key={emailDomain} dense={true}>
                      <ListItemText>
                        <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'space-between' }}>
                          <Box>
                            <strong>{emailDomain}</strong>
                          </Box>
                        </Box>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              }
            />

            <Accordion
              variant="panel"
              level="h2"
              defaultExpanded={true}
              icon={<People />}
              title="Users"
              body={
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell align="right">Signed Up</TableCell>
                        <TableCell align="right">Login Count</TableCell>
                        <TableCell align="right">Last Login</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Delete User</TableCell>
                        <TableCell align="center">Resend Invite</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow
                          key={user.user_uuid}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{user.email}</TableCell>
                          <TableCell align="right">{user.date_created}</TableCell>
                          <TableCell align="right">{user.logged_in_count}</TableCell>
                          <TableCell align="right">{user.last_logged_in}</TableCell>
                          <TableCell align="center">
                            {user.status === 'PENDING' && (
                              <Badge color="info" badgeContent="pending"></Badge>
                            )}
                            {user.status === 'UNCONFIRMED' && (
                              <Badge color="info" badgeContent="pending"></Badge>
                            )}
                            {user.status === 'CONFIRMED' && (
                              <Badge color="success" badgeContent="confirmed"></Badge>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton onClick={() => openUserDialog(user)}>
                              <Icon iconName="Delete" />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            {(user.status === 'UNCONFIRMED' || user.status === 'PENDING') && (
                              <IconButton onClick={() => resendInvite(user)}>
                                <Icon iconName="Refresh" />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            />
          </Box>
        </Box>
      </Box>
      <Dialog
        open={showUserDialog}
        onClose={handleRemoveUserDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Remove User?</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '8px' }}>
            Are you sure you want to delete <strong>{userToRemove?.email || 'this user'}</strong>?
          </DialogContentText>
          <DialogContentText>
            This will remove the user from the system and they will no longer be able to access the
            application.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveUserDialogClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={removeUser} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AdminPage
