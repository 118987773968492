/** @jsxImportSource @emotion/react */
import { Box, Tooltip, useTheme } from '@mui/material'
import {
  headerButton,
  infoPanelTabs,
  sideDrawerContentContainer,
  tabsContainer,
} from './SideDrawerContent.styles'
import { Tabs } from '@src/components/Molecules/Tabs'
import { useState } from 'react'
import { InputsTab } from './InputsTab'
import { ResultsTab } from './ResultsTab'
import { Button } from '@src/components/Atoms/Button'
import { Icon } from '@src/components/Atoms/Icon'

interface SideDrawerContentProps {
  openWizard: () => void
}

export const SideDrawerContent = ({ openWizard }: SideDrawerContentProps) => {
  const theme = useTheme()
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const tabs = [
    {
      label: 'Inputs',
      icon: <Icon iconName="s3://inputs" colour="#fff" />,
      content: (
        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <InputsTab></InputsTab>
        </Box>
      ),
      value: 'inputs',
    },
    {
      label: 'Results',
      icon: <Icon iconName="s3://results" colour="#fff" />,
      content: (
        <Box sx={{ overflowY: 'auto', flexGrow: 1 }}>
          <ResultsTab></ResultsTab>
        </Box>
      ),
      value: 'results',
    },
  ]
  return (
    <Box css={sideDrawerContentContainer()}>
      <Box css={tabsContainer}>
        <Tabs
          css={infoPanelTabs({ theme })}
          handleChangeTab={(index) => setCurrentTabIndex(index)}
          tabs={tabs}
          color="uintel"
          tabIndex={currentTabIndex}
          headerButtons={[
            <Tooltip key="save" title="Coming Soon" arrow>
              <Box>
                <Button onClick={openWizard} css={headerButton} disabled>
                  <Icon iconName="s3://save" colour="#fff" />
                </Button>
              </Box>
            </Tooltip>,
            <Tooltip key="load" title="Coming Soon" arrow>
              <Box>
                <Button onClick={openWizard} css={headerButton} disabled>
                  <Icon iconName="s3://folder" colour="#fff" />
                </Button>
              </Box>
            </Tooltip>,
          ]}
        />
      </Box>
    </Box>
  )
}
