import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import { initSentry } from '@modules/Sentry'
import { initDatadog } from '@modules/Datadog'
import { FeatureFlagsProvider } from '@contexts/FeatureFlagsContext'
import { MapProvider } from '@contexts/RiskMapContext'
import { LayerProvider } from '@contexts/LayerContext'
import store from '@redux/store'
import { router } from '@routes/router'
import { TutorialContextProvider } from '@contexts/TutorialContext'
import { AccordionStateProvider } from '@contexts/AccordionStateContext/AccordionStateContext'
import { PreferencesProvider } from '@contexts/PreferencesContext'
import { RiskFetcherProvider } from '@contexts/RiskFetcherContext'
import { LayerDetailsProvider } from '@contexts/LayerDetailsContext'
import { SnackbarProvider } from '@contexts/SnackbarContext'
import { PrioritisationContextProvider } from '@contexts/PrioritisationContext'
import { PrioritisationMapProvider } from '@contexts/PrioritisationMapContext'

// Initialize Datadog only in production
if (import.meta.env.VITE_UI_ENV === 'production') initDatadog()
// We only want to initialize Sentry in production
if (import.meta.env.PROD) initSentry()

const AppRoot = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AccordionStateProvider>
          <PreferencesProvider>
            <FeatureFlagsProvider>
              <TutorialContextProvider>
                <SnackbarProvider>
                  <MapProvider>
                    <PrioritisationMapProvider>
                      <LayerProvider>
                        <RiskFetcherProvider>
                          <LayerDetailsProvider>
                            <PrioritisationContextProvider>
                              <RouterProvider router={router} />
                            </PrioritisationContextProvider>
                          </LayerDetailsProvider>
                        </RiskFetcherProvider>
                      </LayerProvider>
                    </PrioritisationMapProvider>
                  </MapProvider>
                </SnackbarProvider>
              </TutorialContextProvider>
            </FeatureFlagsProvider>
          </PreferencesProvider>
        </AccordionStateProvider>
      </Provider>
    </React.StrictMode>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<AppRoot />)
