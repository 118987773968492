import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const inputsTabContainer = () =>
  css`
    padding-bottom: 16px;
    font-size: 16px;
    flex: 1;
    position: relative;
  `

export const flexColumn = css`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  margin-top: -8px;
`

export const headerContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #aaa;
  padding: 8px 8px;
  margin-bottom: 8px;

  h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .buttons {
    display: flex;
    gap: 8px;
    button {
      font-size: 14px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
`

export const valueSetContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

export const valueSetHeader = (theme: Theme) =>
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.palette.primary[900]};
    color: ${theme.palette.primary[100]};
    padding: 8px;
  `

export const valueSetTitle = css`
  display: flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0.3px;
`

export const valueSetName = css`
  font-size: 16px;
`

export const toggleButton = (theme: Theme) =>
  css`
    padding: ${theme.spacing(1)};
    font-size: 14px;
  `

export const sliderContainer = css`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 12px;
`

export const elementsContainer = (theme: Theme) =>
  css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 24px 16px 24px;
    background-color: ${theme.palette.grey[100]}30;
    font-size: 14px;
    margin-bottom: 8px;
  `

export const elementsTitle = css`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`

export const elementsGrid = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`

export const elementsList = css`
  padding-left: 24px;
`

export const footerButtonsContainer = (valueWeightingsHaveChanged: boolean) => css`
  display: flex;
  justify-content: space-between;
  padding: ${valueWeightingsHaveChanged ? '20px' : '0px'} 20px;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -4px 4px -4px #0b294830;
  background-color: rgb(231, 239, 247);
  flex-direction: row-reverse;
  opacity: ${valueWeightingsHaveChanged ? 1 : 0};
  height: ${valueWeightingsHaveChanged ? '80px' : 0};
  transform: translateY(${valueWeightingsHaveChanged ? 0 : 8}px);
  transition: opacity 0.3s, transform 0.3s, height 0.3s, padding 0.3s;

  button {
    min-height: 40px;
  }
`

export const inputsTabParent = () =>
  css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `
