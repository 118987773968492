import { BASE_API_URL } from '@src/app-constants'
import axiosOriginal, { AxiosError, InternalAxiosRequestConfig } from 'axios'

const axios = axiosOriginal.create({ withCredentials: true })
export default axios

const storedClientName = window.localStorage.getItem('client_name')
const accessJwt = window.localStorage.getItem('access_token')

if (storedClientName) axios.defaults.headers.common['client_name'] = storedClientName
if (accessJwt) axios.defaults.headers.common['authorization'] = `Bearer ${accessJwt}`

// Cognito access tokens expire by default after an hour and can be refreshed.
// The below intercepts any unauthorised responses caused by an expired token, requests a fresh token,
// and retries the request with the new token. While this is happening any other requests are added
// a queue and then also retried with the fresh token once available.
// Code modified from this SO answer: https://stackoverflow.com/a/56838553

type PromiseExectutor = { resolve: (value: unknown) => void; reject: (reason?: unknown) => void }
type ExtendedAxiosRequest = (InternalAxiosRequestConfig & { _retry?: boolean }) | undefined

let isRefreshingToken = false
let requestQueue: PromiseExectutor[] = []

axios.interceptors.response.use(
  (response) => response,

  async (error: AxiosError) => {
    const originalRequest: ExtendedAxiosRequest = error.config

    if (
      error.response?.status == 401 &&
      error.response.data == 'TokenExpired' &&
      originalRequest &&
      !originalRequest._retry
    ) {
      if (isRefreshingToken) {
        return new Promise((resolve, reject) => {
          requestQueue.push({ resolve, reject })
        })
          .then(() => {
            originalRequest.headers.authorization = makeBearerString()
            return axios(originalRequest)
          })
          .catch((err) => err)
      }

      // If header of the request has changed, it means the token has been refreshed
      if (originalRequest.headers.authorization !== makeBearerString()) {
        originalRequest.headers.authorization = makeBearerString()
        return Promise.resolve(axios(originalRequest))
      }

      originalRequest._retry = true
      isRefreshingToken = true

      // Refresh the token then process the queue
      return new Promise((resolve, reject) => {
        refreshAccessToken()
          .then(({ data }) => {
            window.localStorage.setItem('access_token', data.access_token)
            axios.defaults.headers.common['authorization'] = `Bearer ${data.access_token}`
            processQueue()
            resolve(axios(originalRequest))
          })
          .catch((err) => {
            processQueue(err)
            reject(err)
          })
          .then(() => {
            isRefreshingToken = false
          })
      })
    }

    return Promise.reject()
  },
)

const refreshAccessToken = async () => {
  return await axios.post(`${BASE_API_URL}/user/refresh_token`, {
    user_uuid: window.localStorage.getItem('user_uuid'),
    refresh_token: window.localStorage.getItem('refresh_token'),
  })
}

const processQueue = (error?: AxiosError) => {
  requestQueue.forEach((promiseExecutor) => {
    if (error) {
      promiseExecutor.reject(error)
    } else {
      promiseExecutor.resolve(null)
    }
  })
  requestQueue = []
}

const makeBearerString = () => `Bearer ${window.localStorage.getItem('access_token')}`

export const downloadFile = async (fileUrl: string) => {
  // Apparently there's no non-hackey way to programatically download files.
  // This generates an anchor element and triggers a click - blergh.

  const response = await axios.get(fileUrl, { responseType: 'blob' })

  // The filename of the download is in the content-disposition header.
  // It looks something like this:
  // 'attachment; filename="UrbanIntelligenceRiskSummaryGeoJSONExport.zip"'
  // (Don't forget to expose the content-disposition header via CORS in the API :-) )
  const contentDisposition = response.headers['content-disposition']
  const filename = contentDisposition.match(/filename="(.*?)"/)[1]

  const a = document.createElement('a')
  const url = URL.createObjectURL(response.data)
  a.href = url
  a.download = filename

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      removeEventListener('click', clickHandler)
    }, 150)
  }

  a.addEventListener('click', clickHandler, false)
  a.click()
}
