/** @jsxImportSource @emotion/react */
import { SimplifiedGeoJSONFeature } from '@redux/prioritisationMap/prioritisationMapSlice'
import { BASE_API_URL } from '@src/app-constants'
import { useLoadingState } from '@src/hooks/useLoadingState'
import axios from '@src/utils/customAxios'
import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import {
  HotspotHistogramDatum,
  HotspotLegend,
  HotspotVulnerabilitiesData,
} from './PrioritisationContext.types'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import {
  flattenToMapLayerById,
  useLayerDataFetcher,
} from '@src/components/Molecules/RiskSideDrawerContent/data_fetchers/layerDataFetcher'
import { usePrioritisationMap } from '@contexts/PrioritisationMapContext'
import { Snackbar, useSnackbars } from '@contexts/SnackbarContext'

interface PrioritisationContextProps {
  valueWeightings: { [key: string]: number }
  setValueWeightings: (value_set_weights: { [key: string]: number }) => void
  elementGroups: ValueSet[]
  initializePrioritisation: () => void
  assessment: Assessment | null
  scenarioGroup: MCAScenario | null
  prioritisationLayers: PrioritisationMapLayer[] | null
  setScenarioGroup: (scenarioGroup: MCAScenario) => void
  area: string | null
  setArea: (areaFeature: string) => void
  areaFeature: SimplifiedGeoJSONFeature | null
  legend: HotspotLegend | null
  legendIsLoading: boolean
  legendIsInitialized: boolean
  setAreaFeature: (areaFeature: MapboxGeoJSONFeature) => void
  allScenarioGroups: MCAScenario[] | null
  adaptationAreaPriorities: {
    area: string
    areaGroup: string
    priorityIndex: number
    color: string
  }[]
  exposurePercIsLoading: boolean
  exposurePercIsInitialized: boolean
  adaptationAreaPrioritiesIsLoading: boolean
  adaptationAreaPrioritiesIsInitialized: boolean
  exposurePercByElement: ElementExposure[]
  landingOverlayShown: boolean
  setLandingOverlayShown: (shown: boolean) => void
}

export interface ElementExposure {
  element: string
  percentage: number
  exposure: number
  unit: string
}

export interface ValueSet {
  value_set_id: string
  elements: {
    type: string
    weight: number
    label: string
  }[]
}

export interface PrioritisationMapLayer {
  layer_id: string
  display_name: string
  layer_type: string
}

export interface MCAHazardScenario {
  hazard_uid: string
  scenario_name: string
  display_name: string
}

export interface MCAScenario {
  scenario_id: string
  description: string
  hazard_scenarios: MCAHazardScenario[]
  parameters: {
    [key: string]: string | number | boolean | null
  }
}

export interface Assessment {
  assessment_id: string
  description: string
  scenario_ids: string[]
  value_set_config: {
    value_set_weights: { [key: string]: number }
  }
  is_default: boolean
}

export const prioritisationColorLegend = {
  'Very high': '#7D0000',
  High: '#F80000',
  Moderate: '#FF7A7B',
  Low: '#FFBBBA',
  'Very low': '#FFDADA',
}

export const PrioritisationContext = createContext({} as PrioritisationContextProps)
export const PrioritisationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const layersData = useLayerDataFetcher()
  const [valueWeightings, setValueWeightings] = useState<
    PrioritisationContextProps['valueWeightings']
  >({})
  const [elementGroups, setElementGroups] = useState<PrioritisationContextProps['elementGroups']>(
    [],
  )
  const { selectedPrioLayer, setSelectedPrioLayer } = usePrioritisationMap()
  const [assessment, setAssessment] = useState<Assessment | null>(null)
  const [prioritisationLayers, setPrioritisationLayers] = useState<PrioritisationMapLayer[] | null>(
    null,
  )
  const [scenarioGroup, setScenarioGroup] = useState<MCAScenario | null>(null)
  const [allScenarioGroups, setAllScenarioGroups] = useState<MCAScenario[] | null>(null)
  const [vulnerabilitiesCache, setVulnerabilitiesCache] = useState<{
    [key: string]: HotspotVulnerabilitiesData
  }>({})
  const [histogramCache, setHistogramCache] = useState<{
    [key: string]: {
      vulnerabilities: HotspotVulnerabilitiesData
      histogram: HotspotHistogramDatum[]
    }
  }>({})
  const { clientName } = useSelector((state: RootState) => state.user)
  // Area Feature for the map's sake
  // "Area" is what really controls the sidedrawer content
  const [area, setArea] = useState<string | null>(null)
  const [areaFeature, setAreaFeatureState] = useState<SimplifiedGeoJSONFeature | null>(null)
  const initializedForClient = useRef<string | null>(null)
  const [
    exposurePercByElement,
    setExposurePercByElement,
    exposurePercIsLoading,
    setExposurePercIsLoading,
    exposurePercIsInitialized,
  ] = useLoadingState<PrioritisationContextProps['exposurePercByElement']>([])
  const [
    adaptationAreaPriorities,
    setAdaptationAreaPriorities,
    adaptationAreaPrioritiesIsLoading,
    setAdaptationAreaPrioritiesIsLoading,
    adaptationAreaPrioritiesIsInitialized,
  ] = useLoadingState<PrioritisationContextProps['adaptationAreaPriorities']>([])
  const [legend, setLegend, legendIsLoading, setLegendIsLoading, legendIsInitialized] =
    useLoadingState<HotspotLegend | null>(null)
  const { displaySnackbar, removeSnackbar } = useSnackbars()
  const [landingOverlayShown, setLandingOverlayShown] = useState(false)

  const setAreaFeature = (areaFeature: MapboxGeoJSONFeature) => {
    setAreaFeatureState(areaFeature as SimplifiedGeoJSONFeature)
    setArea(areaFeature.properties?.name)
  }

  const initializePrioritisation = () => {
    if (initializedForClient.current === clientName) return
    const abortController = new AbortController()
    let defaultAssessment: Assessment | undefined = undefined
    const fetchAssessments = async () => {
      const assessmentsRes = await axios.get<Assessment[]>(
        `${BASE_API_URL}/prioritisation/assessments`,
        {
          signal: abortController.signal,
        },
      )
      if (assessmentsRes.status !== 200) return
      setAssessment(assessmentsRes.data[0])
      defaultAssessment = assessmentsRes.data.find((a) => a.is_default)
      const assessmentValues = assessmentsRes.data[0].value_set_config.value_set_weights
      setValueWeightings(assessmentValues)
    }
    const fetchScenarios = async () => {
      const flatLayerData = flattenToMapLayerById(layersData)
      const scenariosRes = await axios.get<MCAScenario[]>(
        `${BASE_API_URL}/prioritisation/scenarios`,
        {
          signal: abortController.signal,
        },
      )
      if (scenariosRes.status !== 200) return
      const scenariosWithHazardNames = scenariosRes.data.map((s) => ({
        ...s,
        hazard_scenarios: s.hazard_scenarios.map((hs) => ({
          ...hs,
          scenario_name: hs.display_name,
          display_name: flatLayerData[hs.hazard_uid]?.hazard_name ?? hs.display_name,
        })),
      }))
      setAllScenarioGroups(scenariosWithHazardNames)
      const foundScenario = scenariosWithHazardNames.find((s) =>
        defaultAssessment?.scenario_ids.some((id) => id === s.scenario_id),
      )
      setScenarioGroup(foundScenario ?? scenariosWithHazardNames[0])
    }
    const fetchValueSets = async () => {
      const assessmentValueSets = assessment?.value_set_config.value_set_weights ?? {}
      const valueSetsRes = await axios.get<ValueSet[]>(
        `${BASE_API_URL}/prioritisation/value_sets?value_sets=${Object.keys(
          assessmentValueSets,
        ).join(',')}`,
        {
          signal: abortController.signal,
        },
      )
      if (valueSetsRes.status !== 200) return
      setElementGroups(valueSetsRes.data)
    }
    const fetchPrioritisationLayers = async () => {
      const layersRes = await axios.get<PrioritisationMapLayer[]>(
        `${BASE_API_URL}/prioritisation/layers`,
        {
          signal: abortController.signal,
        },
      )
      if (layersRes.status !== 200) return
      setPrioritisationLayers(layersRes.data)
      if (!selectedPrioLayer) {
        setSelectedPrioLayer(layersRes.data[0])
      }
    }
    fetchPrioritisationLayers()
    fetchAssessments()
    fetchScenarios()
    fetchValueSets()
    initializedForClient.current = clientName
  }

  // Loading Vulnerabilities Data
  const processVulnerabilities = (vulnData: HotspotVulnerabilitiesData) => {
    const areaGroups = Object.keys(vulnData.regions).toSorted(
      (a, b) => Object.keys(vulnData.regions[a]).length - Object.keys(vulnData.regions[b]).length,
    )
    const uniqueIndices = areaGroups.reduce((acc, areaGroup) => {
      acc[areaGroup] = 0
      return acc
    }, {} as { [key: string]: number })

    const previousValues = areaGroups.reduce((acc, areaGroup) => {
      acc[areaGroup] = null
      return acc
    }, {} as { [key: string]: number | null })
    const priorities = Object.entries(vulnData.regions).flatMap(([areaGroup, areasInGroup]) =>
      Object.entries(areasInGroup).map(([areaName, area]) => {
        // color for total score by legend buckets
        const areaCategory = Object.entries(vulnData.legend).find(
          ([_, range]) => area.totalAreaScore >= range[0] && area.totalAreaScore <= range[1],
        )?.[0]
        if (
          previousValues[areaGroup] === null ||
          previousValues[areaGroup] !== area.totalAreaScore
        ) {
          uniqueIndices[areaGroup]++
          previousValues[areaGroup] = area.totalAreaScore
        }
        return {
          area: areaName,
          areaGroup,
          priorityIndex: uniqueIndices[areaGroup],
          color: prioritisationColorLegend[areaCategory as keyof HotspotLegend],
        }
      }),
    )

    setLegend(vulnData.legend)
    setLegendIsLoading(false)
    setAdaptationAreaPriorities(priorities)
    setAdaptationAreaPrioritiesIsLoading(false)
  }
  const updateVulnerabilies = async () => {
    if (!scenarioGroup || !clientName || !layersData) return
    const abortController = new AbortController()
    const hazard_scenarios = scenarioGroup?.hazard_scenarios
    const scenariosString = hazard_scenarios.map((s) => s.hazard_uid).join(',')
    const cacheKey = JSON.stringify(valueWeightings) + '|' + scenariosString + '|' + clientName

    if (vulnerabilitiesCache[cacheKey]) {
      processVulnerabilities(vulnerabilitiesCache[cacheKey])
      return
    }

    const loadingMessage: Snackbar = {
      message: 'Loading...',
      type: 'info',
    }

    const loadingTimeout = setTimeout(() => {
      displaySnackbar(loadingMessage)
    }, 1000)

    const vulnerabilitiesResponse = await axios.post<HotspotVulnerabilitiesData>(
      `${BASE_API_URL}/prioritisation/vulnerabilities/${scenariosString}`,
      {
        value_set_weights: valueWeightings,
      },
      { signal: abortController.signal },
    )

    if (vulnerabilitiesResponse.status !== 200) {
      clearTimeout(loadingTimeout)
      removeSnackbar(loadingMessage)
      return
    }
    setVulnerabilitiesCache((prev) => ({
      ...prev,
      [cacheKey]: vulnerabilitiesResponse.data,
    }))
    processVulnerabilities(vulnerabilitiesResponse.data)

    clearTimeout(loadingTimeout)
    removeSnackbar(loadingMessage)
  }

  // Loading Histogram Data
  const processHistogram = (histoData: HotspotHistogramDatum[]) => {
    const flatLayerData = flattenToMapLayerById(layersData)
    setExposurePercByElement(
      histoData.map((h) => ({
        element: flatLayerData[h.element_id]?.display_name ?? h.element_id,
        percentage: Math.round(h.percentage_exposed * 10) / 10,
        exposure: Math.round(h.exposed_metric * 10) / 10,
        unit: flatLayerData[h.element_id]?.unit ?? ' units',
      })),
    )
    setExposurePercIsLoading(false)
  }
  const updateHistogram = async () => {
    if (!scenarioGroup || !clientName || !layersData) return
    const abortController = new AbortController()
    const hazard_scenarios = scenarioGroup?.hazard_scenarios
    const scenariosString = hazard_scenarios.map((s) => s.hazard_uid).join(',')
    const cacheKey =
      JSON.stringify(valueWeightings) + '|' + scenariosString + '|' + clientName + '|' + area

    if (histogramCache[cacheKey]) {
      processHistogram(histogramCache[cacheKey].histogram)
      return
    }

    const areaQuery = area ? `?regions=${area}` : ''

    const histogramResponse = await axios.post<HotspotHistogramDatum[]>(
      `${BASE_API_URL}/prioritisation/histogram_data/${scenariosString}${areaQuery}`,
      { signal: abortController.signal },
    )

    if (histogramResponse.status !== 200) {
      return
    }
    setHistogramCache((prev) => ({
      ...prev,
      [cacheKey]: {
        vulnerabilities: vulnerabilitiesCache[cacheKey],
        histogram: histogramResponse.data,
      },
    }))
    processHistogram(histogramResponse.data)
  }

  useEffect(() => {
    if (scenarioGroup && clientName && clientName !== 'openaccess' && layersData) {
      setAdaptationAreaPrioritiesIsLoading(true)
      updateVulnerabilies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioGroup, allScenarioGroups, valueWeightings, assessment, clientName, layersData])

  useEffect(() => {
    if (scenarioGroup && clientName && clientName !== 'openaccess' && layersData) {
      setExposurePercIsLoading(true)
      updateHistogram()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioGroup, allScenarioGroups, valueWeightings, assessment, clientName, layersData, area])

  return (
    <PrioritisationContext.Provider
      value={{
        valueWeightings,
        setValueWeightings,
        elementGroups,
        initializePrioritisation,
        assessment,
        scenarioGroup,
        prioritisationLayers,
        area,
        setArea,
        legend,
        legendIsLoading,
        legendIsInitialized,
        areaFeature,
        setAreaFeature,
        allScenarioGroups,
        setScenarioGroup,
        adaptationAreaPriorities,
        exposurePercByElement,
        exposurePercIsLoading,
        adaptationAreaPrioritiesIsLoading,
        exposurePercIsInitialized,
        adaptationAreaPrioritiesIsInitialized,
        landingOverlayShown,
        setLandingOverlayShown,
      }}
    >
      {children}
    </PrioritisationContext.Provider>
  )
}

export const usePrioritisationContext = () => useContext(PrioritisationContext)
