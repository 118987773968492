import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const geocoderContainer = ({ theme }: { theme: Theme }) => css`
  margin: 0 ${theme.spacing(1)};
  z-index: 1000;

  & .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    max-height: 30px;
    width: 100%;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  @media screen and (min-width: 640px) {
    .mapboxgl-ctrl-geocoder--icon-search {
      top: 6px;
    }
    .mapboxgl-ctrl-geocoder--input {
      height: 30px;
    }
  }
`

export const geocoderButtonBox = ({ theme }: { theme: Theme }) => css`
  width: 30px;
  height: 30px;

  // matches mapbox style
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme.palette.white.main};
  border-radius: ${theme.shape.borderRadius}px;

  transition: all 0.2s;

  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    background-color: ${theme.palette.white.light};
  }
`
