import { Content, TableCell } from 'pdfmake/interfaces'
import { AdaptationAreaPriority } from '../PrioritisationReport'

const cellHeight = 24
const cellPaddingTop = 5.79

export const GenerateMultipageApdaptionAreaPriorities = (
  area: string | null,
  priorities: AdaptationAreaPriority[],
): Content => {
  const generateApdaptionAreaPriorities = (
    adaptationAreaPriorities: {
      area: string
      areaGroup: string
      priorityIndex: number
      color: string
    }[],
    includeHeader = false,
  ): TableCell[][] => {
    const toReturn: TableCell[][] = includeHeader ? [['Adaptation area', 'Priority']] : []
    adaptationAreaPriorities.forEach((adaptationArea) =>
      toReturn.push([
        { text: adaptationArea.area, style: area == adaptationArea.area ? 'bold' : '' },
        { text: adaptationArea.priorityIndex, style: area == adaptationArea.area ? 'bold' : '' },
      ]),
    )
    return toReturn
  }

  const toReturn: Content = []
  let position = 0
  let rowsPerPage = 10
  let title = 'AREAS BY PRIORITY RANKING'

  while (position < priorities.length) {
    const prioritySlice = priorities.slice(position, position + rowsPerPage)
    let selectedAreaRow = prioritySlice.findIndex((row) => row.area == area)
    if (selectedAreaRow >= 0) selectedAreaRow++ // Off by one error and also if not found result is -1 so don't want to add 1 :-)

    position += rowsPerPage

    toReturn.push({ text: title, style: 'h2' })
    toReturn.push({
      table: {
        widths: ['*', '*'],
        heights: cellHeight - 2 - cellPaddingTop,
        headerRows: 1,
        body: generateApdaptionAreaPriorities(prioritySlice, true),
      },
      fontSize: 10,
      layout: {
        fillColor: (row) =>
          row == selectedAreaRow ? '#B6CDE5' : row % 2 == 1 ? '#EFF2F5' : '#fff',
        vLineWidth: () => 0,
        hLineWidth: () => 0,
        paddingTop: () => cellPaddingTop,
      },
    })

    rowsPerPage = 26
    title = 'AREAS BY PRIORITY RANKING continued'
  }

  return toReturn
}
