/** @jsxImportSource @emotion/react */
import { Box, Button, MenuItem, Slide, Tooltip, useTheme } from '@mui/material'
import {
  footerButtonsContainer,
  resultsScrollContainer,
  resultsTabContainer,
  resultsTabParent,
  tooltipArrow,
  viewAreasButton,
} from './ResultsTab.styles'
import { usePrioritisationContext } from '@contexts/PrioritisationContext'
import { Icon } from '@src/components/Atoms/Icon'
import { useRef, useState } from 'react'
import { ClickAwayListener, Grow, Paper, Popper, MenuList } from '@mui/material'
import { useSnackbars } from '@contexts/SnackbarContext'
import { downloadPrioritisationReport } from '@src/components/Molecules/PdfGeneration/PrioritisationReport'
import { usePrioritisationMap } from '@contexts/PrioritisationMapContext'
import { PriorityTable } from './PriorityTable'
import { ExposureHistogram } from './ExposureHistogram'
import { RootState } from '@redux/store'
import { useSelector } from 'react-redux'

export const ResultsTab = () => {
  const theme = useTheme()
  const { clientDisplayName } = useSelector((state: RootState) => state.user)
  const { displaySnackbar } = useSnackbars()
  const {
    scenarioGroup,
    adaptationAreaPriorities,
    area,
    setArea,
    exposurePercByElement,
    valueWeightings,
    exposurePercIsLoading,
    adaptationAreaPrioritiesIsLoading,
    elementGroups,
  } = usePrioritisationContext()
  const { selectedPrioLayer, map, flyTo, clientLocation } = usePrioritisationMap()
  const arrowRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handlePdfReportClick = async () => {
    setOpen(false)
    if (!map || !selectedPrioLayer) return

    displaySnackbar({
      message: 'Generating report...',
      type: 'info',
    })

    await downloadPrioritisationReport(map, {
      area,
      scenarioGroup,
      valueWeightings,
      adaptationAreaPriorities,
      areasType: selectedPrioLayer.layer_type,
      exposurePercByElement,
      elementGroups,
      clientDisplayName,
    })

    displaySnackbar({ message: 'Report exported', type: 'success' })
  }

  const viewAllAreas = () => {
    setArea('')
    flyTo({ ...clientLocation, usePadding: true, pitch: 0, bearing: 0 })
  }

  return (
    <Box css={resultsTabParent()}>
      <Box css={resultsScrollContainer()}>
        <Box css={resultsTabContainer(theme)}>
          <h1>Results</h1>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '4px',
            }}
          >
            <p>
              For {scenarioGroup?.scenario_id} scenario
              {area ? ` for ${area}` : ''}.
            </p>
            {area && (
              <Button css={viewAreasButton} variant="text" color="uintel" onClick={viewAllAreas}>
                View all areas
              </Button>
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <PriorityTable />
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <h2>Top 10 exposed elements by percentage</h2>
            <ExposureHistogram />
          </Box>
        </Box>
        <Slide
          direction="up"
          in={!!(selectedPrioLayer && !exposurePercIsLoading && !adaptationAreaPrioritiesIsLoading)}
          mountOnEnter
          unmountOnExit
        >
          <Box css={footerButtonsContainer(true)}>
            <Button ref={anchorRef} variant="contained" color="uintel" onClick={handleToggle}>
              Export <Icon iconName="ChevronRight" colour="#FFF" />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              sx={{ zIndex: (theme) => theme.zIndex.tooltip }}
              placement="right-end"
              modifiers={[
                {
                  name: 'flip',
                  enabled: false,
                  options: {
                    altBoundary: true,
                    rootBoundary: 'document',
                    padding: 8,
                  },
                },
                {
                  name: 'preventOverflow',
                  enabled: false,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: 'document',
                    padding: 8,
                  },
                },
                {
                  name: 'offset',
                  options: {
                    offset: [0, 16],
                  },
                },
              ]}
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: 'left bottom',
                  }}
                >
                  <Paper css={{ border: '2px solid', borderColor: theme.palette.uintel.main }}>
                    <span
                      ref={arrowRef}
                      css={tooltipArrow(theme.palette.uintel.main)}
                      className="arrow"
                    />
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow">
                        <Tooltip title="Coming Soon" placement="right" arrow>
                          <Box>
                            <MenuItem onClick={handleClose} disabled>
                              Table (.xls)
                            </MenuItem>
                          </Box>
                        </Tooltip>
                        <MenuItem onClick={handlePdfReportClick}>Report (.pdf)</MenuItem>
                        <Tooltip title="Coming Soon" placement="right" arrow>
                          <Box>
                            <MenuItem onClick={handleClose} disabled>
                              Raw data (.csv)
                            </MenuItem>
                          </Box>
                        </Tooltip>
                        <Tooltip title="Coming Soon" placement="right" arrow>
                          <Box>
                            <MenuItem onClick={handleClose} disabled>
                              Shapefile (.GeoJSON)
                            </MenuItem>
                          </Box>
                        </Tooltip>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Slide>
      </Box>
    </Box>
  )
}
