import { useEffect } from 'react'
import { SpecificLocationAccordion } from './SpecificLocationAccordion'
import { useMap } from '@contexts/RiskMapContext'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { GroupedLayers } from '..'
import { EmptySideDrawer } from '../../EmptyTab'

export const SelectedTab = ({ groupedLayers }: { groupedLayers: GroupedLayers }) => {
  // Add location specific details
  const { selectedFeature, selectedLayer } = useSelector((state: RootState) => state.riskMap)
  const { flyToFeature } = useMap()

  const selectedGeoJSONFeature = selectedFeature as mapboxgl.MapboxGeoJSONFeature

  // fly to selected feature
  useEffect(() => {
    if (selectedFeature) {
      flyToFeature(selectedFeature as GeoJSON.Feature, {
        essential: true,
        duration: 2000,
        usePadding: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature])

  if (selectedGeoJSONFeature && selectedGeoJSONFeature.layer.source) {
    const maplayerListOfHazardLayers = groupedLayers.hazards.reduce((acc, hazardGroup) => {
      return [...acc, ...hazardGroup.scenarios.map((scenario) => scenario.layer)]
    }, [] as MapLayer[])
    return (
      <>
        <SpecificLocationAccordion
          selectedFeature={selectedGeoJSONFeature}
          selectedAsset={selectedLayer}
          assetLayers={groupedLayers.assets}
          hazardLayers={maplayerListOfHazardLayers}
          informationLayers={groupedLayers.information}
        />
      </>
    )
  }
  if (groupedLayers.assets.length > 0) {
    return <EmptySideDrawer text="Click an element on the map to get insights here" type="select" />
  }
  return (
    <EmptySideDrawer
      text="No elements are visible on the map. Add elements to gain insights"
      type="element"
      buttonText="Add Elements"
    />
  )
}
