/** @jsxImportSource @emotion/react */

import { Box, Button, ClickAwayListener, Grow, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  exportMenuStyles,
  infoPanelFooter,
  infoPanelTabs,
  informativePanel,
  tabContent,
  tabsContainer,
} from './InformativePanel.styles'
import { Link } from 'react-router-dom'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { RootState } from '@redux/store'
import {
  RiskSideDrawerLayerTabs,
  setSideDrawerInfoLayerFocus,
} from '@redux/riskSideDrawer/riskSideDrawerSlice'
import { RiskSummary } from './RiskSummaryTab'
import { Home, Info, OpenInNew, Warning } from '@mui/icons-material'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { SelectedTab } from './SelectedTab'
import { LayersTab } from './LayersTab'
import { RiskSummaryReportButton } from '@src/components/Molecules/PdfGeneration/RiskSummary'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { SelectedElementReportButton } from '@src/components/Molecules/PdfGeneration/SelectedElement'
import { printButton } from '@src/components/Molecules/PdfGeneration/RiskSummary/RiskSummaryReport.styles'
import { ExportRiskSummaryXlsxButton } from '@src/components/Molecules/PdfGeneration/RiskSummary/ExportRiskSummaryXlxs'
import { ExportRiskSummaryGeoJsonButton } from '@src/components/Molecules/PdfGeneration/RiskSummary/ExportRiskSummaryGeoJson'
import AccordionArrowDownIcon from '@assets/icons/AccordionArrowDownIcon'
import { Tabs } from '@src/components/Molecules/Tabs'

export type HazardGroup = {
  hazard_type: string
  scenarios: {
    scenario: string
    layer: MapLayer
  }[]
}

export type GroupedLayers = {
  hazards: HazardGroup[]
  assets: MapLayer[]
  information: MapLayer[]
}

function groupHazardsByType(hazardLayers: MapLayer[]): HazardGroup[] {
  const hazardLayersByType: HazardGroup[] = []
  hazardLayers.forEach((readonly_layer) => {
    const layer = { ...readonly_layer }
    const hazardType = layer.hazard_title ?? layer.display_name.split('(')[0].trim()
    let hazardScenario = layer.display_name
    if (layer.scenario_name) {
      hazardScenario = layer.scenario_name
    } else if (hazardScenario.includes('(')) {
      hazardScenario = hazardScenario.split('(')[1].split(')')[0].trim()
      layer.scenario_name = hazardScenario
    }
    const hazardGroup = hazardLayersByType.find((group) => group.hazard_type === hazardType)
    if (hazardGroup) {
      hazardGroup.scenarios.push({ scenario: hazardScenario, layer })
    } else {
      hazardLayersByType.push({
        hazard_type: hazardType,
        scenarios: [{ scenario: hazardScenario, layer }],
      })
    }
  })
  return hazardLayersByType
}

export const InformativePanel = () => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const { methods_report_url } = useSelector((state: RootState) => state.user)
  const { infoLayerFocus } = useSelector((state: RootState) => state.sideDrawer)

  const { selectedLayer: selectedAsset, layers } = useSelector((state: RootState) => state.riskMap)

  const { features } = useFeatureFlags()
  const reportPrinterEnabled = features.find(
    (feature) => feature.feature == 'sideDrawerReport' && feature.enabled,
  )
  const exportRiskSummaryXlsx = features.find(
    (feature) => feature.feature == 'exportRiskSummaryXlsx' && feature.enabled,
  )
  const sideDrawerReportEnabled = features.find(
    (feature) => feature.feature == 'sideDrawerReport' && feature.enabled,
  )

  const groupedLayers = useMemo<GroupedLayers>(() => {
    return {
      hazards: groupHazardsByType(layers.filter((layer) => layer.layerType === 'hazard')),
      assets: layers.filter((layer) => layer.layerType === 'asset'),
      information: layers.filter((layer) => layer.layerType === 'information'),
    }
  }, [layers])

  useEffect(() => {
    if (selectedAsset) {
      dispatch(setSideDrawerInfoLayerFocus({ layerFocus: 'selection', isOpen: true }))
    }
  }, [dispatch, selectedAsset])

  const currentTabIndex = useMemo(() => {
    switch (infoLayerFocus) {
      case 'layers':
        return 0
      case 'risk':
        return 1
      case 'selection':
        return 2
      default:
        return 0
    }
  }, [infoLayerFocus])
  const tabs = [
    {
      label: 'Layers',
      icon: <Home />,
      content: (
        <Box css={tabContent}>
          <LayersTab />
        </Box>
      ),
      value: 'layers',
    },
    {
      label: 'Risk Summary',
      icon: <Warning />,
      content: (
        <Box css={tabContent}>
          <RiskSummary groupedLayers={groupedLayers} />
        </Box>
      ),
      value: 'risk',
    },
    {
      label: 'Selected',
      icon: <Info />,
      content: (
        <Box css={tabContent}>
          <SelectedTab groupedLayers={groupedLayers} />
        </Box>
      ),
      value: 'selection',
    },
  ]

  const handleLayerFocusChange = (newValue: number) => {
    const value = tabs[newValue].value as RiskSideDrawerLayerTabs
    dispatch(setSideDrawerInfoLayerFocus({ layerFocus: value, isOpen: true }))
  }

  const footerContent = []
  const exportMenuItems: ReactNode[] = []
  if (methods_report_url && infoLayerFocus === 'risk') {
    footerContent.push(
      <Link
        to={methods_report_url}
        target="_blank"
        style={{ width: '100%', textDecoration: 'none' }}
        className="tutorial-methodology-button"
        key="tutorialMethodology"
      >
        <Button css={printButton({ theme })} endIcon={<OpenInNew />}>
          Methodology Report
        </Button>
      </Link>,
    )
  }
  if (
    infoLayerFocus === 'risk' &&
    layers.some((layer) => layer.layerType === 'hazard') &&
    layers.some((layer) => layer.layerType === 'asset')
  ) {
    if (reportPrinterEnabled)
      exportMenuItems.push(<RiskSummaryReportButton key="risksummaryreport" />)
    if (exportRiskSummaryXlsx) {
      exportMenuItems.push(<ExportRiskSummaryXlsxButton key="exportriskxlsx" />)

      exportMenuItems.push(<ExportRiskSummaryGeoJsonButton key="exportriskgeojson" />)
    }
  } else if (sideDrawerReportEnabled && infoLayerFocus === 'selection' && selectedAsset) {
    footerContent.push(<SelectedElementReportButton key="selectedElementReport" />)
  }

  const [showExportMenu, setShowExportMenu] = useState(false)

  return (
    <Box css={informativePanel}>
      <Box css={tabsContainer}>
        <Tabs
          css={infoPanelTabs({ theme })}
          handleChangeTab={handleLayerFocusChange}
          tabs={tabs}
          tabIndex={currentTabIndex}
        />
      </Box>
      {!!footerContent.length && (
        <Box css={infoPanelFooter({ theme })}>{footerContent.map((content) => content)}</Box>
      )}
      {!!exportMenuItems.length && (
        <Box sx={{ position: 'relative' }}>
          <Button
            sx={{
              width: '100%',
              svg: {
                transform: showExportMenu ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.14s',
              },
            }}
            onClick={() => {
              setShowExportMenu((prev) => !prev)
            }}
          >
            Export Risk Summary
            <AccordionArrowDownIcon height="16px" />
          </Button>
          <Grow
            in={showExportMenu}
            style={{ transformOrigin: '50% 100% 0' }}
            mountOnEnter
            unmountOnExit
            timeout={140}
          >
            <Box css={exportMenuStyles({ theme })}>
              <ClickAwayListener onClickAway={() => setShowExportMenu(false)}>
                <Box className="tutorial-summary-report-button">{...exportMenuItems}</Box>
              </ClickAwayListener>
            </Box>
          </Grow>
        </Box>
      )}
      <Box className="tutorial-sidedrawer" />
    </Box>
  )
}
