/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'
import {
  pageContent,
  storyContainer,
  storyFlexContainer,
  storyScrollingContainer,
} from './ActiveStory.styles'
import { UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMap } from '@contexts/RiskMapContext'
import {
  Story,
  generateSequencedSteps,
  getCurrentContexts,
  getCurrentElements,
  getCurrentHazards,
  getIndexedLayers,
} from '../Stories.utils'
import { useLayers } from '@contexts/LayerContext'
import { StoryStep } from './StoryStep'
import { StoryControls } from './StoryControls'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import SelectionTool from '@src/components/Pages/Map/MapTools/SelectionTool'

export const ActiveStory = ({ story }: { story: Story }) => {
  const theme = useTheme()

  const { flyTo, setBasemap, map } = useMap()

  const { regions: regionsData, layerData } = useSelector((state: RootState) => state.riskMap)
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [mapRenderedStepIndex, setMapRenderedStepIndex] = useState(0)
  // Index the layers
  const { elementsData, scenariosData, contextsData } = useMemo(
    () =>
      layerData
        ? getIndexedLayers(layerData)
        : { elementsData: {}, scenariosData: {}, contextsData: {} },
    [layerData],
  )
  const sequencedSteps = useMemo(
    () => generateSequencedSteps(story, elementsData, scenariosData, contextsData),
    [story, elementsData, scenariosData, contextsData],
  )

  const deleteSelection = useCallback(() => {
    if (!map) return
    SelectionTool.deleteSelectionArea(map)
  }, [map])

  const { prepareAndUpdateLayers } = useLayers()

  // Update the currently used layers
  const allStoryLayers = useMemo(() => {
    const allElementLayers = story.elements ?? []
    const allContextLayers = story.contexts ?? []
    const allScenarioLayers = story.scenarios ?? []

    for (const chapter of story.chapters) {
      allElementLayers.push(...(chapter.elements ?? []))
      allContextLayers.push(...(chapter.contexts ?? []))
      allScenarioLayers.push(...(chapter.scenarios ?? []))

      for (const step of chapter.steps) {
        allElementLayers.push(...(step.elements ?? []))
        allContextLayers.push(...(step.contexts ?? []))
        allScenarioLayers.push(...(step.scenarios ?? []))
      }
    }

    const reduceLayers = (
      layerIds: string[],
      layerData: {
        [key: string]: MapLayer
      },
    ) =>
      layerIds.reduce(
        (acc, id) =>
          layerData[id]
            ? {
                ...acc,
                [id]: {
                  ...layerData[id],
                },
              }
            : acc,
        {},
      )

    return {
      ...getCurrentElements(reduceLayers(allElementLayers, elementsData)),
      ...getCurrentContexts(reduceLayers(allContextLayers, contextsData)),
      ...getCurrentHazards(reduceLayers(allScenarioLayers, scenariosData)),
    }
  }, [story, elementsData, scenariosData, contextsData])

  const stepsParentRef = useRef<HTMLDivElement>(null)
  const stepsScrollRef = useRef<HTMLDivElement>(null)
  const [isBlockingScrollDetection, setIsBlockingScrollDetection] = useState(false)
  const blockingScrollDetectionTimeout = useRef<NodeJS.Timeout | null>(null)
  const blockScrollDetection = useCallback(() => {
    setIsBlockingScrollDetection(true)
    if (blockingScrollDetectionTimeout.current !== null) {
      clearTimeout(blockingScrollDetectionTimeout.current)
    }
    blockingScrollDetectionTimeout.current = setTimeout(() => {
      setIsBlockingScrollDetection(false)
    }, 1000)
  }, [])
  const updatingMapLayersTimeout = useRef<NodeJS.Timeout | null>(null)

  const updateLayerVisibilities = useCallback(() => {
    const step = sequencedSteps[activeStepIndex]
    if (!map || !map.getStyle()) {
      updatingMapLayersTimeout.current = setTimeout(updateLayerVisibilities, 400)
      return
    }
    if (!map) return
    const layerIds = [...(step.elements ?? []), ...(step.contexts ?? []), ...(step.scenarios ?? [])]

    prepareAndUpdateLayers({
      layers: layerIds.flatMap((id) => (allStoryLayers[id] ? [allStoryLayers[id]] : [])),
      regionMasks:
        step.regions?.flatMap((region) => {
          const regionMask = regionsData.find((r) => r.region === region)
          if (!regionMask) return []
          return [regionMask]
        }) ?? [],
    })
  }, [sequencedSteps, activeStepIndex, map, prepareAndUpdateLayers, allStoryLayers, regionsData])

  useEffect(() => {
    const step = sequencedSteps[activeStepIndex]

    if (updatingMapLayersTimeout.current !== null) {
      clearTimeout(updatingMapLayersTimeout.current)
    }

    // Load next layers after a delay to wait for flyTo to finish
    //updatingMapLayersTimeout.current = setTimeout(updateLayerVisibilities, 1800)

    const waitForRenderIfTrue = (
      renderFunc: () => void,
      callback: () => void,
      condition = true,
    ) => {
      if (condition) {
        map?.once('render', () => {
          callback()
        })
        renderFunc()
      } else {
        callback()
      }
    }

    if (step.location && map) {
      map.once('moveend', () => {
        waitForRenderIfTrue(
          () => {
            if (step.basemap) setBasemap(step.basemap)
          },
          () => {
            updateLayerVisibilities()
          },
          !!step.basemap,
        )
      })
      flyTo({
        ...step.location,
        usePadding: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRenderedStepIndex])

  const changeStep = useCallback(
    (increment: number) => {
      setActiveStepIndex((prev) => {
        // scroll to the new step
        let new_stepIndex = prev
        if ((increment > 0 && prev < sequencedSteps.length - 1) || (increment < 0 && prev > 0)) {
          new_stepIndex = prev + increment
        }

        // Scroll to the new step
        if (stepsParentRef.current && stepsScrollRef.current) {
          const element = stepsParentRef.current.children[new_stepIndex]
          const parent = stepsScrollRef.current
          if (!element || !parent) return prev
          const parentRect = parent.getBoundingClientRect()
          const elementRect = element.getBoundingClientRect()

          // Calculate the new scroll position
          const newScrollTop = parent.scrollTop + elementRect.top - parentRect.top - 20

          // Smoothly scroll to the new position
          blockScrollDetection()
          parent.scrollTo({
            top: newScrollTop,
            behavior: 'smooth',
          })
        }

        return new_stepIndex
      })
      setMapRenderedStepIndex((prev) => {
        if (increment > 0 && prev < sequencedSteps.length - 1) {
          return prev + 1
        } else if (increment < 0 && prev > 0) {
          return prev - 1
        }
        return prev
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sequencedSteps],
  )

  const scrollTimeout = useRef<NodeJS.Timeout | null>(null)

  const onScrollEnd = useCallback(() => {
    setActiveStepIndex((current) => {
      setMapRenderedStepIndex(current)
      return current
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStepIndex])

  const onScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const target = e.target as HTMLDivElement

    for (const child_index in stepsParentRef.current?.children || []) {
      const child = stepsParentRef.current?.children[child_index] as HTMLElement
      if (
        Math.max(child.offsetTop, child.offsetTop + child.clientHeight - target.clientHeight / 2) >=
        target.scrollTop
      ) {
        setActiveStepIndex(+child_index)
        break
      }
    }

    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current)
    }
    scrollTimeout.current = setTimeout(() => {
      onScrollEnd()
    }, 800)
  }

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        changeStep(-1)
      } else if (event.key === 'ArrowDown') {
        changeStep(1)
      }
    },
    [changeStep],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    deleteSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box css={pageContent(theme)}>
      <StoryControls
        activeStepIndex={activeStepIndex}
        sequencedSteps={sequencedSteps}
        changeStep={changeStep}
      />
      <Box
        css={storyContainer(theme)}
        onScroll={isBlockingScrollDetection ? undefined : onScroll}
        ref={stepsScrollRef}
      >
        <Box css={storyScrollingContainer()}>
          <Box css={storyFlexContainer(theme)} ref={stepsParentRef}>
            {sequencedSteps.map((sequencedStep, index) => (
              <Box key={index}>
                <StoryStep
                  sequencedStep={sequencedStep}
                  isActive={activeStepIndex == index}
                  totalSteps={sequencedSteps.length}
                  handleOnClick={() => {
                    changeStep(index - activeStepIndex)
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
