/** @jsxImportSource @emotion/react */

import * as Sentry from '@sentry/browser'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  TextField,
  useTheme,
} from '@mui/material'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import {
  formWrapper,
  HeaderContainer,
  HeaderImgContainer,
  hiddenInput,
  SubmitFeedbackModalContainer,
  SubmitFeedbackModalContentContainer,
  uiButton,
  uiButtonOutlined,
} from './SubmitFeedbackModal.styles'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { useSnackbars } from '@contexts/SnackbarContext'

export const SubmitFeedbackModal = ({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: (arg0: boolean) => void
}) => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const { userEmail } = useSelector((state: RootState) => state.user)
  const { layers } = useSelector((state: RootState) => state.riskMap)

  const [feedbackType, setFeedbackType] = useState<string | null>(null)
  const [feedback, setFeedback] = useState<string>('')
  const [screenshot, setScreenshot] = useState<File | null>(null)
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const submitFeedback = async () => {
    let id = Sentry.lastEventId()
    if (!id) {
      id = Sentry.captureMessage('User Feedback')
    }
    let description = feedback
    if (layers.length) {
      description +=
        '\nLayers: \n' + layers.map((layer) => layer.id || layer.type || layer.assetTag).join(',\n')
    }

    const attachments = []
    if (screenshot) {
      const arrayBufferRes = await screenshot.arrayBuffer()
      const unint8Array = new Uint8Array(arrayBufferRes)
      if (unint8Array) {
        attachments.push({
          filename: screenshot.name.trim().replace(' ', '_'),
          data: unint8Array,
        })
      }
    }

    Sentry.captureFeedback(
      {
        name: feedbackType ?? 'General',
        email: userEmail,
        message: description,
        associatedEventId: id,
      },
      {
        attachments,
      },
    )
    displaySnackbar({
      message: 'Feedback submitted successfully',
      type: 'success',
    })
    handleClose(false)
  }

  const handleBlur = (value: string, field: string) => {
    if (value === '') {
      setValidationErrors((prev) => [...prev, `${field} cannot be empty`])
    } else {
      setValidationErrors((prev) => prev.filter((error) => error !== `${field} cannot be empty`))
    }
  }

  useEffect(() => {
    if (feedbackType && validationErrors.includes('Feedback Type cannot be empty')) {
      setValidationErrors((prev) =>
        prev.filter((error) => error !== 'Feedback Type cannot be empty'),
      )
    }
  }, [feedbackType, validationErrors])

  useEffect(() => {
    if (feedback && validationErrors.includes('Feedback cannot be empty')) {
      setValidationErrors((prev) => prev.filter((error) => error !== 'Feedback cannot be empty'))
    }
  }, [feedback, validationErrors])

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Box css={SubmitFeedbackModalContainer({ theme })}>
        <Card css={SubmitFeedbackModalContentContainer({ theme })} elevation={0}>
          <CardMedia
            css={HeaderImgContainer({ theme })}
            component="img"
            image="/logos/UILogoFullWhite.svg"
            alt="uintel logo"
          />
          <CardHeader title="Submit Feedback" css={HeaderContainer({ theme })}></CardHeader>
          <CardContent sx={{ paddingX: theme.spacing(6), paddingY: theme.spacing(4), flexGrow: 1 }}>
            <Box css={formWrapper({ theme })}>
              <Autocomplete
                id="bug-type"
                options={['Comment', 'Issue', 'Suggestion']}
                defaultValue={'Comment'}
                value={feedbackType}
                onChange={(_, value) => setFeedbackType(value)}
                renderInput={(params) => <TextField {...params} label="Type of Feedback" />}
                onBlur={() => handleBlur(feedbackType ?? '', 'Feedback Type')}
              />
              <TextField
                id="feedback"
                label="Feedback"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={4}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                onBlur={() => handleBlur(feedback, 'Feedback')}
              />
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<ScreenshotMonitorIcon />}
                css={uiButton(theme)}
              >
                {screenshot ? 'Change Screenshot' : 'Upload Screenshot'}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setScreenshot(e.target.files ? e.target.files[0] : null)}
                  css={hiddenInput}
                />
              </Button>
              {validationErrors.map((error) => (
                <Box key={error} color="error.main">
                  {error}
                </Box>
              ))}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: theme.spacing(4),
                }}
              >
                <Button
                  variant="text"
                  css={uiButtonOutlined(theme)}
                  onClick={() => handleClose(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => submitFeedback()}
                  disabled={!feedbackType || !feedback}
                  css={uiButton(theme)}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  )
}
