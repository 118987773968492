import mapboxgl from 'mapbox-gl'
import {
  blobToDataUrl,
  preparePageDuplicateMapAndExportImageBlob,
} from '@src/components/Pages/Map/MapTools/ExportMap/mapExporter'
import { Content } from 'pdfmake/interfaces'
import {
  assets,
  makeColumnarLegends,
  makeScale,
} from '@src/components/Molecules/PdfGeneration/pdfGeneratorUtils'
import * as turf from '@turf/turf'
import { makeSvg, Size } from '../../Report.utils'
import { LegendData, LegendItem } from '@src/components/Molecules/Legend'
import { prioritisationColorLegend } from '@contexts/PrioritisationContext'

export const GeneratePrioritisationMapContent = async (
  map: mapboxgl.Map,
  bodySize: Size,
): Promise<Content> => {
  const bearing = map.getBearing()
  const compass = assets.compass(-bearing)
  const pitch = map.getPitch()

  const mapHeightInPixels = map.getCanvas().height
  const mapWidthInPixels = map.getCanvas().width

  const mapWidth = bodySize.width
  const mapHeight = 308
  const aspectRatio = mapWidth / mapHeight

  let mapImageDataUrl = ''
  await preparePageDuplicateMapAndExportImageBlob(
    map,
    2.0,
    aspectRatio,
    async (imageBlob: Blob) => {
      mapImageDataUrl = await blobToDataUrl(imageBlob)
    },
    [],
  )

  const mapWidthInMetres = turf.distance(
    map.unproject([0, mapHeightInPixels / 2.0]).toArray(),
    map.unproject([mapWidthInPixels, mapHeightInPixels / 2.0]).toArray(),
    { units: 'meters' },
  )

  const scale = mapWidthInMetres / mapWidth

  // Get a distinct list of legends
  const legends: LegendData[] = []
  const prioritisationLegendItems: LegendItem[] = Object.entries(prioritisationColorLegend).map(
    (entry) => {
      return { label: entry[0], color: entry[1] }
    },
  )

  legends.push({
    title: 'Relative Risk',
    sections: [
      {
        type: 'categorical',
        items: prioritisationLegendItems,
      },
    ],
  })

  const toReturn: Content = {
    stack: [
      { image: mapImageDataUrl, width: mapWidth },
      makeSvg(compass, { relativePosition: { x: 0, y: -mapHeight } }),
      makeSvg(makeScale(scale, pitch), { relativePosition: { x: 37.5, y: -mapHeight } }),
      makeSvg(makeColumnarLegends(legends, mapWidth, mapHeight), {
        relativePosition: { x: 0, y: -mapHeight },
      }),
    ],
    marginBottom: 5,
    unbreakable: true,
  }
  return toReturn
}
