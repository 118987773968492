/** @jsxImportSource @emotion/react */
import { Box, Collapse, MenuItem, Select, Tooltip, useTheme } from '@mui/material'
import {
  elementsContainer,
  elementsGrid,
  elementsList,
  elementsTitle,
  flexColumn,
  footerButtonsContainer,
  headerContainer,
  inputsTabContainer,
  inputsTabParent,
  sliderContainer,
  toggleButton,
  valueSetContainer,
  valueSetHeader,
  valueSetName,
  valueSetTitle,
} from './InputsTab.styles'
import { Button } from '@src/components/Atoms/Button'
import { FullWidthAccordion } from '@src/components/Atoms/FullWidthAccordion'
import { useEffect, useMemo, useState } from 'react'
import { usePrioritisationContext } from '@contexts/PrioritisationContext'
import { PrioritySlider } from '../../PrioritySlider'
import { FancySuspense } from '@src/components/Atoms/FancySuspense'
import { ToWords } from 'to-words'

const sentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
export const InputsTab = () => {
  const theme = useTheme()
  const toWords = new ToWords()
  const {
    elementGroups,
    valueWeightings,
    assessment,
    scenarioGroup,
    setScenarioGroup,
    setValueWeightings,
    allScenarioGroups,
  } = usePrioritisationContext()
  const [visibleElements, setVisibleElements] = useState<{ [key: string]: boolean }>({})
  const [updatedValueWeightings, setUpdatedValueWeightings] = useState<{ [key: string]: number }>(
    {},
  )
  const valueWeightingsHaveChanged = useMemo(() => {
    return Object.keys(valueWeightings).some(
      (key) => valueWeightings[key] !== updatedValueWeightings[key],
    )
  }, [valueWeightings, updatedValueWeightings])
  const toggleVisibleElements = (name: string) => {
    setVisibleElements((prev) => ({ ...prev, [name]: !prev[name] }))
  }
  const applyChanges = () => {
    setValueWeightings(updatedValueWeightings)
  }
  useEffect(() => {
    setUpdatedValueWeightings(valueWeightings)
  }, [valueWeightings])
  return (
    <Box css={inputsTabParent()}>
      <Box css={inputsTabContainer()}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, overflowY: 'auto' }}>
          <FullWidthAccordion
            level="h2"
            defaultExpanded={true}
            body={
              <Box css={flexColumn}>
                <Box css={headerContainer}>
                  <h4>TOTAL OF {toWords.convert(elementGroups.length)} VALUE SETS</h4>
                  <Box className="buttons">
                    <Button
                      variant="text"
                      onClick={() => {
                        setUpdatedValueWeightings({
                          ...valueWeightings,
                          ...assessment?.value_set_config.value_set_weights,
                        })
                      }}
                      color="uintel"
                    >
                      Reset
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        setUpdatedValueWeightings((prev) => {
                          return Object.keys(prev).reduce((acc, key) => {
                            acc[key] = 3
                            return acc
                          }, {} as { [key: string]: number })
                        })
                      }}
                      color="uintel"
                    >
                      Balance
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <p css={{ padding: '0 8px' }}>
                    Use the sliders to adjust the importance of each value set below.
                  </p>
                  {elementGroups.map(({ value_set_id, elements }) => {
                    return (
                      <Box key={value_set_id} css={valueSetContainer}>
                        <Box css={valueSetHeader(theme)}>
                          <Box css={valueSetTitle}>
                            <b css={valueSetName}>{sentenceCase(value_set_id)}</b>
                          </Box>
                          <Button
                            variant="text"
                            css={toggleButton(theme)}
                            onClick={() => toggleVisibleElements(value_set_id)}
                            color="uintel"
                          >
                            {visibleElements[value_set_id] ? 'Hide' : 'View'} elements
                          </Button>
                        </Box>
                        <Box css={sliderContainer}>
                          <PrioritySlider
                            value={updatedValueWeightings[value_set_id] ?? 1}
                            onChange={(_, value) => {
                              setUpdatedValueWeightings({
                                ...updatedValueWeightings,
                                [value_set_id]: value as number,
                              })
                            }}
                            color="uintel"
                          />
                        </Box>
                        <Collapse in={visibleElements[value_set_id]}>
                          <Box css={elementsContainer(theme)}>
                            <h5 css={elementsTitle}>ELEMENTS</h5>
                            <Box css={elementsGrid}>
                              <ul css={elementsList}>
                                {elements
                                  .slice(0, Math.ceil(elements.length / 2))
                                  .map((element) => (
                                    <li key={element.type}>{element.label}</li>
                                  ))}
                              </ul>
                              <ul css={elementsList}>
                                {elements.slice(Math.ceil(elements.length / 2)).map((element) => (
                                  <li key={element.type}>{element.label}</li>
                                ))}
                              </ul>
                            </Box>
                          </Box>
                        </Collapse>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            }
            title="Values"
          />
          <FullWidthAccordion
            level="h2"
            defaultExpanded={true}
            body={
              <Box sx={{ paddingBottom: '16px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    borderBottom: '1px solid #000',
                    padding: '0 8px 8px 8px',
                  }}
                >
                  <Tooltip title="Coming Soon" placement="left" arrow>
                    <Box>
                      <Button
                        variant="text"
                        disabled
                        color="uintel"
                        css={{ fontSize: '13px', padding: '4px 0' }}
                      >
                        + Create
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
                <Box sx={{ padding: '0 8px' }}>
                  <Box sx={{ paddingTop: 4, fontSize: '14px' }}>Select a scenario below</Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px 0',
                      gap: 8,
                    }}
                  >
                    <FancySuspense isLoading={!scenarioGroup}>
                      {scenarioGroup && allScenarioGroups && (
                        <>
                          <Box sx={{ flexGrow: 1 }}>
                            <Select
                              css={{ width: '100%' }}
                              value={scenarioGroup.scenario_id}
                              onChange={(e) => {
                                const scenarioGroup = allScenarioGroups.find(
                                  (group) => group.scenario_id === e.target.value,
                                )
                                if (scenarioGroup) setScenarioGroup(scenarioGroup)
                              }}
                              color="uintel"
                            >
                              {allScenarioGroups.map((sg) => (
                                <MenuItem key={sg.scenario_id} value={sg.scenario_id}>
                                  {sg.scenario_id}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: '#f5f5f5',
                              padding: '8px 24px 16px 12px',
                              fontSize: '14px',
                              maxWidth: '50%',
                            }}
                          >
                            <b>Hazards in this scenario</b>
                            <ul css={{ marginLeft: '24px' }}>
                              {scenarioGroup?.hazard_scenarios.map((scenario) => (
                                <li
                                  key={scenario.hazard_uid}
                                  css={{ lineHeight: '1.3', paddingTop: '4px' }}
                                >
                                  {scenario.display_name}
                                </li>
                              ))}
                            </ul>
                          </Box>
                        </>
                      )}
                    </FancySuspense>
                  </Box>
                </Box>
              </Box>
            }
            title="Scenarios"
          />
        </Box>
      </Box>
      <Box css={footerButtonsContainer(valueWeightingsHaveChanged)}>
        <Button variant="contained" onClick={applyChanges} color="uintel">
          Apply Changes
        </Button>
      </Box>
    </Box>
  )
}
