import { UserState } from '@redux/user/userSlice'
import { CombinedState } from '@reduxjs/toolkit'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BASE_API_URL } from '../../app-constants'
import axios from '@src/utils/customAxios'

export interface PreferencesProviderProps {
  children: React.ReactNode
}

export interface PreferencesContext {
  term_preference: { risk: boolean; hazard: boolean }
  use_hazard_name: { flag: boolean }
}

const default_preferences: PreferencesContext = {
  term_preference: { risk: false, hazard: false },
  use_hazard_name: { flag: false },
}

export const Preferences = createContext({} as PreferencesContext)

export const PreferencesProvider = ({ children }: PreferencesProviderProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [preferences, setPreferences] = useState<PreferencesContext>(default_preferences)

  const { clientName } = useSelector(
    (
      state: CombinedState<{
        user: UserState
      }>,
    ) => state.user,
  )

  const apiGetClientPreferences = useCallback(async () => {
    try {
      const preferencesResponse = await axios.get<PreferencesContext>(
        `${BASE_API_URL}/client/preferences`,
      )

      return preferencesResponse.data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: `, error)
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName])

  useEffect(() => {
    ;(async () => {
      try {
        if (!clientName) return
        const data = await apiGetClientPreferences()
        if (!data) return

        setPreferences(data)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [apiGetClientPreferences, clientName])

  return (
    <Preferences.Provider value={preferences}>
      {isLoading ? 'Loading...' : children}
    </Preferences.Provider>
  )
}

export const usePreferences = () => useContext(Preferences)
