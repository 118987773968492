import { Content } from 'pdfmake/interfaces'
import { ScenariosByAsset } from '@src/components/Molecules/RiskSideDrawerContent/data_fetchers/scenariosFetcher'
import { englishList, firstLetterToUpperCase } from '@src/utils/strings.utils'
import { AssetDetails, HorizontalBar, parseHtml } from '../../Report.utils'
import { LegendItem } from '@src/components/Molecules/Legend'
import { HazardDetail } from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog'

export const GenerateKnownRisksToSelectedElement = (
  assetDetails: AssetDetails,
  scenarios: ScenariosByAsset[],
  hazardDetails: HazardDetail[],
): Content => {
  if (!assetDetails.asset) return { stack: [] }

  const riskTypeByScenarioId: { [scenarioId: string]: string } = {}
  const riskCounts: { [riskId: string]: { title: string; count: number } } = {}

  hazardDetails.forEach((hazardDetail) =>
    hazardDetail.scenarios.forEach((scenario) => {
      riskTypeByScenarioId[scenario.assetTag] = hazardDetail.type
      if (!Object.hasOwn(riskCounts, hazardDetail.type))
        riskCounts[hazardDetail.type] = { title: hazardDetail.title, count: 0 }
    }),
  )

  scenarios.forEach((scenario) => {
    if (riskTypeByScenarioId[scenario.hazard_scenario])
      riskCounts[riskTypeByScenarioId[scenario.hazard_scenario]].count++
  })

  const exposedRisks = Object.values(riskCounts).filter((riskCount) => riskCount.count > 0)

  const knownScenarioDescriptions: string[] = exposedRisks.map(
    (exposedRisk) =>
      `${exposedRisk.count} ${exposedRisk.title} scenario${exposedRisk.count > 1 ? 's' : ''}`,
  )

  return {
    text:
      knownScenarioDescriptions.length > 0
        ? `This element is exposed to the following number of modelled events: ${englishList(
            knownScenarioDescriptions,
          )}.`
        : `This element is not exposed to any modelled events.`,
    marginBottom: 20,
    unbreakable: true,
  }
}

export const GenerateKnownRiskListForSelectedElement = (
  assetDetails: AssetDetails | null | undefined,
  hazardDetails: HazardDetail[],
  assetScenarios: ScenariosByAsset[],
  vulnerabilityLegendItems: LegendItem[],
  prefer_risk_term: boolean,
  prefer_hazard_term: boolean,
  bodyWidth: number,
): Content => {
  if (!assetDetails) return []

  // We don't want to include any scenarios in the list of the same
  // hazard type already added to the map that effects the element.
  const selectedScenarioTypes: string[] = []
  hazardDetails.forEach((hazardDetail) =>
    hazardDetail.scenarios.forEach((scenario) => {
      if (assetDetails.hazards?.some((h) => h.hazard_id == scenario.hazard_id)) {
        selectedScenarioTypes.push(hazardDetail.type)
        return
      }
    }),
  )

  interface ScenarioToRender extends ScenariosByAsset {
    unit: string
    vulnerabilityColor: string | undefined
  }

  const scenarioByHazardType: { [type: string]: ScenarioToRender[] } = {}

  assetScenarios.forEach((assetScenario) => {
    const hazardDetail = hazardDetails.find((hazardDetail) =>
      hazardDetail.scenarios.find((scenario) => scenario.assetTag == assetScenario.hazard_scenario),
    )
    const hazardType = hazardDetail?.type

    if (!hazardType || selectedScenarioTypes.includes(hazardType)) return

    const hazardUnit = hazardDetail.scenarios[0].tilesets[0].unit
    const scenarioToRender: ScenarioToRender = {
      unit: hazardUnit ?? '',
      vulnerabilityColor: vulnerabilityLegendItems[assetScenario.vulnerability_style].color,
      ...assetScenario,
    }

    scenarioByHazardType[hazardType]
      ? scenarioByHazardType[hazardType].push(scenarioToRender)
      : (scenarioByHazardType[hazardType] = [scenarioToRender])
  })

  // Pick the hazard with the least effect from each group
  const minimalScenarios: ScenarioToRender[] = []
  Object.keys(scenarioByHazardType).forEach((hazardId) => {
    const minimalScenario = scenarioByHazardType[hazardId].sort(
      (a, b) => a.vulnerability_style - b.vulnerability_style,
    )[0]
    minimalScenarios.push(minimalScenario)
  })

  if (minimalScenarios.length == 0) return []

  const hazardOrRisk = prefer_hazard_term ? 'hazards' : 'risk sources'
  const heading =
    assetDetails.hazards?.length == 0
      ? `KNOWN ${hazardOrRisk.toLocaleUpperCase()}`
      : `OTHER KNOWN ${hazardOrRisk.toLocaleUpperCase()}`
  const subheading =
    assetDetails.hazards?.length == 0
      ? `This element is exposed to these known ${hazardOrRisk}.`
      : `This element is also exposed to these unselected known ${hazardOrRisk}.`

  return {
    stack: [
      { text: heading, style: 'h2', pageBreak: 'before' },
      { text: subheading, marginBottom: 20 },
      HorizontalBar(bodyWidth),
      minimalScenarios.map((s): Content => {
        return [
          {
            margin: [0, 0, 0, 5],
            table: {
              body: [
                [
                  {
                    text: '',
                    fillColor: s.vulnerabilityColor,
                    rowSpan: 2,
                  },
                  {
                    stack: [
                      { text: s.display_name, font: 'Inter700', marginBottom: 3 },
                      parseHtml(s.sentence, 10.5),
                    ],
                    colSpan: 2,
                    margin: [10, 0, 0, 0],
                  },
                  '',
                ],
                [
                  '',
                  {
                    text: [
                      { text: prefer_risk_term ? 'Vulnerability: ' : 'Consequence: ' },
                      { text: firstLetterToUpperCase(s.vulnerability), font: 'Inter700' },
                    ],
                    margin: [10, 0, 0, 0],
                  },
                  s.exposure_value == null
                    ? []
                    : {
                        text: [
                          { text: 'Exposure: ' },
                          { text: `${s.exposure_value} ${s.unit}`, font: 'Inter700' },
                        ],
                      },
                ],
              ],
              widths: ['auto', '*', '*'],
            },
            layout: 'noBorders',
            unbreakable: true,
          },
          HorizontalBar(bodyWidth, 9),
        ]
      }) ?? [],
    ],
  }
}
