import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { Content } from 'pdfmake/interfaces'
import { convertFigmaPatternsToImages } from '../../Report.utils'

export const GenerateTitlePageContent = async (clientName: string): Promise<Content[]> => {
  let svgCoverResponse: Response

  svgCoverResponse = await fetch(
    `${PUBLIC_ASSETS_BASE_URL}/reports-ui/cover_pages/${clientName}.svg`,
  )

  if (!svgCoverResponse.ok)
    // Load a default cover page if one isn't avilable for a client.
    svgCoverResponse = await fetch(`${PUBLIC_ASSETS_BASE_URL}/reports-ui/cover_pages/default.svg`)

  const svgCover = convertFigmaPatternsToImages(await svgCoverResponse.text())

  return [
    {
      svg: svgCover,
      absolutePosition: { x: 0, y: 0 },
      pageBreak: 'after',
    },
  ]
}
