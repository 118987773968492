/** @jsxImportSource @emotion/react */
import { useSnackbars } from '@contexts/SnackbarContext'
import { useMap } from '@contexts/RiskMapContext'
import { MenuItem, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { exportButton } from './ExportRiskSummaryXlxs.styles'
import { RegionOption } from '../../RegionFilter'
import { BASE_API_URL } from '@src/app-constants'
import { Polygon } from 'geojson'
import { useEffect, useState } from 'react'
import { downloadFile } from '@src/utils/customAxios'

export const ExportRiskSummaryGeoJsonButton = () => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const { layers, drawAreas } = useSelector((state: RootState) => state.riskMap)
  const { clientName } = useSelector((state: RootState) => state.user)
  const { regionMasks } = useMap()
  const [geoJsonUrl, setGeoJsonUrl] = useState('')

  useEffect(() => {
    const assetLayers = layers.filter((layer) => layer.layerType == 'asset')
    const hazardLayers = layers.filter((layer) => layer.layerType == 'hazard')
    const assetLayerTypes = assetLayers.map((assetLayer) => assetLayer.type)
    const hazardLayerAssetTags = hazardLayers.map((hazardLayer) => hazardLayer.assetTag)

    const regions = regionMasks ? regionMasks.map((mask: RegionOption) => mask.region) : []
    const query = []
    if (regions.length > 0) query.push(`regions=${regions.join(',')}`)

    const drawAreaCoordinateArray: Polygon | null =
      drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
    const drawAreaCsv = drawAreaCoordinateArray
      ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
      : ''

    setGeoJsonUrl(
      `${BASE_API_URL}/asset/export/risksummary/${clientName}/geojson/${assetLayerTypes.join(
        ',',
      )}/${hazardLayerAssetTags.join(',')}/${drawAreaCsv}?${query.join('&')}`,
    )
  }, [layers, drawAreas, regionMasks, clientName])

  const handleClick = async () => {
    displaySnackbar({
      message: 'Generating data for export. File will download once complete.',
      type: 'info',
    })

    downloadFile(geoJsonUrl)
  }

  return (
    <MenuItem css={exportButton({ theme })} onClick={handleClick}>
      Spatial file (GeoJSON)
    </MenuItem>
  )
}
