/** @jsxImportSource @emotion/react */
import { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import * as d3 from 'd3'
import { ChartTooltip, ChartTooltipItem } from '../ChartTooltip'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { getConsequencePalette, roundForDisplay } from '../utils/utils'
import {
  parameterKeyTitleConverter,
  useLayerDataFetcher,
} from '../../RiskSideDrawerContent/data_fetchers/layerDataFetcher'
import { capitalizeFirstLetter } from '@src/utils/strings.utils'
import { AxisCalculator } from '../utils/AxisCalculator'
export type DataPoint = {
  [key: string]: string | number
} & {
  total: number
  unspecified?: number
  potential?: number
  exposed?: number
  insignificant?: number
  low?: number
  minor?: number
  moderate?: number
  major?: number
  high?: number
}
const fallbackData: DataPoint[] = [
  { year: 2000, exposed: 0, major: 0, moderate: 0, minor: 0, total: 0 },
  { year: 2020, exposed: 1, major: 0.5, moderate: 0.2, minor: 0.1, total: 0 },
  { year: 2040, exposed: 3, major: 1.5, moderate: 1, minor: 0.5, total: 0 },
  { year: 2060, exposed: 4, major: 2.5, moderate: 1.5, minor: 0.8, total: 0 },
  { year: 2080, exposed: 6, major: 3.5, moderate: 1, minor: 0.5, total: 0 },
  { year: 2100, exposed: 7, major: 4, moderate: 2, minor: 1, total: 0 },
]
export const MakeProjectedRiskChartSvg = ({
  data,
  svgElement,
  width = 900,
  parameter,
  vulnerabilityList,
  consequencePalette,
  parameterTitle,
  parameterUnit,
  yAxisTitle,
  updateTooltip,
}: {
  data: DataPoint[]
  svgElement: HTMLDivElement | null
  width: number
  parameter: string
  vulnerabilityList: string[]
  consequencePalette: { [key: string]: string }
  parameterTitle: string
  parameterUnit: string
  yAxisTitle: string
  updateTooltip?: (args: { show: boolean; data: DataPoint }) => void
}) => {
  if (!data || data.length === 0 || vulnerabilityList.length === 0) return

  const yCalculator = new AxisCalculator([...data.map((d) => d.total ?? 0), 0])

  const isPercentageValue = parameter.includes('aep')
  const showPercent = isPercentageValue ? '%' : ''
  const useLogScale = parameter.includes('aep') || parameter.includes('ari')

  // strong margin right because text gets cut off
  const margin = { top: 12, right: 80, bottom: 40, left: 52 }
  const height = Math.max(width / 3, 200)
  const innerWidth = width - margin.left - margin.right
  const innerHeight = height - margin.top - margin.bottom
  const shouldReverse = ['aep'].includes(parameter)
  d3.select(svgElement).selectAll('svg').remove()
  const svg = d3
    .select(svgElement)
    .append('svg')
    .attr('version', '1.1')
    .attr('xmlns', 'http://www.w3.org/2000/svg')
    .attr('width', width)
    .attr('height', height)
    .attr('font-family', 'Inter')
    .attr('font-size', '8pt')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('color', '#8392a1')
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)
  let x = null
  if (useLogScale) {
    x = d3
      .scaleLog()
      .domain(
        d3.extent(data, (d) => {
          if (typeof d[parameter] === 'string') {
            return parseFloat(d[parameter] as string)
          }
          return d[parameter] as number
        }) as [number, number],
      )
      .range(shouldReverse ? [innerWidth, 0] : [0, innerWidth])
  } else {
    x = d3
      .scaleLinear()
      .domain(
        d3.extent(data, (d) => {
          if (typeof d[parameter] === 'string') {
            return parseFloat(d[parameter] as string)
          }
          return d[parameter] as number
        }) as [number, number],
      )
      .range(shouldReverse ? [innerWidth, 0] : [0, innerWidth])
  }

  const y = d3.scaleLinear().domain(yCalculator.domain.reverse()).nice(8).range([0, innerHeight])
  const line = (yAccessor: (d: DataPoint) => number, _: string) =>
    d3
      .line<DataPoint>()
      .defined((d) => !isNaN(yAccessor(d)))
      .x((d) => x(d[parameter] as number))
      .y((d) => y(yAccessor(d)))

  svg
    .append('g')
    .attr('transform', `translate(${0},${innerHeight})`)
    // will need to match ticks to parameter's values
    .call(
      d3
        .axisBottom(x)
        .tickSizeOuter(6)
        .tickValues(data.map((d) => d[parameter] as number))
        .tickFormat((d) => {
          if (isPercentageValue) {
            return `${d}${showPercent}`
          }
          if (Number.isInteger(d)) {
            return `${d.toString()}${showPercent}`
          } else if (d) {
            return `${d.toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            })}${showPercent}`
          } else {
            return `${d}${showPercent}`
          }
        })
        .tickPadding(8),
    )
    .append('text') // x label
    .attr('fill', '#8392a1')
    .attr('text-anchor', 'middle')
    .attr('x', innerWidth / 2)
    .attr('y', margin.bottom - 4)
    .text(`${parameterTitle} ${parameterUnit ?? ''}`)
    .attr('font-family', 'Inter')
  svg
    .append('g')
    .call(
      d3
        .axisLeft(y)
        .tickSizeOuter(0)
        .tickSize(0)
        .ticks(yCalculator.tickCount)
        .tickFormat((t) => yCalculator.roundValue(t.valueOf())),
    )
    .append('text') // y label
    .attr('fill', '#8392a1')
    .attr('font-family', 'Inter')
    .attr('text-anchor', 'middle')
    .attr('font-size', '10.67px')
    .attr('transform', 'rotate(-90)')
    .attr('y', -margin.left + 10)
    .attr('x', -innerHeight / 2)
    .text(yAxisTitle)

  svg.selectAll('.domain').attr('stroke', '#53687e').attr('stroke-width', 0)

  // Append lines for y-axis ticks
  y.ticks(Math.min(8, y.ticks().length)).forEach((tick) => {
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', innerWidth)
      .attr('y1', y(tick))
      .attr('y2', y(tick))
      .attr('stroke', 'lightgrey')
      .attr('stroke-line')
  })

  let filteredVulnerabiltyList = [...vulnerabilityList]

  if (vulnerabilityList.length === 2) {
    // remove total from vulnerability list if there are only one vulnerabilities
    filteredVulnerabiltyList = vulnerabilityList.filter((v) => v !== 'total')
  }

  for (const vulnerability of filteredVulnerabiltyList) {
    if (data.length === 1) {
      svg
        .append('circle')
        .attr('cx', x(data[0][parameter] as number))
        .attr('cy', y(Number(data[0][vulnerability]) || 0))
        .attr('r', 3)
        .attr('fill', consequencePalette[vulnerability])
      svg
        .append('text')
        .attr('x', x(data[0][parameter] as number) + 5)
        .attr('y', y(Number(data[0][vulnerability]) || 0) + 3)
        .attr('stroke', 'white')
        .attr('stroke-width', 4)
        .text(capitalizeFirstLetter(vulnerability))
      svg
        .append('text')
        .attr('x', x(data[0][parameter] as number) + 5)
        .attr('y', y(Number(data[0][vulnerability]) || 0) + 3)
        .attr('fill', consequencePalette[vulnerability])
        .text(capitalizeFirstLetter(vulnerability))
    } else if (data.length > 1) {
      svg
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', consequencePalette[vulnerability] ?? '#000')
        .attr('stroke-width', 1.5)
        .attr(
          'd',
          line((d) => {
            return Number(d?.[vulnerability] ?? 0)
          }, consequencePalette[vulnerability] ?? '#000'),
        )
      svg
        .selectAll('circle.' + vulnerability)
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', (d) => x(d[parameter] as number))
        .attr('cy', (d) => y(Number(d[vulnerability]) || 0))
        .attr('r', 2)
        .attr('stroke', consequencePalette[vulnerability] ?? '#000')
        .attr('stroke-width', 1.5)
        .attr('fill', 'white')
    }
  }
  if (data.length > 1) {
    const textYValues = filteredVulnerabiltyList
      .map((vulnerability) => ({
        vulnerability,
        yVal:
          y(
            Number(shouldReverse ? data[0][vulnerability] : data[data.length - 1][vulnerability]) ||
              0,
          ) + 3,
      }))
      .toSorted((a, b) => a.yVal - b.yVal)

    let limit = 10
    // space out the text labels
    while (
      textYValues.some((v, i) =>
        textYValues.some((v2, i2) => Math.abs(v2.yVal - v.yVal) < 12 && i !== i2),
      )
    ) {
      textYValues.forEach((v, i) => {
        if (i > 0 && Math.abs(textYValues[i - 1].yVal - v.yVal) < 12) {
          textYValues[i - 1].yVal -= 3
          textYValues[i].yVal += 3
        }
      })
      limit -= 1
      if (limit <= 0) break
    }
    svg
      .selectAll('.legend-text')
      .data(textYValues)
      .enter()
      .append('text') // legend
      .attr('x', innerWidth + 10)
      .attr('y', (d) => d.yVal)
      .attr('fill', (d) => consequencePalette[d.vulnerability])
      .text((d) => capitalizeFirstLetter(d.vulnerability))
  }
  // Append invisible but hoverable vertical lines
  if (updateTooltip)
    svg
      .selectAll('.vertical-line')
      .data(data)
      .enter()
      .append('line')
      .attr('class', 'vertical-line')
      .attr('x1', (d) => x(d[parameter] as number))
      .attr('x2', (d) => x(d[parameter] as number))
      .attr('y1', 0)
      .attr('y2', innerHeight)
      .attr('stroke', '#00000000')
      .attr('stroke-width', 40)
      .on('mouseleave', (_, d) => {
        updateTooltip({ show: false, data: d })
      })
      .on('mouseenter', (_, d) => {
        updateTooltip({ show: true, data: d })
      })
}
export const ProjectedRiskChart = ({
  data = fallbackData,
  parameter,
  vulnerabilityList,
  parameterUnit,
  assetId,
  yAxisTitle,
  yAxisUnit,
}: {
  data: DataPoint[]
  parameter: string
  vulnerabilityList: string[]
  parameterUnit: string
  assetId: string
  yAxisTitle: string
  yAxisUnit: string | undefined
}) => {
  const layerData = useLayerDataFetcher()
  const parameterTitle = parameterKeyTitleConverter(parameter, layerData)

  const isPercentageValue = parameter.includes('aep')
  const showPercent = isPercentageValue ? '%' : ''
  const svgRef = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState<number | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipItems, setTooltipItems] = useState<ChartTooltipItem[]>([])
  const [tooltipHeader, setTooltipHeader] = useState('')
  const vulnerabilityPalette = useSelector(
    (state: RootState) => state.riskMap.legendsData['vulnerability'],
  )
  const consequencePalette = useMemo(
    () => getConsequencePalette(vulnerabilityPalette),
    [vulnerabilityPalette],
  )
  useEffect(() => {
    if (!svgRef.current) return
    const resizeObserver = new ResizeObserver((event) => {
      // Round width to nearest 50
      const newWidth = Math.round(event[0].contentBoxSize[0].inlineSize / 50) * 50
      setWidth(newWidth)
    })
    resizeObserver.observe(svgRef.current)
  }, [])
  const updateTooltip = useCallback(
    ({ show, data }: { show: boolean; data: DataPoint }) => {
      setShowTooltip(show)
      setTooltipHeader(
        `${data[parameter]}${parameterUnit ? parameterUnit : showPercent} ${parameterTitle}`,
      )
      if (data && consequencePalette) {
        const items = Object.keys(data)
          .filter((key) => key !== parameter)
          .sort((a, b) => {
            const order: { [key: string]: number } = {
              unspecified: 0,
              potential: 1,
              insignificant: 2,
              exposed: 3,
              low: 4,
              minor: 5,
              moderate: 6,
              major: 7,
              high: 8,
            }
            return (order[a as keyof DataPoint] ?? 0) - (order[b as keyof DataPoint] ?? 0)
          })
          .map((key) => {
            const item = parseFloat(`${data[key]}`)
            const label =
              yAxisTitle == 'Value ($)'
                ? `$${roundForDisplay(item, '$')}`
                : `${roundForDisplay(item, yAxisUnit)} ${yAxisTitle || ''}`
            return {
              text: `${key[0].toUpperCase() + key.slice(1)}: ${label}`,
              color: consequencePalette[key] ?? '#000',
            }
          })

        let filteredItems = [...items]
        if (filteredItems.length === 2)
          filteredItems = items.filter((item) => !item.text.startsWith('Total:'))
        setTooltipItems(filteredItems)
      }
    },
    [
      parameter,
      parameterUnit,
      showPercent,
      parameterTitle,
      consequencePalette,
      yAxisTitle,
      yAxisUnit,
    ],
  )
  useEffect(() => {
    if (!width || !consequencePalette) return
    MakeProjectedRiskChartSvg({
      data,
      svgElement: svgRef.current,
      width,
      parameter,
      vulnerabilityList,
      consequencePalette,
      parameterTitle,
      parameterUnit,
      yAxisTitle,
      updateTooltip,
    })
  }, [
    data,
    svgRef,
    width,
    parameter,
    vulnerabilityList,
    consequencePalette,
    parameterTitle,
    parameterUnit,
    assetId,
    yAxisTitle,
    updateTooltip,
  ])
  return (
    <ChartTooltip header={tooltipHeader} show={showTooltip} items={tooltipItems}>
      <div ref={svgRef}></div>
    </ChartTooltip>
  )
}
