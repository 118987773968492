import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const resultsTabContainer = (theme: Theme) => css`
  padding: 28px;
  padding-top: 0;
  color: #000;
  flex-grow: 1;
  font-size: 14px;
  overflow-y: auto;
  flex-direction: column;
  display: flex;

  h1 {
    margin: 0;
    color: #000;
    font-size: 28px;
    font-weight: 700;
    line-height: 130%;
  }

  h2 {
    margin: 0;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    padding-top: 48px;
    padding-bottom: 4px;
  }

  > p {
    padding-bottom: 12px;
  }

  .table {
    width: 100%;
    .thead {
      font-weight: 700;
      padding-right: 24px;
      text-align: left;
      display: flex;
      justify-content: space-between;
    }

    .td {
      padding: 4px 8px;
      padding-top: 8px;
      min-width: 160px;
    }

    .tbody {
      max-height: 300px;
      overflow-y: auto;
      padding-right: 16px;
      display: flex;
      flex-direction: column;
      .tr {
        border-top: 1px solid #000;
        display: flex;
        cursor: pointer;
        transition: background-color 0.2s;
        justify-content: space-between;

        &:hover {
          background-color: ${theme.palette.uintel[600]}08;
        }

        &.selected {
          background-color: ${theme.palette.uintel[600]}10;
        }
      }
    }
  }
`

export const footerButtonsContainer = (valueWeightingsHaveChanged: boolean) => css`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  box-shadow: 0px -4px 4px -4px #0b294830;
  background-color: rgb(231, 239, 247);
  flex-direction: row-reverse;
  opacity: ${valueWeightingsHaveChanged ? 1 : 0};
  transform: translateY(${valueWeightingsHaveChanged ? 0 : 8}px);
  transition: opacity 0.3s, transform 0.3s;
`

export const resultsTabParent = () => css`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const resultsScrollContainer = () => css`
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const viewAreasButton = css`
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1.2;
`

export const tooltipArrow = (arrowColor: string) => css`
  position: absolute;
  width: 8px;
  height: 16px;
  left: -8px;
  bottom: 12px;
  background-color: ${arrowColor};
  clip-path: polygon(100% 0%, 0% 50%, 100% 100%);
`
