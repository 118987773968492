import { ElementExposure } from '@contexts/PrioritisationContext'
import { roundForDisplay } from '@src/components/Molecules/Charts/utils/utils'
import { Content, TableCell } from 'pdfmake/interfaces'

export const GenerateExposurePercByElement = (
  exposurePercByElement: ElementExposure[],
): Content => {
  const makeBar = (elementExposure: ElementExposure): Content => {
    const width = 284 * (elementExposure.percentage / 100)
    return [
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 0,
            w: width,
            h: 12,
            color: '#0b2948',
          },
        ],
      },
      {
        text: `${roundForDisplay(elementExposure.percentage, '%')}% (${roundForDisplay(
          elementExposure.exposure,
          elementExposure.unit,
        )}${elementExposure.unit})`,
        //fontSize: 7,
        relativePosition: { x: width + 4, y: -12 },
      },
    ]
  }

  return [
    {
      table: {
        widths: ['33%', '*'],
        body: [
          ...exposurePercByElement.map((exposure): TableCell[] => [
            exposure.element,
            makeBar(exposure),
          ]),
        ],
      },
      layout: 'noBorders',
    },
  ]
}
