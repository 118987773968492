import { Map } from 'mapbox-gl'
import { blobToDataUrl } from '@src/components/Pages/Map/MapTools/ExportMap/mapExporter'
import { Content } from 'pdfmake/interfaces'
import {
  assets,
  makeColumnarLegends,
  makeScale,
} from '@src/components/Molecules/PdfGeneration/pdfGeneratorUtils'
import * as turf from '@turf/turf'
import { addLINZMap } from '@src/components/Molecules/MapView/RiskMapView/RiskMapView.utilities'
import { Feature } from 'geojson'
import { makeSvg, Size } from '../../Report.utils'
import {
  createHiddenDivAndAddToPage,
  getMapImageAsBlob,
  deleteDiv,
} from '@src/components/Molecules/PdfGeneration/mapExporterUtils'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { addRasterLayer } from '@src/components/Molecules/MapView/RiskMapView/LayerTypes'
import { LegendsData } from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog'
import { LegendData } from '@src/components/Molecules/Legend'

export const GenerateSelectedElementMapContent = async (
  selectedElement: Feature,
  bodySize: Size,
  hazardLayers: MapLayer[],
  showFullPageMap: boolean,
  legendsData: LegendsData,
): Promise<Content> => {
  const mapWidthInPixels = 1920
  const mapHeightInPixels = showFullPageMap ? 1449 : 1080

  const hiddenDiv = createHiddenDivAndAddToPage(mapWidthInPixels, mapHeightInPixels)
  const elementMap = await createElementMap(hiddenDiv, selectedElement, hazardLayers)
  const mapImageBlob = await getMapImageAsBlob(elementMap)
  const mapImageDataUrl = await blobToDataUrl(mapImageBlob)
  deleteDiv(hiddenDiv)

  const compass = assets.compass(0)

  const mapWidth = bodySize.width
  const mapHeight = (mapHeightInPixels / mapWidthInPixels) * mapWidth

  const mapWidthInMetres = turf.distance(
    elementMap.unproject([0, mapHeightInPixels / 2.0]).toArray(),
    elementMap.unproject([mapWidthInPixels, mapHeightInPixels / 2.0]).toArray(),
    { units: 'meters' },
  )

  // Get a distinct list of legends
  const legends: LegendData[] = []
  const legendIds: Set<string> = new Set<string>()
  hazardLayers.forEach((layer) => legendIds.add(layer.legend))
  legendIds.forEach((legendId) => legends.push(legendsData[legendId]))

  const scale = mapWidthInMetres / mapWidth

  const location = elementMap.getCenter()
  const toReturn: Content = {
    stack: [
      { image: mapImageDataUrl, width: mapWidth },
      makeSvg(compass, { relativePosition: { x: 0, y: -mapHeight } }),
      makeSvg(makeScale(scale), { relativePosition: { x: 37.5, y: -mapHeight } }),
      makeSvg(makeColumnarLegends(legends, mapWidth, mapHeight), {
        relativePosition: { x: 0, y: -mapHeight },
      }),
      {
        text: `${location.lat.toFixed(5)}, ${location.lng.toFixed(5)}`,
        relativePosition: { x: 0, y: -12 },
        fontSize: 10,
        background: '#AAA',
      },
    ],
    marginBottom: 5,
    unbreakable: true,
  }

  elementMap.remove()
  return toReturn
}

const createElementMap = async (
  hiddenDiv: HTMLDivElement,
  selectedElement: Feature,
  hazardLayers: MapLayer[],
): Promise<Map> =>
  new Promise((resolve) => {
    const bbox = turf.bbox(selectedElement)
    const elementMap = new Map({
      container: hiddenDiv,
      style: { version: 8, sources: {}, layers: [] },
      bounds: [bbox[0], bbox[1], bbox[2], bbox[3]],
      fitBoundsOptions: { maxZoom: 20, padding: 100 },
    })

    elementMap.once('styledata', () => {
      addLINZMap(elementMap)

      let lastId = ''
      hazardLayers.forEach((hazardLayer) => {
        hazardLayer.tilesets.forEach((tileset) => {
          addRasterLayer({
            map: elementMap,
            id: tileset.id,
            isVisible: true,
            shouldUpdate: false,
            beforeId: lastId,
          })

          lastId = tileset.id
        })
      })

      elementMap.addSource('elementSource', { type: 'geojson', data: selectedElement })
      elementMap.addLayer({
        id: 'elementLineLayer',
        type: 'line',
        source: 'elementSource',
        paint: {
          'line-color': 'orange',
          'line-width': 10,
        },
      })

      if (selectedElement.geometry.type == 'Point') {
        elementMap.addLayer({
          id: 'elementCircleLayer',
          type: 'circle',
          source: 'elementSource',
          paint: { 'circle-color': 'orange', 'circle-radius': 20 },
        })
      }
    })

    elementMap.once('idle', () => resolve(elementMap))
  })
