/** @jsxImportSource @emotion/react */

/* import { Box } from '@mui/material'

import { useState } from 'react'
import { Tab, Tabs } from '@src/components/Molecules/Tabs'
import { landingPage } from './LandingPage.styles'
import { WelcomeTab } from './WelcomeTab'
import { ContactsTab } from './ContactsTab'
import { ProcessTab } from './ProcessTab'
import { ObjectivesTab } from './ObjectivesTab'
import { FAQTab } from './FAQTab' */
import { LegacyLandingPage } from './LegacyLandingPage'

export const LandingPage = () => {
  return <LegacyLandingPage />
  /* const [selectedTab, setSelectedTab] = useState(0)

  const tabs: Tab[] = [
    {
      label: 'Welcome',
      content: <WelcomeTab />,
    },
    {
      label: 'Contacts',
      content: <ContactsTab />,
    },
    {
      label: 'The Process',
      content: <ProcessTab />,
    },
    {
      label: 'Project Objectives',
      content: <ObjectivesTab />,
    },
    {
      label: 'FAQs',
      content: <FAQTab />,
    },
  ]

  return (
    <Box css={landingPage()}>
      <Tabs
        tabs={tabs}
        tabIndex={selectedTab}
        handleChangeTab={(newTabIndex) => {
          setSelectedTab(newTabIndex)
        }}
      />
    </Box>
  ) */
}
