import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { riskSideDrawerTab } from '@src/routes/RiskPage'

export const riskSideDrawerTypes = [
  'empty',
  'scenario_option_detail',
  'map_information',
  'asset_detail',
  'action_plan',
] as const
export type RiskSideDrawerType = (typeof riskSideDrawerTypes)[number]

export type RiskSideDrawerLayerTabs = 'layers' | 'risk' | 'selection'

export interface RiskSideDrawerState {
  isOpen: boolean
  infoLayerFocus: RiskSideDrawerLayerTabs
  tab?: riskSideDrawerTab
}

const initialState: RiskSideDrawerState = {
  isOpen: false,
  infoLayerFocus: 'layers', // 'elementlayers' | 'hazardlayers'
  tab: undefined,
}

export const riskSideDrawerSlice = createSlice({
  name: 'riskSideDrawerSlice',
  initialState,
  reducers: {
    setSideDrawerTab: (state, action: PayloadAction<RiskSideDrawerState['tab']>) => {
      if (action.payload === state.tab) return state
      state.tab = action.payload
    },
    setSideDrawerInfoLayerFocus: (
      state,
      action: PayloadAction<{ layerFocus: RiskSideDrawerLayerTabs; isOpen?: boolean }>,
    ) => {
      const { layerFocus, isOpen } = action.payload
      if (isOpen !== undefined) state.isOpen = isOpen
      // tab should default back to informative panel if we're changing the info layer focus
      if (state.tab) state.tab = 'Risk'
      state.infoLayerFocus = layerFocus
    },
    setSideDrawerIsOpen: (state, action: PayloadAction<boolean>) => {
      if (action.payload === state.isOpen) return state
      state.isOpen = action.payload
    },
  },
})

export const { setSideDrawerInfoLayerFocus, setSideDrawerIsOpen, setSideDrawerTab } =
  riskSideDrawerSlice.actions
export const riskSideDrawerReducer = riskSideDrawerSlice.reducer
