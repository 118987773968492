import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

import { MainButton, MoreMenuSize } from './MoreMenu'

export const moreMenuContainer = ({
  theme,
  size,
  showButtons,
  mainButton,
  showOutline,
}: {
  theme: Theme
  size: MoreMenuSize
  showButtons: boolean
  mainButton?: MainButton
  showOutline?: boolean
}) => css`
  width: 30px;
  height: 30px;
  ${showOutline && 'box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1)'}; // matches mapbox style

  width: ${size === 'medium' && theme.spacing(6)};
  height: ${size === 'medium' && theme.spacing(6)};

  width: ${size === 'small' && theme.spacing(5)};
  height: ${size === 'small' && theme.spacing(5)};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${mainButton ? theme.palette.primary.main : theme.palette.white.main};
  border-radius: ${theme.shape.borderRadius}px;

  transition: all 0.2s;

  ${showButtons && 'box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25)'};
  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    background-color: ${mainButton ? theme.palette.primary.dark : theme.palette.white.light};
  }
`

export const moreMenuContentContainer = ({ theme }: { theme: Theme }) => css`
  height: 29px;
  overflow: hidden;

  background-color: ${theme.palette.white.main};

  border-radius: ${theme.shape.borderRadius}px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;

  gap: ${theme.spacing(2)};

  z-index: 10;
`

export const moreMenuExpandedContentContainer = ({ theme }: { theme: Theme }) => css`
  position: absolute;

  left: ${theme.spacing(-1)};
  top: 50%;

  transform: translate(-100%, -50%);
`

export const moreMenuButtonItem = ({
  theme,
  disabled,
  isActive,
}: {
  theme: Theme
  disabled: boolean
  isActive: boolean
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${isActive ? theme.palette.white.light : theme.palette.white.main};
  border-radius: ${theme.shape.borderRadius}px;

  transition: all 0.2s;

  ${!disabled
    ? `&:hover {
    cursor: pointer;
    background-color: ${theme.palette.white.light};
  }`
    : `opacity: 0.6;`};
`

export const moreMenuButtonItemContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  gap: ${theme.spacing(1)};

  &:first-of-type {
    padding-left: ${theme.spacing(1)};
  }

  &:last-of-type {
    padding-right: ${theme.spacing(1)};
  }
`

export const moreMenuButtonTooltip = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`

export const moreMenuCustomIcon = ({ theme, disabled }: { theme: Theme; disabled: boolean }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  & * {
    width: 20px;
    height: 20px;
    color: ${disabled ? theme.palette.grey[300] : theme.palette.grey[700]};

    padding: 0.5px;
  }
`
export const moreMenuMainIcon = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;

  & * {
    width: 24px;
    height: 24px;
    color: ${theme.palette.primary.contrastText};
  }
`
