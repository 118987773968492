import { useState } from 'react'

// This hook is used to manage the loading state of a value
// Value starts as initialValue, is initialized when loading is first set to false
export function useLoadingState<T>(
  initialValue: T,
): [T, (value: T) => void, boolean, (loading: boolean) => void, boolean] {
  const [value, setValue] = useState<T>(initialValue)
  const [loading, setLoadingState] = useState<boolean>(true)
  const [initialized, setInitialized] = useState<boolean>(false)

  const setLoading = (loading: boolean) => {
    setLoadingState(loading)
    if (!loading && !initialized) {
      setInitialized(true)
    }
  }

  return [value, setValue, loading, setLoading, initialized]
}
