/** @jsxImportSource @emotion/react */
import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Icon } from '@src/components/Atoms/Icon'
import { Modal } from '@src/components/Atoms/Modal'
import {
  hazardScenarioDetailsModalContainer,
  hazardScenarioDetailsModalContent,
  hazardScenarioDetailsModalHeader,
  hazardScenarioDetailsModalHeaderInner,
  hazardScenarioDetailsModalHeaderText,
} from './LayerDetailsModal.styles'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { LayerDetailsReport } from '@src/components/Molecules/RiskSideDrawerContent/data_fetchers/layerDetailsFetcher'
import { LayerInfo } from '@src/components/Molecules/RiskSideDrawerContent/tabs/InformativePanel/LayersTab/InfoAccordions/LayerInfo'

export interface LayerDetailsModalProps {
  layer?: MapLayer
  isOpen: boolean
  layerDetails: LayerDetailsReport[] | null
  handleClose: () => void
}

export const LayerDetailsModal = ({
  isOpen,
  layer,
  layerDetails,
  handleClose,
}: LayerDetailsModalProps) => {
  const theme = useTheme()

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box css={hazardScenarioDetailsModalContainer()}>
        <Box css={hazardScenarioDetailsModalHeader({ theme })}>
          <Box css={hazardScenarioDetailsModalHeaderInner()}>
            <Box css={hazardScenarioDetailsModalHeaderText({ theme })}>
              <Icon iconName="Info" />
              <Typography variant="h6" fontWeight={600}>
                Layer Details
              </Typography>
            </Box>

            <IconButton onClick={handleClose}>
              <Icon iconName="Close" />
            </IconButton>
          </Box>
        </Box>
        <Box css={hazardScenarioDetailsModalContent({ theme })}>
          {layer && <LayerInfo layerDetails={layerDetails} />}
        </Box>
      </Box>
    </Modal>
  )
}
