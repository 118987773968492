/** @jsxImportSource @emotion/react */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import { useId } from 'react'

export type DropdownProps = {
  handleChange: (event: SelectChangeEvent) => void
  label?: string
  value: string
  children: JSX.Element | React.ReactNode
  disabled?: boolean
  color?: 'primary' | 'secondary' | 'uintel'
}

export const Dropdown = ({
  handleChange,
  label,
  value,
  children,
  disabled = false,
  color = 'primary',
}: DropdownProps) => {
  const labelId = useId()
  const theme = useTheme()
  return (
    <FormControl fullWidth style={{ marginTop: '4px' }}>
      <InputLabel
        disabled={disabled}
        id={labelId}
        sx={{ color: `${theme.palette[color].main} !important` }}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        label={label}
        onChange={handleChange}
        labelId={labelId}
        value={value}
        color={color}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export type DropdownItemProps = {
  value: number | string
  label: string
}

export const DropdownItem = ({ value, label }: DropdownItemProps) => (
  <MenuItem key={value} value={value}>
    {label}
  </MenuItem>
)
