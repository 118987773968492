import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const elementExposureRow = ({
  index,
  percentage,
  theme,
}: {
  index: number
  percentage: number
  theme: Theme
}) => css`
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: ${Math.min(10, index) * 24}px;
  transition: top 0.4s, opacity 0.2s;
  opacity: ${index < 10 ? 1 : 0};
  pointer-events: ${index < 10 ? 'auto' : 'none'};
  user-select: none;
  overflow: hidden;

  &:hover .td .bar-label {
    transform: translateX(-${percentage * 2 - 100}%);
    box-shadow: -4px 0 4px 0 ${theme.palette.white.main};
    .metrics {
      max-width: 100px;
      opacity: 1;
    }
  }
  .td {
    padding-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 130%;
    margin-right: -16px;
    position: relative;

    .bar {
      transition: width 0.4s;
      width: ${percentage * 0.8}%;
      height: 16px;
      background-color: ${theme.palette.grey[400]};
      margin-right: 8px;
      flex-shrink: 0;
    }

    .bar-label {
      position: absolute;
      font-size: 12px;
      left: ${percentage * 0.8}%;
      background-color: ${percentage > 0.5 ? theme.palette.white.main : 'transparent'};
      padding: 2px 0 4px 4px;
      margin: 0 4px;
      color: ${theme.palette.grey[600]};
      white-space: nowrap;
      transition: margin-left 0.4s, opacity 0.2s, color 0.2s, left 0.4s, transform 0.2s;

      .metrics {
        display: inline-block;
        max-width: 0;
        overflow: hidden;
        opacity: 0;
        transform: translate(2px, 2px);
        transition: max-width 0.4s, opacity 0.2s;
      }
    }
  }
`

export const elementExposureList = (rows: number, exposurePercIsLoading: boolean) => css`
  width: 100%;
  height: ${Math.min(10, rows) * 24}px;
  margin-top: 8px;
  position: relative;
  transition: opacity 0.3s;
  opacity: ${exposurePercIsLoading ? 0.3 : 1};
`
