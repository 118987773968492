/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material/styles'
import { AnimatePresence, motion } from 'framer-motion'
import { Resizable } from 're-resizable'
import { useCallback, useEffect, useState } from 'react'

import {
  sideDrawer,
  sideDrawerButton,
  sideDrawerContent,
  toggleButtonParent,
} from './SideDrawer.styles'
import { ToggleArrowButton } from '@src/components/Atoms/VerticalThumbTab'
import { useMap } from '@contexts/RiskMapContext'

const noCursorClassName = 'pointer-events-none'

export interface SideDrawerProps {
  isOpen: boolean
  side?: 'left' | 'right' | 'top' | 'bottom'
  handleToggle: React.Dispatch<React.SetStateAction<boolean>>
  children: JSX.Element | React.ReactNode
  initialWidth?: number
  tabIcons?: string[]
  tabs?: string[]
  tab?: string
  color?: string
  setTab?: (tabIcon: string) => void
  handleWidthChange?: (width: number) => void
}

export const SideDrawer = ({
  isOpen,
  side = 'left',
  handleToggle,
  initialWidth = 500,
  children,
  tabs = [],
  tabIcons = [],
  tab,
  setTab,
  color,
  handleWidthChange,
}: SideDrawerProps) => {
  const theme = useTheme()
  const [width, setWidth] = useState(initialWidth)
  const { setMapPaddingIsChanging } = useMap()

  // Because both tab & tabIcons are in the same order, we can use the index of a tab to get its icon
  const getTabIcon = useCallback(
    (tab: string) => {
      if (!tabIcons || !tabs) return
      return tabIcons[tabs.indexOf(tab)]
    },
    [tabs, tabIcons],
  )

  // Handles the different states of the buttons, i.e. if it's active, open, etc
  const onButtonClick = useCallback(
    (clicked_tab: string) => {
      if (tab === clicked_tab || !isOpen) {
        handleToggle(!isOpen)
      }
      setTab?.(clicked_tab)
    },
    [tab, isOpen, setTab, handleToggle],
  )

  useEffect(() => {
    if (isOpen && tabs && tabs.length > 0 && tab === undefined) {
      setTab?.(tabs[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, tabs])

  // Handles when isOpen is changed
  useEffect(() => {
    handleWidthChange?.(isOpen ? width : 20)
    setMapPaddingIsChanging({
      left: false,
      right: false,
      top: false,
      bottom: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, isOpen])

  useEffect(() => {
    if (tabs && tab !== undefined) {
      if (!tabs.includes(tab)) {
        if (tabs.length > 0) {
          setTab?.(tabs[0])
        } else {
          handleToggle(false)
        }
      } else {
        setTab?.(tab)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, tabs])

  // if escape key is pressed, close the layers menu
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (isOpen) {
          handleToggle(false)
          event.stopPropagation()
          event.stopImmediatePropagation()
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const arrowDirection = (
    isOpen
      ? {
          left: 'left',
          right: 'right',
          top: 'up',
          bottom: 'down',
        }
      : {
          left: 'right',
          right: 'left',
          top: 'down',
          bottom: 'up',
        }
  )[side] as 'left' | 'right' | 'up' | 'down'

  return (
    <Resizable
      css={sideDrawer({ theme, isOpen, color })}
      minHeight="100%"
      maxWidth={isOpen ? '70vw' : '20px'}
      onResizeStart={() => {
        setMapPaddingIsChanging({
          left: side == 'left',
          right: side == 'right',
          top: side == 'top',
          bottom: side == 'bottom',
        })
      }}
      onResizeStop={(_e, _direction, _ref, d) => {
        setWidth((currentWidth) => currentWidth + d.width)
      }}
      defaultSize={{
        width,
        height: '100%',
      }}
      enable={{
        top: side == 'bottom' && isOpen,
        right: side == 'left' && isOpen,
        bottom: side == 'top' && isOpen,
        left: side == 'right' && isOpen,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleClasses={{
        right: isOpen ? 'right-handle' : noCursorClassName,
        left: isOpen ? 'right-handle' : noCursorClassName,
      }}
    >
      {tabs && tabs.length > 0 ? (
        tabs.map((this_tab, index) => (
          <div css={toggleButtonParent({ theme, side })} key={this_tab}>
            <ToggleArrowButton
              css={sideDrawerButton({ theme, index, side })}
              arrowPosition="left"
              color={color}
              side={side}
              onClick={() => onButtonClick(this_tab)}
              initialIcon={this_tab ? getTabIcon(this_tab) : null}
              expanded={isOpen && this_tab === tab}
              label={this_tab}
            />
          </div>
        ))
      ) : (
        <div css={toggleButtonParent({ theme, side })}>
          <ToggleArrowButton
            css={sideDrawerButton({ theme, index: 0, side })}
            side={side}
            color={color}
            arrowPosition={arrowDirection}
            onClick={() => handleToggle(!isOpen)}
          />
        </div>
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            css={sideDrawerContent({ theme })}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </Resizable>
  )
}
