/** @jsxImportSource @emotion/react */

import { useMap } from '@contexts/RiskMapContext'
import { Box, IconButton, Modal, useTheme } from '@mui/material'
import { MapLayer } from '@redux/riskMap/riskMapSlice'
import { RootState } from '@redux/store'
import { DialogLayersPreviewProps } from '@src/components/Molecules/MapView/RiskMapView/AddLayersDialog/LayersPreview'
import { LayerDetailsModal } from '@src/components/Organisms/Modals/LayerDetailsModal'
import { Icon } from '@src/components/Atoms/Icon'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import { buttonsParent } from './LayerControlButtons.styles'
import { LayerDetailsReport } from '@src/components/Molecules/RiskSideDrawerContent/data_fetchers/layerDetailsFetcher'

const initialInformationDetailsModal = {
  isOpen: false,
  content: null,
}

interface InformationDetailsModal {
  isOpen: boolean
  content: JSX.Element | null
}

export const LayerControlButtons = ({
  dialogProps,
  layer,
  layerDetails,
  isDialogPreview,
  handleDeleteLayer,
}: {
  dialogProps?: DialogLayersPreviewProps
  isDialogPreview: boolean
  layerDetails?: LayerDetailsReport[] | null
  layer: MapLayer
  handleDeleteLayer: (props: { selectedLayer: MapLayer }) => void
}) => {
  const theme = useTheme()
  const { setLayerVisibility, toggleLayerInteractivity, map } = useMap()
  const { layers: storeLayers } = useSelector((state: RootState) => state.riskMap)
  const [informationDetailsModal, setInformationDetailsModal] = useState<InformationDetailsModal>(
    initialInformationDetailsModal,
  )

  const onToggleLayerVisibility = useCallback(
    (layer: MapLayer) => {
      if (isDialogPreview) {
        dialogProps?.handleToggleLayerVisibility(layer)
      } else {
        if (!map) return

        setLayerVisibility({
          hideLayers: layer.visible ? [layer] : [],
          showLayers: layer.visible ? [] : [layer],
          allLayers: storeLayers,
          map,
        })
      }
    },
    [dialogProps, isDialogPreview, map, setLayerVisibility, storeLayers],
  )

  const onToggleLayerInteractivity = useCallback(
    (layer: MapLayer) => {
      if (!map) return

      toggleLayerInteractivity({
        layer,
        layers: storeLayers,
      })
    },
    [map, toggleLayerInteractivity, storeLayers],
  )

  return (
    <Box css={buttonsParent({ theme })}>
      {!isDialogPreview && (
        <>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              onToggleLayerVisibility(layer)
            }}
          >
            <Icon
              iconName={layer.visible ? 'EyeVisible' : 'EyeHidden'}
              colour={theme.palette.primary.main}
              size="medium"
            />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              onToggleLayerInteractivity(layer)
            }}
          >
            <Icon
              iconName={layer.interactivityDisabled ? 'SpeakerNotes' : 'SpeakerNotesOff'}
              colour={theme.palette.primary.main}
              size="medium"
            />
          </IconButton>
        </>
      )}
      {isDialogPreview && layerDetails && layerDetails.length > 0 && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            setInformationDetailsModal({
              isOpen: true,
              content: (
                <LayerDetailsModal
                  isOpen={true}
                  layer={layer}
                  layerDetails={layerDetails}
                  handleClose={() => setInformationDetailsModal(initialInformationDetailsModal)}
                />
              ),
            })
          }}
        >
          <Icon iconName="Info" colour={theme.palette.primary.main} size="medium" />
        </IconButton>
      )}
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          handleDeleteLayer({
            selectedLayer: layer,
          })
        }}
      >
        <Icon iconName="Delete" colour={theme.palette.primary.main} size="medium" />
      </IconButton>
      {informationDetailsModal.isOpen &&
        createPortal(<Modal open>{informationDetailsModal.content ?? <></>}</Modal>, document.body)}
    </Box>
  )
}
