import { BASE_API_URL } from '../../app-constants'
import axios from '@src/utils/customAxios'

export async function fetchAssetSentence(assetType: string, assetId: number): Promise<string> {
  try {
    const response = await axios.get<string>(
      `${BASE_API_URL}/asset/sentence/${assetType}/${assetId}`,
    )
    return response.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`error: `, error)
    return ''
  }
}

export async function fetchAssetIdentifyingInformation(
  assetType: string,
  assetId: number,
): Promise<{ [key: string]: string | number } | null> {
  try {
    const response = await axios.get<{ [key: string]: string | number }>(
      `${BASE_API_URL}/asset/identifying_information/${assetType}/${assetId}`,
    )
    return response.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`error: `, error)
    return null
  }
}

export async function fetchHazardTooltipDetails(
  assetType: string,
  hazardScenario: string,
  assetId: number,
) {
  try {
    const hazardDetailsResponse = await axios.get(
      `${BASE_API_URL}/hazard/sentence/${assetType}/${hazardScenario}/${assetId}`,
    )
    return hazardDetailsResponse.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`error: `, error)
    return null
  }
}

export async function fetchHazardScenarioAssetTypeInfo({
  assetType,
  hazardScenario,
  abortController,
}: {
  assetType: string
  hazardScenario: string
  abortController?: AbortController
}) {
  try {
    const hazardScenarioAssetTypeRes = await axios.get(
      `${BASE_API_URL}/layer/info/${assetType}/${hazardScenario}`,
      {
        signal: abortController?.signal ?? undefined,
      },
    )
    return hazardScenarioAssetTypeRes.data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`error: `, error)
    return null
  }
}
