/** @jsxImportSource @emotion/react */
import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { GenericMapContext } from '@contexts/RiskMapContext'

import { Box } from '@mui/material'

import { ViewOrientation } from '@redux/riskMap/riskMapSlice'

import mapboxgl, { LngLat, Map, NavigationControl } from 'mapbox-gl'

import { RootState } from '@redux/store'
import { useSelector } from 'react-redux'
import SelectionTool from '@src/components/Pages/Map/MapTools/SelectionTool'
import { SerializedStyles } from '@emotion/react'
import { addBaseMapStyle } from '../RiskMapView/RiskMapView.utilities'
import { mapViewContainer } from './GenericMapView.styles'

import './GenericMapTooltip.styles.css'

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

export const GenericMapView = ({
  map,
  mapContext,
  inContainerSlot,
  outsideContainerSlot,
  onLoadFunction,
  customStyle,
  setMapInstance,
  mapPaddingRef,
}: {
  map: Map | null
  mapContext: GenericMapContext
  inContainerSlot?: React.ReactNode
  outsideContainerSlot?: React.ReactNode
  onLoadFunction?: (map: Map) => void
  customStyle?: SerializedStyles
  setMapInstance: (map: Map | null) => void
  mapPaddingRef: MutableRefObject<{
    top: number
    right: number
    bottom: number
    left: number
  }>
}) => {
  mapboxgl.accessToken = MAPBOX_TOKEN

  const { clientLocation, updateDrawAreas, mapPaddingIsChanging, mapIs3D, setMapIs3D } = mapContext
  const { drawAreas, style, layers } = useSelector((state: RootState) => state.riskMap)
  const { clientTerrainMap } = useSelector((state: RootState) => state.user)
  const mapContainer = useRef(null)

  const [viewOrientation, setViewOrientation] = useState<ViewOrientation>({
    center: new LngLat(clientLocation.longitude, clientLocation.latitude),
    zoom: clientLocation.zoom,
    pitch: 0,
    bearing: 0,
    maxPitch: 85,
  })

  const { bearing, center, pitch, zoom } = viewOrientation
  const left_offset = Math.max(mapPaddingRef.current.left + 4, 4)
  const right_offset = Math.max(mapPaddingRef.current.right + 4, 4)
  const left_changing = mapPaddingIsChanging.left
  const right_changing = mapPaddingIsChanging.right

  function addMapControls(map: Map) {
    map.addControl(new NavigationControl({ visualizePitch: true }))
    map.addControl(new mapboxgl.ScaleControl({ unit: 'metric', maxWidth: 200 }))
  }

  function handleMapMove(map: Map) {
    const updatedLongitude = +map.getCenter().lng.toFixed(4)
    const updatedLatitude = +map.getCenter().lat.toFixed(4)
    setViewOrientation({
      zoom: +map.getZoom().toFixed(2),
      center: new LngLat(updatedLongitude, updatedLatitude),
      pitch: +map.getPitch().toFixed(2),
      bearing: +map.getBearing().toFixed(2),
    })
    setMapIs3D(map.getPitch() > 5)
  }

  function initiateMap(mapContainer: HTMLElement) {
    const mapbox = new Map({
      container: mapContainer,
      maxPitch: 85,
      style,
      center,
      bearing,
      pitch,
      zoom,
      attributionControl: false,
    })
    if (mapPaddingRef.current) {
      mapbox.setPadding(mapPaddingRef.current)
    }

    mapbox.addControl(new mapboxgl.AttributionControl(), 'bottom-left')

    mapbox.on('style.load', async () => {
      if (drawAreas.length) SelectionTool.reAddToMap(mapbox)
      addBaseMapStyle(mapbox, style)
      if (onLoadFunction) onLoadFunction(mapbox)
    })

    mapbox.on('load', async () => {
      /* Store the map instance on state to be used by other functions */
      setMapInstance(mapbox)
    })

    mapbox.on('moveend', () => {
      handleMapMove(mapbox)
    })
    return mapbox
  }

  useEffect(() => {
    if (!map || !clientTerrainMap) return
    if (Object.keys(map.getStyle()?.sources ?? {}).includes('ui-client-raster-dem')) return

    const formattedUrl = clientTerrainMap.tile_url.replace(
      'VITE_LINZ_API_KEY',
      import.meta.env.VITE_LINZ_API_KEY,
    )
    map.addSource('ui-client-raster-dem', {
      type: 'raster-dem',
      tiles: [formattedUrl],
      tileSize: clientTerrainMap.tileSize ?? 64,
      maxzoom: clientTerrainMap.maxzoom ?? 14,
    })
    map.setTerrain({
      source: 'ui-client-raster-dem',
      exaggeration: 0.0003,
    })
  }, [clientTerrainMap, style, map])

  /* First render or when mapStyle changes */
  useEffect(() => {
    if (!mapContainer.current) return

    const mapbox = initiateMap(mapContainer.current)
    addMapControls(mapbox)

    return () => {
      mapbox.remove()
      setMapInstance(null)
    }
    /* useEffect dependencies are ignored in order to work with Mapbox render cycles */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style])

  // Clear drawareas when changing pages
  useEffect(() => {
    return () => {
      updateDrawAreas({ drawAreas: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logMapLocation = () => {
    if (!map) return
    if (!viewOrientation.center) return
    const center = viewOrientation.center as LngLat
    // eslint-disable-next-line no-console
    console.log({
      latitude: center.lat,
      longitude: center.lng,
      zoom: viewOrientation.zoom,
      pitch: viewOrientation.pitch,
      bearing: viewOrientation.bearing,
    })

    // eslint-disable-next-line no-console
    console.log(
      'asset layers:',
      layers
        .filter((layer) => layer.layerType == 'asset')
        .map((layer) => layer.type)
        .join(','),
    )
    // eslint-disable-next-line no-console
    console.log(
      'hazard layers:',
      layers
        .filter((layer) => layer.layerType == 'hazard')
        .map((layer) => layer.assetTag)
        .join(','),
    )
    // eslint-disable-next-line no-console
    console.log(
      'info layers:',
      layers
        .filter((layer) => layer.layerType == 'information')
        .map((layer) => layer.id)
        .join(','),
    )
  }

  return (
    <>
      <Box
        css={[
          mapViewContainer(left_offset, right_offset, left_changing, right_changing, mapIs3D),
          customStyle,
        ]}
        onAuxClick={logMapLocation}
      >
        <div ref={mapContainer} style={{ height: '100%' }} />
        {inContainerSlot}
      </Box>
      {outsideContainerSlot}
    </>
  )
}
