/** @jsxImportSource @emotion/react */
import { GenericMapContext, useMap } from '@contexts/RiskMapContext'
import { RootState } from '@redux/store'
import { useSnackbars } from '@contexts/SnackbarContext'
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Filter,
  Fullscreen,
  FullscreenExit,
  HighlightAlt,
  Print,
  Straighten,
} from '@mui/icons-material'
import { updateMapStateProperty } from '@redux/riskMap/riskMapSlice'
import MeasuringTool, { Measurement, MeasuringToolOutput } from './MeasuringTool'
import SelectionTool, { SelectionArea, SelectionToolOutput } from './SelectionTool'
import { createPortal } from 'react-dom'
import { MapToolsPortal } from './MapToolsPortal'
import { Box } from '@mui/material'
import { MapToolsContainer, tutorialMaptoolsLocator } from './MapToolsPortal.styles'
import { RegionFilter } from '@src/components/Molecules/RegionFilter'
import { useTutorialContext } from '@contexts/TutorialContext'
import { roundForDisplay } from '@src/components/Molecules/Charts/utils/utils'
import { generateVulnerabilityPalette } from '@src/components/Molecules/MapView/RiskMapView/RiskMapView.utilities'
import {
  MoreMenu,
  MoreMenuButton,
  MoreMenuOpenState,
} from '@src/components/Molecules/MapView/RiskMapView/MoreMenu/MoreMenu'
import { usePrioritisationMap } from '@contexts/PrioritisationMapContext'

const MapTools = ({
  mapOverlayRef,
  format,
  mapContext,
}: {
  mapOverlayRef: RefObject<HTMLDivElement>
  format: 'prioritisation' | 'risk'
  mapContext: GenericMapContext
}) => {
  const {
    clientName,
    clientDisplayName,
    logos,
    theme: userTheme,
  } = useSelector((state: RootState) => state.user)

  const {
    layers: riskLayers,
    legendsData,
    isFullScreen,
    drawAreas: riskDrawAreas,
  } = useSelector((state: RootState) => state.riskMap)

  const { layers: prioritisationLayers, drawAreas: prioritisationDrawAreas } = useSelector(
    (state: RootState) => state.prioritisationMap,
  )

  const layers = format == 'risk' ? riskLayers : prioritisationLayers
  const drawAreas = format == 'risk' ? riskDrawAreas : prioritisationDrawAreas

  const { tab } = useSelector((state: RootState) => state.sideDrawer)
  const vulnerabilityPalette = generateVulnerabilityPalette(legendsData, 'vulnerability')
  const dispatch = useDispatch()

  const { map, updateDrawAreas } = mapContext
  const { displaySnackbar } = useSnackbars()
  const { openMapToolbar, tutorialActive } = useTutorialContext()

  const [isSelecting, setIsSelecting] = useState(false)
  const [hasSelection, setHasSelection] = useState(false)
  const [isMeasuring, setIsMeasuring] = useState(false)
  const [measurement, setMeasurement] = useState<Measurement>({
    distance: '0 m',
    area: null,
    perimeter: null,
  })
  const [selectionArea, setSelectionArea] = useState<SelectionArea>({
    area: null,
    perimeter: null,
  })
  const expanding = useMemo(() => format !== 'prioritisation', [format])

  const deleteSelection = useCallback(() => {
    if (!map) return
    SelectionTool.deleteSelectionArea(map)
  }, [map])

  const handleExportToPdf = useCallback(async () => {
    if (!map) return

    displaySnackbar({ message: 'Exporting to PDF', type: 'info' })

    try {
      const { exportMapToPdf } = await import('./ExportMap/mapExporter')
      await exportMapToPdf(
        map,
        clientDisplayName,
        layers,
        legendsData,
        logos,
        userTheme.primary.main,
        format,
      )
      displaySnackbar({ message: 'PDF exported', type: 'success' })
    } catch (error) {
      displaySnackbar({ message: 'Error exporting to PDF', type: 'error' })
    }
  }, [
    clientDisplayName,
    displaySnackbar,
    format,
    layers,
    legendsData,
    logos,
    map,
    userTheme.primary.main,
  ])

  const handleExportToClipboard = useCallback(async () => {
    if (!map) return
    const { exportMapToClipboard } = await import('./ExportMap/mapExporter')
    await exportMapToClipboard(map, vulnerabilityPalette)
    displaySnackbar({ message: 'Copied to clipboard', type: 'success' })
  }, [displaySnackbar, map, vulnerabilityPalette])

  const buttons: MoreMenuButton[] = useMemo(() => {
    // Safari browsers require daft async handling to be able to copy to the clipboard
    // It's possible to get it going but would require a significant rebuild of
    // already quite fragile code - if you're keen to get it to work then please be my guest - KP
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    // Doesn't work on Firefox - no appetite to fix.
    const isFirefox = /mozilla.*?gecko.*?firefox/i.test(navigator.userAgent)
    const copyButton: MoreMenuButton[] =
      isSafari || isFirefox
        ? []
        : [
            {
              title: 'Copy map as image to clipboard',
              handleClick: handleExportToClipboard,
              icon: <Filter className="tutorial-copy-map" />,
            },
          ]

    const selectElementsButton: MoreMenuButton[] = [
      {
        title: 'Select elements',
        handleClick: () => {
          if (hasSelection) deleteSelection()
          setIsSelecting(true)
          setIsMeasuring(false)
        },
        icon: <HighlightAlt className="tutorial-polygon-draw" />,
        disabled: !(clientName && !isSelecting),
      },
    ]

    const measureButton: MoreMenuButton[] = [
      {
        title: 'Measure distances and area',
        handleClick: () => {
          setIsMeasuring(true)
          setIsSelecting(false)
        },
        icon: <Straighten className="tutorial-measure-tool" />,
        disabled: isSelecting,
      },
    ]

    const fullscreenButton: MoreMenuButton[] = [
      {
        title: isFullScreen ? 'Exit full screen' : 'Full screen',
        handleClick: () => {
          dispatch(updateMapStateProperty({ isFullScreen: !isFullScreen }))

          if (!isFullScreen) {
            document.dispatchEvent(new CustomEvent('EnteringFullScreen'))
          }
          // Toggle fullscreen
          if (document.fullscreenElement) {
            document.exitFullscreen()
          } else {
            document.documentElement.requestFullscreen()
          }
        },
        icon: isFullScreen ? (
          <FullscreenExit className="tutorial-fullscreen-map" />
        ) : (
          <Fullscreen className="tutorial-fullscreen-map" />
        ),
      },
    ]

    const exportToPdfButton = [
      {
        title: `${clientName ? 'Print map to PDF' : 'Login required'}`,
        handleClick: handleExportToPdf,
        icon: <Print className="tutorial-save-map" />,
        disabled: !clientName,
      },
    ]

    if (tab === 'Stories') {
      return [...exportToPdfButton, ...copyButton, ...fullscreenButton]
    }

    if (format === 'risk')
      return [
        ...selectElementsButton,
        ...exportToPdfButton,
        ...copyButton,
        ...measureButton,
        ...fullscreenButton,
      ]

    if (format === 'prioritisation') return [...exportToPdfButton, ...copyButton]

    return []
  }, [
    handleExportToClipboard,
    tab,
    clientName,
    handleExportToPdf,
    isSelecting,
    isFullScreen,
    hasSelection,
    deleteSelection,
    dispatch,
    format,
  ])
  const regionsEnabled = useMemo(
    () => tab !== 'Stories' && format !== 'prioritisation',
    [format, tab],
  )

  // Watch for fullscreenchange - this does not trigger if user pressed F11
  useEffect(() => {
    function onFullscreenChange() {
      dispatch(updateMapStateProperty({ isFullScreen: Boolean(document.fullscreenElement) }))
    }

    document.addEventListener('fullscreenchange', onFullscreenChange)

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
  }, [dispatch])

  // Close toolbar when entering fullscreen
  const moreMenuOpenRef = useRef<MoreMenuOpenState>(null)
  useEffect(() => {
    function onEnterFullscreen() {
      moreMenuOpenRef.current?.setOpen(false)
    }

    document.addEventListener('EnteringFullScreen', onEnterFullscreen)

    return () => document.removeEventListener('EnteringFullScreen', onEnterFullscreen)
  }, [])

  // Let tutorial control toolbar
  useEffect(() => {
    if (!tutorialActive) return
    if (openMapToolbar) moreMenuOpenRef.current?.setOpen(true)
  }, [openMapToolbar, tutorialActive])

  // If user leaves page while drawing for example isDrawing is reset to false but
  // canInteractWithLayers redux property remains false when it should be true
  // this useEffect picks up on this and also reacts to isDrawing and isMeasuring state changes above.
  useEffect(() => {
    dispatch(updateMapStateProperty({ canInteractWithLayers: !(isSelecting || isMeasuring) }))
    if (!map || !map.getCanvas()) return

    // So that the mouse pointer gets set correctly first
    // deactivate all tools and then active the selected one
    MeasuringTool.deactivateMeasuringTool(map)
    SelectionTool.deactivateSelectionTool(map)
    if (isMeasuring) MeasuringTool.activateMeasuringTool(map, setMeasurement)
    if (isSelecting)
      SelectionTool.activateSelectionTool(
        map,
        updateDrawAreas,
        setIsSelecting,
        setHasSelection,
        displaySnackbar,
        setSelectionArea,
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelecting, isMeasuring])

  useEffect(() => {
    // This is a bit of a hack.
    // If they navigate away from the Risk Map with an active polygon tool and return
    // there's no way to properly recover the state of the SelectionTool due to it being
    // an awful mix of plain old js and react.
    // When there are any draw areas and the hasSelection flag is not set this means the
    // SelectionTool is in the wrong state so clear all the areas and remove all the
    // SelectionTool layers and source.
    if (map && !hasSelection && drawAreas.length > 0) {
      if (map.getLayer('selectionToolLineLayer')) map.removeLayer('selectionToolLineLayer')
      if (map.getLayer('selectionToolAreaLayer')) map.removeLayer('selectionToolAreaLayer')
      if (map.getSource('selectionToolSource')) map.removeSource('selectionToolSource')

      updateDrawAreas({ drawAreas: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawAreas, map])
  return (
    <>
      <MoreMenu
        buttons={buttons}
        size="large"
        moreButtonIcon="Spanner"
        showOutline
        defaultOpen
        ref={moreMenuOpenRef}
        disableExpanding={!expanding}
      >
        {regionsEnabled ? <RegionFilter mapContext={mapContext} /> : undefined}
      </MoreMenu>
      <Box css={tutorialMaptoolsLocator} className="tutorial-maptools" />
      {(isMeasuring || isSelecting || hasSelection) &&
        mapOverlayRef.current &&
        createPortal(
          <MapToolsPortal>
            <Box css={MapToolsContainer}>
              {(isSelecting || hasSelection) && (
                <SelectionToolOutput
                  handleClose={() => deleteSelection()}
                  selectionArea={selectionArea}
                  isSelecting={isSelecting}
                />
              )}
              {isMeasuring && (
                <MeasuringToolOutput
                  handleClose={() => setIsMeasuring(false)}
                  measurement={measurement}
                />
              )}
            </Box>
          </MapToolsPortal>,
          mapOverlayRef.current,
        )}
    </>
  )
}

export function formatDistance(metres: number): string {
  if (metres < 1000) return `${roundForDisplay(metres, 'm')} m`

  const km = metres / 1000

  return `${roundForDisplay(km, 'km')} km`
}

export function formatArea(squareMetres: number): string {
  const OneMillionHecatares = 1e10
  if (squareMetres < OneMillionHecatares) {
    const hectares = squareMetres / 10000
    return `${roundForDisplay(hectares, 'ha')} ha`
  }

  const sqkm = squareMetres / 1e6
  return `${roundForDisplay(sqkm, 'km²')} km²`
}

export const RiskMapTools = ({ mapOverlayRef }: { mapOverlayRef: RefObject<HTMLDivElement> }) => {
  const mapContext = useMap()
  return <MapTools mapOverlayRef={mapOverlayRef} format="risk" mapContext={mapContext} />
}

export const PrioritisationMapTools = ({
  mapOverlayRef,
}: {
  mapOverlayRef: RefObject<HTMLDivElement>
}) => {
  const mapContext = usePrioritisationMap()
  return <MapTools mapOverlayRef={mapOverlayRef} format="prioritisation" mapContext={mapContext} />
}
