import { Content } from 'pdfmake/interfaces'

export const GenerateTableOfContents = (): Content[] => {
  return [
    {
      toc: {
        title: { text: 'Table of contents', style: 'h1' },
        //textMargin: [0, 0, 0, 0],
        numberStyle: { font: 'Inter700' },
      },
      pageBreak: 'after',
      pageOrientation: 'landscape',
    },
  ]
}
